import React from 'react';
import { PlayerAlertModes } from '../../types/game';
import RookieButton from '../../components/RookieButton';

interface Props {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    mode: PlayerAlertModes;
    value: string;
}

const PlayerAlertButton = (props: Props) => {
    return (
        <RookieButton
            severity="secondary"
            type="button"
            onClick={props.onClick}
            icon="track_changes"
        >
            {props.mode === 'None' ? (
                'No Target'
            ) : (
                <span>
                    Target: <strong>{props.value}</strong>
                </span>
            )}
        </RookieButton>
    );
};

export default PlayerAlertButton;
