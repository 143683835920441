import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGetOrganisationTeamsQuery } from '../../api/organisations';
import { useEffect, useMemo, useState } from 'react';
import { useGetLicenceGroupLicencesQuery } from '../../api/licences';
import { BaseEntityType } from '../../types/common';
import { Team } from '../../types/team';
import RookieButton from '../../components/RookieButton';

interface Props {
    organisationID: string;
    licenceGroupID: string;
    onSubmit?: (licenceGroupID: string, selected: Team[]) => void;
    onCancel?: () => void;
}

const AssignLicencesForm = ({
    organisationID,
    onCancel,
    onSubmit,
    licenceGroupID,
}: Props) => {
    const [selected, setSelected] = useState<Team[]>([]);
    const [teamsCursor, setTeamsCursor] = useState<string>('');

    const { data: teamsData } = useGetOrganisationTeamsQuery({
        organisationID,
    });

    const { data: licencesRaw } = useGetLicenceGroupLicencesQuery({
        entityType: BaseEntityType.organisations,
        entityID: organisationID,
        licenceGroupID,
    });

    // Auto paginate teams
    useEffect(() => {
        if (
            teamsData?.lastEvaluatedKey &&
            teamsData.lastEvaluatedKey.cursor &&
            teamsData.lastEvaluatedKey.cursor !== teamsCursor
        ) {
            setTeamsCursor(teamsData.lastEvaluatedKey.cursor);
        }
    }, [teamsData, teamsCursor]);

    const licences = licencesRaw?.data || [];

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(licenceGroupID, selected);
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const teams = useMemo(
        () => teamsData?.data.filter((team) => team.teamStatus === 'Active'),
        [teamsData]
    );

    return (
        <>
            <DataTable
                value={teams || []}
                selection={selected}
                selectionMode="checkbox"
                onSelectionChange={(e) => setSelected(e.value)}
                dataKey="teamID"
                rowClassName={(data) =>
                    licences.find((o) => o.entityID === data.teamID)
                        ? 'p-disabled'
                        : ''
                }
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    selectionMode="multiple"
                    headerStyle={{ width: '3em' }}
                ></Column>
                <Column field="teamName" header="Team"></Column>
            </DataTable>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    textAlign: 'right',
                    marginTop: '20px',
                }}
            >
                <RookieButton
                    label="Cancel"
                    severity="secondary"
                    onClick={handleCancel}
                />
                <RookieButton
                    label="Assign"
                    onClick={handleSubmit}
                    disabled={selected.length <= 0}
                />
            </div>
        </>
    );
};

export default AssignLicencesForm;
