import { useParams } from 'react-router-dom';

import { useGetEventQuery } from '../../api/events';

import { Skeleton } from 'primereact/skeleton';

import { getEntityFromParam } from '../../util/helper';
import { useMemo } from 'react';

const EventBreadcrumb = () => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const { data, isLoading, isError } = useGetEventQuery(
        {
            // @ts-expect-error
            entityType: activeEntity?.entityType,
            // @ts-expect-error
            entityID: activeEntity?.entityID,
            eventID: params.eventID || '',
        },
        { skip: !activeEntity }
    );

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;

    if (isError) return 'Event';

    return data?.data?.eventName;
};
export default EventBreadcrumb;
