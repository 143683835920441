import { useContext, useMemo, useState } from 'react';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';

import { useGetEntityRolesQuery } from '../../api/core';
import {
    useGetStaffDetailsSingleQuery,
    useUpdateStaffMutation,
} from '../../api/staff';

import { ToastContext } from '../../contexts/ToastContext';

import { getEntityFromParam } from '../../util/helper';

import { ReactComponent as AvatarPlaceholder } from '../../assets/images/avatar-placeholder.svg';
import ErrorDisplay from '../../components/ErrorDisplay';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import List from '../../components/List';
import ListDivider from '../../components/ListDivider';
import ListHeader from '../../components/ListHeader';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';

import { ERROR_TYPES } from '../../types/common';
import { EntityRole } from '../../types/roles';
import { StaffFormData } from '../../types/team';

interface Props {
    staffID?: string | null;
    canEditRoles?: boolean;
}

const StaffPane = (props: Props) => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const toast = useContext(ToastContext);

    const [showRoles, setShowRoles] = useState(false);

    const { data, isLoading, isError, isFetching } =
        useGetStaffDetailsSingleQuery(
            {
                // @ts-expect-error entityType param may not exist
                entityType: activeEntity?.entityType,
                // @ts-expect-error entityID param may not exist
                entityID: activeEntity?.entityID,
                userID: props.staffID || '',
            },
            { skip: !props.staffID || !activeEntity }
        );

    const { data: staffData } = useGetEntityRolesQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not  exist
            entityID: activeEntity?.entityID,
            assignable: true,
        },
        { skip: !activeEntity }
    );

    const [updateStaff] = useUpdateStaffMutation();

    const staff = data?.data;

    const isOwner = staff?.roles.some(
        (role) =>
            role.roleName === 'Teams Owner' ||
            role.roleName === 'Organisations Owner'
    );

    const initialValues = {
        roles: staff?.roles.map((role) => role.roleID),
    } as StaffFormData;

    if (isLoading || isFetching) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return (
            <ErrorDisplay
                title="An error has occurred"
                errorType={ERROR_TYPES.somethingsWrong}
            />
        );
    }

    if (!staff) {
        return (
            <ErrorDisplay
                title="No staff found"
                errorType={ERROR_TYPES.notFound}
            />
        );
    }

    const handleSubmitRoles = (
        data: FormikValues,
        helpers: FormikHelpers<StaffFormData>
    ) => {
        const { setSubmitting } = helpers;

        setSubmitting(true);

        updateStaff({
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            userID: props.staffID || '',
            ...data,
        })
            .then((response) => {
                if ('error' in response) {
                    if (toast?.current) {
                        toast.current.show({
                            severity: 'warn',
                            summary: `Error`,
                            // @ts-ignore
                            detail: response.error.data.error,
                        });
                    }
                } else {
                    if (toast?.current) {
                        toast.current.show({
                            severity: 'success',
                            summary: `Success`,
                            detail: `Roles for ${staff.firstName} have been updated.`,
                        });
                    }
                    setShowRoles(false);
                }
            })
            .catch(() => {
                if (toast?.current) {
                    toast.current.show({
                        severity: 'warn',
                        summary: `Error`,
                        detail: 'An error has occurred, please try again.',
                    });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <div className="profile-header">
                <div className="profile-avatar p-overlay-badge">
                    {staff.userDetails.picture ? (
                        <img src={staff.userDetails.picture} alt="" />
                    ) : (
                        <AvatarPlaceholder />
                    )}
                </div>
                <h3 className="profile-title">
                    {staff.firstName + ' ' + staff.lastName}
                </h3>
                <p className="profile-caption">{staff.userDetails.email}</p>
            </div>
            <List>
                <ListDivider />
                <ListHeader>Team</ListHeader>
                <ListItem overline="Entity" title={staff.entityName} />
                <ListItem
                    overline="Roles"
                    title={staff.roles.map((r) => r.roleName).toString()}
                    end={
                        props.canEditRoles &&
                        !isOwner && (
                            <RookieButton
                                icon="edit"
                                text
                                onClick={() => setShowRoles(true)}
                            />
                        )
                    }
                />
                <ListDivider />
                <ListHeader>Activity</ListHeader>
                <ListItem
                    overline="Created"
                    title={format(new Date(staff.createdAt), 'dd/MM/yyyy')}
                />
                <ListItem
                    overline="Last Login"
                    title={format(new Date(staff.lastEdited), 'dd/MM/yyyy')}
                />
            </List>
            <Dialog
                header="Edit Roles"
                visible={showRoles}
                onHide={() => setShowRoles(false)}
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmitRoles}
                    validate={(
                        values: FormikValues
                    ): FormikErrors<StaffFormData> => {
                        if (!values.roles || isEmpty(values.roles)) {
                            return {
                                roles: 'Field cannot be blank',
                            };
                        }
                        return {};
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        errors,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormFields style={{ padding: '0' }}>
                                    <FormGroup
                                        error={errors.roles}
                                        showError={!!errors.roles}
                                    >
                                        <List>
                                            {staffData?.data
                                                .filter(
                                                    (roleData) =>
                                                        !roleData.role.roleID.includes(
                                                            'Owner'
                                                        )
                                                )
                                                .map(
                                                    (
                                                        roleData: EntityRole,
                                                        index: number
                                                    ) => {
                                                        const {
                                                            roleID,
                                                            roleName,
                                                            roleDescription,
                                                        } = roleData.role;

                                                        return (
                                                            <ListItem
                                                                key={roleID}
                                                                multiline
                                                                title={
                                                                    <label
                                                                        style={{
                                                                            fontSize:
                                                                                'inherit',
                                                                        }}
                                                                        htmlFor={`role-${index}`}
                                                                    >
                                                                        {
                                                                            roleName
                                                                        }
                                                                    </label>
                                                                }
                                                                caption={
                                                                    roleDescription
                                                                }
                                                                start={
                                                                    <Checkbox
                                                                        inputId={`role-${index}`}
                                                                        name="roles"
                                                                        value={
                                                                            roleID
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            if (
                                                                                values.roles
                                                                            ) {
                                                                                const checkedRoles =
                                                                                    values.roles.includes(
                                                                                        roleID
                                                                                    )
                                                                                        ? values.roles.filter(
                                                                                              (
                                                                                                  id
                                                                                              ) =>
                                                                                                  id !==
                                                                                                  roleID
                                                                                          )
                                                                                        : [
                                                                                              ...values.roles,
                                                                                              roleID,
                                                                                          ];
                                                                                setFieldValue(
                                                                                    'roles',
                                                                                    checkedRoles
                                                                                );
                                                                            }
                                                                        }}
                                                                        checked={
                                                                            !!(
                                                                                values.roles &&
                                                                                values.roles.includes(
                                                                                    roleID
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                        </List>
                                    </FormGroup>
                                </FormFields>
                                <FormActions
                                    end={
                                        <RookieButton
                                            className={
                                                isSubmitting
                                                    ? 'is-submitting'
                                                    : ''
                                            }
                                            type="submit"
                                            disabled={
                                                isSubmitting || !isEmpty(errors)
                                            }
                                            label="Save"
                                            loading={isSubmitting}
                                        />
                                    }
                                />
                                {isSubmitting && (
                                    <div className="form-overlay">
                                        <ProgressBar mode="indeterminate" />
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
};

export default StaffPane;
