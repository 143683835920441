import { createContext } from 'react';
import { EntityStructure } from '../types/common';
import { DialogProps } from 'primereact/dialog';

interface Context {
    open: ({ entity, modalProps }: { entity: EntityStructure, modalProps?: Partial<DialogProps>}) => void;
    close: () => void;
}

export const PlanContext = createContext<Context | undefined>(undefined);
