import { GameStat, StatConfig, StatTypes } from '../../types/reports';
import { renderResult } from './helpers';

export const columns = [
    'environmentCoeff',
    'integrityScore',
    'fairGameTime',
    'gamesPlayed',
    'matchResult',
    'teamScoringAccuracy',
    'teamGoals',
    'teamBehinds',
    'teamScore',
    'oppositionGoals',
    'oppositionBehinds',
    'oppositionScore',
    'scoreMargin',
    'rotationCount',
];

export const niceFlags = {
    skippedTime: '• Clock Integirty',
    shortLongPeriod: '• Unexpected Period Length',
    playersWithZeroPGT: '• Absent Players Included in Game',
    outsideSchedTime: '• Unexpected Game Start Time',
    forceEndGame: '• Game Not Ended',
};

export const config: { [key: string]: StatConfig } = {
    environmentCoeff: {
        name: 'Enviroment Score',
        shortName: 'ENV',
        type: StatTypes.Count,
        hsArticle: '667e3204d5f6cf2baa1f05c3',
    },
    integrityScore: {
        name: 'Integrity Score',
        shortName: 'INT',
        type: StatTypes.Count,
        hsArticle: '6530a9986ea22a692042cc16',
        flagLabels: [
            'skippedTime',
            'shortLongPeriod',
            'playersWithZeroPGT',
            'outsideSchedTime',
            'forceEndGame',
            'shortLongPeriod',
        ],
    },
    fairGameTime: {
        name: 'Fair Game Time Score',
        shortName: 'FGT',
        type: StatTypes.Count,
        hsArticle: '6530a57b554393421d94c725',
    },
    gamesPlayed: {
        name: 'Total Games Played',
        shortName: 'Games',
        type: StatTypes.Count,
    },
    rotationCount: {
        name: 'Rotation Count',
        shortName: 'ROT',
        description:
            'Number of rotations completed for the game, not including changes made pre game or during period breaks.',
        type: StatTypes.Count,
    },
    wins: {
        name: 'Wins',
        shortName: 'W',
        description: '',
        type: StatTypes.Count,
    },
    losses: {
        name: 'Losses',
        shortName: 'L',
        description: '',
        type: StatTypes.Count,
    },
    draws: {
        name: 'Draws',
        shortName: 'D',
        description: '',
        type: StatTypes.Count,
    },
    teamScoringAccuracy: {
        name: 'Scoring Accuracy',
        shortName: 'ACC',
        description:
            'Percentage of scoring shots (including any missed shots recorded) that resulted in a goal, derived by goals divided by the sum of goals and behinds.',
        type: StatTypes.Percentage,
    },
    teamScore: {
        name: 'Team Score',
        shortName: 'TS',
        description: '',
        type: StatTypes.Count,
    },
    oppositionScore: {
        name: 'Opposition Score',
        shortName: 'OS',
        description: '',
        type: StatTypes.Count,
    },
    scoreMargin: {
        name: 'Score Margin',
        shortName: 'MAR',
        description: '',
        type: StatTypes.Count,
        format: (data: GameStat) => data.teamScore - data.oppositionScore,
    },
    matchResult: {
        name: 'Results',
        shortName: 'Res',
        description: '',
        type: StatTypes.Count,
        format: renderResult, // Implement icons for win, loss, draw
    },
};
