import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getEntityFromParam } from '../../util/helper';
import usePermission from '../../hooks/usePermission';
import { ToastContext } from '../../contexts/ToastContext';
import { PlanContext } from '../../contexts/PlanContext';
import LicencesView from './LicencesView';

import {
    useAssignLicenceMutation,
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
    useGetLicencesQuery,
    useUnAssignLicenceMutation,
} from '../../api/licences';

import { ToastMessage } from 'primereact/toast';

import {
    LicenceGroupInfo,
    LicenceType,
    RookieProduct,
} from '../../types/licences';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { BaseEntityType } from '../../types/common';
import { Permissions } from '../../types/permissions';
import { confirmDialog } from 'primereact/confirmdialog';
import RookieButton from '../../components/RookieButton';
import { useGetEntityQuery } from '../../api/core';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const LicencesContainer = (props: Props) => {
    const { user } = props;

    // Context Hooks
    const planModal = useContext(PlanContext);
    const toast = useContext(ToastContext);

    // Route Hooks
    const navigate = useNavigate();
    const params = useParams();

    const entity = getEntityFromParam(params);

    const { checkPermission } = usePermission(
        params.organisationID || params.teamID || ''
    );

    // API Hooks
    const entityData = useGetEntityQuery(
        // @ts-expect-error
        { entityType: entity?.entityType, entityID: entity?.entityID || '' },
        { skip: !entity }
    );
    const { data: licenceGroupsRaw, isLoading: isLoadingGroups } =
        // @ts-ignore
        useGetLicenceGroupsQuery(entity, { skip: !entity });

    const { data: licencesRaw, isLoading: isLoadingLicences } =
        // @ts-ignore
        useGetLicencesQuery(entity, { skip: !entity });

    const { data: licenceTypes, isLoading: isLoadingTypes } =
        useGetLicenceTypesQuery(
            {
                rookieProduct: RookieProduct.GameDay,
                // @ts-expect-error
                sportID: entityData?.data?.data?.entitySportID,
            },
            { skip: !entityData?.data?.data }
        );

    const [assignLicence] = useAssignLicenceMutation();
    const [unassignLicence] = useUnAssignLicenceMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const showInAppDialog = () => {
        confirmDialog({
            message: `Your subsription is managed via your Apple account. Use the Rookie Me Play app to view or update your subscription.`,
            header: 'Manage on the Play app',
            footer: (confirmProps) => {
                return (
                    <div>
                        <RookieButton
                            label="Ok"
                            onClick={confirmProps.accept}
                        />
                    </div>
                );
            },
        });
    };

    const handleManageBilling = (
        subscriptionID: string,
        inAppPurchase = false
    ) => {
        if (inAppPurchase) {
            showInAppDialog();
        } else if (subscriptionID) {
            navigate(`/u/${user.userID}/subscriptions/${subscriptionID}`);
        }
    };

    const handleUpgrade = (
        licenceGroup: LicenceGroupInfo,
        inAppPurchase = false
    ) => {
        if (inAppPurchase && licenceGroup.price > 0) {
            showInAppDialog();
        } else if (params.teamID) {
            planModal?.open({
                entity: {
                    entityID: params.teamID,
                    entityType: BaseEntityType.teams,
                },
            });
        }
    };

    const handleAssignLicence = (licenceGroupID: string) => {
        if (params.teamID) {
            assignLicence({
                entityID: params.teamID,
                entityType: BaseEntityType.teams,
                licenceGroupID,
                teamsIDList: [params.teamID],
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        detail: 'Successfully applied licence.',
                    });
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        detail: 'Error applying licence.',
                    });
                });
        }
    };

    const handleUnassignLicence = (licenceID: string) => {
        if (params.teamID) {
            unassignLicence({
                entityID: params.teamID,
                entityType: BaseEntityType.teams,
                licenceID,
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        detail: 'Successfully removed licence.',
                    });
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        detail: 'Error removing licence. Please try again.',
                    });
                });
        }
    };

    const licenceGroups = useMemo<LicenceGroupInfo[]>(() => {
        let arr: LicenceGroupInfo[] = [];

        if (licenceGroupsRaw && licenceGroupsRaw?.data.length > 0) {
            licenceGroupsRaw.data.forEach((licenceGroup) => {
                const licenceType: LicenceType | undefined =
                    licenceTypes?.data.find(
                        (type) =>
                            licenceGroup.licenceTypeID === type.licenceTypeID
                    );
                if (!licenceType) {
                    return null;
                }
                const { subscription } = licenceGroup;
                const { product } = licenceType;

                if (product) {
                    const price = product.prices.find(
                        (p) => p.priceID === licenceGroup.priceID
                    );
                    arr.push({
                        id: licenceGroup.licenceGroupID,
                        priceID: price?.priceID || '',
                        productTitle: product.productName,
                        licenceTitle: licenceType.licenceName,
                        price: (price && price.unitAmount / 100) || 0,
                        currency: 'AUD',
                        createdAt: licenceGroup.createdAt,
                        lastEdited: licenceGroup.lastEditedAt,
                        billingPeriod:
                            (price && price.recurring?.interval) || 'unknown', // interval is always month | year
                        renewsAt: subscription
                            ? new Date(subscription.current_period_end * 1000)
                            : null,
                        trialActive:
                            subscription && subscription.trial_end
                                ? new Date().getTime() -
                                      subscription.trial_end * 1000 <
                                  0
                                : false,
                        trialEndsAt:
                            subscription && subscription.trial_end
                                ? new Date(subscription.trial_end * 1000)
                                : null,
                        qtyAvailable:
                            licenceGroup.quantity - licenceGroup.totalAssigned,
                        qtyAssigned: licenceGroup.totalAssigned,
                        qtyTotal: licenceGroup.quantity,
                        status: licenceGroup.status,
                        groupID:
                            licenceGroup.subscriptionID ||
                            licenceGroup.invoiceID ||
                            'unknown',
                        description: product.productDescription,
                        canUpgrade: (price && price.unitAmount < 10000) || true, // hard-code this for now,
                        isOwner:
                            licenceGroup.createdBy === user.userID || false, // hard-code this for now,
                        owner: licenceGroup.createdBy,
                        features: product.productFeatures.map(
                            (feat) => feat.name
                        ),
                        entityID: licenceGroup.entityID,
                        entitySportID: licenceGroup.entitySportID,
                        entityType: licenceGroup.entityType,
                        subscriptionID: licenceGroup.subscriptionID,
                        transactionID: licenceGroup.transactionID,
                    });
                }
            });
        }

        // order by groupID (either subID or invoiceID) - more useful for ass and org
        arr.sort((a, b) =>
            a.groupID === b.groupID ? 0 : a.groupID < b.groupID ? -1 : 1
        );

        return arr;
    }, [licenceGroupsRaw, licenceTypes, user]);
    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    return (
        <LicencesView
            isLoading={isLoadingLicences || isLoadingGroups || isLoadingTypes}
            licences={licencesRaw?.data || []}
            licenceGroups={licenceGroups}
            onUpgrade={handleUpgrade}
            onManageBilling={handleManageBilling}
            onAssignLicence={handleAssignLicence}
            onUnassignLicence={handleUnassignLicence}
            permissions={permissions}
            {...props}
        />
    );
};

export default LicencesContainer;
