import { classNames } from 'primereact/utils';
import React, { useMemo } from 'react';

interface TextProps {
    start?: React.ReactNode;
    end?: React.ReactNode;
    overline?: string | React.ReactNode;
    title?: string | React.ReactNode;
    caption?: string | React.ReactNode;
    supporting?: string | React.ReactNode;
    type: 'link' | 'button' | 'text' | 'list';
    multiline?: boolean;
    align?: 'start' | 'center' | 'end';
    selected?: boolean;
    disabled?: boolean;
    compact?: boolean;
    disableGutters?: boolean;
    disablePadding?: boolean;
    onClick?: any;
}

type ButtonListProps = TextProps & React.ComponentProps<'button'>;
type LinkListProps = TextProps & React.ComponentProps<'a'>;
type ListProps = ButtonListProps | LinkListProps | TextProps;

const ListItem = (props: ListProps) => {
    const Component = useMemo(() => {
        switch (props.type) {
            case 'link':
                return 'a';
            case 'button':
                return 'button';
            case 'list':
                return 'li';
            default:
                return 'div';
        }
    }, [props.type]);

    const containerClass = classNames('list-item-container', {
        'is-multiline': props.multiline,
        'is-compact': props.compact,
        'has-no-padding': props.disablePadding,
        'has-no-gutters': props.disableGutters,
        'is-disabled': props.disabled,
        'is-selected': props.selected,
    });

    return (
        <Component className="list-item" onClick={props.onClick}>
            <div className={containerClass}>
                {props.start && (
                    <div className="list-item-start">{props.start}</div>
                )}
                <div className="list-item-text">
                    {props.overline && (
                        <div className="list-item-overline">
                            {props.overline}
                        </div>
                    )}
                    {props.title && (
                        <div className="list-item-title">{props.title}</div>
                    )}
                    {props.caption && (
                        <div className="list-item-caption">{props.caption}</div>
                    )}
                </div>
                {props.supporting && (
                    <div className="list-item-support">{props.supporting}</div>
                )}
                {props.end && <div className="list-item-end">{props.end}</div>}
            </div>
        </Component>
    );
};

ListItem.defaultProps = {
    type: 'text',
    multiline: false,
};

export default ListItem;
