import { useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '../../api/organisations';
import { Skeleton } from 'primereact/skeleton';

const OrganisationBreadcrumb = () => {
    const { organisationID } = useParams();

    const { data, isLoading, isError } = useGetOrganisationQuery(
        {
            organisationID: organisationID || '',
        },
        {
            skip: !organisationID,
        }
    );

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;

    if (isError) return 'Organisation';

    return data?.data?.organisationName;
};

export default OrganisationBreadcrumb;
