import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Formik,
    FormikErrors,
    FormikHelpers,
    FormikProps,
    FormikValues,
} from 'formik';

import { getEntityFromParam } from '../../util/helper';

// Hooks
import {
    useCreateEventInviteeMutation,
    useGetEventQuery,
} from '../../api/events';

// Components
import { Dropdown } from 'primereact/dropdown';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';

// Types
import { EventInviteeForm, InvitedUser } from '../../types/event';

import { useGetGameRolesQuery } from '../../api/games';
import { useGetStaffDetailsQuery } from '../../api/staff';
import { Checkbox } from 'primereact/checkbox';
import ListItem from '../../components/ListItem';
import { Avatar } from 'primereact/avatar';
import { isEmpty } from 'lodash';
import { Mixpanel } from '../../util/mixpanel';
import usePrivilege from '../../hooks/usePrivilege';
import { GameRole } from '../../types/game';

interface Props {
    eventID?: string;
    formID?: string;
    initialValues: EventInviteeForm;
    onSuccess?: (invite: InvitedUser) => void;
    onError?: (err: any) => void;
}

const requiredFields = ['userID', 'gameRoles'];

const EventInviteForm = ({
    eventID,
    initialValues,
    onSuccess,
    onError,
    formID,
}: Props) => {
    // Route Hooks
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    // Ref Hooks
    const formikRef = useRef<FormikProps<EventInviteeForm>>(null);

    // Privilige Hook
    const { checkLimits } = usePrivilege(
        activeEntity?.entityID,
        activeEntity?.entityType
    );

    // API Hooks
    const [createInvitee] = useCreateEventInviteeMutation();

    const { data: eventRaw, isLoading: loadingEvent } = useGetEventQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            eventID: eventID || '',
        },
        {
            skip: !activeEntity,
        }
    );

    const { data: staffRaw, isLoading: loadingStaff } = useGetStaffDetailsQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
        },
        { skip: !activeEntity }
    );

    const { data: gameRolesRaw, isLoading: loadingGameRoles } =
        useGetGameRolesQuery(
            {
                teamID: activeEntity?.entityID || '',
                eventID: eventID || '',
            },
            {
                skip: !activeEntity,
            }
        );

    const event = useMemo(() => eventRaw?.data, [eventRaw]);
    const users = useMemo(() => staffRaw?.data, [staffRaw]);
    const gameRoles = useMemo(() => gameRolesRaw?.data, [gameRolesRaw]);
    const isLoading = loadingStaff || loadingGameRoles || loadingEvent;

    const gameRoleCounts = useMemo(() => {
        return (
            gameRoles?.reduce((result, curr) => {
                result[curr.role.roleID] = result[curr.role.roleID] || 0;

                event?.eventInvitees.invitees.forEach((invitee) => {
                    invitee.gameRoles.forEach((role) => {
                        if (role === curr.role.roleID) {
                            result[role]++;
                        }
                    });
                });

                return result;
            }, {} as { [key: string]: number }) || {}
        );
    }, [event, gameRoles]);

    const handleSubmit = (
        values: EventInviteeForm,
        helpers: FormikHelpers<EventInviteeForm>
    ) => {
        helpers.setSubmitting(true);

        if (activeEntity && eventID) {
            createInvitee({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                eventID,
                data: values,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response);
                        }
                    } else {
                        Mixpanel.track('Event Invite');
                        // @ts-expect-error
                        if (onSuccess && response?.data?.data[0]) {
                            // @ts-expect-error
                            onSuccess(response.data.data[0]);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    helpers.setSubmitting(false);
                });
        }
    };

    const validate = (values: FormikValues) => {
        let errors: FormikErrors<EventInviteeForm> = {};

        requiredFields.forEach((field) => {
            if (isEmpty(values[field])) {
                errors[field as keyof EventInviteeForm] =
                    'Field cannot be blank';
            }
        });

        return errors;
    };

    const validateGameRole = (gameRole: GameRole, selected: string[]) => {
        const {
            role: { roleID },
        } = gameRole;

        // Total number of time the role has been assigned
        const existingCount =
            event?.eventInvitees.invitees.reduce((result, curr) => {
                if (curr.gameRoles.includes(roleID)) {
                    result++;
                }
                return result;
            }, 0) || 0;

        // Count if role is currently selected
        const selectedCount = selected.includes(roleID) ? 1 : 0;

        switch (roleID) {
            case 'gamesViewer':
                return checkLimits({
                    PLAY_MAX_GAME_VIEWERS:
                        gameRoleCounts.gamesViewer + selectedCount,
                });
            case 'notesKeeper':
                return checkLimits({
                    PLAY_MAX_GAME_NOTEKEEPERS:
                        gameRoleCounts.notesKeeper + selectedCount,
                });
            default:
                // Exclude gamesViewer and notesKeeper role when limiting max delegations
                const excludedDelegations = ['gamesViewer', 'notesKeeper'];

                // Total number of assigned roles
                const totalAssignedRoles = Object.keys(gameRoleCounts)
                    .filter((key) => !excludedDelegations.includes(key))
                    .reduce((result, key) => {
                        return result + gameRoleCounts[key];
                    }, 0);

                // Total number of selected roles
                const totalSelectedRoles =
                    selected.filter(
                        (role) => !excludedDelegations.includes(role)
                    )?.length || 0;

                // Check against max per game and max delegations
                return (
                    existingCount + selectedCount < gameRole.maxPerGame &&
                    checkLimits({
                        PLAY_MAX_GAME_DELEGATIONS:
                            totalAssignedRoles + totalSelectedRoles,
                    })
                );
        }
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
                values,
            }) => {
                return (
                    <form
                        id={formID}
                        className={'p-fluid form'}
                        onSubmit={handleSubmit}
                    >
                        <FormFields>
                            {/* User */}
                            <FormGroup
                                label="Member"
                                htmlFor="userID"
                                error={errors.userID}
                                showError={!!errors.userID && touched.userID}
                            >
                                <Dropdown
                                    id="userID"
                                    name="userID"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.userID}
                                    placeholder="Select member"
                                    emptyMessage={
                                        <ListItem title="No members found." />
                                    }
                                    valueTemplate={
                                        values.userID
                                            ? ({ user }) => {
                                                  return (
                                                      <ListItem
                                                          disableGutters
                                                          disablePadding
                                                          start={
                                                              <Avatar
                                                                  image={
                                                                      user
                                                                          .userDetails
                                                                          .picture
                                                                  }
                                                              />
                                                          }
                                                          title={`${user.firstName} ${user.lastName}`}
                                                          caption={
                                                              user.userDetails
                                                                  .email
                                                          }
                                                      />
                                                  );
                                              }
                                            : null
                                    }
                                    itemTemplate={({ user, disabled }) => {
                                        return (
                                            <ListItem
                                                title={`${user.firstName} ${user.lastName}`}
                                                caption={user.userDetails.email}
                                                selected={
                                                    user.userID ===
                                                    values.userID
                                                }
                                                start={
                                                    <Avatar
                                                        image={
                                                            user.userDetails
                                                                .picture
                                                        }
                                                    />
                                                }
                                                disableGutters
                                                disablePadding
                                                disabled={disabled}
                                            />
                                        );
                                    }}
                                    options={
                                        users
                                            ? users.map((user) => ({
                                                  label: `${user.firstName} ${user.lastName}`,
                                                  value: user.userID,
                                                  user: user,
                                                  disabled:
                                                      event?.eventInvitees.invitees.find(
                                                          (
                                                              invitee: InvitedUser
                                                          ) =>
                                                              invitee.userID ===
                                                              user.userID
                                                      ),
                                              }))
                                            : []
                                    }
                                />
                            </FormGroup>

                            {/* Game Roles */}
                            <FormGroup
                                label="Game Role(s)"
                                htmlFor="gameRoles"
                                error={errors.gameRoles}
                                showError={
                                    !!errors.gameRoles && touched.gameRoles
                                }
                            >
                                <div>
                                    {gameRoles &&
                                        gameRoles.map((gameRole) => {
                                            const {
                                                assignable,
                                                role: {
                                                    roleID,
                                                    roleName,
                                                    roleDescription,
                                                },
                                            } = gameRole;

                                            if (
                                                !assignable ||
                                                !gameRoleCounts
                                            ) {
                                                return null;
                                            }

                                            const disabled =
                                                !validateGameRole(
                                                    gameRole,
                                                    values.gameRoles || []
                                                ) &&
                                                !values?.gameRoles?.includes(
                                                    roleID
                                                );

                                            return (
                                                <ListItem
                                                    key={roleID}
                                                    disabled={disabled}
                                                    multiline={true}
                                                    start={
                                                        <Checkbox
                                                            inputId={roleID}
                                                            name="gameRoles"
                                                            disabled={disabled}
                                                            value={roleID}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            checked={
                                                                values.gameRoles
                                                                    ? values.gameRoles.some(
                                                                          (
                                                                              value
                                                                          ) =>
                                                                              value ===
                                                                              gameRole
                                                                                  .role
                                                                                  .roleID
                                                                      )
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                    title={
                                                        <label htmlFor={roleID}>
                                                            {roleName}
                                                        </label>
                                                    }
                                                    caption={roleDescription}
                                                />
                                            );
                                        })}
                                </div>
                            </FormGroup>
                        </FormFields>
                        <FormActions
                            end={
                                <RookieButton
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        isLoading ||
                                        isEmpty(values.userID) ||
                                        isEmpty(values.gameRoles)
                                    }
                                    loading={isSubmitting}
                                    label="Invite"
                                />
                            }
                        />
                    </form>
                );
            }}
        </Formik>
    );
};

EventInviteForm.defaultProps = {
    initialValues: {
        userID: '',
        gameRoles: [],
    },
};

export default EventInviteForm;
