import { useNavigate, useParams } from 'react-router-dom';

import {
    useGetAssociationOrganisationsQuery,
    useLazyGetAssociationOrganisationsQuery,
    useUpdateAssociationOrganisationMutation,
} from '../../api/associations';

import OrganisationListingView from './OrganisationListingView';
import DocumentHead from '../../components/DocumentHead';

const OrganisationListingContainer = () => {
    const navigate = useNavigate();
    const { organisationID, associationID } = useParams();

    const [updateOrganisation] = useUpdateAssociationOrganisationMutation();
    const [refetch] = useLazyGetAssociationOrganisationsQuery();

    const { data, isLoading, isFetching, isError } =
        useGetAssociationOrganisationsQuery({
            organisationID,
            associationID,
        });

    const handleImportClick = () => {
        const route = organisationID
            ? `/o/${organisationID}/organisations/import`
            : '/organisations/import';

        navigate(route);
    };

    const handleLoadMore = () => {
        const { lastEvaluatedKey } = data;
        if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
            refetch({
                organisationID,
                cursor: lastEvaluatedKey.cursor,
            });
        }
    };

    const handleGenerateCodeClick = (mode, organisation) => {
        updateOrganisation({
            associationID: organisation.associationID,
            organisationID: organisation.organisationID,
            organisationJoinMode: mode,
        });
    };

    return (
        <>
            <DocumentHead
                title="Organisation Listing - Rookie Me Hub"
                description="Organisation listing page"
            />
            <OrganisationListingView
                data={data ? data.data : []}
                isError={isError}
                isLoading={isLoading}
                isFetching={isFetching}
                onImportClick={handleImportClick}
                onGenerateCode={handleGenerateCodeClick}
                onLoadMore={handleLoadMore}
                showPagination={
                    data &&
                    data.lastEvaluatedKey &&
                    data.lastEvaluatedKey.cursor
                }
            />
        </>
    );
};

export default OrganisationListingContainer;
