import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '', {
    debug: process.env.NODE_ENV === 'development',
    persistence: 'localStorage',
});

const defaultProps = {
    App: 'Rookie Me Hub',
};

let actions = {
    identify: (id: string) => {
        mixpanel.identify(id);
    },
    alias: (id: string) => {
        mixpanel.alias(id);
    },
    track: (name: string, props?: any) => {
        mixpanel.track(name, {
            ...defaultProps,
            ...props,
        });
    },
    trackPageView: (props?: any) => {
        mixpanel.track_pageview({
            ...defaultProps,
            ...props,
        });
    },
    people: {
        set: (props?: any) => {
            mixpanel.people.set({
                ...defaultProps,
                ...props,
            });
        },
    },
};

export let Mixpanel = actions;
