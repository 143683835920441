import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';

import { apiEndpoints, prepareHeaders } from './apiEndpoints';

import {
    Season,
    SeasonFormData,
    SeasonResponse,
    SeasonTeam,
    SeasonTeamsResponse,
    SeasonsResponse,
} from '../types/seasons';

export const seasonsApi = createApi({
    reducerPath: 'seasonsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Season'],
    endpoints: (builder) => ({
        getTeamOwnedSeasons: builder.query<
            SeasonsResponse,
            { teamID: string; cursor: string }
        >({
            query: ({ teamID, cursor }) => ({
                url: generatePath(apiEndpoints.getTeamOwnedSeasons.url, {
                    teamID,
                }),
                method: apiEndpoints.getTeamOwnedSeasons.method,
                params: {
                    cursor,
                },
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ seasonID }: Season) => ({
                              type: 'Season' as const,
                              seasonID,
                          })),
                          'Season',
                      ]
                    : ['Season'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];

                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getTeamParticipatingSeasons: builder.query<
            SeasonsResponse,
            { teamID: string; cursor: string }
        >({
            query: ({ teamID, cursor }) => ({
                url: generatePath(
                    apiEndpoints.getTeamParticipatingSeasons.url,
                    {
                        teamID,
                    }
                ),
                method: apiEndpoints.getTeamParticipatingSeasons.method,
                params: {
                    cursor,
                },
            }),
            providesTags: ['Season'],
        }),
        getTeamSeason: builder.query<
            SeasonResponse,
            { teamID: string; seasonID: string }
        >({
            query: ({ teamID, seasonID }) => ({
                url: generatePath(apiEndpoints.getTeamSeason.url, {
                    seasonID,
                    teamID,
                }),
                method: apiEndpoints.getTeamSeason.method,
            }),
            providesTags: ['Season'],
        }),
        createTeamSeason: builder.mutation<Season, SeasonFormData>({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.createTeamSeason.url, {
                    teamID,
                    ...data,
                }),
                method: apiEndpoints.createTeamSeason.method,
                body: data,
            }),
            invalidatesTags: ['Season'],
        }),
        updateTeamSeason: builder.mutation<Season, SeasonFormData>({
            query: ({ teamID, seasonID, ...data }) => ({
                url: generatePath(apiEndpoints.updateTeamSeason.url, {
                    seasonID,
                    teamID,
                    ...data,
                }),
                method: apiEndpoints.updateTeamSeason.method,
                body: data,
            }),
            invalidatesTags: ['Season'],
        }),
        upsertTeamSeason: builder.mutation<
            Season,
            { seasonID?: string; teamID: string }
        >({
            query: ({ teamID, seasonID, ...data }) => ({
                url: generatePath(
                    seasonID
                        ? apiEndpoints.updateTeamSeason.url
                        : apiEndpoints.createTeamSeason.url,
                    { seasonID, teamID }
                ),
                method: seasonID
                    ? apiEndpoints.updateTeamSeason.method
                    : apiEndpoints.createTeamSeason.method,
                body: data,
            }),
            invalidatesTags: ['Season'],
        }),
        deleteTeamSeason: builder.mutation<
            void,
            { seasonID: string; teamID: string }
        >({
            query: ({ teamID, seasonID }) => ({
                url: generatePath(apiEndpoints.deleteTeamSeason.url, {
                    seasonID,
                    teamID,
                }),
                method: apiEndpoints.deleteTeamSeason.method,
            }),
            invalidatesTags: ['Season'],
        }),

        /**
         *
         * Association Seasons
         *
         **/

        getAssociationSeasons: builder.query<
            SeasonsResponse,
            { associationID: string; cursor: string }
        >({
            query: ({ associationID, cursor }) => ({
                url: generatePath(apiEndpoints.getAssociationSeasons.url, {
                    associationID,
                }),
                method: apiEndpoints.getAssociationSeasons.method,
                params: {
                    cursor,
                },
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ seasonID }: Season) => ({
                              type: 'Season' as const,
                              seasonID,
                          })),
                          'Season',
                      ]
                    : ['Season'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];

                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getAssociationSeason: builder.query<
            SeasonResponse,
            { associationID: string; seasonID: string }
        >({
            query: ({ associationID, seasonID }) => ({
                url: generatePath(apiEndpoints.getAssociationSeason.url, {
                    seasonID,
                    associationID,
                }),
                method: apiEndpoints.getAssociationSeason.method,
            }),
            providesTags: ['Season'],
        }),
        createAssociationSeason: builder.mutation<Season, SeasonFormData>({
            query: ({ associationID, ...data }) => ({
                url: generatePath(apiEndpoints.createAssociationSeason.url, {
                    associationID,
                    ...data,
                }),
                method: apiEndpoints.createAssociationSeason.method,
                body: data,
            }),
            invalidatesTags: ['Season'],
        }),
        updateAssociationSeason: builder.mutation<Season, SeasonFormData>({
            query: ({ associationID, seasonID, ...data }) => ({
                url: generatePath(apiEndpoints.updateAssociationSeason.url, {
                    seasonID,
                    associationID,
                    ...data,
                }),
                method: apiEndpoints.updateAssociationSeason.method,
                body: data,
            }),
            invalidatesTags: ['Season'],
        }),
        upsertAssociationSeason: builder.mutation<Season, SeasonFormData>({
            query: ({ associationID, seasonID, ...data }) => ({
                url: generatePath(
                    seasonID
                        ? apiEndpoints.updateAssociationSeason.url
                        : apiEndpoints.createAssociationSeason.url,
                    { seasonID, associationID }
                ),
                method: seasonID
                    ? apiEndpoints.updateAssociationSeason.method
                    : apiEndpoints.createAssociationSeason.method,
                body: data,
            }),
            invalidatesTags: ['Season'],
        }),
        deleteAssociationSeason: builder.mutation<
            void,
            { seasonID: string; associationID: string }
        >({
            query: ({ associationID, seasonID }) => ({
                url: generatePath(apiEndpoints.deleteAssociationSeason.url, {
                    associationID,
                    seasonID,
                }),
                method: apiEndpoints.deleteAssociationSeason.method,
            }),
            invalidatesTags: ['Season'],
        }),

        /**
         *
         * Association Season Teams
         *
         **/

        getAssociationSeasonTeams: builder.query<
            SeasonTeamsResponse,
            {
                associationID: string;
                seasonID: string;
                cursor?: string;
                expand?: string;
            }
        >({
            query: ({ associationID, seasonID, expand, cursor }) => {
                const searchParams = new URLSearchParams({
                    ...(expand && { expand }),
                    ...(cursor && { cursor }),
                }).toString();

                return {
                    url: `${generatePath(
                        apiEndpoints.getAssociationSeasonTeams.url,
                        {
                            seasonID,
                            associationID,
                        }
                    )}${searchParams ? '?' + searchParams : ''}`,
                    method: apiEndpoints.getAssociationSeasonTeams.method,
                };
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ seasonID }: SeasonTeam) => ({
                              type: 'Season' as const,
                              seasonID,
                          })),
                          'Season',
                      ]
                    : ['Season'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];

                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        createAssociationSeasonTeam: builder.mutation<
            SeasonTeam,
            { associationID: string; seasonID: string; teamID: string }
        >({
            query: ({ associationID, seasonID, ...data }) => ({
                url: generatePath(
                    apiEndpoints.createAssociationSeasonTeam.url,
                    {
                        associationID,
                        seasonID,
                    }
                ),
                method: apiEndpoints.createAssociationSeasonTeam.method,
                body: data,
            }),
            invalidatesTags: ['Season'],
        }),
        deleteAssociationSeasonTeam: builder.mutation<
            void,
            { associationID: string; seasonID: string; teamID: string }
        >({
            query: ({ associationID, seasonID, teamID }) => ({
                url: generatePath(
                    apiEndpoints.deleteAssociationSeasonTeam.url,
                    {
                        associationID,
                        seasonID,
                        teamID,
                    }
                ),
                method: apiEndpoints.deleteAssociationSeasonTeam.method,
            }),
            invalidatesTags: ['Season'],
        }),
    }),
});

export const {
    useGetTeamParticipatingSeasonsQuery,
    useLazyGetTeamParticipatingSeasonsQuery,
    useGetTeamOwnedSeasonsQuery,
    useLazyGetTeamOwnedSeasonsQuery,
    useGetTeamSeasonQuery,
    useCreateTeamSeasonMutation,
    useUpdateTeamSeasonMutation,
    useDeleteTeamSeasonMutation,
    useUpsertTeamSeasonMutation,
    useGetAssociationSeasonsQuery,
    useGetAssociationSeasonQuery,
    useCreateAssociationSeasonMutation,
    useUpdateAssociationSeasonMutation,
    useUpsertAssociationSeasonMutation,
    useDeleteAssociationSeasonMutation,
    useGetAssociationSeasonTeamsQuery,
    useCreateAssociationSeasonTeamMutation,
    useDeleteAssociationSeasonTeamMutation,
} = seasonsApi;
