import React from 'react';
import { IconOptions } from 'primereact/utils';
import Icon from './Icon';
import { Button, ButtonProps } from 'primereact/button';

const RookieButton = React.forwardRef<any, ButtonProps>((props, ref) => {
    const getIcon = (
        options: IconOptions<ButtonProps, NonNullable<unknown>>
    ) => {
        if (props.icon) {
            if (typeof props.icon === 'string') {
                return (
                    <Icon
                        className={options.iconProps.className}
                        name={props.icon}
                    />
                );
            } else {
                return <>{props.icon}</>;
            }
        }
    };
    return <Button ref={ref} {...props} icon={getIcon} />;
});

export default RookieButton;
