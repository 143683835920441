import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useGetGameStatsBasicReportQuery } from '../../api/reports';
import { useGetEventQuery } from '../../api/events';
import { useGetTeamQuery } from '../../api/teams';
import { useGetStatsQuery } from '../../api/stats';

import { calculateTotals } from './helpers';
import { periodSuffix } from '../../util/helper';
import { defaultReportState, defaultTooltipOptions } from './constants';
import { statKeys } from './gameTeamStatsBasicConfig';
import { teamHeader } from './DataTableCells';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';

import ErrorDisplay from '../../components/ErrorDisplay';
import TextSwap from '../../components/TextSwap';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

import {
    GameStat,
    ReportColumn,
    ReportState,
    TeamStat,
} from '../../types/reports';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import { Mixpanel } from '../../util/mixpanel';

const GameTeamStatsBasicReport = () => {
    // Route hooks
    const { teamID, eventID } = useParams();

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    // State hooks
    const [filter, setFilter] = useState(0);
    const [reportData, setReportData] =
        useState<ReportState<TeamStat[]>>(defaultReportState);

    // API hooks
    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );

    const eventData = useGetEventQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: teamID || '',
            eventID: eventID || '',
            expand: 'gameSummaryReport',
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const requestReportData = useGetGameStatsBasicReportQuery(
        {
            eventID: eventID || '',
            teamID: teamID || '',
            sessionID: timestampRef,
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const team = teamData?.data?.data;
    const event = eventData?.data?.data;

    const gameSummaryReport = useMemo(
        () => event?.gameDetails?.gameSummaryReport,
        [event]
    );

    const totalPeriods = useMemo(
        () => gameSummaryReport?.gameSummary?.noPeriods || 0,
        [gameSummaryReport]
    );

    const statConfigData = useGetStatsQuery(
        {
            sportID: gameSummaryReport?.sportID || '',
        },
        {
            skip: !gameSummaryReport?.sportID,
        }
    );

    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl) {
            setReportData((prev) => ({
                ...prev,
                error: null,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data: handleData(data),
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        }
    }, [requestReportData]);

    const getStatName = useCallback(
        (statID: string): string => {
            const stat = statConfigData?.data?.data.find(
                (stat) => stat.statID === statID
            );

            return stat?.statName || `Unknown Stat (${statID})`;
        },
        [statConfigData]
    );

    const handleData = (data: GameStat[]): TeamStat[] => {
        // filter out 'total' period results as this will be soon redundant and calculated
        let filtered: GameStat[] = data.filter((d) => d.period !== 'total');

        // add totals
        let summed: GameStat[] = calculateTotals(filtered, statKeys);

        // append to results
        let allData: GameStat[] = [...filtered, ...summed];

        // set empty array to transpose results
        let newData: TeamStat[] = [];

        // transpose stats so that each row is a stat instead of a team
        statKeys.forEach((key) => {
            const grouped: { [key: string]: TeamStat } = allData.reduce(
                (result: any, stat: GameStat) => {
                    result[stat.period] = result[stat.period] || {
                        statID: key,
                        period: Number(stat.period),
                    };

                    result[stat.period][stat.playerID] =
                        (result[stat.period][stat.playerID] || 0) + stat[key];

                    return result;
                },
                {}
            );

            newData.push(...Object.values(grouped));
        });

        return newData;
    };

    const tableValues = useMemo(() => {
        // add stat names to data
        let newData: TeamStat[] = reportData.data
            ? reportData.data?.map((data) => ({
                  ...data,
                  statName: getStatName(data.statID),
              }))
            : [];

        // filter data by period
        newData = newData.filter((row: TeamStat) => row.period === filter);

        return newData;
    }, [reportData, filter, getStatName]);

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Basic Team Game Stats',
        });
    };

    const tableHeader = (
        <Toolbar
            start={
                <div className="p-buttonset">
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[{ label: 'All', value: 0 }, ...periodOptions]}
                    />
                </div>
            }
            end={
                <RookieButton
                    type="button"
                    onClick={() => exportCSV()}
                    label="Export CSV"
                    severity="secondary"
                />
            }
        />
    );

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const emptyMessage = () => {
        const status = requestReportData?.data?.data?.reportStatus;

        if (
            ['ErrorEvent', 'ErrorServer'].includes(status) ||
            requestReportData.isError ||
            reportData.isError
        ) {
            const errorMsg = requestReportData?.data?.data?.errorMessage;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={'An error has occurred.'}
                    desc={
                        errorMsg ||
                        'Try refreshing, if the issue persists please contact support'
                    }
                    errorType={ERROR_TYPES.somethingsWrong}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        if (['Queued', 'Started', 'Building'].includes(status)) {
            const msgs = [
                'Downloading Database...',
                'Fetching Teams...',
                ...statKeys.map(
                    (statID) => `Fetching ${getStatName(statID)}...`
                ),
            ];
            const eta = status === 'Queued' ? 5 : status === 'Started' ? 4 : 3;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={
                        <TextSwap
                            strings={msgs}
                            animationDuration="4s"
                            animationType="slideUp"
                            interval={4000}
                        />
                    }
                    desc={`Your report is getting built. Please refresh the page after ${eta} minutes.`}
                    errorType={ERROR_TYPES.maintenance}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        return (
            <ErrorDisplay
                hasReturn={false}
                title={'No game data found.'}
                desc="If you believe this to be an error, please contact support."
                errorType={ERROR_TYPES.empty}
                actions={[
                    {
                        label: 'Support',
                        command: () =>
                            window
                                .open(
                                    'https://www.rookieme.com/contact',
                                    '_blank'
                                )
                                ?.focus(),
                    },
                ]}
            />
        );
    };

    const gameTeamStatsBasicColumns: ReportColumn[] = [
        {
            field: 'statName',
            name: 'Stat',
            shortName: 'Stat',
            header: 'Stat',
            sortable: true,
            style: { width: '300px' },
            body: (data: TeamStat) => {
                return statConfigData.isLoading || !data ? (
                    <Skeleton width="2rem" height="1rem" />
                ) : (
                    data.statName
                );
            },
        },
        {
            field: 'team',
            name: 'Team',
            shortName: 'Team',
            sortable: true,
            style: { width: '120px' },
            align: 'right',
            alignHeader: 'right',
            body: (data) => {
                if (!data || reportData.isLoading) {
                    <Skeleton width="2rem" height="1rem" />;
                }
                return data && data.team ? data.team : '-';
            },
            header: () =>
                teamData.isLoading ? (
                    <Skeleton width="3rem" height="1rem" />
                ) : (
                    teamHeader(team?.teamName || '', teamID)
                ),
            exportable: false,
        },
        {
            field: 'opposition',
            name: 'Opposition',
            shortName: 'Opposition',
            sortable: true,
            style: { width: '120px' },
            align: 'right',
            alignHeader: 'right',
            body: (data) => {
                if (!data || reportData.isLoading) {
                    <Skeleton width="2rem" height="1rem" />;
                }
                return data && data.opposition ? data.opposition : '-';
            },
            header: () =>
                eventData.isLoading ? (
                    <Skeleton width="3rem" height="1rem" />
                ) : (
                    teamHeader(event?.gameDetails?.opponentTeam?.teamName || '')
                ),
            exportable: false,
        },
        {
            field: 'team',
            header: 'Team',
            name: 'Team',
            shortName: 'Team',
            hidden: true,
        },
        {
            field: 'opposition',
            header: 'Opposition',
            name: 'Opposition',
            shortName: 'Opposition',
            hidden: true,
        },
    ];

    return (
        <DataTable
            ref={dataTable}
            header={tableHeader}
            value={
                requestReportData.isLoading || reportData.isLoading
                    ? Array(5)
                    : tableValues
            }
            footer={tableFooter}
            emptyMessage={emptyMessage()}
            columnResizeMode="expand"
            resizableColumns
            exportFilename="game-stats-basic-report"
        >
            {gameTeamStatsBasicColumns.map((col) => (
                <Column
                    key={col.field}
                    headerTooltipOptions={defaultTooltipOptions}
                    {...col}
                />
            ))}
        </DataTable>
    );
};

export default GameTeamStatsBasicReport;
