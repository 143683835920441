export const metaKeys = [
    'player.firstName',
    'player.lastName',
    'player.colour',
    'player.uniformNumber',
];

export const statKeys = [
    'afl_scoring_g', // Goal
    'afl_scoring_b', // Behind
    'afl_possessions_cc', // Centre Clearance
    'afl_possessions_sc', // Stoppage Clearance
    'afl_possessions_i50', // Inside 50
    'afl_assist_1__perc', // 1%er
    'afl_possessions_50', // 50m
    'afl_error_50__minus', // 50m Against
    'afl_error_cl', // Clanger
    'afl_error_ftc', // Fail to Chase
    'afl_possessions_fk__plus', // Free Kick For
    'afl_error_fb', // Fumble
    'afl_possessions_gb', // Ground Ball
    'afl_possessions_h', // Handball
    'afl_possessions_hr', // Handball Receive
    'afl_possessions_hb', // Hard Ball
    'afl_possessions_ho', // Hit Out
    'afl_possessions_k', // Kick
    'afl_possessions_m', // Mark
    'afl_possessions_mc', // Mark Contested
    'afl_error_md', // Mark Dropped
    'afl_possessions_mu', //Mark Uncontested
    'afl_error_ms', // Missed Shot at Goal
    'afl_assist_pa', // Pressure Act
    'afl_possessions_r50', // Rebound 50m
    'afl_scoring_rb', // Rushed Behind
    'afl_assist_s', // Spoil
    'afl_assist_t', // Tackle
    'afl_error_tm', // Tackle Missed
    'afl_possessions_tu__plus', // Turnover +
];
