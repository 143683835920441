import { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import UsersView from './StaffView';
import DocumentHead from '../../components/DocumentHead';
import usePermission from '../../hooks/usePermission';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { Permissions } from '../../types/permissions';
import { Mixpanel } from '../../util/mixpanel';

interface Props {
    roles: Roles;
    route: Route;
}

const StaffContainer = (props: Props) => {
    const { teamID, organisationID, associationID } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab');

    const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);

    // Permission Hook
    const { checkPermission } = usePermission(
        associationID || organisationID || teamID || ''
    );

    const handleShowInvite = () => {
        setShowInviteDialog(true);

        Mixpanel.track('Open Staff Invite');
    };

    const handleCloseInvite = () => {
        setShowInviteDialog(false);
    };

    const handleCloseDrawer = () => {
        setSearchParams((params) => {
            params.delete('id');
            return params;
        });
    };

    useEffect(() => {
        // Handle redirection to "active" for specific URLs with /staff or /events
        if (tab !== 'active' && tab !== 'pending') {
            navigate({ search: '?tab=active' }, { replace: true });
        }
    }, [navigate, tab]);

    const permissions = teamID
        ? {
              canCreate: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
              canDelete: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
              canEdit: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
              canView: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
          }
        : organisationID
        ? {
              canCreate: checkPermission([
                  Permissions.POST_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
              canDelete: checkPermission([
                  Permissions.DELETE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
              canEdit: checkPermission([
                  Permissions.PUT_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
              canView: checkPermission([
                  Permissions.GET_ORGANISATIONS_ALL,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
          }
        : associationID
        ? {
              canCreate: checkPermission([
                  Permissions.POST_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
              canDelete: checkPermission([
                  Permissions.DELETE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
              canEdit: checkPermission([
                  Permissions.PUT_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
              canView: checkPermission([
                  Permissions.GET_USERS_DETAILS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
          }
        : {
              canView: false,
              canEdit: false,
              canCreate: false,
              canDelete: false,
          };

    return (
        <>
            <DocumentHead
                title="Staff - Rookie Me Hub"
                description="Staff management page"
            />
            <UsersView
                showInviteDialog={showInviteDialog}
                onCloseInviteDialog={handleCloseInvite}
                onShowInviteDialog={handleShowInvite}
                onCloseDrawer={handleCloseDrawer}
                permissions={permissions}
                {...props}
            />
        </>
    );
};

export default StaffContainer;
