import { useGetMeQuery } from '../../api/core';
import DocumentHead from '../../components/DocumentHead';
import AccountView from './AccountView';

const AccountContainer = () => {
    const { data: me, isLoading, isError } = useGetMeQuery();

    return (
        <>
            <DocumentHead
                title="Account - Rookie Me Hub"
                description="User account page"
            />
            <AccountView
                user={me?.data}
                isLoading={isLoading}
                isError={isError}
            />
        </>
    );
};

export default AccountContainer;
