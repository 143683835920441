import { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    useDeleteStaffMutation,
    useGetStaffDetailsQuery,
    useLazyGetStaffDetailsQuery,
} from '../../api/staff';

import StaffActiveView from './StaffActiveView';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { UserDetails } from '../../types/user';
import { getEntityFromParam } from '../../util/helper';

interface Props {
    roles: Roles;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const StaffActiveContainer = (props: Props) => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const [, setSearchParams] = useSearchParams();

    const [focusedUser, setFocusedUser] = useState<UserDetails | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [refetch] = useLazyGetStaffDetailsQuery();

    const { data, isLoading, isError, isFetching } = useGetStaffDetailsQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
        },
        {
            skip: !activeEntity,
        }
    );

    const [deleteUser] = useDeleteStaffMutation();

    const handleShowDelete = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteUser = (userID: string) => {
        if (
            focusedUser?.roles.some(
                (role) =>
                    role.roleID === 'teamsOwner' ||
                    role.roleID === 'organisationsOwner'
            )
        ) {
            //Cannot delete owner
            return;
        } else {
            if (activeEntity) {
                deleteUser({
                    entityType: activeEntity.entityType,
                    entityID: activeEntity.entityID,
                    userID,
                })
                    .then((response) => {
                        console.log('DELETE SUCCESS', response);
                    })
                    .catch((err) => {
                        console.log('DELETE ERROR', err);
                    })
                    .finally(() => {
                        setShowDeleteDialog(false);
                    });
            }
        }
    };

    const handleClickUser = (userID: string) => {
        setSearchParams((params) => {
            params.set('id', userID);
            return params;
        });
    };

    const handleViewUser = (userID: string) => {
        console.log('VIEW', userID);
    };

    const handleFocusUser = (user: UserDetails) => {
        setFocusedUser(user);
    };

    const handleLoadMore = () => {
        if (
            activeEntity &&
            data &&
            data.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor
        ) {
            refetch({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                cursor: data.lastEvaluatedKey.cursor,
            });
        }
    };

    return (
        <StaffActiveView
            data={data ? data.data : []}
            isError={isError}
            isFetching={isFetching}
            isLoading={isLoading}
            showDeleteDialog={showDeleteDialog}
            focusedUser={focusedUser}
            onCloseDeleteDialog={handleCloseDelete}
            onClickUser={handleClickUser}
            onDeleteUser={handleDeleteUser}
            onViewUser={handleViewUser}
            onFocusUser={handleFocusUser}
            onShowDeleteDialog={handleShowDelete}
            roles={props.roles}
            onLoadMore={handleLoadMore}
            showPagination={!!data?.lastEvaluatedKey?.cursor}
            permissions={props.permissions}
        />
    );
};

export default StaffActiveContainer;
