import { toUpper } from 'lodash';
import { Avatar, AvatarProps } from 'primereact/avatar';
import { BaseEntityType } from '../types/common';
import { useState } from 'react';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    entityName: string;
    avatarProps?: AvatarProps;
}

const EntityAvatar = ({
    entityType,
    entityID,
    entityName,
    avatarProps,
}: Props) => {
    const [error, setError] = useState(false);

    return (
        <Avatar
            template={
                !error && (
                    <img
                        src={`${
                            process.env.REACT_APP_API_URL || ''
                        }/${entityType}/${entityID}/kit`}
                        alt={entityName}
                        onError={() => setError(true)}
                    />
                )
            }
            label={toUpper(entityName.slice(0, 2))}
            {...avatarProps}
        />
    );
};

export default EntityAvatar;
