import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    useCancelSubscriptionMutation,
    useGetLicenceGroupsQuery,
    useGetSubscriptionInvoicesQuery,
    useGetSubscriptionQuery,
} from '../../api/licences';

import { ToastContext } from '../../contexts/ToastContext';

import SubscriptionSingleView from './SubscriptionSingleView';

import { confirmDialog } from 'primereact/confirmdialog';
import { ToastMessage } from 'primereact/toast';

import { User } from '../../types/user';
import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { entityMap } from '../../routes/routes';
import { BaseEntityType } from '../../types/common';
import { DataTableValue } from 'primereact/datatable';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const SubscriptionSingleContainer = (props: Props) => {
    const { userID, subscriptionID } = useParams();
    const navigate = useNavigate();
    const toast = useContext(ToastContext);

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const subscription = useGetSubscriptionQuery({
        userID,
        subscriptionID,
    });

    const invoices = useGetSubscriptionInvoicesQuery({
        userID,
        subscriptionID,
    });

    const { data: licenceGroupsRaw } = useGetLicenceGroupsQuery(
        {
            entityID: subscription?.data?.data?.metadata?.entityID || '',
            entityType: subscription?.data?.data?.metadata?.entityType || '',
        },
        { skip: !subscription.data }
    );

    const [cancelSubscription] = useCancelSubscriptionMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleSubscriptionClick = (data: DataTableValue) => {
        const meta = subscription?.data?.data?.metadata;

        if (!meta.entityID || !meta.entityType) {
            return false;
        }

        const entitySegment = entityMap[meta.entityType];

        const licenceGroup =
            licenceGroupsRaw?.data &&
            licenceGroupsRaw.data.find(
                (group) => group.subscriptionID === data.subscription
            );

        navigate(
            `/${entitySegment.urlSegment}/${meta.entityID}/licences/${
                licenceGroup && meta.entityType !== BaseEntityType.teams
                    ? licenceGroup.licenceGroupID
                    : ''
            }`
        );
    };

    const handleCancleSubscription = () => {
        if (userID && subscriptionID) {
            confirmDialog({
                message: 'Are you sure you want to cancel this subscription',
                header: 'Cancel Subscription?',
                acceptClassName: 'p-button-danger',
                accept: () => {
                    cancelSubscription({
                        userID,
                        subscriptionID,
                    })
                        .then((response) => {
                            if ('data' in response) {
                                showToast({
                                    severity: 'success',
                                    summary: 'Subscription Cancelled',
                                    detail: response.data?.data?.message,
                                });
                            }
                        })
                        .catch(() => {
                            showToast({
                                severity: 'error',
                                summary: 'Error',
                                detail: `There was an error cancelling your subscription, please try again.`,
                            });
                        });
                },
            });
        }
    };

    return (
        <SubscriptionSingleView
            subscription={subscription}
            invoices={invoices}
            showAddModal={showAddModal}
            onShowAddModal={() => setShowAddModal(true)}
            onCloseAddModal={() => setShowAddModal(false)}
            onCancelSubscription={handleCancleSubscription}
            onSubscriptionClick={handleSubscriptionClick}
            {...props}
        />
    );
};

export default SubscriptionSingleContainer;
