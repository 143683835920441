import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { Auth0User, Auth0Form } from '../types/user';
import { generatePath } from 'react-router-dom';

export const profilesApi = createApi({
    reducerPath: 'profilesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        updateProfile: builder.mutation<Auth0User, Auth0Form>({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.updateProfile.url, { userID }),
                method: apiEndpoints.updateProfile.method,
                body: data,
            }),
        }),
        authProcessUpdateProfile: builder.mutation<Auth0User, Auth0Form>({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.authProcessUpdateProfile.url, {
                    userID,
                }),
                method: apiEndpoints.authProcessUpdateProfile.method,
                body: data,
            }),
        }),
        verifyProfile: builder.mutation<null, { userID: string }>({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.verifyEmail.url, { userID }),
                method: apiEndpoints.verifyEmail.method,
            }),
        }),
    }),
});

export const {
    useUpdateProfileMutation,
    useAuthProcessUpdateProfileMutation,
    useVerifyProfileMutation,
} = profilesApi;
