import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useGetStaffInvitesQuery,
    useResendStaffInviteMutation,
    useRevokeStaffInviteMutation,
} from '../../api/staff';

import StaffPendingView from './StaffPendingView';

import { getEntityFromParam } from '../../util/helper';
import { Ticket } from '../../types/ticket';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';

interface Props {
    roles: Roles;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const StaffPendingContainer = ({ permissions }: Props) => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    // Context hooks
    const toast = useContext(ToastContext);

    const [showRevokeDialog, setShowRevokeDialog] = useState<boolean>(false);
    const [showResendDialog, setShowResendDialog] = useState<boolean>(false);

    const { data, isLoading, isError, isFetching } = useGetStaffInvitesQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
        },
        { skip: !activeEntity }
    );

    const [revokeInvite] = useRevokeStaffInviteMutation();
    const [resendInvite] = useResendStaffInviteMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleCloseRevoke = () => {
        setShowRevokeDialog(false);
    };

    const handleShowRevoke = () => {
        setShowRevokeDialog(true);
    };

    const handleCloseResend = () => {
        setShowResendDialog(false);
    };

    const handleShowResend = () => {
        setShowResendDialog(true);
    };

    const handleRevokeUser = (ticket: Ticket) => {
        if (activeEntity) {
            revokeInvite({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                ticketID: ticket.ticketID,
            })
                .then(() => {
                    showToast({
                        severity: 'success',
                        summary: 'Invite Revoked',
                        detail: 'The invitation has successfully been revoked.',
                    });
                })
                .catch(() => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'There was an error revoking the invite. Please try again later.',
                    });
                });
        }
    };

    const handleResendInvite = (ticket: Ticket) => {
        if (activeEntity) {
            resendInvite({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                ticketID: ticket.ticketID,
            })
                .then(() => {
                    showToast({
                        severity: 'success',
                        summary: 'Sucess',
                        detail: 'The invitation has successfully resent.',
                    });
                })
                .catch(() => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'There was an error resending the invite. Please try again later.',
                    });
                });
        }
    };

    return (
        <StaffPendingView
            data={data ? data.data : []}
            isError={isError}
            isFetching={isFetching}
            isLoading={isLoading}
            onCloseRevokeDialog={handleCloseRevoke}
            showRevokeDialog={showRevokeDialog}
            onShowRevokeDialog={handleShowRevoke}
            onRevokeUser={handleRevokeUser}
            onCloseResendDialog={handleCloseResend}
            showResendDialog={showResendDialog}
            onShowResendDialog={handleShowResend}
            onResendInvite={handleResendInvite}
            permissions={permissions}
        />
    );
};

export default StaffPendingContainer;
