import React, { useState, createContext, useEffect } from 'react';
import {
    LayoutContextProps,
    LayoutConfig,
    LayoutState,
    ThemeMode,
    ThemeScale,
} from '../types/layout';

interface Props {
    children: React.ReactNode;
}

const fontSize = {
    [ThemeScale.Default]: 16,
    [ThemeScale.Medium]: 18,
    [ThemeScale.Large]: 20,
};

export const LayoutContext = createContext({} as LayoutContextProps);

export const LayoutProvider = ({ children }: Props) => {
    const lsConfig = localStorage.getItem('layoutConfig');
    const lsState = localStorage.getItem('layoutState');

    const [layoutConfig, setLayoutConfig] = useState<LayoutConfig>(
        lsConfig
            ? JSON.parse(lsConfig)
            : {
                  theme: ThemeMode.Light,
                  scale: ThemeScale.Default,
              }
    );

    const [layoutState, setLayoutState] = useState<LayoutState>(
        lsState
            ? JSON.parse(lsState)
            : {
                  pinSidebar: false,
                  showMenuDrawer: false,
                  collapsedSidebar: true,
              }
    );

    useEffect(() => {
        if (layoutConfig.theme) {
            document.body.classList.toggle(
                'is-theme-darkest',
                layoutConfig.theme === ThemeMode.Dark
            );
        }

        if (layoutConfig.scale) {
            document.documentElement.style.fontSize = `${
                fontSize[layoutConfig.scale]
            }px`;
        }

        localStorage.setItem('layoutConfig', JSON.stringify(layoutConfig));
    }, [layoutConfig]);

    useEffect(() => {
        localStorage.setItem('layoutState', JSON.stringify(layoutState));
    }, [layoutState]);

    const onMenuToggle = () => {
        setLayoutState((prev) => ({
            ...prev,
            showMenuDrawer: !prev.showMenuDrawer,
            collapsedSidebar: prev.showMenuDrawer,
        }));
    };

    const onAnchorSidebarToggle = () => {
        setLayoutState((prev) => ({
            ...prev,
            pinSidebar: !prev.pinSidebar,
            collapsedSidebar: prev.pinSidebar,
        }));
    };

    const value: LayoutContextProps = {
        layoutConfig,
        setLayoutConfig,
        layoutState,
        setLayoutState,
        onMenuToggle,
        onAnchorSidebarToggle,
    };

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
};
