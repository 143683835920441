import { orderBy } from 'lodash';
import {
    GameStat,
    StatConfig,
    StatTypes,
    TimeCategories,
    TimeDisplays,
} from '../../types/reports';
import { formatTime, formatPercentage } from '../../util/helper';
import { ColumnBodyOptions, ColumnSortEvent } from 'primereact/column';

interface SportSpecificColumns {
    [sportKey: string]: {
        basic: string[];
        advanced: string[];
        expert: string[];
    };
}

export const sportSpecificColumns: SportSpecificColumns = {
    fball_au: {
        basic: ['timeOnField', 'timeOffField'],
        advanced: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
        ],
        expert: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
            'timePos__fball_goalie',
            'timePos__fball_left_forward',
            'timePos__fball_right_forward',
            'timePos__fball_centre_forward_1',
            'timePos__fball_centre_forward_2',
            'timePos__fball_offensive_mid_1',
            'timePos__fball_offensive_mid_2',
            'timePos__fball_left_wing',
            'timePos__fball_right_wing',
            'timePos__fball_left_offensive_mid',
            'timePos__fball_right_offensive_mid',
            'timePos__fball_centre_midfield_1',
            'timePos__fball_centre_midfield_2',
            'timePos__fball_left_midfield',
            'timePos__fball_right_midfield',
            'timePos__fball_left_defensive_midfield',
            'timePos__fball_right_defensive_midfield',
            'timePos__fball_defensive_midfield',
            'timePos__fball_left_wing_back',
            'timePos__fball_centre_back_1',
            'timePos__fball_centre_back_2',
            'timePos__fball_right_wing_back',
            'timePos__fball_left_full_back',
            'timePos__fball_right_full_back',
            'timePos__fball_sweeper',
        ],
    },
    aus_football_au: {
        basic: ['timeOnField', 'timeOffField'],
        advanced: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
        ],
        expert: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
            'timePos__afl_left_back_pocket',
            'timePos__afl_full_back',
            'timePos__afl_right_back_pocket',
            'timePos__afl_left_half_back',
            'timePos__afl_mid_left_half_back',
            'timePos__afl_centre_half_back',
            'timePos__afl_mid_centre_half_back',
            'timePos__afl_right_half_back',
            'timePos__afl_mid_right_half_back',
            'timePos__afl_left_wing',
            'timePos__afl_attacking_left_wing',
            'timePos__afl_defensive_left_wing',
            'timePos__afl_ruck',
            'timePos__afl_midfield',
            'timePos__afl_mid_1',
            'timePos__afl_mid_2',
            'timePos__afl_mid_3',
            'timePos__afl_right_wing',
            'timePos__afl_attacking_right_wing',
            'timePos__afl_defensive_right_wing',
            'timePos__afl_left_half_forward',
            'timePos__afl_mid_left_half_forward',
            'timePos__afl_centre_half_forward',
            'timePos__afl_mid_centre_half_forward',
            'timePos__afl_right_half_forward',
            'timePos__afl_mid_right_half_forward',
            'timePos__afl_left_forward_pocket',
            'timePos__afl_full_forward',
            'timePos__afl_right_forward_pocket',
        ],
    },
    fhoc_au: {
        basic: ['timeOnField', 'timeOffField'],
        advanced: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
        ],
        expert: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
            'timePos__hoc_goalie',
            'timePos__hoc_sweeper',
            'timePos__hoc_left_back',
            'timePos__hoc_left_full_back',
            'timePos__hoc_right_back',
            'timePos__hoc_right_full_back',
            'timePos__hoc_centre_back_1',
            'timePos__hoc_centre_back_2',
            'timePos__hoc_def_mid_left',
            'timePos__hoc_def_mid',
            'timePos__hoc_def_mid_right',
            'timePos__hoc_left_wing',
            'timePos__hoc_centre_mid_1',
            'timePos__hoc_centre_mid_2',
            'timePos__hoc_right_wing',
            'timePos__hoc_left_forward',
            'timePos__hoc_left_half',
            'timePos__hoc_left_inner',
            'timePos__hoc_centre_forward_1',
            'timePos__hoc_centre_forward_2',
            'timePos__hoc_right_forward',
            'timePos__hoc_right_half',
            'timePos__hoc_right_inner',
        ],
    },
    netb_au: {
        basic: ['timeOnField', 'timeOffField'],
        advanced: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
        ],
        expert: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'zoneTimeFOR',
            'zoneTimeMID',
            'zoneTimeBAC',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
            'timePos__netb_goal_shooter',
            'timePos__netb_goal_attack',
            'timePos__netb_wing_attack',
            'timePos__netb_centre',
            'timePos__netb_wing_defence',
            'timePos__netb_goal_defence',
            'timePos__netb_goal_keeper',
        ],
    },
    bball_au: {
        basic: ['timeOnField', 'timeOffField'],
        advanced: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
        ],
        expert: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
            'timePos__bball_point_guard',
            'timePos__bball_shooting_guard',
            'timePos__bball_small_forward',
            'timePos__bball_forward',
            'timePos__bball_guard',
            'timePos__bball_centre',
            'timePos__bball_utility',
        ],
    },
    lacr_au: {
        basic: ['timeOnField', 'timeOffField'],
        advanced: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
        ],
        expert: [
            'totalPGT',
            'timeOnField',
            'timeOffField',
            'timeBench',
            'timeInjury',
            'timeAssessment',
            'timeDisciplinary',
            'timeOpposition',
            'timeAbsent',
            'timePos__lacr_attack_1',
            'timePos__lacr_attack_2',
            'timePos__lacr_attack_3',
            'timePos__lacr_mid_1',
            'timePos__lacr_mid_2',
            'timePos__lacr_mid_3',
            'timePos__lacr_defence_1',
            'timePos__lacr_defence_2',
            'timePos__lacr_defence_3',
            'timePos__lacr_goal_keeper',
        ],
    },
};

export const getColumnsByTeamSportID = (teamSportID: string | undefined) => {
    if (!teamSportID || !sportSpecificColumns[teamSportID]) {
        return {
            basic: [],
            advanced: [],
            expert: [],
        };
    }

    const sportColumns = sportSpecificColumns[teamSportID];
    const basicColumns = sportColumns.basic;
    const advancedColumns = sportColumns.advanced;
    const expertColumns = sportColumns.expert;

    return {
        basic: basicColumns,
        advanced: advancedColumns,
        expert: expertColumns,
    };
};

export const calculateMidfieldTime = (data: GameStat): number => {
    const { timePos__afl_mid_1, timePos__afl_mid_2, timePos__afl_mid_3 } = data;
    return timePos__afl_mid_1 + timePos__afl_mid_2 + timePos__afl_mid_3;
};

export const calculateStintMidfieldTime = (data: GameStat): number => {
    const {
        countStintPos__afl_mid_1,
        countStintPos__afl_mid_2,
        countStintPos__afl_mid_3,
    } = data;
    return (
        countStintPos__afl_mid_1 +
        countStintPos__afl_mid_2 +
        countStintPos__afl_mid_3
    );
};

export const config: { [key: string]: StatConfig } = {
    totalPGT: {
        name: 'Percentage Game Time',
        shortName: 'PGT',
        description: '',
        category: TimeCategories.General,
        type: StatTypes.Percentage,
        hsArticle: '662dc12f0cfcb4508af6a0fc',
        sortable: true,
        exportable: false,
        sortFunction: ({ data, order }: ColumnSortEvent) => {
            return (
                order &&
                orderBy(
                    data,
                    (o) => o.timeOnField / o.period__available_time,
                    order > 0 ? 'asc' : 'desc'
                )
            );
        },
        format: (data: GameStat) => {
            const PGT = data.timeOnField / data.period__available_time;
            return formatPercentage(!isNaN(PGT) ? PGT : 0);
        },
    },
    PT: {
        name: 'Period Time',
        shortName: 'PT',
        description: '',
        category: TimeCategories.General,
        type: StatTypes.Time,
    },
    timeOnField: {
        name: 'Percentage Match Time',
        shortName: 'PMT',
        description: 'Time a player has spent on the field.',
        category: TimeCategories.Status,
        type: StatTypes.Time,
        hsArticle: '662dc12f0cfcb4508af6a0fc',
    },
    timeOffField: {
        name: 'Time Off Field',
        shortName: 'OFF',
        description: 'Time a player has spent off the field (stints).',
        category: TimeCategories.Status,
        type: StatTypes.Time,
    },
    timeBench: {
        name: 'Time on Bench',
        shortName: 'BEN',
        description: 'Time a player has spent on the bench (stints).',
        category: TimeCategories.Status,
        type: StatTypes.Time,
    },
    timeAssessment: {
        name: 'Time in Assessment',
        shortName: 'ASS',
        description: 'Time a player has spent in assessment (stints).',
        category: TimeCategories.Status,
        type: StatTypes.Time,
    },
    timeInjury: {
        name: 'Injury',
        shortName: 'INJ',
        description: 'Time a player has spent injured (stints).',
        category: TimeCategories.Status,
        type: StatTypes.Time,
    },
    timeDisciplinary: {
        name: 'Disciplinary',
        shortName: 'DIS',
        description: 'Time a player has spent in disciplinary (stints).',
        category: TimeCategories.Status,
        type: StatTypes.Time,
    },
    timeOpposition: {
        name: 'Opposition',
        shortName: 'OPP',
        description:
            'Time a player has spent playing the opposition team (stints).',
        category: TimeCategories.Status,
        type: StatTypes.Time,
    },
    timeAbsent: {
        name: 'Absent',
        shortName: 'ABS',
        description:
            'Time a player has spent playing for the opposition team (stints).',
        category: TimeCategories.Status,
        type: StatTypes.Time,
    },
    zoneTimeFOR: {
        name: 'Forward Zone',
        shortName: 'FOR',
        description:
            'Time a player has spent in the forward zone on the field.',
        category: TimeCategories.Zone,
        type: StatTypes.Time,
        format: (
            data: GameStat,
            options: ColumnBodyOptions,
            timeDisplay?: TimeDisplays
        ) => {
            const zoneTime = data[options.field];
            const zonePercentage = zoneTime / data.timeOnField;
            return timeDisplay === TimeDisplays.Percentage
                ? formatPercentage(!isNaN(zonePercentage) ? zonePercentage : 0)
                : formatTime(zoneTime);
        },
    },
    zoneTimeMID: {
        name: 'Midfield Zone',
        shortName: 'MID',
        description:
            'Time a player has spent in the midfield zone on the field.',
        category: TimeCategories.Zone,
        type: StatTypes.Time,
        format: (
            data: GameStat,
            options: ColumnBodyOptions,
            timeDisplay?: TimeDisplays
        ) => {
            const zoneTime = data[options.field];
            const zonePercentage = zoneTime / data.timeOnField;
            return timeDisplay === TimeDisplays.Percentage
                ? formatPercentage(!isNaN(zonePercentage) ? zonePercentage : 0)
                : formatTime(zoneTime);
        },
    },
    zoneTimeBAC: {
        name: 'Defensive Zone',
        shortName: 'DEF',
        description:
            'Time a player has spent in the defensive zone on the field.',
        category: TimeCategories.Zone,
        type: StatTypes.Time,
        format: (
            data: GameStat,
            options: ColumnBodyOptions,
            timeDisplay?: TimeDisplays
        ) => {
            const zoneTime = data[options.field];
            const zonePercentage = zoneTime / data.timeOnField;
            return timeDisplay === TimeDisplays.Percentage
                ? formatPercentage(!isNaN(zonePercentage) ? zonePercentage : 0)
                : formatTime(zoneTime);
        },
    },
    timePos__afl_mid_left_half_forward: {
        name: 'Mid-left half-forward',
        shortName: 'MID LHF',
        description:
            'Time a player has spent playing in the mid-left half-forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_mid_centre_half_back: {
        name: 'Mid-centre half-back',
        shortName: 'MID CHB',
        description:
            'Time a player has spent playing in the mid-centre half-back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_ruck: {
        name: 'Ruck',
        shortName: 'RU',
        description:
            'Time a player has spent playing in the ruck position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_mid_right_half_forward: {
        name: 'Mid-right half-forward',
        shortName: 'MID RHF',
        description:
            'Time a player has spent playing in the mid-right half-forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_right_half_forward: {
        name: 'Right half-forward',
        shortName: 'RHF',
        description:
            'Time a player has spent playing in the right half-forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_left_forward_pocket: {
        name: 'Left forward pocket',
        shortName: 'LFP',
        description:
            'Time a player has spent playing in the left forward pocket position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_attacking_left_wing: {
        name: 'Attacking left wing',
        shortName: 'ALW',
        description:
            'Time a player has spent playing in the attacking left wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_left_back_pocket: {
        name: 'Left back pocket',
        shortName: 'LBP',
        description:
            'Time a player has spent playing in the left back pocket position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_right_wing: {
        name: 'Right wing',
        shortName: 'RW',
        description:
            'Time a player has spent playing in the right wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_right_forward_pocket: {
        name: 'Right forward pocket',
        shortName: 'RFP',
        description:
            'Time a player has spent playing in the right forward pocket position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_defensive_left_wing: {
        name: 'Defensive left wing',
        shortName: 'DLW',
        description:
            'Time a player has spent playing in the defensive left wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_full_forward: {
        name: 'Full forward',
        shortName: 'FF',
        description:
            'Time a player has spent playing in the full forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_defensive_right_wing: {
        name: 'Defensive right wing',
        shortName: 'DRW',
        description:
            'Time a player has spent playing in the Defensive right wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_left_half_back: {
        name: 'Left half-back',
        shortName: 'LHB',
        description:
            'Time a player has spent playing in the left half-back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_left_wing: {
        name: 'Left wing',
        shortName: 'LW',
        description:
            'Time a player has spent playing in the left wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_right_back_pocket: {
        name: 'Back pocket',
        shortName: 'BP',
        description:
            'Time a player has spent playing in the back pocket position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_midfield: {
        name: 'Midfield',
        shortName: 'MIDS',
        description:
            'Summation of midfield time across MID1, MID2 and MID3 positions (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
        format: (data: GameStat) => formatTime(calculateMidfieldTime(data)),
    },
    timePos__afl_mid_1: {
        name: 'Mid 1',
        shortName: 'MID1',
        description:
            'Time a player has spent playing in a midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_mid_2: {
        name: 'Mid 2',
        shortName: 'MID2',
        description:
            'Time a player has spent playing in a midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_mid_3: {
        name: 'Mid 3',
        shortName: 'MID3',
        description:
            'Time a player has spent playing in a midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_left_half_forward: {
        name: 'Left half-forward',
        shortName: 'LHF',
        description:
            'Time a player has spent playing in the left half-forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_mid_left_half_back: {
        name: 'Mid-left half-back',
        shortName: 'MLHB',
        description:
            'Time a player has spent playing in the mid-left half-back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_full_back: {
        name: 'Full back',
        shortName: 'FB',
        description:
            'Time a player has spent playing in the full back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_attacking_right_wing: {
        name: 'Attacking right wing',
        shortName: 'ARW',
        description:
            'Time a player has spent playing in the attacking right wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_mid_centre_half_forward: {
        name: 'Mid-centre half-forward',
        shortName: 'MCHF',
        description:
            'Time a player has spent playing in the mid-centre half-forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_mid_right_half_back: {
        name: 'Mid-right half-back',
        shortName: 'MRHB',
        description:
            'Time a player has spent playing in the mid-right half-back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_right_half_back: {
        name: 'Right half-back',
        shortName: 'RHB',
        description:
            'Time a player has spent playing in the right half-back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },

    timePos__afl_centre_half_back: {
        name: 'Centre half-back',
        shortName: 'CHB',
        description:
            'Time a player has spent playing in the centre half-back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__afl_centre_half_forward: {
        name: 'Centre half-forward',
        shortName: 'CHF',
        description:
            'Time a player has spent playing in the centre half-forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    countStintPos__afl_mid_left_half_forward: {
        name: 'Mid-left half-forward',
        shortName: 'COUNT MLHF',
        description:
            'Amount of stints a player has had in the centre half-forward position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_mid_centre_half_back: {
        name: 'Mid-centre half-back',
        shortName: 'COUNT MCHB',
        description:
            'Amount of stints a player has had in the mid-centre half-back position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_ruck: {
        name: 'Ruck',
        shortName: 'COUNT RU',
        description: 'Amount of stints a player has had in the ruck position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_mid_right_half_forward: {
        name: 'Mid-right half-forward',
        shortName: 'COUNT MRHF',
        description:
            'Amount of stints a player has had in the mid-right half-forward position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_right_half_forward: {
        name: 'Right half-forward',
        shortName: 'COUNT RHF',
        description:
            'Amount of stints a player has had in the right half-forward position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_left_forward_pocket: {
        name: 'Left forward pocket',
        shortName: 'COUNT LFP',
        description:
            'Amount of stints a player has had in the left forward pocket position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_attacking_left_wing: {
        name: 'Attacking left wing',
        shortName: 'COUNT ALW',
        description:
            'Amount of stints a player has had in the attacking left wing position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_left_back_pocket: {
        name: 'Left back pocket',
        shortName: 'COUNT LBP',
        description:
            'Amount of stints a player has had in the left back pocket position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_right_wing: {
        name: 'Right wing',
        shortName: 'COUNT RW',
        description:
            'Amount of stints a player has had in the right wing position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_right_forward_pocket: {
        name: 'Right forward pocket',
        shortName: 'COUNT RFP',
        description:
            'Amount of stints a player has had in the right forward pocket position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_defensive_left_wing: {
        name: 'Defensive left wing',
        shortName: 'COUNT DLW',
        description:
            'Amount of stints a player has had in the defensive left wing position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_full_forward: {
        name: 'Full forward',
        shortName: 'CFF',
        description:
            'Amount of stints a player has had in the full forward position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_defensive_right_wing: {
        name: 'Defensive right wing',
        shortName: 'COUNT DRW',
        description:
            'Amount of stints a player has had in the defensive right wing position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_left_half_back: {
        name: 'Left half-back',
        shortName: 'COUNT LHB',
        description:
            'Amount of stints a player has had in the left half-back position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_left_wing: {
        name: 'Left wing',
        shortName: 'COUNT LW',
        description:
            'Amount of stints a player has had in the left wing position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_right_back_pocket: {
        name: 'Back pocket',
        shortName: 'CBP',
        description:
            'Amount of stints a player has had in the back pocket position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_midfield: {
        name: 'Midfield',
        shortName: 'MIDS',
        description:
            'Summation of midfield time across MID1, MID2 and MID3 positions.',
        category: TimeCategories.Position,
        type: StatTypes.Time,
        format: (data: GameStat) =>
            formatTime(calculateStintMidfieldTime(data)),
    },
    countStintPos__afl_mid_1: {
        name: 'Mid 1',
        shortName: 'COUNT MID1',
        description:
            'Amount of stints a player has had in the midfield 1 position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_mid_2: {
        name: 'Mid 2',
        shortName: 'COUNT MID2',
        description:
            'Amount of stints a player has had in the midfield 2 position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_mid_3: {
        name: 'Mid 3',
        shortName: 'COUNT MID3',
        description:
            'Amount of stints a player has had in the midfield 3 position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_left_half_forward: {
        name: 'Left half-forward',
        shortName: 'COUNT LHF',
        description:
            'Amount of stints a player has had in the left half-forward position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_mid_left_half_back: {
        name: 'Mid-left half-back',
        shortName: 'COUNT MLHB',
        description:
            'Amount of stints a player has had in the mid-left half-back position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_full_back: {
        name: 'Full back',
        shortName: 'COUNT FB',
        description:
            'Amount of stints a player has had in the full back position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_attacking_right_wing: {
        name: 'Attacking right wing',
        shortName: 'COUNT ARW',
        description:
            'Amount of stints a player has had in the attacking right wing position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_mid_centre_half_forward: {
        name: 'Mid-centre half-forward',
        shortName: 'COUNT MCHF',
        description:
            'Amount of stints a player has had in the mid-centre half-forward position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_mid_right_half_back: {
        name: 'Mid-right half-back',
        shortName: 'COUNT MRHB',
        description:
            'Amount of stints a player has had in the mid-right half-back position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_right_half_back: {
        name: 'Right half-back',
        shortName: 'COUNT RHB',
        description:
            'Amount of stints a player has had in the right half-back position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_centre_half_back: {
        name: 'Centre half-back',
        shortName: 'COUNT CHB',
        description:
            'Amount of stints a player has had in the centre half-back position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintPos__afl_centre_half_forward: {
        name: 'Centre half-forward',
        shortName: 'COUNT CHF',
        description:
            'Amount of stints a player has had in the centre half-forward position.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintBench: {
        name: 'Bench',
        shortName: 'COUNT BEN',
        description: 'Amount of stints a player has had on the bench.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintAssessment: {
        name: 'Assessment',
        shortName: 'COUNT ASS',
        description: 'Amount of stints a player has had in assessment.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintInjury: {
        name: 'Injury',
        shortName: 'COUNT INJ',
        description: 'Amount of stints a player has had in injury.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintDisciplinary: {
        name: 'Disciplinary',
        shortName: 'COUNT DIS',
        description: 'Amount of stints a player has had in disciplinary.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintOpposition: {
        name: 'Opposition',
        shortName: 'COUNT OPP',
        description:
            'Amount of stints a player has had playing for the opposition.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintAbsent: {
        name: 'Absent',
        shortName: 'COUNT ABS',
        description: 'Amount of stints a player has been absent.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintOnField: {
        name: 'Stints on the field',
        shortName: 'COUNT ON',
        description: 'Amount of stints a player has been on the field.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    countStintOffField: {
        name: 'Stints off the field',
        shortName: 'COUNT OFF',
        description: 'Amount of stints a player has been off the field.',
        category: TimeCategories.Stint,
        type: StatTypes.Count,
    },
    period__unavailable_time: {
        name: 'Unavailable period time',
        shortName: 'PUT',
        description: 'Amount of unavailable within a period.',
        category: TimeCategories.General,
        type: StatTypes.Time,
    },
    period__available_time: {
        name: 'Available period time',
        shortName: 'PAT',
        description: 'Amount of unavailable within a period.',
        category: TimeCategories.General,
        type: StatTypes.Time,
    },
    PMT: {
        name: 'Period Match Time',
        shortName: 'PMT',
        description: 'Total match time.',
        category: TimeCategories.General,
        type: StatTypes.Time,
    },
    rotationsPerQuarter: {
        name: 'Rotations Per Period',
        shortName: 'RPP',
        description: 'Total rotations per period.',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    // Begin field hockey
    timePos__hoc_centre_back_1: {
        name: 'Centre Back 1',
        shortName: 'CB1',
        description:
            'Time a player has spent playing in the Centre Back 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_centre_back_2: {
        name: 'Centre Back 2',
        shortName: 'CB2',
        description:
            'Time a player has spent playing in the Centre Back 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_centre_forward_1: {
        name: 'Centre Forward 1',
        shortName: 'CF1',
        description:
            'Time a player has spent playing in the Centre Forward 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_centre_forward_2: {
        name: 'Centre Forward 2',
        shortName: 'CF2',
        description:
            'Time a player has spent playing in the Centre Forward 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_centre_mid_1: {
        name: 'Centre Midfield 1',
        shortName: 'CM1',
        description:
            'Time a player has spent playing in the Centre Midfield 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_centre_mid_2: {
        name: 'Centre Midfield 2',
        shortName: 'CM2',
        description:
            'Time a player has spent playing in the Centre Midfield 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_def_mid: {
        name: 'Defensive Midfield',
        shortName: 'DM',
        description:
            'Time a player has spent playing in the Defensive Midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_def_mid_left: {
        name: 'Defensive Midfield Left',
        shortName: 'DML',
        description:
            'Time a player has spent playing in the Defensive Midfield Left position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_def_mid_right: {
        name: 'Defensive Midfield Right',
        shortName: 'DMR',
        description:
            'Time a player has spent playing in the Defensive Midfield Right position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_goalie: {
        name: 'Goalie',
        shortName: 'G',
        description:
            'Time a player has spent playing in the Goalie position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_left_back: {
        name: 'Left Back',
        shortName: 'LB',
        description:
            'Time a player has spent playing in the Left Back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_left_forward: {
        name: 'Left Forward',
        shortName: 'LF',
        description:
            'Time a player has spent playing in the Left Forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_left_full_back: {
        name: 'Left Full Back',
        shortName: 'LFB',
        description:
            'Time a player has spent playing in the Left Full Back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_left_half: {
        name: 'Left Half',
        shortName: 'LH',
        description:
            'Time a player has spent playing in the Left Half position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_left_inner: {
        name: 'Left Inner',
        shortName: 'LI',
        description:
            'Time a player has spent playing in the Left Inner position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_left_wing: {
        name: 'Left Wing',
        shortName: 'LW',
        description:
            'Time a player has spent playing in the Left Wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_right_forward: {
        name: 'Right Forward',
        shortName: 'RF',
        description:
            'Time a player has spent playing in the Right Forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_right_full_back: {
        name: 'Right Full Back',
        shortName: 'RFB',
        description:
            'Time a player has spent playing in the Right Full Back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_right_half: {
        name: 'Right Half',
        shortName: 'RH',
        description:
            'Time a player has spent playing in the Right Half position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_right_inner: {
        name: 'Right Inner',
        shortName: 'RI',
        description:
            'Time a player has spent playing in the Right Inner position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_right_wing: {
        name: 'Right Wing',
        shortName: 'RW',
        description:
            'Time a player has spent playing in the Right Wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__hoc_sweeper: {
        name: 'Sweeper',
        shortName: 'SW',
        description:
            'Time a player has spent playing in the Sweeper position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    countStintPos__hoc_centre_back_1: {
        name: 'Centre Back 1',
        shortName: 'CB1',
        description:
            'Number of stints a player has had in the Centre Back 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_centre_back_2: {
        name: 'Centre Back 2',
        shortName: 'CB2',
        description:
            'Number of stints a player has had in the Centre Back 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_centre_forward_1: {
        name: 'Centre Forward 1',
        shortName: 'CF1',
        description:
            'Number of stints a player has had in the Centre Forward 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_centre_forward_2: {
        name: 'Centre Forward 2',
        shortName: 'CF2',
        description:
            'Number of stints a player has had in the Centre Forward 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_centre_mid_1: {
        name: 'Centre Midfield 1',
        shortName: 'CM1',
        description:
            'Number of stints a player has had in the Centre Midfield 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_centre_mid_2: {
        name: 'Centre Midfield 2',
        shortName: 'CM2',
        description:
            'Number of stints a player has had in the Centre Midfield 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_def_mid: {
        name: 'Defensive Midfield',
        shortName: 'DM',
        description:
            'Number of stints a player has had in the Defensive Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_def_mid_left: {
        name: 'Defensive Midfield Left',
        shortName: 'DML',
        description:
            'Number of stints a player has had in the Defensive Midfield Left position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_def_mid_right: {
        name: 'Defensive Midfield Right',
        shortName: 'DMR',
        description:
            'Number of stints a player has had in the Defensive Midfield Right position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_goalie: {
        name: 'Goalie',
        shortName: 'G',
        description:
            'Number of stints a player has had in the Goalie position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_left_back: {
        name: 'Left Back',
        shortName: 'LB',
        description:
            'Number of stints a player has had in the Left Back position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_left_forward: {
        name: 'Left Forward',
        shortName: 'LF',
        description:
            'Number of stints a player has had in the Left Forward position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_left_full_back: {
        name: 'Left Full Back',
        shortName: 'LFB',
        description:
            'Number of stints a player has had in the Left Full Back position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_left_half: {
        name: 'Left Half',
        shortName: 'LH',
        description:
            'Number of stints a player has had in the Left Half position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_left_inner: {
        name: 'Left Inner',
        shortName: 'LI',
        description:
            'Number of stints a player has had in the Left Inner position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_left_wing: {
        name: 'Left Wing',
        shortName: 'LW',
        description:
            'Number of stints a player has had in the Left Wing position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_right_forward: {
        name: 'Right Forward',
        shortName: 'RF',
        description:
            'Number of stints a player has had in the Right Forward position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_right_full_back: {
        name: 'Right Full Back',
        shortName: 'RFB',
        description:
            'Number of stints a player has had in the Right Full Back position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_right_half: {
        name: 'Right Half',
        shortName: 'RH',
        description:
            'Number of stints a player has had in the Right Half position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_right_inner: {
        name: 'Right Inner',
        shortName: 'RI',
        description:
            'Number of stints a player has had in the Right Inner position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_right_wing: {
        name: 'Right Wing',
        shortName: 'RW',
        description:
            'Number of stints a player has had in the Right Wing position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__hoc_sweeper: {
        name: 'Sweeper',
        shortName: 'SW',
        description:
            'Number of stints a player has had in the Sweeper position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    // Begin netball
    timePos__netb_goal_shooter: {
        name: 'Goal Shooter',
        shortName: 'GS',
        description:
            'Time a player has spent playing in the Goal Shooter position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__netb_goal_attack: {
        name: 'Goal Attack',
        shortName: 'GA',
        description:
            'Time a player has spent playing in the Goal Attack position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__netb_wing_attack: {
        name: 'Wing Attack',
        shortName: 'WA',
        description:
            'Time a player has spent playing in the Wing Attack position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__netb_centre: {
        name: 'Centre',
        shortName: 'C',
        description:
            'Time a player has spent playing in the Centre position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__netb_wing_defence: {
        name: 'Wing Defence',
        shortName: 'WD',
        description:
            'Time a player has spent playing in the Wing Defence position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__netb_goal_defence: {
        name: 'Goal Defence',
        shortName: 'GD',
        description:
            'Time a player has spent playing in the Goal Defence position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__netb_goal_keeper: {
        name: 'Goal Keeper',
        shortName: 'GK',
        description:
            'Time a player has spent playing in the Goal Keeper position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    countStintPos__netb_goal_shooter: {
        name: 'Goal Shooter',
        shortName: 'GS',
        description:
            'Number of stints a player has had in the Goal Shooter position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__netb_goal_attack: {
        name: 'Goal Attack',
        shortName: 'GA',
        description:
            'Number of stints a player has had in the Goal Attack position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__netb_wing_attack: {
        name: 'Wing Attack',
        shortName: 'WA',
        description:
            'Number of stints a player has had in the Wing Attack position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__netb_centre: {
        name: 'Centre',
        shortName: 'C',
        description:
            'Number of stints a player has had in the Centre position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__netb_wing_defence: {
        name: 'Wing Defence',
        shortName: 'WD',
        description:
            'Number of stints a player has had in the Wing Defence position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__netb_goal_defence: {
        name: 'Goal Defence',
        shortName: 'GD',
        description:
            'Number of stints a player has had in the Goal Defence position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__netb_goal_keeper: {
        name: 'Goal Keeper',
        shortName: 'GK',
        description:
            'Number of stints a player has had in the Goal Keeper position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    // Begin basketball
    timePos__bball_point_guard: {
        name: 'Point Guard',
        shortName: 'PG',
        description:
            'Time a player has spent playing in the Point Guard position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__bball_shooting_guard: {
        name: 'Shooting Guard',
        shortName: 'SG',
        description:
            'Time a player has spent playing in the Shooting Guard position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__bball_small_forward: {
        name: 'Small Forward',
        shortName: 'SF',
        description:
            'Time a player has spent playing in the Small Forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__bball_forward: {
        name: 'Forward',
        shortName: 'F',
        description:
            'Time a player has spent playing in the Forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__bball_guard: {
        name: 'Guard',
        shortName: 'G',
        description:
            'Time a player has spent playing in the Guard position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__bball_centre: {
        name: 'Centre',
        shortName: 'C',
        description:
            'Time a player has spent playing in the Centre position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__bball_utility: {
        name: 'Utility',
        shortName: 'U',
        description:
            'Time a player has spent playing in the Utility position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    countStintPos__bball_point_guard: {
        name: 'Point Guard',
        shortName: 'PG',
        description:
            'Number of stints a player has had in the Point Guard position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__bball_shooting_guard: {
        name: 'Shooting Guard',
        shortName: 'SG',
        description:
            'Number of stints a player has had in the Shooting Guard position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__bball_small_forward: {
        name: 'Small Forward',
        shortName: 'SF',
        description:
            'Number of stints a player has had in the Small Forward position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__bball_forward: {
        name: 'Forward',
        shortName: 'F',
        description:
            'Number of stints a player has had in the Forward position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__bball_guard: {
        name: 'Guard',
        shortName: 'G',
        description:
            'Number of stints a player has had in the Guard position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__bball_centre: {
        name: 'Centre',
        shortName: 'C',
        description:
            'Number of stints a player has had in the Centre position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__bball_utility: {
        name: 'Utility',
        shortName: 'U',
        description:
            'Number of stints a player has had in the Utility position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    // Begin soccer
    timePos__fball_goalie: {
        name: 'Goalie',
        shortName: 'G',
        description:
            'Time a player has spent playing in the Goalie position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_left_forward: {
        name: 'Left Forward',
        shortName: 'LF',
        description:
            'Time a player has spent playing in the Left Forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_right_forward: {
        name: 'Right Forward',
        shortName: 'RF',
        description:
            'Time a player has spent playing in the Right Forward position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_centre_forward_1: {
        name: 'Centre Forward 1',
        shortName: 'CF1',
        description:
            'Time a player has spent playing in the Centre Forward 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_centre_forward_2: {
        name: 'Centre Forward 2',
        shortName: 'CF2',
        description:
            'Time a player has spent playing in the Centre Forward 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_offensive_mid_1: {
        name: 'Offensive Mid 1',
        shortName: 'OM1',
        description:
            'Time a player has spent playing in the Offensive Mid 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_offensive_mid_2: {
        name: 'Offensive Mid 2',
        shortName: 'OM2',
        description:
            'Time a player has spent playing in the Offensive Mid 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_left_wing: {
        name: 'Left Wing',
        shortName: 'LW',
        description:
            'Time a player has spent playing in the Left Wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_right_wing: {
        name: 'Right Wing',
        shortName: 'RW',
        description:
            'Time a player has spent playing in the Right Wing position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_left_offensive_mid: {
        name: 'Left Offensive Mid',
        shortName: 'LOM',
        description:
            'Time a player has spent playing in the Left Offensive Mid position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_right_offensive_mid: {
        name: 'Right Offensive Midfield',
        shortName: 'ROM',
        description:
            'Time a player has spent playing in the Right Offensive Midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_centre_midfield_1: {
        name: 'Centre Midfield 1',
        shortName: 'CM1',
        description:
            'Time a player has spent playing in the Centre Midfield 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_centre_midfield_2: {
        name: 'Centre Midfield 2',
        shortName: 'CM2',
        description:
            'Time a player has spent playing in the Centre Midfield 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_left_midfield: {
        name: 'Left Midfield',
        shortName: 'LM',
        description:
            'Time a player has spent playing in the Left Midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_right_midfield: {
        name: 'Right Midfield',
        shortName: 'RM',
        description:
            'Time a player has spent playing in the Right Midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_left_defensive_midfield: {
        name: 'Left Defensive Midfield',
        shortName: 'LDM',
        description:
            'Time a player has spent playing in the Left Defensive Midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_right_defensive_midfield: {
        name: 'Right Defensive Midfield',
        shortName: 'RDM',
        description:
            'Time a player has spent playing in the Right Defensive Midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_defensive_midfield: {
        name: 'Defensive Midfield',
        shortName: 'DM',
        description:
            'Time a player has spent playing in the Defensive Midfield position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_left_wing_back: {
        name: 'Left Wing Back',
        shortName: 'LWB',
        description:
            'Time a player has spent playing in the Left Wing Back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_centre_back_1: {
        name: 'Centre Back 1',
        shortName: 'CB1',
        description:
            'Time a player has spent playing in the Centre Back 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_centre_back_2: {
        name: 'Centre Back 2',
        shortName: 'CB2',
        description:
            'Time a player has spent playing in the Centre Back 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_right_wing_back: {
        name: 'Right Wing Back',
        shortName: 'RWB',
        description:
            'Time a player has spent playing in the Right Wing Back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_left_full_back: {
        name: 'Left Full Back',
        shortName: 'LFB',
        description:
            'Time a player has spent playing in the Left Full Back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_right_full_back: {
        name: 'Right Full Back',
        shortName: 'LFB',
        description:
            'Time a player has spent playing in the Right Full Back position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__fball_sweeper: {
        name: 'Sweeper',
        shortName: 'S',
        description:
            'Time a player has spent playing in the Sweeper position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    countStintPos__fball_goalie: {
        name: 'Goalie',
        shortName: 'G',
        description:
            'Number of stints a player has had in the Goalie position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_left_forward: {
        name: 'Left Forward',
        shortName: 'LF',
        description:
            'Number of stints a player has had in the Left Forward position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_right_forward: {
        name: 'Right Forward',
        shortName: 'RF',
        description:
            'Number of stints a player has had in the Right Forward position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_centre_forward_1: {
        name: 'Centre Forward 1',
        shortName: 'CF1',
        description:
            'Number of stints a player has had in the Centre Forward 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_centre_forward_2: {
        name: 'Centre Forward 2',
        shortName: 'CF2',
        description:
            'Number of stints a player has had in the Centre Forward 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_offensive_mid_1: {
        name: 'Offensive Midfield 1',
        shortName: 'OM1',
        description:
            'Number of stints a player has had in the Offensive Midfield 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_offensive_mid_2: {
        name: 'Offensive Midfield 2',
        shortName: 'OM2',
        description:
            'Number of stints a player has had in the Offensive Midfield 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_left_wing: {
        name: 'Offensive Left Wing',
        shortName: 'OM2',
        description:
            'Number of stints a player has had in the Offensive Left Wing position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_right_wing: {
        name: 'Offensive Right Wing',
        shortName: 'OM2',
        description:
            'Number of stints a player has had in the Offensive Right Wing position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_left_offensive_mid: {
        name: 'Left Offensive Midfield',
        shortName: 'LOM',
        description:
            'Number of stints a player has had in the Left Offensive Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_right_offensive_mid: {
        name: 'Right Offensive Midfield',
        shortName: 'ROM',
        description:
            'Number of stints a player has had in the Right Offensive Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_centre_midfield_1: {
        name: 'Centre Midfield 1',
        shortName: 'CM1',
        description:
            'Number of stints a player has had in the Centre Midfield 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_centre_midfield_2: {
        name: 'Centre Midfield 2',
        shortName: 'CM2',
        description:
            'Number of stints a player has had in the Centre Midfield 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_left_midfield: {
        name: 'Left Midfield',
        shortName: 'LM',
        description:
            'Number of stints a player has had in the Left Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_right_midfield: {
        name: 'Right Midfield',
        shortName: 'RM',
        description:
            'Number of stints a player has had in the Right Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_left_defensive_midfield: {
        name: 'Left Defensive Midfield',
        shortName: 'LDM',
        description:
            'Number of stints a player has had in the Left Defensive Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_right_defensive_midfield: {
        name: 'Right Defensive Midfield',
        shortName: 'RDM',
        description:
            'Number of stints a player has had in the Right Defensive Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_defensive_midfield: {
        name: 'Defensive Midfield',
        shortName: 'DM',
        description:
            'Number of stints a player has had in the Defensive Midfield position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_left_wing_back: {
        name: 'Left Wing Back',
        shortName: 'LWB',
        description:
            'Number of stints a player has had in the Left Wing Back position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_right_wing_back: {
        name: 'Right Wing Back',
        shortName: 'RWB',
        description:
            'Number of stints a player has had in the Right Wing Back position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_left_full_back: {
        name: 'Left Full Back',
        shortName: 'LFB',
        description:
            'Number of stints a player has had in the Left Full Back position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_right_full_back: {
        name: 'Right Full Back',
        shortName: 'RFB',
        description:
            'Number of stints a player has had in the Right Full Back position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__fball_sweeper: {
        name: 'Sweeper',
        shortName: 'SW',
        description:
            'Number of stints a player has had in the Sweeper position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    // Begin netball
    timePos__lacr_attack_1: {
        name: 'Attack 1',
        shortName: 'A1',
        description:
            'Time a player has spent playing in the Attack 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_attack_2: {
        name: 'Attack 2',
        shortName: 'A2',
        description:
            'Time a player has spent playing in the Attack 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_attack_3: {
        name: 'Attack 3',
        shortName: 'A3',
        description:
            'Time a player has spent playing in the Attack 3 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_mid_1: {
        name: 'Mid 1',
        shortName: 'M1',
        description:
            'Time a player has spent playing in the Mid 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_mid_2: {
        name: 'Mid 2',
        shortName: 'M2',
        description:
            'Time a player has spent playing in the Mid 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_mid_3: {
        name: 'Mid 3',
        shortName: 'M3',
        description:
            'Time a player has spent playing in the Mid 3 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_defence_1: {
        name: 'Defence 1',
        shortName: 'D1',
        description:
            'Time a player has spent playing in the Defence 1 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_defence_2: {
        name: 'Defence 2',
        shortName: 'D2',
        description:
            'Time a player has spent playing in the Defence 2 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_defence_3: {
        name: 'Defence 3',
        shortName: 'D3',
        description:
            'Time a player has spent playing in the Defence 3 position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    timePos__lacr_goal_keeper: {
        name: 'Goal Keeper',
        shortName: 'GK',
        description:
            'Time a player has spent playing in the Goal Keeper position (stints).',
        category: TimeCategories.Position,
        type: StatTypes.Time,
    },
    countStintPos__lacr_attack_1: {
        name: 'Attack 1',
        shortName: 'A1',
        description:
            'Number of stints a player has had in the Attack 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_attack_2: {
        name: 'Attack 2',
        shortName: 'A2',
        description:
            'Number of stints a player has had in the Attack 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_attack_3: {
        name: 'Attack 3',
        shortName: 'A3',
        description:
            'Number of stints a player has had in the Attack 3 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_mid_1: {
        name: 'Mid 1',
        shortName: 'M1',
        description:
            'Number of stints a player has had in the Mid 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_mid_2: {
        name: 'Mid 2',
        shortName: 'M2',
        description:
            'Number of stints a player has had in the Mid 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_mid_3: {
        name: 'Mid 3',
        shortName: 'M3',
        description:
            'Number of stints a player has had in the Mid 3 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_defence_1: {
        name: 'Defence 1',
        shortName: 'D1',
        description:
            'Number of stints a player has had in the Defence 1 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_defence_2: {
        name: 'Defence 2',
        shortName: 'D2',
        description:
            'Number of stints a player has had in the Defence 2 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_defence_3: {
        name: 'Defence 3',
        shortName: 'D3',
        description:
            'Number of stints a player has had in the Defence 3 position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
    countStintPos__lacr_goal_keeper: {
        name: 'Goal Keeper',
        shortName: 'GK',
        description:
            'Number of stints a player has had in the Goal Keeper position (stints).',
        category: TimeCategories.General,
        type: StatTypes.Count,
    },
};
