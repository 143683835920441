import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

export type TokenEndpointResponse = {
  id_token: string;
  access_token: string;
  refresh_token?: string;
  expires_in: number;
  scope?: string;
};

let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<TokenEndpointResponse>;

export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: (options?: GetTokenSilentlyOptions | undefined) => Promise<TokenEndpointResponse>) =>
    (getAccessTokenSilently = func),
};