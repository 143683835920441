import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    useUpdateAssociationSeasonMutation,
    useUpdateTeamSeasonMutation,
} from '../../api/seasons';

import { ButtonProps } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { ProgressBar } from 'primereact/progressbar';

import { SeasonFormData } from '../../types/seasons';
import { useContext } from 'react';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';
import RookieButton from '../../components/RookieButton';
import FormGroup from '../../components/FormGroup';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';

interface Props {
    actions?: ButtonProps[];
    seasonID?: string;
    associationID?: string;
    teamID?: string;
    initialValues?: any;
    onCancel?: () => void;
    onError?: () => void;
    onSuccess?: () => void;
}

const MAX_PERIODS = 10;
const MAX_PERIOD_LENGTH = 50;

const periodOptions = Array.from({ length: MAX_PERIODS }).map((u, i) => ({
    label: i + 1,
    value: i + 1,
}));

const periodLengthOptions = Array.from({ length: MAX_PERIOD_LENGTH }).map(
    (u, i) => ({
        label: `${i + 1} min`,
        value: i + 1,
    })
);

const availableFields: Array<keyof SeasonFormData> = [
    'maxOnField',
    'maxOnBench',
    'periods',
    'periodLength',
    'timeOn',
    'estimatedPeriodLength',
    'maxPlayers',
    'autoEndPeriod',
    'clockModeUp',
    'showAssessment',
    'showDisciplinary',
    'showOpposition',
    'showInjury',
];

const SeasonSettings = (props: Props) => {
    const {
        actions,
        initialValues,
        seasonID,
        teamID,
        associationID,
        onSuccess,
        onError,
    } = props;

    const useUpdateSeasonMutation = associationID
        ? useUpdateAssociationSeasonMutation
        : useUpdateTeamSeasonMutation;
    const [updateSeason] = useUpdateSeasonMutation();

    const toast = useContext(ToastContext);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleSubmit = (data: SeasonFormData) => {
        if ((associationID || teamID) && seasonID) {
            updateSeason({
                teamID,
                associationID,
                seasonID,
                ...data,
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        summary: 'Success',
                        detail: `Updated ${
                            'data' in response ? response.data.seasonName : ''
                        } settings`,
                    });

                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: `Could not update settings.`,
                    });
                    if (onError) {
                        onError();
                    }
                });
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={pick(initialValues, availableFields)}
            onSubmit={handleSubmit}
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormFields row divider>
                            <FormGroup label="Periods" htmlFor="periods">
                                <Dropdown
                                    inputId="periods"
                                    name="periods"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Periods"
                                    value={values.periods}
                                    options={periodOptions}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Period Length"
                                htmlFor="periodLength"
                            >
                                <Dropdown
                                    inputId="periodLength"
                                    name="periodLength"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Period Length"
                                    value={values.periodLength}
                                    options={periodLengthOptions}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Auto-end Period"
                                htmlFor="autoEndPeriod"
                            >
                                <InputSwitch
                                    onChange={(e) => {
                                        setFieldValue('autoEndPeriod', e.value);
                                    }}
                                    inputId="autoEndPeriod"
                                    name="autoEndPeriod"
                                    checked={values.autoEndPeriod}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Clock Mode Up"
                                htmlFor="clockModeUp"
                            >
                                <InputSwitch
                                    onChange={(e) => {
                                        setFieldValue('clockModeUp', e.value);
                                    }}
                                    inputId="clockModeUp"
                                    name="clockModeUp"
                                    checked={values.clockModeUp}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Show Assessment"
                                htmlFor="showAssessment"
                            >
                                <InputSwitch
                                    onChange={(e) => {
                                        setFieldValue(
                                            'showAssessment',
                                            e.value
                                        );
                                    }}
                                    inputId="showAssessment"
                                    name="showAssessment"
                                    checked={values?.showAssessment}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Show Disciplinary"
                                htmlFor="showDisciplinary"
                            >
                                <InputSwitch
                                    onChange={(e) => {
                                        setFieldValue(
                                            'showDisciplinary',
                                            e.value
                                        );
                                    }}
                                    inputId="showDisciplinary"
                                    name="showDisciplinary"
                                    checked={values?.showDisciplinary}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Show Opposition"
                                htmlFor="showOpposition"
                            >
                                <InputSwitch
                                    onChange={(e) => {
                                        setFieldValue(
                                            'showOpposition',
                                            e.value
                                        );
                                    }}
                                    inputId="showOpposition"
                                    name="showOpposition"
                                    checked={values?.showOpposition}
                                    required
                                />
                            </FormGroup>
                            <FormGroup label="Show Injury" htmlFor="showInjury">
                                <InputSwitch
                                    onChange={(e) => {
                                        setFieldValue('showInjury', e.value);
                                    }}
                                    inputId="showInjury"
                                    name="showInjury"
                                    checked={values?.showInjury}
                                    required
                                />
                            </FormGroup>
                        </FormFields>
                        <FormActions
                            start={
                                actions &&
                                actions.map((action, i) => (
                                    <RookieButton
                                        key={`season-settings-action-${i}`}
                                        {...action}
                                    />
                                ))
                            }
                            end={
                                <RookieButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    label="Save Settings"
                                />
                            }
                        />

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

SeasonSettings.defaultProps = {
    initialValues: {
        periods: 4,
        periodLength: 15,
        maxPlayers: 25,
        autoEndPeriod: false,
        clockModeUp: true,
        showInjury: true,
        showAssessment: true,
        showDisciplinary: true,
        showOpposition: true,
    },
};

export default SeasonSettings;
