import { classNames } from 'primereact/utils';

type ColumnSize = 'full' | 'oneThird' | 'oneHalf';

interface SectionProps {
    children: React.ReactNode;
    className?: string;
}

interface ColumnProps {
    children: React.ReactNode;
    className?: string;
    size?: ColumnSize;
}

interface AnnotationProps {
    children: React.ReactNode;
    title: React.ReactNode;
    description?: string;
}

const PageSection = (props: SectionProps) => {
    const { children, className } = props;

    const columnsClass = classNames('section', className);

    return <div className={columnsClass}>{children}</div>;
};

export const Column = (props: ColumnProps) => {
    const { children, className, size } = props;

    const columnClass = classNames('column', className, {
        [`is-${size}`]: size,
    });

    return <div className={columnClass}>{children}</div>;
};

export const Annotation = (props: AnnotationProps) => {
    const { title, description, children } = props;

    return (
        <div className="annotated-section">
            <div className="annotation">
                {title && <h2 className="annotation-title">{title}</h2>}
                {description && (
                    <p className="annotation-desc">{description}</p>
                )}
            </div>
            <div className="annotation-content">{children}</div>
        </div>
    );
};

export default PageSection;
