import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';

import { useGetGameStatsBasicReportQuery } from '../../api/reports';
import { useGetPlayersQuery } from '../../api/players';

import { periodSuffix } from '../../util/helper';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

import { GameStat, ReportState } from '../../types/reports';
import { defaultReportState, defaultTooltipOptions } from './constants';
import { statKeys } from './gamePlayerStatsBasicConfig';
import ErrorDisplay from '../../components/ErrorDisplay';
import TextSwap from '../../components/TextSwap';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { StatConfig } from '../../types/sports';
import { Skeleton } from 'primereact/skeleton';
import { playerGroupCell, playerNameCell, textCell } from './DataTableCells';
import { useGetStatsQuery } from '../../api/stats';
import RookieButton from '../../components/RookieButton';
import { Mixpanel } from '../../util/mixpanel';
import { useGetEventQuery } from '../../api/events';
import { calculateTotals } from './helpers';

const GamePlayerStatsBasicReport = () => {
    // Route hooks
    const { teamID, eventID } = useParams();

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    // State hooks
    const [filter, setFilter] = useState(0);
    const [reportData, setReportData] =
        useState<ReportState<GameStat[]>>(defaultReportState);
    const [playersCursor, setPlayersCursor] = useState<string>('');

    // API hooks
    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'All',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const requestReportData = useGetGameStatsBasicReportQuery(
        {
            eventID: eventID || '',
            teamID: teamID || '',
            sessionID: timestampRef,
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const eventData = useGetEventQuery(
        {
            eventID: eventID || '',
            entityType: BaseEntityType.teams,
            entityID: teamID || '',
            expand: 'gameSummaryReport',
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const gameSummaryReport = useMemo(
        () => eventData?.data?.data.gameDetails?.gameSummaryReport,
        [eventData]
    );

    const statConfigData = useGetStatsQuery(
        {
            sportID: gameSummaryReport?.sportID || '',
        },
        {
            skip: !gameSummaryReport?.sportID,
        }
    );

    const totalPeriods = useMemo(
        () => gameSummaryReport?.gameSummary?.noPeriods || 0,
        [gameSummaryReport]
    );

    // Auto paginate players
    useEffect(() => {
        const { data } = playerData;

        if (
            data?.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor &&
            data.lastEvaluatedKey.cursor !== playersCursor
        ) {
            setPlayersCursor(data.lastEvaluatedKey.cursor);
        }
    }, [playerData, playersCursor]);

    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl) {
            setReportData((prev) => ({
                ...prev,
                error: null,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data: handleData(data),
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        }
    }, [requestReportData]);

    useEffect(() => {
        const players = playerData?.data?.data;

        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: GameStat) => ({
                        ...stat,
                        player:
                            players.find((p) => p.playerID === stat.playerID) ||
                            stat.playerID,
                    })),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerData, reportData.isLoading]);

    const getStatName = useCallback(
        (statID: string): string => {
            const stat = statConfigData?.data?.data.find(
                (stat) => stat.statID === statID
            );

            return stat?.statName || `Unknown Stat (${statID})`;
        },
        [statConfigData]
    );

    const handleData = (rawData: GameStat[]) => {
        const summed = calculateTotals(rawData, statKeys);

        return [...rawData, ...summed];
    };

    const tableValues = useMemo(() => {
        const filteredData = reportData.data
            ? reportData.data.filter(
                  (row: GameStat) => Number(row.period) === filter
              )
            : [];

        const sortedData = sortBy(
            filteredData,
            (s) => s.player && Number(s.player.uniformNumber)
        );

        return sortedData;
    }, [reportData, filter]);

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Basic Player Game Stats',
        });
    };

    const tableHeader = (
        <Toolbar
            start={
                <div className="p-buttonset">
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[{ label: 'All', value: 0 }, ...periodOptions]}
                    />
                </div>
            }
            end={
                <RookieButton
                    type="button"
                    onClick={() => exportCSV()}
                    label="Export CSV"
                    severity="secondary"
                />
            }
        />
    );

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const emptyMessage = () => {
        const status = requestReportData?.data?.data?.reportStatus;

        if (
            ['ErrorEvent', 'ErrorServer'].includes(status) ||
            requestReportData.isError ||
            reportData.isError
        ) {
            const errorMsg = requestReportData?.data?.data?.errorMessage;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={'An error has occurred.'}
                    desc={
                        errorMsg ||
                        'Try refreshing, if the issue persists please contact support'
                    }
                    errorType={ERROR_TYPES.somethingsWrong}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        if (['Queued', 'Started', 'Building'].includes(status)) {
            const msgs = [
                'Downloading Database...',
                'Fetching Players...',
                ...statKeys.map(
                    (statID) => `Fetching ${getStatName(statID)}s...`
                ),
            ];
            const eta = status === 'Queued' ? 5 : status === 'Started' ? 4 : 3;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={
                        <TextSwap
                            strings={msgs}
                            animationDuration="4s"
                            animationType="slideUp"
                            interval={4000}
                        />
                    }
                    desc={`Your report is getting built. Please refresh the page after ${eta} minutes.`}
                    errorType={ERROR_TYPES.maintenance}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        return (
            <ErrorDisplay
                hasReturn={false}
                title={'No game data found.'}
                desc="If you believe this to be an error, please contact support."
                errorType={ERROR_TYPES.empty}
                actions={[
                    {
                        label: 'Support',
                        command: () =>
                            window
                                .open(
                                    'https://www.rookieme.com/contact',
                                    '_blank'
                                )
                                ?.focus(),
                    },
                ]}
            />
        );
    };

    return (
        <DataTable
            ref={dataTable}
            header={tableHeader}
            value={
                requestReportData.isLoading || reportData.isLoading
                    ? Array(5)
                    : tableValues
            }
            footer={tableFooter}
            emptyMessage={emptyMessage()}
            columnResizeMode="expand"
            resizableColumns
            exportFilename="player-stats-basic-report"
        >
            <Column
                field="player.uniformNumber"
                header="#"
                sortable={true}
                body={textCell}
            />
            <Column
                field="player.lastName"
                header="Name"
                sortable={true}
                body={playerNameCell}
                exportable={false}
            />
            <Column
                field="player.firstName"
                header="First Name"
                hidden={true}
            />
            <Column field="player.lastName" header="Last Name" hidden={true} />
            <Column
                field="player.colour"
                header="Group"
                sortable={true}
                body={playerGroupCell}
            />
            <Column />

            {statKeys.map((key) => {
                const stat = statConfigData?.data?.data?.find(
                    (s: StatConfig) => s.statID === key
                );

                return (
                    <Column
                        key={key}
                        field={key}
                        header={
                            statConfigData.isLoading || !stat ? (
                                <Skeleton width="1rem" height="1rem" />
                            ) : (
                                stat.statShortName
                            )
                        }
                        headerTooltip={stat?.statName}
                        headerTooltipOptions={defaultTooltipOptions}
                        sortable={true}
                        body={textCell}
                    />
                );
            })}
        </DataTable>
    );
};

export default GamePlayerStatsBasicReport;
