import { User } from '@auth0/auth0-react';

import {
    TeamGrade,
    TeamSport,
    TeamCategory,
    TeamGender,
    TeamAge,
} from '../types/team';

export const teamGradeOptions = Object.keys(TeamGrade).map((key, i) => ({
    label: Object.values(TeamGrade)[i],
    value: key,
}));

export const teamSportOptions = Object.keys(TeamSport).map((key, i) => ({
    label: Object.values(TeamSport)[i],
    value: key,
}));

export const teamTypeOptions = Object.keys(TeamCategory).map((key, i) => ({
    label: Object.values(TeamCategory)[i],
    value: key,
}));

export const teamGenderOptions = Object.keys(TeamGender).map((key, i) => ({
    label: Object.values(TeamGender)[i],
    value: key,
}));

export const teamAgeOptions = Object.values(TeamAge).map((val, i) => ({
    label: val,
    value: val,
}));

export const ID_TOKEN_PARAM = 'id_token';
export const CUSTOM_TOKEN_ID = 'custom_token_id';
export const USE_CUSTOM_TOKEN = 'use_custom_token';

export const AUTH_UUID_KEY = 'https://passport.rookieme.com/uuid';

export const loginSourceMap = {
    unknown: 'Unknown',
    'google-oauth2': 'Google',
    auth0: 'Auth0',
    facebook: 'Facebook',
    twitter: 'Twitter',
    'oauth2|CoachAFL': 'Coach AFL',
} as keyof User['sub'];

export const entityNameMap = {
    users: 'user',
    teams: 'team',
    organisations: 'organisation',
    associations: 'association',
};
