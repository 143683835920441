import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import EntityPreview from '../../components/EntityPreview';
import Loader from '../../components/Loader';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';

import CollectionsForm from './CollectionsForm';
import AddToAssociationCollectionForm from './AddToAssociationCollectionForm';
import AddToOrganisationCollectionForm from './AddToOrganisationCollectionForm';

import { BaseEntityType } from '../../types/common';
import { Collection } from '../../types/collections';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { Team } from '../../types/team';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    collectionID: string | undefined;
    collection: Collection | undefined;
    collectionTeams: Team[] | undefined;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    showPagination: boolean;
    onLoadMore?: () => void;
    onDelete?: (collectionID: string) => void;
    onEdit?: (collection: Collection) => void;
    roles: Roles;
    onCloseEditModal: () => void;
    editing: boolean;
    showAddModal: boolean;
    onToggleAddModal: () => void;
    onRemoveTeam: (teamID: string) => void;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const CollectionSingleView = (props: Props) => {
    return (
        <PageContainer>
            <PageHeader
                title={props.collection?.collectionName || 'Collection'}
                actions={
                    props.permissions.canCreate
                        ? [
                              {
                                  label: 'Edit',
                                  command: props.onEdit,
                                  severity: 'secondary',
                              },
                              {
                                  label: 'Add Team',
                                  command: props.onToggleAddModal,
                              },
                          ]
                        : []
                }
            />

            {props.isLoading ? (
                <Loader />
            ) : (
                <DataTable
                    value={props.collectionTeams}
                    footer={
                        props.showPagination &&
                        props.onLoadMore && (
                            <RookieButton
                                label="Load More"
                                onClick={props.onLoadMore}
                                size="large"
                                className="load-more"
                                severity="secondary"
                                loading={props.isFetching}
                            />
                        )
                    }
                >
                    <Column
                        header="Team Name"
                        field="teamName"
                        body={(row) => {
                            return (
                                <ListItem
                                    title={row.teamName}
                                    start={
                                        <EntityPreview
                                            design={row.design}
                                            style={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '4px',
                                            }}
                                        />
                                    }
                                />
                            );
                        }}
                    />
                    <Column header="Age" field="age" />
                    <Column header="Gender" field="gender" />
                    <Column header="Grade" field="grade" />
                    <Column
                        body={(row) => (
                            <RookieButton
                                onClick={() => props.onRemoveTeam(row.teamID)}
                                icon="remove"
                                severity="secondary"
                            />
                        )}
                        align="right"
                    />
                </DataTable>
            )}
            {props.entityType && props.entityID && (
                <Dialog
                    onHide={props.onCloseEditModal}
                    visible={props.editing}
                    header={
                        props.editing ? 'Edit Collection' : 'Create Collection'
                    }
                    resizable={false}
                    draggable={false}
                >
                    <CollectionsForm
                        collection={props.collection}
                        entityType={props.entityType}
                        entityID={props.entityID}
                        onUpdate={() => props.onCloseEditModal()}
                        onCreate={() => props.onCloseEditModal()}
                        onError={() => props.onCloseEditModal()}
                    />
                </Dialog>
            )}
            {props.entityType && props.entityID && props.collectionID && (
                <Sidebar
                    onHide={props.onToggleAddModal}
                    visible={props.showAddModal}
                    position="right"
                    header="Select Teams"
                >
                    {props.entityType === BaseEntityType.associations ? (
                        <AddToAssociationCollectionForm
                            collectionID={props.collectionID}
                            entityID={props.entityID}
                            entityType={props.entityType}
                            disabledIDs={props.collectionTeams?.map(
                                (team) => team.teamID
                            )}
                            onComplete={props.onToggleAddModal}
                        />
                    ) : (
                        <AddToOrganisationCollectionForm
                            collectionID={props.collectionID}
                            entityID={props.entityID}
                            entityType={props.entityType}
                            disabledIDs={props.collectionTeams?.map(
                                (team) => team.teamID
                            )}
                            onComplete={props.onToggleAddModal}
                        />
                    )}
                </Sidebar>
            )}
        </PageContainer>
    );
};

export default CollectionSingleView;
