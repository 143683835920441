import { useGetMeQuery } from '../api/core';
import { useGetNotificationsQuery } from '../api/notifications';
import {
    Notification,
    NotificationStatus,
    NotificationType,
} from '../types/notification';
import { Link } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { entityMap } from '../routes/routes';
import Loader from './Loader';
import ErrorDisplay from './ErrorDisplay';

interface Props {
    notification: Notification;
}

const NotificationItem = ({
    notification: { actor, entity, node, notificationType, status },
}: Props) => {
    const entityRoute = entityMap[entity.entityType].urlSegment;
    const entityID = entity.entityID;
    const nodeLink = `${entityRoute}/${entityID}/${node.entityType}/${node.entityID}`;
    let content = '';

    switch (notificationType) {
        case NotificationType.EventInvite:
            content = `${actor.name} has invited you to the event ${node.name}`;
            break;
        case NotificationType.EventUpdate:
            content = `${actor.name} has updated the event ${node.name}`;
            break;
        case NotificationType.TeamInvite:
            content = `${actor.name} has joined the team ${node.name}`;
            break;
    }

    return (
        <div
            className={`notification-item${
                status === NotificationStatus.Unread ? ' is-unread' : ''
            } `}
        >
            <Link to={nodeLink} className="notification-link">
                <Avatar image={actor.picture} className="notification-img" />
                <div className="notification-content">{content}</div>
            </Link>
        </div>
    );
};

const Notifications = () => {
    const me = useGetMeQuery();
    const userID = me.data?.data.userID;
    const { data, isLoading, isError } = useGetNotificationsQuery(
        { userID: userID || '' },
        {
            skip: !userID,
        }
    );

    const renderLoader = () => <Loader />;

    const renderError = () => (
        <ErrorDisplay
            proportion="compact"
            hasReturn={false}
            title="Error loading notifications."
        />
    );

    const renderNotifications = () => {
        return data?.data.map((notice) => (
            <NotificationItem
                key={notice.notificationID}
                notification={notice}
            />
        ));
    };

    return (
        <div className="notifications">
            <div className="notifications-header">
                <h5 className="notifications-title">Notifications</h5>
            </div>
            <div className="notifications-body">
                {isLoading
                    ? renderLoader()
                    : isError
                    ? renderError()
                    : renderNotifications()}
            </div>
        </div>
    );
};

export default Notifications;
