import { toUpper } from 'lodash';

import { useGetAssociationQuery } from '../api/associations';
import { useGetOrganisationQuery } from '../api/organisations';
import { useGetTeamQuery } from '../api/teams';

import { BaseEntityType } from '../types/common';

import { Avatar } from 'primereact/avatar';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
}

const EntityName = (props: Props) => {
    const associationData = useGetAssociationQuery(
        { associationID: props.entityID },
        { skip: props.entityType !== BaseEntityType.associations }
    );

    const organisationData = useGetOrganisationQuery(
        { organisationID: props.entityID },
        { skip: props.entityType !== BaseEntityType.organisations }
    );
    const teamData = useGetTeamQuery(
        { teamID: props.entityID },
        {
            skip: props.entityType !== BaseEntityType.teams,
        }
    );

    let entity;
    if (associationData.data?.data) {
        entity = {
            entityName: associationData.data.data.associationName,
            jumperKit: associationData.data.data.jumperKit,
        };
    } else if (organisationData.data?.data) {
        entity = {
            entityName: organisationData.data.data.organisationName,
            jumperKit: organisationData.data.data.jumperKit,
        };
    } else if (teamData.data?.data) {
        entity = {
            entityName: teamData.data.data.teamName,
            jumperKit: teamData.data.data.jumperKit,
        };
    }

    if (!entity) {
        return null;
    }

    return (
        <div className="detail-cell">
            <Avatar
                template={
                    entity.jumperKit && (
                        <span
                            className="svg-image"
                            dangerouslySetInnerHTML={{
                                __html: entity.jumperKit,
                            }}
                        />
                    )
                }
                label={toUpper(entity.entityName.slice(0, 2))}
            />
            <span>{entity.entityName}</span>
        </div>
    );
};

export default EntityName;
