import DocumentHead from '../../components/DocumentHead';
import CompleteAccountView from './CompleteAccountView';

const CompleteAccountContainer = () => {
    return (
        <>
            <DocumentHead
                title="Complete Registration - Rookie Me Hub"
                description="Complete Account Registration"
            />
            <CompleteAccountView />
        </>
    );
};

export default CompleteAccountContainer;
