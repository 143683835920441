import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';

import { apiEndpoints, prepareHeaders } from './apiEndpoints';

import {
    Association,
    AssociationFormData,
    AssociationResponse,
} from '../types/association';
import {
    Organisation,
    OrganisationFormData,
    OrganisationResponse,
    OrganisationsResponse,
} from '../types/organisation';

export const associationsApi = createApi({
    reducerPath: 'associationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Association', 'Organisation'],
    endpoints: (builder) => ({
        getAssociation: builder.query<AssociationResponse, AssociationFormData>(
            {
                query: ({ associationID }) => ({
                    url: generatePath(apiEndpoints.getAssociation.url, {
                        associationID,
                    }),
                    method: apiEndpoints.getAssociation.method,
                    params: { expand: 'jumperKit' },
                }),
                providesTags: ['Association'],
            }
        ),
        createAssociation: builder.mutation<Association, AssociationFormData>({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.createAssociation.url, {
                    userID,
                }),
                method: apiEndpoints.createAssociation.method,
                body: data,
            }),
            invalidatesTags: ['Association'],
        }),
        updateAssociation: builder.mutation<Association, AssociationFormData>({
            query: ({ associationID, ...data }) => ({
                url: generatePath(apiEndpoints.updateAssociation.url, {
                    associationID,
                }),
                method: apiEndpoints.updateAssociation.method,
                body: data,
            }),
            invalidatesTags: ['Association'],
        }),
        upsertAssociation: builder.mutation<Association, AssociationFormData>({
            query: ({ associationID, ...data }) => ({
                url: !associationID
                    ? apiEndpoints.createAssociation.url
                    : generatePath(apiEndpoints.updateAssociation.url, {
                          associationID,
                      }),
                method: !associationID
                    ? apiEndpoints.createAssociation.method
                    : apiEndpoints.updateAssociation.method,
                body: data,
            }),
            invalidatesTags: ['Association'],
        }),
        deleteAssociation: builder.mutation<void, AssociationFormData>({
            query: ({ associationID }) => ({
                url: generatePath(apiEndpoints.deleteAssociation.url, {
                    associationID,
                }),
                method: apiEndpoints.deleteAssociation.method,
            }),
            invalidatesTags: ['Association'],
        }),

        /**
         *
         * Association Organisations
         *
         **/
        getAssociationOrganisations: builder.query<
            OrganisationsResponse,
            AssociationFormData
        >({
            query: ({ associationID, ...params }) => ({
                url: generatePath(
                    apiEndpoints.getAssociationOrganisations.url,
                    { associationID }
                ),
                method: apiEndpoints.getAssociationOrganisations.method,
                params,
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ organisationID }) => ({
                              type: 'Organisation' as const,
                              organisationID,
                          })),
                          'Organisation',
                      ]
                    : ['Organisation'];
            },
        }),
        createAssociationOrganisation: builder.mutation<
            OrganisationResponse,
            OrganisationFormData
        >({
            query: ({ associationID, organisationID, ...data }) => ({
                url: generatePath(
                    apiEndpoints.createAssociationOrganisation.url,
                    {
                        associationID,
                        organisationID,
                    }
                ),
                method: apiEndpoints.createAssociationOrganisation.method,
                body: data,
            }),
            invalidatesTags: ['Organisation'],
        }),
        updateAssociationOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ organisationID, associationID, ...data }) => ({
                url: generatePath(
                    apiEndpoints.updateAssociationOrganisation.url,
                    {
                        organisationID,
                        associationID,
                    }
                ),
                method: apiEndpoints.updateAssociationOrganisation.method,
                body: data,
            }),
            invalidatesTags: ['Organisation'],
        }),
        upsertAssociationOrganisation: builder.mutation<
            Organisation,
            OrganisationFormData
        >({
            query: ({ associationID, organisationID, ...data }) => ({
                url: generatePath(
                    organisationID
                        ? apiEndpoints.updateAssociationOrganisation.url
                        : apiEndpoints.createAssociationTeam.url,
                    {
                        associationID,
                        organisationID,
                    }
                ),
                method: organisationID
                    ? apiEndpoints.updateAssociationOrganisation.method
                    : apiEndpoints.createAssociationTeam.method,
                body: data,
            }),
        }),
        deleteAssociationOrganisation: builder.mutation<
            void,
            { associationID: string; organisationID: string }
        >({
            query: ({ organisationID, associationID }) => ({
                url: generatePath(
                    apiEndpoints.deleteAssociationOrganisation.url,
                    {
                        organisationID,
                        associationID,
                    }
                ),
                method: apiEndpoints.deleteAssociationOrganisation.method,
            }),
            invalidatesTags: ['Organisation'],
        }),
    }),
});

export const {
    useGetAssociationQuery,
    useCreateAssociationMutation,
    useUpdateAssociationMutation,
    useUpsertAssociationMutation,
    useDeleteAssociationMutation,
    useGetAssociationOrganisationsQuery,
    useLazyGetAssociationOrganisationsQuery,
    useCreateAssociationOrganisationMutation,
    useUpdateAssociationOrganisationMutation,
    useUpsertAssociationOrganisationMutation,
    useDeleteAssociationOrganisationMutation,
} = associationsApi;
