import { useParams } from 'react-router-dom';
import { useGetAssociationQuery } from '../../api/associations';
import { Skeleton } from 'primereact/skeleton';

const AssociationBreadcrumb = () => {
    const { associationID } = useParams();

    const { data, isLoading, isError } = useGetAssociationQuery(
        {
            associationID: associationID || '',
        },
        {
            skip: !associationID,
        }
    );

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;

    if (isError) return 'Association';

    return data?.data?.associationName;
};

export default AssociationBreadcrumb;
