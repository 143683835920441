import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import OrganisationLicenceSingleView from './OrganisationLicenceSingleView.tsx';

import {
    useAssignLicenceMutation,
    useGetLicenceGroupLicencesQuery,
    useGetLicenceGroupQuery,
    useUnAssignLicenceMutation,
} from '../../api/licences';

import { BaseEntityType } from '../../types/common';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { Team } from '../../types/team';
import usePermission from '../../hooks/usePermission';
import { Permissions } from '../../types/permissions';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const OrganisationLicenceSingleContainer = (props: Props) => {
    const { user } = props;
    const { organisationID, licenceGroupID } = useParams();
    const navigate = useNavigate();

    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const { checkPermission } = usePermission(organisationID || '');

    const { data: licencesRaw, isLoading } = useGetLicenceGroupLicencesQuery(
        {
            entityType: BaseEntityType.organisations,
            // @ts-ignore
            entityID: organisationID,
            // @ts-ignore
            licenceGroupID,
        },
        {
            skip: !organisationID || !licenceGroupID,
        }
    );

    const { data: licenceGroup } = useGetLicenceGroupQuery(
        {
            entityType: BaseEntityType.organisations,
            // @ts-ignore
            entityID: organisationID,
            // @ts-ignore
            licenceGroupID,
        },
        {
            skip: !organisationID || !licenceGroupID,
        }
    );

    const [assignLicence] = useAssignLicenceMutation();
    const [unassignLicence] = useUnAssignLicenceMutation();

    const handleAssignLicence = (groupID: string, teamsIDList: Team[]) => {
        if (organisationID && licenceGroupID) {
            assignLicence({
                entityType: BaseEntityType.organisations,
                entityID: organisationID,
                teamsIDList: teamsIDList.map((team) => team.teamID),
                licenceGroupID,
            });
        }
    };

    const handleUnassignLicence = (licenceID: string) => {
        if (organisationID) {
            unassignLicence({
                entityType: BaseEntityType.organisations,
                entityID: organisationID,
                licenceID,
            });
        }
    };

    const handleManageBilling = (subscriptionID: string) => {
        if (subscriptionID) {
            navigate(`/u/${user.userID}/subscriptions/${subscriptionID}`);
        }
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
    };

    return (
        <OrganisationLicenceSingleView
            licences={licencesRaw?.data || []}
            licenceGroup={licenceGroup?.data}
            onAssignLicence={handleAssignLicence}
            onUnassignLicence={handleUnassignLicence}
            showAssignModal={showAssignModal}
            showAddModal={showAddModal}
            onShowAddModal={() => setShowAddModal(true)}
            onCloseAddModal={() => setShowAddModal(false)}
            onShowAssignModal={() => setShowAssignModal(true)}
            onCloseAssignModal={() => setShowAssignModal(false)}
            onManageBilling={handleManageBilling}
            isLoading={isLoading}
            permissions={permissions}
            user={user}
        />
    );
};

export default OrganisationLicenceSingleContainer;
