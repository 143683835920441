import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import useGetRole from './useGetRole';
import { useGetMeQuery } from '../api/core';

import { UseQueryResult } from '../types/api';
import { Role } from '../types/roles';

type Scope = string[];
type PermissionHook = {
    checkPermission: (scope: Scope) => boolean;
    isAdmin: boolean;
    permissions: string[];
    role: UseQueryResult<Role>;
};

const usePermission = (entityID?: string, skip = false): PermissionHook => {
    const meData = useGetMeQuery(undefined, {
        skip,
    });
    const me = meData?.data?.data;
    const role = useGetRole(me?.userID, entityID);

    const permissions = useMemo(() => role.data?.permissions || [], [role]);
    const isAdmin = !!me?.admin;

    const checkPermission = React.useCallback(
        (scope: Scope) => {
            return (
                isEmpty(scope) ||
                isAdmin ||
                scope.some((p) => permissions.includes(p))
            );
        },
        [isAdmin, permissions]
    );

    return {
        checkPermission,
        isAdmin,
        permissions,
        role,
    };
};

export default usePermission;
