import { isEqual } from 'lodash';

import ErrorDisplay from '../../components/ErrorDisplay';

import { ERROR_TYPES } from '../../types/common';

import {
    CalendarProps,
    DateLocalizer,
    Event,
    Navigate,
    NavigateAction,
    ViewProps,
} from 'react-big-calendar';

interface Accessors {
    title?: (event: Event) => string;
    tooltip?: (event: Event) => string;
    end?: (event: Event) => Date;
    start?: (event: Event) => Date;
}

const inRange = (
    e: Event,
    start: Date,
    end: Date,
    accessors: Accessors,
    localizer: DateLocalizer
) => {
    if (!accessors.start || !accessors.end) return;

    const event = {
        start: accessors.start(e),
        end: accessors.end(e),
    };
    const range = {
        start: start,
        end: end,
    };

    // @ts-expect-error incorrect type from package should only accept one argument.
    return localizer.inEventRange({ event, range });
};

const isSelected = (event: Event, selected: object | undefined) => {
    if (!event || selected == null) return false;

    return isEqual(event, selected);
};

const CalendarAgenda = (props: ViewProps) => {
    const {
        accessors,
        components,
        date,
        getters,
        length,
        localizer,
        onDoubleClickEvent,
        onSelectEvent,
        selected,
    } = props;

    const messages = localizer.messages;
    const end = localizer.add(date, length, 'day');
    const range = localizer.range(date, end, 'day');
    let events = props.events;

    if (events) {
        events = events.filter((event) => {
            return inRange(
                event,
                localizer.startOf(date, 'day'),
                localizer.endOf(end, 'day'),
                accessors,
                localizer
            );
        });

        events.sort((a, b) => {
            return +accessors.start(a) - +accessors.start(b);
        });
    }

    const renderDay = (day: Date, events: Event[], dayKey: string) => {
        const Event = components.event;
        const Date = components.date;

        events = events.filter((e) => {
            return inRange(
                e,
                localizer.startOf(day, 'day'),
                localizer.endOf(day, 'day'),
                accessors,
                localizer
            );
        });

        return events.map((event: Event, idx: number) => {
            const title = accessors.title(event);
            const end = accessors.end(event);
            const start = accessors.start(event);
            const userProps = getters.eventProp(
                event,
                start,
                end,
                isSelected(event, selected)
            );
            const dateLabel =
                idx === 0 && localizer.format(day, 'agendaDateFormat');

            const first = idx === 0 && (
                <td rowSpan={events.length} className="rbc-agenda-date-cell">
                    {Date ? <Date day={day} label={dateLabel} /> : dateLabel}
                </td>
            );

            return (
                <tr key={dayKey + '_' + idx}>
                    {first}
                    <td className="rbc-agenda-event-cell">
                        <div
                            className={`rbc-event ${userProps.className}`}
                            style={userProps.style}
                            onClick={(e) =>
                                onSelectEvent && onSelectEvent(event, e)
                            }
                            onDoubleClick={(e) =>
                                onDoubleClickEvent &&
                                onDoubleClickEvent(event, e)
                            }
                        >
                            <div className="rbc-event-content">
                                {Event ? (
                                    <Event event={event} title={title} />
                                ) : (
                                    <div>
                                        <div>{timeRangeLabel(day, event)}</div>
                                        <div>{title}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const timeRangeLabel = (day: Date, event: Event) => {
        let labelClass = '';
        const TimeComponent = components.time;
        let label = localizer.messages.allDay;
        const end = accessors.end(event);
        const start = accessors.start(event);

        if (!accessors.allDay(event)) {
            if (localizer.eq(start, end)) {
                label = localizer.format(start, 'agendaTimeFormat');
            } else if (localizer.isSameDate(start, end)) {
                label = localizer.format(
                    {
                        start: start,
                        end: end,
                    },
                    'agendaTimeRangeFormat'
                );
            } else if (localizer.isSameDate(day, start)) {
                label = localizer.format(start, 'agendaTimeFormat');
            } else if (localizer.isSameDate(day, end)) {
                label = localizer.format(end, 'agendaTimeFormat');
            }
        }

        if (localizer.gt(day, start, 'day')) {
            labelClass = 'rbc-continues-prior';
        }

        if (localizer.lt(day, end, 'day')) {
            labelClass += ' rbc-continues-after';
        }

        return (
            <span className={labelClass.trim()}>
                {TimeComponent ? (
                    <TimeComponent event={event} day={day} label={label} />
                ) : (
                    label
                )}
            </span>
        );
    };

    return (
        <div className="rbc-agenda-view">
            {events?.length !== 0 ? (
                <>
                    <div className="rbc-agenda-content">
                        <table className="rbc-agenda-table">
                            <tbody>
                                {range.map((day: Date, idx: string) => {
                                    return (
                                        events && renderDay(day, events, idx)
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <span className="rbc-agenda-empty">
                    <ErrorDisplay
                        desc={messages.noEventsInRange}
                        errorType={ERROR_TYPES.fileError}
                        hasReturn={false}
                        proportion="enlarged"
                        title="No Events Found"
                    />
                </span>
            )}
        </div>
    );
};

CalendarAgenda.defaultProps = {
    length: 30,
};

CalendarAgenda.range = (start: Date, props: CalendarProps) => {
    console.log('PRO', props);
    const length = !props.length
        ? CalendarAgenda.defaultProps.length
        : props.length;
    const localizer = props.localizer;
    const end = localizer.add(start, length, 'day');

    return {
        start: start,
        end: end,
    };
};

CalendarAgenda.navigate = (
    date: Date,
    action: NavigateAction,
    props: CalendarProps
) => {
    const length = !props.length
        ? CalendarAgenda.defaultProps.length
        : props.length;
    const localizer = props.localizer;

    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -length, 'day');
        case Navigate.NEXT:
            return localizer.add(date, length, 'day');
        default:
            return date;
    }
};

CalendarAgenda.title = (start: Date, props: ViewProps) => {
    const length = !props.length
        ? CalendarAgenda.defaultProps.length
        : props.length;
    const localizer = props.localizer;
    const end = localizer.add(start, length, 'day');

    return localizer.format(
        {
            start: start,
            end: end,
        },
        'agendaHeaderFormat'
    );
};

export default CalendarAgenda;
