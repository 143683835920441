import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';

import { useCreateStaffInviteMutation } from '../../api/staff';
import { useGetEntityRolesQuery } from '../../api/core';

import { getEntityFromParam } from '../../util/helper';

import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';

import { INPUT_TYPES } from '../../types/common';
import { StaffFormData } from '../../types/team';
import { EntityRole } from '../../types/roles';
import { Mixpanel } from '../../util/mixpanel';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import { isEmpty } from 'lodash';

interface Props {
    formHasActions?: boolean;
    initialValues: StaffFormData;
    onCancel?: Function;
    onSubmit?: Function;
}

const requiredFields = ['email', 'roles'];

const StaffForm = (props: Props) => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const { formHasActions, initialValues, onSubmit } = props;

    const { data: staffData } = useGetEntityRolesQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not  exist
            entityID: activeEntity?.entityID,
            assignable: true,
        },
        { skip: !activeEntity }
    );

    const [createStaff] = useCreateStaffInviteMutation();

    const handleSubmit = (
        data: StaffFormData,
        helpers: FormikHelpers<StaffFormData>
    ) => {
        const { setSubmitting } = helpers;

        setSubmitting(true);

        if (activeEntity) {
            createStaff({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                email: data.email,
                roles: data.roles, // This should be an array of selected roles
                message: data.message, // The optional message
            })
                .then((response: any) => {
                    Mixpanel.track('Invite Staff');

                    if ('errors' in response) {
                        onSubmit && onSubmit('error', response);
                    } else {
                        onSubmit && onSubmit('success');
                    }
                })
                .catch((err: any) => {
                    onSubmit && onSubmit('error', err);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    // TODO: this seemingly still applies email to check to any field in form ?
    const staffFormValidation = (values: FormikValues) => {
        const errors: FormikErrors<StaffFormData> = {};

        requiredFields.forEach((field) => {
            if (!values[field] || isEmpty(values[field])) {
                errors[field as keyof StaffFormData] = 'Field cannot be blank';
            } else if (
                values['email'] &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values['email']
                )
            ) {
                errors['email' as keyof StaffFormData] =
                    'Invalid email address';
            }
        });

        return errors;
    };

    const initial: StaffFormData = {
        ...initialValues,
        email: '',
        roles: [],
        message: '',
    };

    return (
        <Formik
            initialValues={initial}
            onSubmit={handleSubmit}
            validate={staffFormValidation}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormFields>
                            <FormGroup
                                label="Email"
                                htmlFor="email"
                                error={errors.email}
                                showError={!!errors.email && touched.email}
                            >
                                <InputText
                                    id="email"
                                    name="email"
                                    type={INPUT_TYPES.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    required
                                    placeholder="Enter staff email address"
                                    disabled={isSubmitting}
                                />
                            </FormGroup>

                            {/* Roles checkboxes */}
                            <FormGroup
                                label="Role(s)"
                                error={errors.roles}
                                showError={!!errors.roles}
                            >
                                <List>
                                    {staffData?.data
                                        .filter(
                                            (roleData) =>
                                                !roleData.role.roleID.includes(
                                                    'Owner'
                                                )
                                        )
                                        .map(
                                            (
                                                roleData: EntityRole,
                                                index: number
                                            ) => {
                                                const {
                                                    roleID,
                                                    roleName,
                                                    roleDescription,
                                                } = roleData.role;

                                                return (
                                                    <ListItem
                                                        key={roleID}
                                                        multiline
                                                        start={
                                                            <Checkbox
                                                                inputId={`role-${index}`}
                                                                name="roles"
                                                                value={roleID}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        values.roles
                                                                    ) {
                                                                        const checkedRoles =
                                                                            values.roles.includes(
                                                                                roleID
                                                                            )
                                                                                ? values.roles.filter(
                                                                                      (
                                                                                          id
                                                                                      ) =>
                                                                                          id !==
                                                                                          roleID
                                                                                  )
                                                                                : [
                                                                                      ...values.roles,
                                                                                      roleID,
                                                                                  ];
                                                                        setFieldValue(
                                                                            'roles',
                                                                            checkedRoles
                                                                        );
                                                                    }
                                                                }}
                                                                checked={
                                                                    !!(
                                                                        values.roles &&
                                                                        values.roles.includes(
                                                                            roleID
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        caption={
                                                            roleDescription
                                                        }
                                                        title={
                                                            <label
                                                                htmlFor={`role-${index}`}
                                                                style={{
                                                                    fontSize:
                                                                        'inherit',
                                                                }}
                                                            >
                                                                {roleName}
                                                            </label>
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                </List>
                            </FormGroup>

                            {/* Optional message field */}
                            <FormGroup
                                label="Message (optional)"
                                htmlFor="message"
                            >
                                <InputTextarea
                                    id="message"
                                    name="message"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.message}
                                    rows={5}
                                    placeholder="Write a message for your staff"
                                />
                            </FormGroup>
                        </FormFields>

                        {formHasActions && (
                            <FormActions
                                start={
                                    <RookieButton
                                        severity="secondary"
                                        disabled={isSubmitting}
                                        onClick={() =>
                                            props.onCancel && props.onCancel()
                                        }
                                        type="button"
                                        label="Cancel"
                                    />
                                }
                                end={
                                    <RookieButton
                                        className={
                                            isSubmitting
                                                ? 'is-submitting'
                                                : undefined
                                        }
                                        type="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        label="Send Invite"
                                    />
                                }
                            />
                        )}

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

StaffForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        email: '',
    },
};

export default StaffForm;
