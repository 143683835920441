import {
    percentageCell,
    playerGroupCell,
    playerNameCell,
    textCell,
    timeCell,
} from './DataTableCells';

import { ReportColumn } from '../../types/reports';

export const metaKeys = [
    'player.firstName',
    'player.lastName',
    'player.colour',
    'player.uniformNumber',
];

export const statKeys = [
    'PT',
    'timeAbsent',
    'timeAssessment',
    'timeBench',
    'timeDisciplinary',
    'timeInjury',
    'timeOffField',
    'timeOnField',
    'timeOpposition',
    'zoneTimeBAC',
    'zoneTimeFOR',
    'zoneTimeMID',
];

export const gameTimeAdvancedColumns: ReportColumn[] = [
    {
        field: 'player.uniformNumber',
        name: 'Player Number',
        header: '#',
        shortName: '#',
        sortable: true,
        body: textCell,
    },
    {
        field: 'player.lastName',
        name: 'Name',
        header: 'Name',
        shortName: 'Name',
        sortable: true,
        body: playerNameCell,
        exportable: false,
    },
    {
        field: 'player.firstName',
        name: 'First Name',
        header: 'First Name',
        shortName: 'First Name',
        hidden: true,
    },
    {
        field: 'player.lastName',
        name: 'Last Name',
        header: 'Last Name',
        shortName: 'Last Name',
        hidden: true,
    },
    {
        field: 'player.colour',
        name: 'Group',
        header: 'Group',
        shortName: 'Group',
        sortable: true,
        body: playerGroupCell,
    },
    {
        field: 'totalPGT',
        name: 'Percentage Game Time',
        header: 'PGT',
        shortName: 'PGT',
        sortable: true,
        body: percentageCell,
        headerTooltip: 'Percentage Game Time',
        hsArticle: '662dc12f0cfcb4508af6a0fc',
    },
    {
        field: 'timeOnField',
        name: 'Field Time',
        header: 'PMT',
        shortName: 'PMT',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Percentage Match Time',
    },
    {
        field: 'timeBench',
        name: 'Bench Time',
        header: 'BEN',
        shortName: 'BEN',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Time on Bench',
    },
    {
        field: 'timeInjury',
        name: 'Injury Time',
        header: 'INJ',
        shortName: 'INJ',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Time in Injury',
    },
    {
        field: 'zoneTimeFOR',
        name: 'Forward Zone Time',
        header: 'FWD',
        shortName: 'FWD',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Forward Zone Time',
    },
    {
        field: 'zoneTimeMID',
        name: 'Midfield Zone Time',
        shortName: 'MID',
        header: 'MID',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Midfield Zone Time',
    },
    {
        field: 'zoneTimeBAC',
        name: 'Defense Zone Time',
        shortName: 'DEF',
        header: 'DEF',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Defense Zone Time',
    },
    {
        field: 'timeAssessment',
        name: 'Assessment Time',
        header: 'ASS',
        shortName: 'ASS',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Time in Assessment',
    },
    {
        field: 'timeDisciplinary',
        name: 'Disciplinary Time',
        header: 'DIS',
        shortName: 'DIS',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Time in Disciplinary',
    },
    {
        field: 'timeOpposition',
        name: 'Opposition Time',
        header: 'OPP',
        shortName: 'OPP',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Time with Opposition',
    },
    {
        field: 'timeAbsent',
        name: 'Absent Time',
        header: 'ABS',
        shortName: 'ABS',
        sortable: true,
        body: timeCell,
        headerTooltip: 'Time in Absent',
    },
];
