import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Sidebar } from 'primereact/sidebar';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';

import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import StaffActiveContainer from './StaffActiveContainer';
import StaffForm from './StaffForm';
import StaffPane from './StaffPane';
import StaffPendingContainer from './StaffPendingContainer';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { Action } from '../../types/common';

const STATUS_SUCCESS = 'success';

interface Props {
    onCloseDrawer: () => void;
    onCloseInviteDialog: () => void;
    onShowInviteDialog: () => void;
    roles: Roles;
    showInviteDialog: boolean;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const tabs = [
    {
        id: 'active',
        label: 'Active',
        component: StaffActiveContainer,
    },
    {
        id: 'pending',
        label: 'Pending',
        component: StaffPendingContainer,
    },
];

const StaffView = (props: Props) => {
    const staffFormToast = useRef<Toast>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab');
    const activeTabIndex = tabs.findIndex((t) => tab === t.id);

    const handleSubmitResult = (submitResult: string) => {
        const hasSubmitted = submitResult === STATUS_SUCCESS;

        staffFormToast.current?.show({
            severity: hasSubmitted ? 'success' : 'warn',
            summary: hasSubmitted
                ? 'Staff Member Invited'
                : 'Invitation Unsuccessful',
            detail: hasSubmitted
                ? 'The invitation has been successfully sent.'
                : 'The Invitation failed to send, please check details and try again.',
        });

        if (submitResult === 'success') {
            props.onCloseInviteDialog();
        }
    };

    const pageHeaderActions: Action[] = props.permissions.canCreate
        ? [
              {
                  key: 'create',
                  label: 'Add Staff',
                  command: props.onShowInviteDialog,
                  icon: 'add',
                  type: 'button',
              },
          ]
        : [];

    const TabContent = tabs[activeTabIndex]
        ? tabs[activeTabIndex].component
        : 'div';

    return (
        <PageContainer>
            <PageHeader actions={pageHeaderActions} title="Staff" />
            <TabMenu
                model={tabs}
                activeIndex={activeTabIndex}
                onTabChange={(e) => setSearchParams({ tab: tabs[e.index].id })}
            />

            <TabContent {...props} />

            <Sidebar
                position="right"
                onHide={props.onCloseDrawer}
                visible={searchParams.has('id')}
            >
                <StaffPane
                    staffID={searchParams.get('id')}
                    canEditRoles={props.permissions.canEdit}
                />
            </Sidebar>

            <Sidebar
                onHide={props.onCloseInviteDialog}
                header="Add Staff"
                visible={props.showInviteDialog}
                position="right"
            >
                <StaffForm
                    onCancel={props.onCloseInviteDialog}
                    onSubmit={handleSubmitResult}
                />
            </Sidebar>

            <Toast ref={staffFormToast} />
        </PageContainer>
    );
};

export default StaffView;
