import { useState } from 'react';
import { useCreatePutPresignedURLMutation } from '../api/resources';
import { PresignedOptions } from '../types/resources';
import { BaseEntityType } from '../types/common';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    onUpload?(event: ProgressEvent<EventTarget>): void;
    onError?(error: unknown): void;
    onProgress?(event: ProgressEvent<EventTarget>): void;
}

const useFileUpload = (props: Props) => {
    const { entityType, entityID, onError, onProgress, onUpload } = props;
    const [uploadProgress, setUploadProgress] = useState(0);
    const [createPresignedURL] = useCreatePutPresignedURLMutation();

    const getPresignedUrl = async (options: PresignedOptions) => {
        const response = await createPresignedURL({
            entityType: entityType,
            entityID: entityID,
            ...options,
        });

        if ('data' in response && response.data) {
            return response.data.data.signedUrl;
        } else {
            throw new Error('Failed to obtain presigned URL.');
        }
    };

    const uploadToPresignedUrl = async (presignedUrl: string, file: File) => {
        try {
            const xhr = new XMLHttpRequest();

            xhr.upload.onprogress = (event) => {
                if (onProgress) {
                    onProgress(event);
                }

                const percentCompleted = Math.round(
                    (event.loaded * 100) / event.total
                );
                setUploadProgress(percentCompleted);
            };

            xhr.open('PUT', presignedUrl, true);
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.timeout = 10000; // 10 seconds timeout
            xhr.ontimeout = () => {
                throw new Error('Upload timed out.');
            };

            xhr.onload = (event) => {
                if (xhr.status === 200) {
                    if (onUpload) {
                        onUpload(event);
                    }
                } else {
                    throw new Error(`Upload failed: ${xhr.statusText}`);
                }
            };

            xhr.onerror = () => {
                throw new Error('Network error');
            };

            xhr.send(file);
        } catch (error) {
            if (onError) {
                onError(error);
            }
        }
    };

    const uploadFile = async (file: File, options: PresignedOptions) => {
        try {
            if (!file) {
                throw new Error('No file selected.');
            }

            const presignedUrl = await getPresignedUrl(options);
            await uploadToPresignedUrl(presignedUrl, file);
        } catch (error) {
            if (onError) {
                onError(error);
            }
        }
    };

    return {
        uploadFile,
        uploadToPresignedUrl,
        uploadProgress,
    };
};

export default useFileUpload;
