import { StatConfig, StatTypes } from '../../types/reports';
export const columns = ['environmentCoeff', 'integrityScore', 'fairGameTime'];

export const niceFlags: { [key: string]: string } = {
    skippedTime: '• Clock Integirty',
    shortLongPeriod: '• Unexpected Period Length',
    playersWithZeroPGT: '• Absent Players Included in Game',
    outsideSchedTime: '• Unexpected Game Start Time',
    forceEndGame: '• Game Not Ended',
};

export const config: { [key: string]: StatConfig } = {
    environmentCoeff: {
        name: 'Enviroment Score',
        shortName: 'ENV',
        type: StatTypes.Count,
        hsArticle: '667e3204d5f6cf2baa1f05c3',
    },
    integrityScore: {
        name: 'Integrity Score',
        shortName: 'INT',
        type: StatTypes.Count,
        hsArticle: '6530a9986ea22a692042cc16',
        flagLabels: [
            'skippedTime',
            'shortLongPeriod',
            'playersWithZeroPGT',
            'outsideSchedTime',
            'forceEndGame',
            'shortLongPeriod',
        ],
    },
    fairGameTime: {
        name: 'Fair Game Time Score',
        shortName: 'FGT',
        type: StatTypes.Count,
        hsArticle: '6530a57b554393421d94c725',
    },
};
