import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { isValidToken, parseJwt } from '../util/helper';
import ErrorDisplay from '../components/ErrorDisplay';

import { Route } from '../types/route';
import { ERROR_TYPES } from '../types/common';

interface Props {
    children: React.ReactElement;
    route: Route;
}

const SessionRoute = ({ children }: Props) => {
    const [searchParams] = useSearchParams();
    const sessionID = searchParams.get('session_id');

    const error = (
        <ErrorDisplay
            errorType={ERROR_TYPES.somethingsWrong}
            desc="Session ID not provided or valid."
            title="An error has occurred"
            proportion="enlarged"
        />
    );

    if (!sessionID) {
        return error;
    } else {
        const parsedJWT = parseJwt(sessionID);
        const validJWT = isValidToken(parsedJWT);

        if (!validJWT) {
            return error;
        }
    }

    return children;
};

export default SessionRoute;
