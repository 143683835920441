export const metaKeys = [
    'player.firstName',
    'player.lastName',
    'player.colour',
    'player.uniformNumber',
];

export const statKeys = [
    'afl_scoring_g', // Goal
    'afl_scoring_b', // Behind
    'afl_scoring_rb', // Rushed Behind
    'afl_error_ms', // Missed Shot at Goal
];
