import {
    playerGroupCell,
    playerNameCell,
    textCell,
    timeCell,
} from './DataTableCells';

import { ReportColumn } from '../../types/reports';

export const metaKeys = [
    'player.firstName',
    'player.lastName',
    'player.colour',
    'player.uniformNumber',
];

export const statKeys = ['timeInjury', 'timeOffField', 'timeOnField', 'PT'];

export const gameTimeBasicColumns: ReportColumn[] = [
    {
        field: 'player.uniformNumber',
        name: 'Player Number',
        header: '#',
        shortName: '#',
        sortable: true,
        body: textCell,
    },
    {
        field: 'player.lastName',
        name: 'Name',
        header: 'Name',
        shortName: 'Name',
        sortable: true,
        body: playerNameCell,
        exportable: false,
    },
    {
        field: 'player.firstName',
        name: 'First Name',
        header: 'First Name',
        shortName: 'First Name',
        hidden: true,
    },
    {
        field: 'player.lastName',
        name: 'Last Name',
        header: 'Last Name',
        shortName: 'Last Name',
        hidden: true,
    },
    {
        field: 'player.colour',
        name: 'Group',
        header: 'Group',
        shortName: 'Group',
        sortable: true,
        body: playerGroupCell,
    },
    {
        field: 'timeOnField',
        name: 'On Field Time',
        header: 'ON',
        shortName: 'ON',
        sortable: true,
        body: timeCell,
    },
    {
        field: 'timeOffField',
        name: 'Off Field Time',
        header: 'OFF',
        shortName: 'OFF',
        sortable: true,
        body: timeCell,
    },
    {
        field: 'timeInjury',
        name: 'Injury Time',
        header: 'INJ',
        shortName: 'INJ',
        sortable: true,
        body: timeCell,
    },
];
