import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { upperFirst, isEmpty, groupBy, isEqual } from 'lodash';
import { entityMap } from '../routes/routes';
import { getActiveEntity } from '../util/helper';

import ErrorDisplay from './ErrorDisplay';
import ListItem from './ListItem';
import Icon from './Icon';

import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';

import { Role, Roles } from '../types/roles';
import { ERROR_TYPES } from '../types/common';
import { useGetMeQuery, useGetRolesQuery } from '../api/core';
import EntityAvatar from './EntityAvatar';
import { Me } from '../types/user';
import RookieButton from './RookieButton';

const ENTITY_LIMIT = 3;

interface Props {
    onEntityClick?(role: Role): any;
}

type GroupedRoles = Record<keyof Roles, Roles[keyof Roles][]>;

const defaultUser: Me = {
    admin: false,
    email: '',
    emailVerified: 'false',
    expiry: '',
    firstName: '',
    lastName: '',
    loginSource: '',
    picture: '',
    roles: [],
    userID: '',
};

const EntitySwitcher = ({ onEntityClick }: Props) => {
    // Route Helpers
    const { pathname } = useLocation();

    // API
    const { data: dataUser } = useGetMeQuery();
    const user =
        dataUser && dataUser.data ? (dataUser.data as Me) : defaultUser;

    const { data: dataRoles } = useGetRolesQuery(
        {
            userID: user && user.userID,
        },
        { skip: !user || !user.userID }
    );

    const roles = dataRoles && dataRoles.data ? dataRoles.data : [];

    // Ref Hooks
    const entityPopoverRef = useRef<OverlayPanel>(null);

    // State Hooks
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    // Active Entity
    const active = getActiveEntity(roles, pathname);

    const recentEntitiesStorage = localStorage.getItem('recentEntities');

    /**
     * @desc store recently used roles in LS and maintain correct order.
     * @param role
     */
    const handleRecentEntities = (role: Role) => {
        let recentEntities = JSON.parse(recentEntitiesStorage || '[]');

        // check if LS exists, if not create entry
        if (!recentEntitiesStorage) {
            localStorage.setItem(
                'recentEntities',
                JSON.stringify(recentEntities)
            );
        }

        const containsRole = recentEntities.some(
            (recentObj: Role) => recentObj.entityID === role.entityID
        );
        const isFirstInRecent =
            !isEmpty(recentEntities) &&
            recentEntities[0].entityID === role.entityID;

        // check if entity exists in recents list already before adding role to LS.
        // entityObj does exist within array but not at index 0
        if (containsRole && !isFirstInRecent) {
            // remove role from list before adding back as first item
            recentEntities = recentEntities.filter(
                (rec: Role) => role.entityID !== rec.entityID
            );
            recentEntities.unshift(role);

            // entityObj does not exist within array
        } else if (!containsRole) {
            recentEntities.unshift(role);
        }

        // check array length and trim if exceeds limit
        if (recentEntities.length > ENTITY_LIMIT) {
            recentEntities.length = ENTITY_LIMIT;
        }

        // re-apply recentEntities to LS array
        localStorage.setItem('recentEntities', JSON.stringify(recentEntities));
    };

    const renderMenuItem = (role: Role) => {
        const id = role.entityID;
        const type = role.entityType;
        const title = role.entityName;
        const caption = upperFirst(role.roles[0].roleName);
        const entityRoute = entityMap[type].urlSegment;
        const activeEntity = !isEmpty(active) && isEqual(active.entityID, id);

        return (
            <li key={id} className="p-menuitem">
                <div className="p-menuitem-content">
                    <Link
                        className="p-menuitem-link"
                        to={!activeEntity ? `/${entityRoute}/${id}` : '#'}
                        onClick={(e) => {
                            onEntityClick && onEntityClick(role);
                            entityPopoverRef.current &&
                                entityPopoverRef.current.toggle(e);
                            handleRecentEntities(role);
                        }}
                    >
                        <ListItem
                            start={
                                <EntityAvatar
                                    entityID={id}
                                    entityName={title}
                                    entityType={type}
                                />
                            }
                            title={upperFirst(title)}
                            caption={upperFirst(caption)}
                            end={
                                active &&
                                id === active.entityID && (
                                    <Icon name="check_circle" />
                                )
                            }
                            disablePadding
                            compact
                        />
                    </Link>
                </div>
            </li>
        );
    };

    const renderMenuGroup = (
        groupName: string,
        items: Role[],
        viewAllUrl?: string
    ) => {
        return (
            <>
                <div className="p-menuitem-row">
                    <div className="p-submenu-header">
                        {upperFirst(groupName)}
                    </div>
                    {viewAllUrl && (
                        <Link
                            className="entity-view-action"
                            to={viewAllUrl}
                            onClick={(e) => {
                                entityPopoverRef.current &&
                                    entityPopoverRef.current.toggle(e);
                            }}
                        >
                            <RookieButton label="View All" link size="small" />
                        </Link>
                    )}
                </div>
                <ul className="p-menu-list">
                    {items.map((item) => renderMenuItem(item))}
                </ul>
            </>
        );
    };

    /**
     * @desc remap roles into a format prime menuModel recognises, pass in custom template for items.
     * @returns JSX
     */
    const grouped = groupBy(roles, 'entityType') as GroupedRoles;
    const recent = recentEntitiesStorage && JSON.parse(recentEntitiesStorage);
    let menuItems: MenuItem[] = [];

    if (recent && recent.length > 0) {
        menuItems.push({
            label: 'recent',
            template: () => {
                return renderMenuGroup('recent', recent);
            },
        });
    }

    Object.keys(grouped).forEach((key) => {
        if (grouped[key as keyof Roles].length > 0) {
            const viewAllUrl = `/u/${user.userID}/entities/${key}`;
            let items = grouped[key as keyof Roles] as Role[];

            // filter out recent entities, so there's no duplicates and limit
            if (recent && recent.length > 0) {
                items = items
                    .filter(
                        (allRole) =>
                            !recent.some(
                                (recentRole: Role) =>
                                    allRole.entityID === recentRole.entityID
                            )
                    )
                    .slice(0, ENTITY_LIMIT);
            } else {
                items = items.slice(0, ENTITY_LIMIT);
            }

            items.length > 0 &&
                menuItems.push({
                    label: upperFirst(key),
                    template: () => {
                        return renderMenuGroup(key, items, viewAllUrl);
                    },
                });
        }
    });

    // visually entity switcher state indicator
    const entitySwitcherListItemIndicator = !isMenuVisible ? (
        <Icon name="unfold_more" />
    ) : (
        <Icon name="unfold_less" />
    );

    // entity switchers active item rendered within ListItem
    const entitySwitcherListItem = active ? (
        <ListItem
            start={
                <EntityAvatar
                    entityID={active.entityID}
                    entityName={active.entityName}
                    entityType={active.entityType}
                />
            }
            title={active.entityName}
            caption={active.roles ? active.roles[0].roleName : ''}
            end={entitySwitcherListItemIndicator}
            disablePadding={true}
            compact
        />
    ) : (
        <ListItem
            title="Select Entity..."
            end={entitySwitcherListItemIndicator}
            disablePadding={true}
            compact
        />
    );

    return (
        <>
            <div
                className="entity-switcher"
                onClick={(e) =>
                    entityPopoverRef.current &&
                    entityPopoverRef.current.toggle(e)
                }
                aria-controls="entity-popover"
                aria-haspopup
            >
                {entitySwitcherListItem}
            </div>
            <OverlayPanel
                id="entity-popover"
                className="entity-popover"
                ref={entityPopoverRef}
                onShow={() => setIsMenuVisible(true)}
                onHide={() => setIsMenuVisible(false)}
            >
                {isEmpty(roles) ? (
                    <ErrorDisplay
                        desc="Select an option below."
                        errorType={ERROR_TYPES.fileError}
                        hasReturn={false}
                        proportion="compact"
                        title="No Roles Found"
                    />
                ) : (
                    <Menu id="entity-menu" model={menuItems} />
                )}
            </OverlayPanel>
        </>
    );
};

export default EntitySwitcher;
