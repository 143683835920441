import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useGetStaffDetailsQuery } from '../../api/staff';
import { BaseEntityType } from '../../types/common';

import { useGetTeamParticipatingSeasonsQuery } from '../../api/seasons';

import { ConfirmDialog } from 'primereact/confirmdialog';

import TeamSeasonsParticipatingView from './TeamSeasonsParticipatingView';

const TeamSeasonsParticipatingContainer = () => {
    // Navigation Hooks
    const { teamID } = useParams();

    // State Hooks
    const [cursor, setCursor] = useState<string>('');

    // API Hooks
    const { data, isError, isLoading, isFetching } =
        useGetTeamParticipatingSeasonsQuery(
            {
                teamID: teamID || '',
                cursor,
            },
            {
                skip: !teamID,
            }
        );

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey?.cursor) {
            setCursor(data.lastEvaluatedKey.cursor);
        }
    };
    const { data: staffData } = useGetStaffDetailsQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: teamID ? teamID : '',
            cursor: cursor,
        },
        {
            skip: !teamID,
        }
    );
    
    return (
        <>
            <TeamSeasonsParticipatingView
                data={data?.data || []}
                staffData={staffData?.data || []}
                isLoading={isLoading}
                isError={isError}
                isFetching={isFetching}
                onLoadMore={handleLoadMore}
                showPagination={!isEmpty(cursor)}
            />
            <ConfirmDialog />
        </>
    );
};

export default TeamSeasonsParticipatingContainer;
