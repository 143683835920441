import { StatConfig, StatTypes } from '../../types/reports';

export const columns = {
    basic: [
        'afl_scoring_g',
        'afl_scoring_b',
        'afl_scoring_rb',
        'afl_scoring_rb',
        'afl_error_ms',
        'fhoc_scoring_fg',
        'fhoc_scoring_pcg',
        'fhoc_scoring_pg',
        'fhoc_scoring_ps',
        'fhoc_scoring_pm',
        'fhoc_scoring_sog',
        'fhoc_scoring_ms',
        'fhoc_scoring_sot',
        'net_scoring_goal',
        'net_scoring_mg',
        'net_scoring_ga',
        'net_scoring_sg',
        'net_scoring_msg',
        'net_scoring_3ps',
        'net_scoring_3psm',
        'bball_scoring_fg',
        'bball_scoring_3-pg',
        'bball_scoring_ftg',
        'fball_scoring_g',
        'fball_scoring_pg',
        'fball_scoring_fkg',
        'fball_scoring_opg',
        'lacr_scoring_g',
        'lacr_scoring_fpg',
        'lacr_scoring_w',
        'lacr_scoring_s',
    ],
    advanced: [
        'afl_assist_1__perc',
        'afl_possessions_50',
        'afl_error_50__minus',
        'afl_scoring_sa',
        'afl_scoring_b',
        'afl_scoring_acc',
        'afl_possessions_cc',
        'afl_error_cl',
        'afl_error_ftc',
        'afl_possessions_fk__plus',
        'afl_error_fb',
        'afl_scoring_g',
        'afl_possessions_gb',
        'afl_possessions_h',
        'afl_possessions_hr',
        'afl_possessions_hb',
        'afl_possessions_ho',
        'afl_possessions_i50',
        'afl_possessions_k',
        'afl_possessions_m',
        'afl_possessions_mc',
        'afl_error_md',
        'afl_possessions_mu',
        'afl_error_ms',
        'afl_assist_pa',
        'afl_possessions_r50',
        'afl_scoring_rb',
        'afl_assist_s',
        'afl_possessions_sc',
        'afl_assist_t',
        'afl_error_tm',
        'afl_possessions_tu__plus',
        'fhoc_scoring_fg',
        'fhoc_scoring_pcg',
        'fhoc_scoring_pg',
        'fhoc_scoring_ps',
        'fhoc_scoring_pm',
        'fhoc_scoring_sog',
        'fhoc_scoring_ms',
        'fhoc_scoring_sot',
        'fhoc_scoring_sis',
        'fhoc_non-scoring_ic',
        'fhoc_non-scoring_pc',
        'fhoc_non-scoring_sc',
        'fhoc_non-scoring_uc',
        'fhoc_non-scoring_lc',
        `fhoc_non-scoring_s`,
        'fhoc_non-scoring_f',
        'fhoc_non-scoring_os',
        'fhoc_non-scoring_ep',
        'fhoc_non-scoring_ip',
        'fhoc_non-scoring_ekp',
        'fhoc_non-scoring_ikp',
        'fhoc_non-scoring_d',
        'fhoc_non-scoring_fh',
        'fhoc_non-scoring_b',
        'net_scoring_goal',
        'net_scoring_mg',
        'net_scoring_ga',
        'net_scoring_sg',
        'net_scoring_msg',
        'net_scoring_3ps',
        'net_scoring_3psm',
        'net_non-scoring_t',
        'net_non-scoring_dr',
        'net_non-scoring_i',
        'net_non-scoring_p',
        'net_non-scoring_cpr',
        'net_non-scoring_or',
        'net_non-scoring_d',
        'bball_scoring_fg',
        'bball_scoring_fga',
        'bball_scoring_3-pg',
        'bball_scoring_3-pa',
        'bball_scoring_ftg',
        'bball_scoring_fta',
        'bball_scoring_sa',
        'bball_non-scoring_dr',
        'bball_non-scoring_or',
        'bball_non-scoring_s',
        'bball_non-scoring_b',
        'bball_non-scoring_t',
        'bball_non-scoring_pf',
        'fball_scoring_g',
        'fball_scoring_pg',
        'fball_scoring_fkg',
        'fball_scoring_opg',
        'fball_scoring_s',
        'fball_scoring_SoT',
        'fball_scoring_SoffT',
        'fball_scoring_ga',
        'fball_scoring_bs',
        'fball_non-scoring_t',
        'fball_non-scoring_cp',
        'fball_non-scoring_ip',
        'fball_non-scoring_tu',
        'fball_non-scoring_st',
        'fball_non-scoring_kp',
        'fball_non-scoring_d',
        'fball_non-scoring_c',
        'fball_non-scoring_fk',
        'fball_non-scoring_ti',
        'fball_non-scoring_gk',
        'fball_non-scoring_f',
        'fball_non-scoring_o',
        'fball_non-scoring_h',
        'fball_scoring_sv',
        'fball_scoring_sc',
        'fball_scoring_sb',
        'fball_scoring_kr',
        'lacr_scoring_g',
        'lacr_scoring_fpg',
        'lacr_scoring_w',
        'lacr_scoring_s',
        'lacr_scoring_bspos',
        'lacr_scoring_bsneg',
        'lacr_scoring_a',
        'lacr_non-scoring_pf',
        'lacr_non-scoring_p',
        'lacr_non-scoring_c',
        'lacr_non-scoring_cneg',
        'lacr_non-scoring_fopos',
        'lacr_non-scoring_foneg',
        'lacr_non-scoring_topos',
        'lacr_non-scoring_to',
        'lacr_non-scoring_gb',
        'lacr_non-scoring_dc',
        'lacr_non-scoring_fp',
        'lacr_non-scoring_tf',
    ],
    expert: [
        'afl_assist_1__perc',
        'afl_possessions_50',
        'afl_error_50__minus',
        'afl_scoring_b',
        'afl_scoring_acc',
        'afl_possessions_cc',
        'afl_scoring_sa',
        'afl_error_cl',
        'afl_error_ftc',
        'afl_possessions_fk__plus',
        'afl_error_fb',
        'afl_scoring_g',
        'afl_possessions_gb',
        'afl_possessions_h',
        'afl_possessions_hr',
        'afl_possessions_hb',
        'afl_possessions_ho',
        'afl_possessions_i50',
        'afl_possessions_k',
        'afl_possessions_m',
        'afl_possessions_mc',
        'afl_error_md',
        'afl_possessions_mu',
        'afl_error_ms',
        'afl_assist_pa',
        'afl_possessions_r50',
        'afl_scoring_rb',
        'afl_assist_s',
        'afl_possessions_sc',
        'afl_assist_t',
        'afl_error_tm',
        'afl_possessions_tu__plus',
        'fhoc_scoring_fg',
        'fhoc_scoring_pcg',
        'fhoc_scoring_pg',
        'fhoc_scoring_ps',
        'fhoc_scoring_pm',
        'fhoc_scoring_sog',
        'fhoc_scoring_ms',
        'fhoc_scoring_sot',
        'fhoc_scoring_sis',
        'fhoc_non-scoring_ic',
        'fhoc_non-scoring_pc',
        'fhoc_non-scoring_sc',
        'fhoc_non-scoring_uc',
        'fhoc_non-scoring_lc',
        `fhoc_non-scoring_s`,
        'fhoc_non-scoring_f',
        'fhoc_non-scoring_os',
        'fhoc_non-scoring_ep',
        'fhoc_non-scoring_ip',
        'fhoc_non-scoring_ekp',
        'fhoc_non-scoring_ikp',
        'fhoc_non-scoring_d',
        'fhoc_non-scoring_fh',
        'fhoc_non-scoring_b',
        'net_scoring_goal',
        'net_scoring_mg',
        'net_scoring_ga',
        'net_scoring_sg',
        'net_scoring_msg',
        'net_scoring_3ps',
        'net_scoring_3psm',
        'net_non-scoring_t',
        'net_non-scoring_dr',
        'net_non-scoring_ep',
        'net_non-scoring_ecf',
        'net_non-scoring_i',
        'net_non-scoring_p',
        'net_non-scoring_cpr',
        'net_non-scoring_icf',
        'net_non-scoring_or',
        'net_non-scoring_ip',
        'net_non-scoring_d',
        'bball_scoring_fg',
        'bball_scoring_fga',
        'bball_scoring_3-pg',
        'bball_scoring_3-pa',
        'bball_scoring_ftg',
        'bball_scoring_fta',
        'bball_scoring_sa',
        'bball_non-scoring_dr',
        'bball_non-scoring_or',
        'bball_non-scoring_s',
        'bball_non-scoring_b',
        'bball_non-scoring_t',
        'bball_non-scoring_pf',
        'fball_scoring_g',
        'fball_scoring_pg',
        'fball_scoring_fkg',
        'fball_scoring_opg',
        'fball_scoring_s',
        'fball_scoring_SoT',
        'fball_scoring_SoffT',
        'fball_scoring_ga',
        'fball_scoring_bs',
        'fball_non-scoring_t',
        'fball_non-scoring_cp',
        'fball_non-scoring_ip',
        'fball_non-scoring_tu',
        'fball_non-scoring_st',
        'fball_non-scoring_kp',
        'fball_non-scoring_d',
        'fball_non-scoring_c',
        'fball_non-scoring_fk',
        'fball_non-scoring_ti',
        'fball_non-scoring_gk',
        'fball_non-scoring_f',
        'fball_non-scoring_o',
        'fball_non-scoring_h',
        'fball_scoring_sv',
        'fball_scoring_sc',
        'fball_scoring_sb',
        'fball_scoring_kr',
        'lacr_scoring_g',
        'lacr_scoring_fpg',
        'lacr_scoring_w',
        'lacr_scoring_s',
        'lacr_scoring_bspos',
        'lacr_scoring_bsneg',
        'lacr_scoring_a',
        'lacr_non-scoring_pf',
        'lacr_non-scoring_p',
        'lacr_non-scoring_c',
        'lacr_non-scoring_cneg',
        'lacr_non-scoring_fopos',
        'lacr_non-scoring_foneg',
        'lacr_non-scoring_topos',
        'lacr_non-scoring_to',
        'lacr_non-scoring_gb',
        'lacr_non-scoring_dc',
        'lacr_non-scoring_fp',
        'lacr_non-scoring_tf',
    ],
};

export const config: { [key: string]: StatConfig } = {
    afl_assist_1__perc: {
        name: '1%ers',
        shortName: 'ONEPER',
        description:
            'Defensive efforts and supporting actions which are not statistically recognised or rewarded throughout a match. such as knock-ons, spoils, smothers, and shepherds',
        type: StatTypes.Count,
    },
    afl_possessions_50: {
        name: '50-Metre Penalties For',
        shortName: '50F',
        description:
            'Awarded to a team/player if an infringement occurs after a mark, free-kick or during a behind kick-in.',
        type: StatTypes.Count,
    },
    afl_scoring_sa: {
        name: 'Score Assist',
        shortName: 'SA',
        description: 'An involvement that led to a direct score for the team.',
        type: StatTypes.Count,
    },
    afl_error_50__minus: {
        name: '50-Metre Penalties Against',
        shortName: '50A',
        description:
            'Awarded to the opposition team/player if an infringement occurs after a mark, free-kick or during a behind kick-in.',
        type: StatTypes.Count,
    },
    afl_scoring_b: {
        name: 'Behinds',
        shortName: 'B',
        description:
            'A minor score, as judged by the goal umpire. Behinds are worth one point to a team’s total score.',
        type: StatTypes.Count,
    },
    afl_possessions_cc: {
        name: 'Centre Clearances',
        shortName: 'CC',
        description:
            'Credited to the player who has the first effective disposal in a chain that clears the centre square area, or an ineffective kick or clanger kick that clear the centre square area.',
        type: StatTypes.Count,
    },
    afl_error_cl: {
        name: 'Clanger',
        shortName: 'CL',
        description:
            'An error made by a player resulting in a negative result for his side. Disposal clangers are any kick or handball that directly turns the ball over to the opposition. Frees and 50-metre penalties against, No Pressure Errors, Dropped Marks and Debits are all included in clangers.',
        type: StatTypes.Count,
    },
    afl_error_ftc: {
        name: 'Failure to chase',
        shortName: 'FTC',
        description:
            'The lack of effort and pressure in pursuing an opponent who is running with the ball, even if the player never catches up and tackles their opponent.',
        type: StatTypes.Count,
    },
    afl_possessions_fk__plus: {
        name: 'Frees For',
        shortName: 'FF',
        description:
            'When a player is interfered with and is awarded a free kick.',
        type: StatTypes.Count,
    },
    afl_error_fb: {
        name: 'Fumbles',
        shortName: 'FUM',
        description:
            'Losing possession of the ball whilst under little or no pressure from the opposition',
        type: StatTypes.Count,
    },
    afl_scoring_g: {
        name: 'Goals',
        shortName: 'G',
        description:
            'A major score, as judged by the goal umpire. Worth six points to a team’s total score.',
        type: StatTypes.Count,
    },
    afl_possessions_gb: {
        name: 'Groundball Gets',
        shortName: 'GBG',
        description: 'Sum of looseball-gets.',
        type: StatTypes.Count,
    },
    afl_possessions_h: {
        name: 'Handballs',
        shortName: 'H',
        description: 'Disposing of the ball by hand.',
        type: StatTypes.Count,
    },
    afl_possessions_hr: {
        name: 'Handball Receives',
        shortName: 'HR',
        description:
            'An uncontested possession that is the result of a teammate’s handball.',
        type: StatTypes.Count,
    },
    afl_possessions_hb: {
        name: 'Hardball Gets',
        shortName: 'HBG',
        description: 'Sum of hardball-gets.',
        type: StatTypes.Count,
    },
    afl_possessions_ho: {
        name: 'Hitouts',
        shortName: 'HO',
        description:
            'Knocking the ball out of a ruck contest following a stoppage with clear control, regardless of which side wins the following contest at ground level.',
        type: StatTypes.Count,
    },
    afl_possessions_i50: {
        name: 'Inside 50',
        shortName: 'I50',
        description: 'Moving the ball from the midfield into the forward zone.',
        type: StatTypes.Count,
    },
    afl_possessions_k: {
        name: 'Kicks',
        shortName: 'K',
        description:
            'Disposing of the ball by foot. Including kicks off the ground.',
        type: StatTypes.Count,
    },
    afl_possessions_m: {
        name: 'Marks',
        shortName: 'M',
        description:
            'When a player catches a kicked ball without anyone else touching it or the ball hitting the ground.',
        type: StatTypes.Count,
    },
    afl_possessions_mc: {
        name: 'Contested Marks',
        shortName: 'CM',
        description:
            'When a player takes a mark under physical pressure of an opponent or in a pack.',
        type: StatTypes.Count,
    },
    afl_error_md: {
        name: 'Dropped Marks',
        shortName: 'DM',
        description:
            'When a player fails to mark the ball whilst under little or no pressure from the opposition.',
        type: StatTypes.Count,
    },
    afl_possessions_mu: {
        name: 'Uncontested Marks',
        shortName: 'UM',
        description:
            'Marks taken under no physical pressure from an opponent. Includes marks taken on the lead and from opposition kicks.',
        type: StatTypes.Count,
    },
    afl_error_ms: {
        name: 'Missed Shots At Goal',
        shortName: 'MS',
        description:
            'Genuine shots at goal that either fell short with no score being registered or resulted in an out on the full.',
        type: StatTypes.Count,
    },
    afl_assist_pa: {
        name: 'Pressure Acts',
        shortName: 'PA',
        description:
            "Reducing an opponent's decision making or preventing them from effectively disposing the ball via direct physical contact or by applying pressure without physical contact.",
        type: StatTypes.Count,
    },
    afl_possessions_r50: {
        name: 'Rebound Inside 50s',
        shortName: 'R50',
        description:
            'Moving the ball from the defensive zone into the midfield.',
        type: StatTypes.Count,
    },
    afl_scoring_rb: {
        name: 'Rushed Behinds',
        shortName: 'RB',
        description:
            'Behinds that have not been scored directly off a player’s boot, excluding those that were touched on the goal line.',
        type: StatTypes.Count,
    },
    afl_assist_s: {
        name: 'Spoils',
        shortName: 'S',
        description:
            'Knocking the ball away from a marking contest preventing an opponent from taking a mark.',
        type: StatTypes.Count,
    },
    afl_possessions_sc: {
        name: 'Stoppage Clearance',
        shortName: 'SC',
        description:
            'Credited to the player who has the first effective disposal in a chain that clears the stoppage area, or an ineffective kick or clanger kick that clears the stoppage area.',
        type: StatTypes.Count,
    },
    afl_assist_t: {
        name: 'Tackles',
        shortName: 'T',
        description:
            'Using physical contact to prevent an opponent getting an effective disposal.',
        type: StatTypes.Count,
    },
    afl_error_tm: {
        name: 'Missed Tackles',
        shortName: 'MT',
        description:
            'When a player has an opportunity to tackle the opponent with the ball, and either they fall off the tackle, the tackle is broken, or the player misses them completely.',
        type: StatTypes.Count,
    },
    afl_possessions_tu__plus: {
        name: 'Turnovers',
        shortName: 'TO',
        description:
            'Losing possession to the opposition in general play. General play excludes events that happen between a stoppage and the clearance.',
        type: StatTypes.Count,
    },
    afl_scoring_accuracy: {
        name: 'Scoring Accuracy',
        shortName: 'SAC',
        description:
            'Losing possession to the opposition in general play. General play excludes events that happen between a stoppage and the clearance.',
        type: StatTypes.Count,
    },
    // HOCKEY STATS BELOW
    fhoc_scoring_fg: {
        name: 'Field Goal',
        shortName: 'FG',
        description: 'A goal scored in open play.',
        type: StatTypes.Count,
    },
    fhoc_scoring_pcg: {
        name: 'Penalty Corner Goal',
        shortName: 'PCG',
        description: 'A goal scored from a penalty corner.',
        type: StatTypes.Count,
    },
    fhoc_scoring_pg: {
        name: 'Penalty Goal',
        shortName: 'PG',
        description: 'A goal scored from a penalty shot.',
        type: StatTypes.Count,
    },
    fhoc_scoring_ms: {
        name: 'Missed Shot',
        shortName: 'MS',
        description: 'A missed shot on goal.',
        type: StatTypes.Count,
    },
    fhoc_scoring_pm: {
        name: 'Penalty Miss',
        shortName: 'PM',
        description: 'A shot on goal missed from a penalty.',
        type: StatTypes.Count,
    },
    fhoc_scoring_sis: {
        name: 'Score Involvement',
        shortName: 'SI',
        description:
            'Any involvement of a player the lead to a succesful shot on goal.',
        type: StatTypes.Count,
    },
    fhoc_scoring_sog: {
        name: 'Shot on Goal',
        shortName: 'SOG',
        description: 'A shot taken for goal.',
        type: StatTypes.Count,
    },
    fhoc_scoring_sot: {
        name: 'Shot on Target',
        shortName: 'SOT',
        description: 'A shot taken for goal that was on target.',
        type: StatTypes.Count,
    },
    fhoc_scoring_ps: {
        name: 'Penalty Shot',
        shortName: 'PS',
        description: 'A shot taken from a penalty.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_b': {
        name: 'Block',
        shortName: 'BS',
        description: 'A blocked shot on goal for the opposition.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_ic': {
        name: 'Inside Circle',
        shortName: 'IC',
        description: 'A succesful pass inside the circle.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_pc': {
        name: 'Penalty Corner',
        shortName: 'PC',
        description: 'A penalty corner taken by the given team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_sc': {
        name: 'Successful Corner',
        shortName: 'SC',
        description: 'A successful penalty corner taken by the given team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_uc': {
        name: 'Unsuccessful Corner',
        shortName: 'UC',
        description: 'An unsuccessful penalty corner taken by the given team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_lc': {
        name: 'Long Corner',
        shortName: 'UC',
        description: 'A long corner taken by the given team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_s': {
        name: 'Steal',
        shortName: 'S',
        description: 'Possession taken from the opposing team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_f': {
        name: 'Foul',
        shortName: 'F',
        description: 'A foul against the given team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_os': {
        name: 'Offside',
        shortName: 'OS',
        description: 'When a player is called for being offside.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_ep': {
        name: 'Effect Pass',
        shortName: 'EP',
        description: 'A successful pass to another player on the same team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_ip': {
        name: 'Ineffective Pass',
        shortName: 'EP',
        description: 'An unsuccessful pass to another player on the same team.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_ekp': {
        name: 'Effective Key Pass',
        shortName: 'EKP',
        description:
            'A successful pass to another player who makes an attempt on goal without scoring.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_ikp': {
        name: 'Ineffective Key Pass',
        shortName: 'IKP',
        description:
            'An unsuccessful pass to another player makes an attempt on goal without scoring.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_d': {
        name: 'Dribble',
        shortName: 'D',
        description: 'A dribble movement by the given player.',
        type: StatTypes.Count,
    },
    'fhoc_non-scoring_fh': {
        name: 'Free Hit +',
        shortName: 'FH',
        description: 'When a player is interfered with and awarded a free hit.',
        type: StatTypes.Count,
    },
    // Netball stats below
    net_scoring_goal: {
        name: 'Goal',
        shortName: 'G',
        description: 'A succesfull attempt on goal.',
        type: StatTypes.Count,
    },
    net_scoring_mg: {
        name: 'Missed Goal',
        shortName: 'MG',
        description: 'An unsuccesfull attempt on goal.',
        type: StatTypes.Count,
    },
    net_scoring_ga: {
        name: 'Goal Assist',
        shortName: 'GA',
        description:
            'Awarded to a player where their pass has led to a successful attempt on goal.',
        type: StatTypes.Count,
    },
    net_scoring_sg: {
        name: 'Super Goal',
        shortName: 'SG',
        description: 'A successful super goal attempt.',
        type: StatTypes.Count,
    },
    net_scoring_msg: {
        name: 'Missed Super Goal',
        shortName: 'MSG',
        description: 'An unsuccessful super goal attempt.',
        type: StatTypes.Count,
    },
    net_scoring_3ps: {
        name: '3-P Super Goal',
        shortName: '3PS',
        description: 'A successful three point super goal attempt.',
        type: StatTypes.Count,
    },
    net_scoring_3psm: {
        name: '3-P Super Goal Miss',
        shortName: '3PSM',
        description: 'An unsuccessful three point super goal attempt.',
        type: StatTypes.Count,
    },
    'net_non-scoring_t': {
        name: 'Turnover -',
        shortName: 'T',
        description:
            'A pass that has led to a direct turnover to the opposition.',
        type: StatTypes.Count,
    },
    'net_non-scoring_dr': {
        name: 'Defensive Rebound',
        shortName: 'DR',
        description: 'A rebound gathered from an opposition missed shot.',
        type: StatTypes.Count,
    },
    'net_non-scoring_ep': {
        name: 'Effective Pass',
        shortName: 'EP',
        description:
            'A pass from a player that has reached the intended target.',
        type: StatTypes.Count,
    },
    'net_non-scoring_ecf': {
        name: 'Effective Circle Feed',
        shortName: 'ECF',
        description:
            'A pass from a player that has reached the intended target inside shooting circle.',
        type: StatTypes.Count,
    },
    'net_non-scoring_i': {
        name: 'Intercept',
        shortName: 'I',
        description: 'Possession gained from an opposition pass.',
        type: StatTypes.Count,
    },
    'net_non-scoring_p': {
        name: 'Penalty -',
        shortName: 'P',
        description:
            'A free pass given to the opposition from an infringement.',
        type: StatTypes.Count,
    },
    'net_non-scoring_cpr': {
        name: 'Centre Pass Receive',
        shortName: 'CPR',
        description: 'A pass recieved from a centre pass.',
        type: StatTypes.Count,
    },
    'net_non-scoring_icf': {
        name: 'Ineffective Circle Feed',
        shortName: 'ICF',
        description:
            'A pass from a player that has not reached the intended target inside shooting circle.',
        type: StatTypes.Count,
    },
    'net_non-scoring_or': {
        name: 'Offensive Rebound',
        shortName: 'OR',
        description: 'A rebound gathered from a missed shot.',
        type: StatTypes.Count,
    },
    'net_non-scoring_ip': {
        name: 'Ineffective Pass',
        shortName: 'EP',
        description:
            'A pass from a player that has not reached the intended target.',
        type: StatTypes.Count,
    },
    'net_non-scoring_d': {
        name: 'Deflection +',
        shortName: 'D',
        description: 'A defelcted pass from an opposition player.',
        type: StatTypes.Count,
    },
    // BASKETBALL STATS BELOW
    bball_scoring_fg: {
        name: 'Field Goal',
        shortName: 'FG',
        description: 'A count of successful 2 point shots.',
        type: StatTypes.Count,
    },
    bball_scoring_fga: {
        name: 'Field Goal Miss',
        shortName: 'FGM',
        description: 'An unsuccessful 2 point shot.',
        type: StatTypes.Count,
    },
    'bball_scoring_3-pg': {
        name: '3-Point',
        shortName: '3PT',
        description: 'A count of successful 3 point shots.',
        type: StatTypes.Count,
    },
    'bball_scoring_3-pa': {
        name: '3-Point Miss',
        shortName: '3PTM',
        description: 'An unsuccessful 3 point shot.',
        type: StatTypes.Count,
    },
    bball_scoring_ftg: {
        name: 'Free Throw Goal',
        shortName: 'FT',
        description: 'A count of successful free throw shot.',
        type: StatTypes.Count,
    },
    bball_scoring_fta: {
        name: 'Free Throw Miss',
        shortName: 'FTM',
        description: 'An unsuccessful free throw shot.',
        type: StatTypes.Count,
    },
    bball_scoring_sa: {
        name: 'Assist',
        shortName: 'AST',
        description:
            'A pass from a player that has led to a succesful scoring attempt.',
        type: StatTypes.Count,
    },
    'bball_non-scoring_dr': {
        name: 'Defensive Rebound',
        shortName: 'DREB',
        description: 'A rebound gathered from an unsuccessful oppositon shot.',
        type: StatTypes.Count,
    },
    'bball_non-scoring_or': {
        name: 'Offensive Rebound',
        shortName: 'OREB',
        description: 'A rebound gathered from an unsuccessful shot.',
        type: StatTypes.Count,
    },
    'bball_non-scoring_s': {
        name: 'Steal',
        shortName: 'S',
        description: 'Possession successfully taken from the oppostion.',
        type: StatTypes.Count,
    },
    'bball_non-scoring_b': {
        name: 'Block',
        shortName: 'BLK',
        description: 'Blocking an opposition shot.',
        type: StatTypes.Count,
    },
    'bball_non-scoring_t': {
        name: 'Turnover -',
        shortName: 'TO',
        description: 'A pass intercepted by the opposition.',
        type: StatTypes.Count,
    },
    'bball_non-scoring_pf': {
        name: 'Personal Fouls',
        shortName: 'PF',
        description: 'A foul given against a player for an infringement.',
        type: StatTypes.Count,
    },
    // SOCCER STATS BELOW
    fball_scoring_g: {
        name: 'Goals',
        shortName: 'G',
        description: 'A count of goals scored.',
        type: StatTypes.Count,
    },
    fball_scoring_pg: {
        name: 'Penalty Goals',
        shortName: 'PG',
        description: 'A count of goals scored from the penalty spot.',
        type: StatTypes.Count,
    },
    fball_scoring_fkg: {
        name: 'Free Kick Goals',
        shortName: 'FKG',
        description: 'A count of goals scored from free kicks.',
        type: StatTypes.Count,
    },
    fball_scoring_opg: {
        name: 'Open Play Goals',
        shortName: 'FKG',
        description: 'A count of goals scored from open play.',
        type: StatTypes.Count,
    },
    fball_scoring_s: {
        name: 'Shot at Goal',
        shortName: 'S',
        description: 'A count of shots at goal.',
        type: StatTypes.Count,
    },
    fball_scoring_SoT: {
        name: 'Shot On Target',
        shortName: 'SOT',
        description: 'A count of shots at goal that were on target.',
        type: StatTypes.Count,
    },
    fball_scoring_SoffT: {
        name: 'Shot Off Target',
        shortName: 'SOFFT',
        description: 'A count of shots at goal that were off target.',
        type: StatTypes.Count,
    },
    fball_scoring_ga: {
        name: 'Goal Assists',
        shortName: 'GA',
        description: 'A pass that has led to a successful shot on goal.',
        type: StatTypes.Count,
    },
    fball_scoring_bs: {
        name: 'Blocked Shot +',
        shortName: 'BS',
        description: 'A blocked opposition shot on goal.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_t': {
        name: 'Touch',
        shortName: 'T',
        description: 'A successful pass via defelction (touch pass).',
        type: StatTypes.Count,
    },
    'fball_non-scoring_cp': {
        name: 'Completed Pass',
        shortName: 'CP',
        description: 'A pass that has reached the intended target.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_ip': {
        name: 'Incomplete Pass',
        shortName: 'IP',
        description: 'A pass that has not reached the intended target.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_tu': {
        name: 'Turnover -',
        shortName: 'TO-',
        description:
            'A pass where possession has been turned over to the opposition.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_st': {
        name: 'Steal +',
        shortName: 'ST+',
        description: 'Possession has been taken from the opposition.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_kp': {
        name: 'Key Pass',
        shortName: 'KP',
        description:
            'The final pass to a player who makes an attempt on goal without scoring.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_d': {
        name: 'Dribble',
        shortName: 'D',
        description: 'A dribble completed by a player.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_c': {
        name: 'Corner',
        shortName: 'C',
        description: 'A corner taken by a team / player.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_fk': {
        name: 'Free Kick',
        shortName: 'FK',
        description: 'A free kick awarded to the player / team.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_ti': {
        name: 'Throw In',
        shortName: 'TI',
        description: 'A throw in taken.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_gk': {
        name: 'Goal Kick',
        shortName: 'GK',
        description: 'A goal kick taken by the given team.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_f': {
        name: 'Foul - ',
        shortName: 'F',
        description: 'A foul against the given team.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_o': {
        name: 'Offside',
        shortName: 'OFF',
        description: 'An offside call aginst the given player or team.',
        type: StatTypes.Count,
    },
    'fball_non-scoring_h': {
        name: 'Handball -',
        shortName: 'H',
        description: 'A handball against the given team / player.',
        type: StatTypes.Count,
    },
    fball_scoring_sv: {
        name: 'Save',
        shortName: 'SV',
        description: 'A saved shot on goal.',
        type: StatTypes.Count,
    },
    fball_scoring_sc: {
        name: 'Save Caught',
        shortName: 'SC',
        description: 'A saved shot on goal that was caught by the goal keeper.',
        type: StatTypes.Count,
    },
    fball_scoring_sb: {
        name: 'Save Blocked',
        shortName: 'SB',
        description:
            'A saved shot on goal that was blocked by the goal keeper, not caught.',
        type: StatTypes.Count,
    },
    fball_scoring_kr: {
        name: 'Keeper Recovery',
        shortName: 'KR',
        description:
            'A saved shot on goal where the keeper has not caught the ball but recovered possession.',
        type: StatTypes.Count,
    },
    // lacrosse stats
    lacr_scoring_bsneg: {
        name: 'Blocked Shot -',
        shortName: 'BS-',
        description: 'An shot that is blocked by an opposition player.',
        type: StatTypes.Count,
    },
    lacr_scoring_g: {
        name: 'Goal',
        shortName: 'G',
        description: 'A goal scored in general play.',
        type: StatTypes.Count,
    },
    lacr_scoring_w: {
        name: 'Wide',
        shortName: 'W',
        description:
            'When a shot on goal is missed without a save being required of the goal keeper.',
        type: StatTypes.Count,
    },
    lacr_scoring_s: {
        name: 'Save',
        shortName: 'S',
        description: 'When a goal keeper stops a shot on goal.',
        type: StatTypes.Count,
    },
    lacr_scoring_a: {
        name: 'Assist',
        shortName: 'A',
        description:
            'A pass from a player that has led to a successful attempt on goal.',
        type: StatTypes.Count,
    },
    lacr_scoring_bspos: {
        name: 'Blocked Shot +',
        shortName: 'BS+',
        description: 'An opposition shot that is blocked by a player.',
        type: StatTypes.Count,
    },
    lacr_scoring_fpg: {
        name: 'Free Position Goal',
        shortName: 'FPG',
        description: 'A goal scored from a free position scenario.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_pf': {
        name: 'Personal Foul',
        shortName: 'PF',
        description: 'A personal foul marked against a player.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_p': {
        name: 'Pipe',
        shortName: 'P',
        description: 'A ground ball for the opposition.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_cneg': {
        name: 'Clear -',
        shortName: 'C-',
        description:
            'A team loses possession before getting an offensive attempt.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_foneg': {
        name: 'Faceoff -',
        shortName: 'FO-',
        description: 'A faceoff loss.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_topos': {
        name: 'Turnover +',
        shortName: 'TO+',
        description:
            'The defence gains control of the ball after contact with the ball, crosse or player.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_gb': {
        name: 'Ground Balls',
        shortName: 'GB',
        description: 'A loose ball picked up with the crosse from the ground.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_dc': {
        name: 'Draw Controls',
        shortName: 'DC',
        description:
            'A player successfully gains control of the ball after a draw.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_fp': {
        name: 'Free Position',
        shortName: 'FP',
        description: 'A free position awarded to the team / player.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_c': {
        name: 'Clear +',
        shortName: 'C+',
        description:
            'A team passes the offensive restraining line and is clearly able to get an offensive attempt.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_to': {
        name: 'Turnover -',
        shortName: 'TO-',
        description: 'A player gives the ball to the opposition.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_fopos': {
        name: 'Faceoff +',
        shortName: 'FO+',
        description: 'A faceoff win.',
        type: StatTypes.Count,
    },
    'lacr_non-scoring_tf': {
        name: 'Technical Foul',
        shortName: 'TF',
        description: 'A technical foul marked against the team or player.',
        type: StatTypes.Count,
    },
};
