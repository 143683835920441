import { PaginatedResponse, Response } from './api';
import { ReportBase } from './reports';
import { OpponentTeam, Team } from './team';

export enum PlayerPositionStatus {
    Absent = 'Absent',
    Bench = 'Bench',
    Injury = 'Injury',
    Assessment = 'Assessment',
    Disciplinary = 'Disciplinary',
    Opposition = 'Opposition',
    Field = 'Field',
}

export enum GameAlertMode {
    None = 'None',
    EqualGameTime = 'EqualGameTime',
    MinimumGameTime = 'MinimumGameTime',
    PerformanceThresholds = 'PerformanceThresholds',
}

export interface PlayerPosition {
    playerPositionID: string;
    playerID: string;
    positionID: string;
    status: PlayerPositionStatus;
}

export interface GameLineUp {
    lineUpName: string;
    eventID: string;
    formationID: string;
    period: number;
    playerPositions: PlayerPosition[];
    createdAt: string;
    createdBy: string;
    lastEdited: string;
    lastEditedBy: string;
}

export enum PlayerAlertModes {
    None = 'None',
    MinimumGameTime = 'MinimumGameTime',
    EqualGameTime = 'EqualGameTime',
}

export interface GameSettings {
    alertMode: GameAlertMode;
    gameTimeTarget: number;
    autoEndPeriod: boolean;
    clockModeUp: boolean;
    estimatedPeriodLength: number;
    maxOnBench: boolean;
    maxOnField: boolean;
    maxPlayers: boolean;
    periodLength: boolean;
    periods: boolean;
    showAssessment: boolean;
    showDisciplinary: boolean;
    showInjury: boolean;
    showOpposition: boolean;
    timeOn: boolean;
}

export interface Game {
    createdAt: string;
    createdBy: string;
    eventID: string;
    gameName: string;
    isHomeTeam: boolean;
    lastEdited: string;
    lastEditedBy: string;
    lineUps?: GameLineUp[];
    opponentTeam: OpponentTeam;
    opponentTeamID: string;
    ownerTeam?: Team;
    seasonID: string;
    settings: GameSettings;
    teamID: string;
    gameSummaryReport?: ReportBase;
}

export interface GameRole {
    role: {
        roleID: string;
        roleName: string;
        roleDescription: string;
    };
    maxPerEntity: number;
    maxPerEvent: number;
    maxPerGame: number;
    permissions: string[];
    assignable: boolean;
    yesCount: number;
    attendingCount: number;
}

export type GameRoleResponse = Response<GameRole>;
export type GameRolesResponse = PaginatedResponse<GameRole[]>;
