import React from 'react';

interface Props {
    children: React.ReactNode;
}

const ListHeader = ({ children }: Props) => {
    return <div className="list-header">{children}</div>;
};

ListHeader.displayName = 'ListHeader';

export default ListHeader;
