import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { PlanContext } from '../../contexts/PlanContext';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import RookieButton from '../../components/RookieButton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { LicenceGroupInfo, Licence } from '../../types/licences';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import ErrorDisplay from '../../components/ErrorDisplay';
import { isInAppSubscription } from '../../util/helper';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
    licences: Licence[];
    licenceGroups: LicenceGroupInfo[];
    isLoading: boolean;
    onManageBilling: (subscriptionID: string, inAppPurchase: boolean) => void;
    onUpgrade: (licenceGroup: LicenceGroupInfo, inAppPurchase: boolean) => void;
    onAssignLicence: (licenceGroupID: string) => void;
    onUnassignLicence: (licence: string) => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const LicencesView = (props: Props) => {
    const {
        licences,
        licenceGroups,
        isLoading,
        onManageBilling,
        onUpgrade,
        onAssignLicence,
        onUnassignLicence,
        permissions,
    } = props;
    const { teamID } = useParams();
    const planModal = useContext(PlanContext);

    const licenceGroupsWithoutActive = licenceGroups.filter(
        (licenceGroup) =>
            licences.length === 0 ||
            licences.find(
                (licence) => licence.licenceGroupID !== licenceGroup.id
            )
    );

    return (
        <PageContainer>
            <PageHeader title="Licences" />
            {isLoading ? (
                <div>Loading...</div>
            ) : licenceGroups.length === 0 && licences.length === 0 ? (
                <ErrorDisplay
                    errorType={ERROR_TYPES.empty}
                    hasReturn={false}
                    hasReporting={false}
                    title="This entity does not have any licenses associated with
                it."
                    actions={
                        teamID && permissions.canCreate
                            ? [
                                  {
                                      label: 'Add Licence',
                                      command: () =>
                                          planModal?.open({
                                              entity: {
                                                  entityID: teamID,
                                                  entityType:
                                                      BaseEntityType.teams,
                                              },
                                          }),
                                  },
                              ]
                            : []
                    }
                />
            ) : (
                <div>
                    <h2>Active</h2>
                    <DataTable
                        value={licences}
                        className="p-card"
                        style={{ margin: '20px 0 40px' }}
                    >
                        <Column
                            header="Current Plan"
                            body={(data) => {
                                const licenceName =
                                    data.licenceTypeDetails?.licenceName;
                                const group = licenceGroups.find(
                                    (licenceGroup) =>
                                        licenceGroup.id === data.licenceGroupID
                                );

                                // Append "(7 day free trial)" if group.trialActive is true
                                return `${licenceName}${
                                    group?.trialActive
                                        ? ' (7 day free trial)'
                                        : ''
                                }`;
                            }}
                        />
                        {licenceGroups.length > 0 &&
                            licenceGroups.some((group) => group.isOwner) && (
                                <Column
                                    header="Type"
                                    body={(data) => {
                                        const group = licenceGroups.find(
                                            (licenceGroup) =>
                                                licenceGroup.id ===
                                                data.licenceGroupID
                                        );
                                        return group &&
                                            group.billingPeriod === 'year'
                                            ? 'Annual'
                                            : 'Monthly';
                                    }}
                                />
                            )}
                        {licenceGroups.length > 0 &&
                            licenceGroups.some((group) => group.isOwner) && (
                                <Column
                                    header="Created"
                                    body={(data) =>
                                        data.createdAt &&
                                        format(
                                            new Date(data.createdAt),
                                            'MMM d, yyyy'
                                        )
                                    }
                                />
                            )}
                        {licenceGroups.length > 0 &&
                            licenceGroups.some((group) => group.isOwner) && (
                                <Column
                                    header="Renew"
                                    body={(data) => {
                                        const group = licenceGroups.find(
                                            (licenceGroup) =>
                                                licenceGroup.id ===
                                                data.licenceGroupID
                                        );
                                        console.log(group);

                                        if (group) {
                                            return group.renewsAt
                                                ? format(
                                                      new Date(group.renewsAt),
                                                      'MMM d, yyyy'
                                                  )
                                                : 'Contact Rookie Me'; // Show "Contact Rookie Me" if renewsAt is null
                                        }

                                        return ''; // Default empty string if group is not found
                                    }}
                                />
                            )}
                        <Column
                            header="Owner"
                            body={(data) => {
                                const group = licenceGroups.find(
                                    (licenceGroup) =>
                                        licenceGroup.id === data.licenceGroupID
                                );

                                return group && group.isOwner
                                    ? 'Me'
                                    : 'Assigned to you';
                            }}
                        />
                        <Column
                            header="Managed on"
                            body={(data) => {
                                const group = licenceGroups.find(
                                    (licenceGroup) =>
                                        licenceGroup.id === data.licenceGroupID
                                );

                                const inApp =
                                    !!group && isInAppSubscription(group);

                                return inApp ? 'In-app' : 'Hub';
                            }}
                        />
                        <Column
                            body={(data) => {
                                const group = licenceGroups.find(
                                    (licenceGroup) =>
                                        licenceGroup.id === data.licenceGroupID
                                );

                                const inApp =
                                    !!group && isInAppSubscription(group);

                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '10px',
                                            justifyContent: 'right',
                                        }}
                                    >
                                        {permissions.canEdit && (
                                            <RookieButton
                                                label="Unassign"
                                                severity="secondary"
                                                onClick={() =>
                                                    onUnassignLicence(
                                                        data.licenceID
                                                    )
                                                }
                                            />
                                        )}
                                        {group &&
                                            group.subscriptionID &&
                                            group.isOwner && (
                                                <RookieButton
                                                    severity="secondary"
                                                    label="Manage"
                                                    onClick={() =>
                                                        onManageBilling(
                                                            group.subscriptionID,
                                                            inApp
                                                        )
                                                    }
                                                />
                                            )}
                                        {group &&
                                            group.status === 'Active' &&
                                            group.canUpgrade &&
                                            group.isOwner &&
                                            group.licenceTitle !== 'Expert' && (
                                                <RookieButton
                                                    label="Upgrade"
                                                    onClick={() =>
                                                        onUpgrade(group, inApp)
                                                    }
                                                />
                                            )}
                                    </div>
                                );
                            }}
                        />
                    </DataTable>

                    {licenceGroupsWithoutActive.length > 0 && (
                        <>
                            <h2>Inactive</h2>
                            <DataTable
                                value={licenceGroupsWithoutActive}
                                className="p-card"
                                style={{ margin: '20px 0 40px' }}
                            >
                                <Column header="Licence" field="licenceTitle" />
                                <Column
                                    header="Created"
                                    body={(data) =>
                                        data.createdAt &&
                                        format(
                                            new Date(data.createdAt),
                                            'MMM d, yyyy'
                                        )
                                    }
                                />
                                <Column
                                    header="Trial"
                                    body={(data) =>
                                        data.trialActive
                                            ? `Ends ${format(
                                                  new Date(data.trialEndsAt),
                                                  'MMM d, yyyy'
                                              )}`
                                            : 'No trial'
                                    }
                                />
                                <Column
                                    header="Renews"
                                    body={(data) => {
                                        return data.renewsAt ? (
                                            <>
                                                Renews every{' '}
                                                {data.billingPeriod}.<br />
                                                Next renewal on{' '}
                                                {format(
                                                    new Date(data.renewsAt),
                                                    'MMM d, yyyy'
                                                )}
                                            </>
                                        ) : (
                                            "Doesn't renew"
                                        );
                                    }}
                                />
                                <Column
                                    header="Owner"
                                    body={(data) =>
                                        data.isOwner ? 'Me' : 'Someone else'
                                    }
                                />
                                <Column
                                    header="Managed on"
                                    body={(data) => {
                                        const inApp = isInAppSubscription(data);

                                        return inApp ? 'In-app' : 'Hub';
                                    }}
                                />
                                <Column
                                    body={(data) => {
                                        const inApp = isInAppSubscription(data);
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    justifyContent: 'right',
                                                }}
                                            >
                                                {['Active', 'Invalid'].some(
                                                    (status) =>
                                                        data.status === status
                                                ) &&
                                                    data.subscriptionID &&
                                                    permissions.canEdit && (
                                                        <RookieButton
                                                            label="Manage"
                                                            severity="secondary"
                                                            onClick={() =>
                                                                onManageBilling(
                                                                    data.subscriptionID,
                                                                    inApp
                                                                )
                                                            }
                                                        />
                                                    )}

                                                {data.qtyAvailable > 0 && (
                                                    <RookieButton
                                                        label="Use"
                                                        onClick={() =>
                                                            onAssignLicence(
                                                                data.id
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </DataTable>
                        </>
                    )}
                </div>
            )}
        </PageContainer>
    );
};

export default LicencesView;
