import { createContext, RefObject, useRef } from 'react';
import { Toast } from 'primereact/toast';

export const ToastContext = createContext<RefObject<Toast> | undefined>(
    undefined
);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
    const globalToast = useRef(null);

    return (
        <ToastContext.Provider value={globalToast}>
            {children}
            <Toast
                ref={globalToast}
                appendTo={document.body}
                baseZIndex={9999}
            />
        </ToastContext.Provider>
    );
};

export default ToastProvider;
