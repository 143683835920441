import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetAssociationQuery } from '../../api/associations';

import AssociationForm from './AssociationForm';
import TransferEntityForm from '../users/TransferEntityForm';

import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';

import DocumentHead from '../../components/DocumentHead';
import ErrorDisplay from '../../components/ErrorDisplay';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { User } from '../../types/user';

interface Props {
    user?: User;
}

const AssociationSettings = (props: Props) => {
    const { associationID } = useParams();

    const formResponseToast = useRef<Toast>(null);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    const navigate = useNavigate();

    // API Calls
    const { data, isLoading, isError } = useGetAssociationQuery(
        { associationID },
        {
            skip: !associationID,
        }
    );

    // Handle form deletion submission result
    const handleDelete = (status: string) => {
        setIsDeleted(true);
        const isSuccess = status === 'success';

        formResponseToast.current?.show({
            severity: isSuccess ? 'success' : 'warn',
            summary: isSuccess
                ? 'Association Deleted'
                : 'Deletion Unsuccessful',
            detail: isSuccess
                ? `The Association has been successfully deleted.`
                : `Association deletion could not be completed, try again later.`,
        });
    };

    // Handle form update submission result
    const handleUpdate = (status: string) => {
        const isSuccess = status === 'success';

        formResponseToast.current?.show({
            severity: isSuccess ? 'success' : 'warn',
            summary: isSuccess ? 'Association Updated' : 'Update Unsuccessful',
            detail: isSuccess
                ? `The Association details have been amended.`
                : `Association update could not be completed, try again later.`,
        });
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc="We were unable to load your Association Settings, try again or contact Association Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    return (
        <>
            <DocumentHead
                title="Association Settings - Rookie Me Hub"
                description="Association settings page"
            />
            {data?.data && (
                <AssociationForm
                    associationID={data.data?.associationID}
                    initialValues={data?.data}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                />
            )}
            <RookieButton
                label="Transfer Ownership"
                onClick={() => setShowTransferModal(true)}
            />
            <Toast
                ref={formResponseToast}
                onHide={() =>
                    navigate(
                        isDeleted
                            ? `/u/${props.user?.userID}/entities/associations`
                            : '',
                        { replace: false }
                    )
                }
            />
            <Sidebar
                header="Transfer Ownership"
                onHide={() => setShowTransferModal(false)}
                visible={showTransferModal}
                position="right"
            >
                {associationID && (
                    <TransferEntityForm
                        entityType={BaseEntityType.associations}
                        entityID={associationID}
                        onSuccess={() => setShowTransferModal(false)}
                    />
                )}
            </Sidebar>
        </>
    );
};

export default AssociationSettings;
