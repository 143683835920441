import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
    useCreatePlayerMutation,
    useGetPlayersQuery,
    useUpdatePlayerMutation,
} from '../../api/players';
import PlayersView from './PlayersView';
import DocumentHead from '../../components/DocumentHead';

import usePermission from '../../hooks/usePermission';

import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { Player, PlayerStatus } from '../../types/team';
import { Permissions } from '../../types/permissions';
import { Mixpanel } from '../../util/mixpanel';
import { ToastContext } from '../../contexts/ToastContext';
import { uniq } from 'lodash';
import { useGetStaffDetailsQuery } from '../../api/staff';
import { BaseEntityType } from '../../types/common';

interface Props {
    roles: Roles;
    route: Route;
}

const PlayersContainer = (props: Props) => {
    const { teamID, organisationID } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get('tab');

    // Context hooks
    const toast = useContext(ToastContext);

    const [focusedUser, setFocusedUser] = useState<Player | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [isImporting, setIsImporting] = useState<boolean>(false);

    const [cursor, setCursor] = useState('');

    const { checkPermission } = usePermission(organisationID || teamID || '');

    const { data, isLoading, isError, isFetching } = useGetPlayersQuery(
        {
            teamID: teamID ? teamID : '',
            status: activeTab === 'Archived' ? 'Archived' : 'Active',
            cursor: cursor,
        },
        {
            skip: !teamID,
        }
    );

    const { data: staffData } = useGetStaffDetailsQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: teamID ? teamID : '',
            cursor: cursor,
        },
        {
            skip: !teamID,
        }
    );

    const [createPlayer] = useCreatePlayerMutation();
    const [updatePlayer] = useUpdatePlayerMutation();

    useEffect(() => {
        if (searchParams.get('id')) {
            const player = data?.data.find(
                (p) => p.playerID === searchParams.get('id')
            );

            if (player) {
                setFocusedUser(player);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Handle redirection to "upcoming" for specific URLs with /events
        if (!activeTab || !['Active', 'Archived'].includes(activeTab)) {
            navigate(
                {
                    search: '?tab=Active',
                },
                {
                    replace: true,
                }
            );
        }
    }, [navigate, activeTab]);

    const handleShowInvite = (isCreate: boolean) => {
        if (searchParams.get('id')) {
            setSearchParams((params) => {
                params.delete('id');
                return params;
            });
        }
        if (isCreate) {
            setFocusedUser(null);
        }
        setSearchParams((params) => {
            params.set('create', 'add');
            return params;
        });

        Mixpanel.track('Open Player Create');
    };

    const handleCloseInvite = () => {
        if (searchParams.get('create')) {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
    };

    const handleShowDelete = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleImport = async ({ validData, all }: any) => {
        let successCount = 0;
        let errors: string[] = [];

        setIsImporting(true);

        // Show initial summary
        if (toast && toast.current) {
            toast.current.show({
                summary: `Importing ${all.length} players`,
                sticky: true,
            });
        }

        const delay = (ms = 50) =>
            new Promise((resolve) => setTimeout(resolve, ms));

        Promise.allSettled(
            validData.map(async (data: any, index: number) => {
                return delay(index * 300).then(async () => {
                    return createPlayer({
                        teamID,
                        ...data,
                    }).then((response) => {
                        if ('data' in response) {
                            successCount++;
                        } else if ('error' in response) {
                            // @ts-ignore
                            errors.push(response.error.data.error.toString());
                        }
                    });
                });
            })
        ).then(() => {
            setIsImporting(false);

            const failedCount = validData.length - successCount;
            const uniqErrors = uniq(errors);

            if (toast && toast.current) {
                toast.current.replace({
                    severity:
                        successCount === 0
                            ? 'error'
                            : successCount === validData.length
                            ? 'success'
                            : undefined,
                    summary: `${successCount}/${all.length} Imported`,
                    detail:
                        failedCount > 0
                            ? `${failedCount} failed due to the following reasons: ${
                                  uniqErrors.length > 0
                                      ? uniqErrors.toString()
                                      : validData.length !== all.length
                                      ? 'Invalid data'
                                      : 'Unknown'
                              }`
                            : '',
                    sticky: true,
                });
            }
        });
    };

    const handleDeleteUser = (playerID: string) => {
        updatePlayer({ playerID, teamID, status: PlayerStatus.Archived })
            .then((response) => {
                console.log('DELETE SUCCESS', response);
            })
            .catch((err) => {
                console.log('DELETE ERROR', err);
            })
            .finally(() => {
                setShowDeleteDialog(false);
            });
    };

    const handleClickUser = (playerID: string) => {
        if (searchParams.get('create')) {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
        setSearchParams((params) => {
            params.set('id', playerID);
            return params;
        });
    };

    const handleCloseDrawer = () => {
        if (searchParams.get('id')) {
            setSearchParams((params) => {
                params.delete('id');
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
    };

    const handleViewUser = (playerID: string) => {
        console.log('VIEW', playerID);
    };

    const handleFocusUser = (player: Player) => {
        setFocusedUser(player);
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey && teamID) {
            const { lastEvaluatedKey } = data;
            if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
                setCursor(lastEvaluatedKey.cursor);
            }
        }
    };

    const handleTabChange = () => {
        setCursor('');
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_PLAYERS_USERS,
            Permissions.POST_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_PLAYERS_USERS,
            Permissions.DELETE_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Players - Rookie Me Hub"
                description="Players management page"
            />
            <PlayersView
                data={data && data.data ? data.data : []}
                staffData={staffData?.data || []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                isImporting={isImporting}
                showDeleteDialog={showDeleteDialog}
                focusedUser={focusedUser}
                onLoadMore={handleLoadMore}
                onCloseDeleteDialog={handleCloseDelete}
                onCloseInviteDialog={handleCloseInvite}
                onShowInviteDialog={handleShowInvite}
                onShowDeleteDialog={handleShowDelete}
                onClickUser={handleClickUser}
                onDeleteUser={handleDeleteUser}
                onImport={handleImport}
                onViewUser={handleViewUser}
                onFocusUser={handleFocusUser}
                onCloseDrawer={handleCloseDrawer}
                onTabChange={handleTabChange}
                roles={props.roles}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                permissions={permissions}
            />
        </>
    );
};

export default PlayersContainer;
