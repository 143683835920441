import { useEffect } from 'react';
import { useNavigate, useOutlet, useParams } from 'react-router-dom';
import { useGetAssociationSeasonQuery } from '../../api/seasons';

import SeasonSingleView from './SeasonSingleView';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';

interface Props {
    roles: Roles;
    route: Route;
}

const SeasonSingleContainer = (props: Props) => {
    // Route Hooks
    const { associationID, seasonID } = useParams();
    const navigate = useNavigate();
    const outlet = useOutlet();

    // API Hooks
    const seasonData = useGetAssociationSeasonQuery({
        associationID: associationID || '',
        seasonID: seasonID || '',
    });

    useEffect(() => {
        // Handle redirection to "teams" for specific URLs with /seasons
        if (!outlet) {
            navigate('teams', {
                replace: true,
            });
        }
    }, [outlet, navigate]);

    return <SeasonSingleView seasonData={seasonData} />;
};

export default SeasonSingleContainer;
