import { generatePath } from 'react-router-dom';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { apiEndpoints, prepareHeaders } from './apiEndpoints';

import { EntityStructure } from '../types/common';
import { DesignOptions, DesignsResponse } from '../types/design';
import { EntityRolesResponse, RolesResponse } from '../types/roles';
import { Team, TeamResponse } from '../types/team';
import { MeResponse, UserResponse } from '../types/user';
import { OrganisationResponse } from '../types/organisation';
import { AssociationResponse } from '../types/association';

export const coreApi = createApi({
    reducerPath: 'coreApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getMe: builder.query<MeResponse, void>({
            query: () => ({
                url: apiEndpoints.getMe.url,
                method: apiEndpoints.getMe.method,
            }),
        }),
        getRoles: builder.query<
            RolesResponse,
            { userID: string; params?: any }
        >({
            query: ({ userID, params }) => ({
                url: generatePath(apiEndpoints.getRoles.url, { userID }),
                method: apiEndpoints.getRoles.method,
                params,
            }),
        }),
        getEntityRoles: builder.query<
            EntityRolesResponse,
            EntityStructure & { assignable: boolean }
        >({
            query: ({ entityType, entityID, assignable }) => ({
                url: generatePath(apiEndpoints.getEntityRoles.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEntityRoles.method,
                params: {
                    assignable,
                },
            }),
        }),
        getEntity: builder.query<
            AssociationResponse &
                OrganisationResponse &
                TeamResponse & { expand?: string },
            EntityStructure
        >({
            query: ({ entityType, entityID, ...params }) => ({
                url: generatePath(apiEndpoints.getEntity.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEntity.method,
                params,
            }),
        }),
        getUserDetails: builder.query<UserResponse, { userID: string }>({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getUserDetails.url, { userID }),
                method: apiEndpoints.getUserDetails.method,
            }),
        }),
        getDesignOptions: builder.query<DesignsResponse, void>({
            query: () => ({
                url: apiEndpoints.getDesignOptions.url,
                method: apiEndpoints.getDesignOptions.method,
            }),
        }),
        getEntityKit: builder.query<string, EntityStructure>({
            query: ({ entityType, entityID }) => ({
                url: generatePath(apiEndpoints.getEntityKit.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEntityKit.method,
                responseHandler: 'text',
            }),
        }),
        updateEntityKit: builder.mutation<
            Team,
            EntityStructure & { data: DesignOptions }
        >({
            query: ({ entityType, entityID, data }) => ({
                url: generatePath(apiEndpoints.updateEntityKit.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.updateEntityKit.method,
                body: data,
            }),
        }),
        transferEntityOwnership: builder.mutation<
            any,
            EntityStructure & { data: any }
        >({
            query: ({ entityType, entityID, data }) => ({
                url: generatePath(apiEndpoints.transferEntityOwnership.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.transferEntityOwnership.method,
                body: data,
            }),
        }),
    }),
});

export const {
    useGetMeQuery,
    useLazyGetMeQuery,
    useGetRolesQuery,
    useLazyGetRolesQuery,
    useGetEntityRolesQuery,
    useGetEntityQuery,
    useGetUserDetailsQuery,
    useLazyGetUserDetailsQuery,
    useGetDesignOptionsQuery,
    useGetEntityKitQuery,
    useUpdateEntityKitMutation,
    useTransferEntityOwnershipMutation,
} = coreApi;
