import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import {
    useCreateAssociationMutation,
    useDeleteAssociationMutation,
    useUpdateAssociationMutation,
} from '../../api/associations';

import { Avatar } from 'primereact/avatar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';

import { INPUT_TYPES } from '../../types/common';
import { AssociationFormData } from '../../types/association';
import { Mixpanel } from '../../util/mixpanel';
import { Skeleton } from 'primereact/skeleton';
import { orderBy } from 'lodash';
import { useGetEntityCategoriesQuery } from '../../api/sports';
import { Dropdown } from 'primereact/dropdown';
import { useLazyGetRolesQuery } from '../../api/core';

interface FormProps {
    associationID?: string;
    associationName: string;
    entityCategoryID?: string;
    shortName?: string;
}

interface AssociationFormProps {
    formHasActions: boolean;
    initialValues: FormProps;
    associationID?: AssociationFormData['associationID'];
    onCancel?: () => void;
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    onDelete?: (status: string) => void;
    onUpdate?: (data: any) => void;
}

export default function AssociationForm(
    props: AssociationFormProps
): JSX.Element {
    const {
        formHasActions,
        initialValues,
        associationID: associationIDProp,
        onDelete,
        onCancel,
        onSuccess,
        onError,
        onUpdate,
    } = props;
    const { associationID: associationIDParam, userID } = useParams();

    const associationID = associationIDProp || associationIDParam;

    const [getRoles] = useLazyGetRolesQuery();
    const [createAssociation] = useCreateAssociationMutation();
    const [updateAssociation] = useUpdateAssociationMutation();
    const [deleteAssociation] = useDeleteAssociationMutation();

    const { data: categories, isLoading: isLoadingCategories } =
        useGetEntityCategoriesQuery({
            sportID: 'aus_football_au',
        });

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const requiredFields: Array<keyof FormProps> = [
        'associationName',
        'shortName',
        'entityCategoryID',
    ];

    const isCreate = !associationID;

    const handleSubmit = (
        data: FormProps,
        { setSubmitting }: FormikHelpers<FormProps>
    ) => {
        setSubmitting(true);

        if (isCreate) {
            createAssociation({
                userID,
                ...data,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) onError('An error has occurred.');
                    } else {
                        if (onSuccess) onSuccess(response);

                        Mixpanel.track('Create Association');
                    }
                })
                .catch((error) => {
                    if (onError) onError(error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            updateAssociation({
                associationID,
                ...data,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) onError('An error has occurred.');
                    } else {
                        if (onUpdate) onUpdate(response);

                        Mixpanel.track(`Update Association`);
                    }
                })
                .catch((error) => {
                    if (onError) onError(error);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    const handleDelete = ({
        setSubmitting,
    }: {
        setSubmitting: (isSubmitting: boolean) => void;
    }) => {
        // set formik to submitting
        setSubmitting(true);

        // close modal to show form
        setShowDeleteDialog(false);

        // init deletion method
        deleteAssociation({ associationID: associationID ?? '' })
            .then(() => {
                onDelete && onDelete('success');
            })
            .catch((err) => {
                console.dir(err);
                onDelete && onDelete('error');

                if (userID) {
                    getRoles({ userID });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const confirmRemovalModalHeader = (
        <h4 className={'title-is-bold'}>Confirm Deletion</h4>
    );

    const confirmRemovalModalHeaderIcon = (
        <RookieButton
            text={true}
            severity="secondary"
            onClick={() => setShowDeleteDialog(false)}
            type="button"
            icon="close"
        />
    );

    const confirmRemovalModalContent = !isCreate && (
        <div className="modal-content">
            <p className="delete-msg">
                Are you sure you want to delete this Association?
            </p>
            <div className="list">
                <ListItem
                    start={<Avatar label={initialValues.associationName} />}
                    title={initialValues.associationName}
                />
            </div>
        </div>
    );

    const entityCategories = useMemo(() => {
        const options = categories?.data
            ?.filter((cat) => cat.entityType === 'associations')
            .map((cat) => ({
                label: cat.entityCategoryName,
                value: cat.entityCategoryID,
            }));

        return orderBy(options, 'label');
    }, [categories]);

    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                const errors: { [key: string]: string } = {};
                requiredFields.forEach((field) => {
                    if (!values[field]) {
                        errors[field] = 'Required';
                    }
                });
                return errors;
            }}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setSubmitting,
            }) => (
                <>
                    <form onSubmit={handleSubmit}>
                        {!isCreate && (
                            <div className="wrapped-form_header">
                                <h4 className="title">Association Settings</h4>
                            </div>
                        )}
                        <FormFields>
                            <FormGroup
                                label="Association Type"
                                htmlFor="entityCategoryID"
                                error={errors.entityCategoryID}
                                showError={
                                    !!errors.entityCategoryID &&
                                    touched.entityCategoryID
                                }
                            >
                                {isLoadingCategories ? (
                                    <Skeleton height="44px" />
                                ) : (
                                    <Dropdown
                                        id="entityCategoryID"
                                        name="entityCategoryID"
                                        value={values.entityCategoryID}
                                        options={entityCategories}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required={isCreate}
                                        disabled={!isCreate}
                                    />
                                )}
                            </FormGroup>
                            <FormGroup
                                label="Association ID"
                                htmlFor="associationID"
                                error={errors.associationID}
                                showError={
                                    !!errors.associationID &&
                                    touched.associationID
                                }
                            >
                                <InputText
                                    id="associationID"
                                    name="associationID"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Association ID"
                                    value={values.associationID}
                                    required={isCreate}
                                    disabled={!isCreate}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Association Name"
                                htmlFor="associationName"
                                error={errors.associationName}
                                showError={
                                    !!errors.associationName &&
                                    touched.associationName
                                }
                            >
                                <InputText
                                    id="associationName"
                                    name="associationName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Association Name"
                                    value={values.associationName}
                                    required={isCreate}
                                    disabled={!isCreate}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Short Name"
                                htmlFor="associationName"
                                error={errors.associationName}
                                showError={
                                    !!errors.associationName &&
                                    touched.associationName
                                }
                            >
                                <InputText
                                    id="shortName"
                                    name="shortName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Association Short Name"
                                    value={values.shortName}
                                    required={isCreate}
                                />
                            </FormGroup>
                        </FormFields>

                        {formHasActions && (
                            <FormActions
                                start={
                                    <>
                                        {isCreate && (
                                            <RookieButton
                                                text={true}
                                                severity="secondary"
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    onCancel && onCancel()
                                                }
                                                type="button"
                                                icon="cancel"
                                                label="Cancel"
                                            />
                                        )}
                                        {!isCreate && (
                                            <RookieButton
                                                severity="danger"
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    setShowDeleteDialog(true)
                                                }
                                                type="button"
                                                icon="delete"
                                                label="Delete"
                                            />
                                        )}
                                    </>
                                }
                                end={
                                    <RookieButton
                                        className={
                                            isSubmitting ? 'is-submitting' : ''
                                        }
                                        disabled={isSubmitting}
                                        type="submit"
                                        icon={
                                            isSubmitting
                                                ? 'autorenew'
                                                : 'add_home'
                                        }
                                        label={
                                            !isCreate && isSubmitting
                                                ? 'Updating'
                                                : !isCreate
                                                ? 'Update Association'
                                                : isSubmitting
                                                ? 'Creating'
                                                : 'Create Association'
                                        }
                                    />
                                }
                            />
                        )}

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                        {!isCreate && (
                            <ConfirmDialog
                                className="modal delete-modal"
                                closeOnEscape
                                draggable={false}
                                dismissableMask
                                footer={
                                    <>
                                        <RookieButton
                                            text={true}
                                            severity="secondary"
                                            onClick={() =>
                                                setShowDeleteDialog(false)
                                            }
                                            type="button"
                                            icon="cancel"
                                            label="Cancel"
                                        />
                                        <RookieButton
                                            severity="danger"
                                            onClick={() =>
                                                handleDelete({ setSubmitting })
                                            }
                                            type="submit"
                                            icon="delete"
                                            label='="Yes, Delete'
                                        />
                                    </>
                                }
                                header={confirmRemovalModalHeader}
                                icons={confirmRemovalModalHeaderIcon}
                                message={confirmRemovalModalContent}
                                onHide={() => setShowDeleteDialog(false)}
                                visible={showDeleteDialog}
                            />
                        )}
                    </form>
                </>
            )}
        </Formik>
    );
}

AssociationForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        associationID: '',
        associationName: '',
        shortName: '',
        entityCategoryID: '',
    },
};
