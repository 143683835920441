import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetOrganisationQuery } from '../../api/organisations';

import OrganisationForm from './OrganisationForm';
import TransferEntityForm from '../users/TransferEntityForm';

import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';

import ErrorDisplay from '../../components/ErrorDisplay';
import DocumentHead from '../../components/DocumentHead';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { User } from '../../types/user';
import { Permissions } from '../../types/permissions';
import usePermission from '../../hooks/usePermission';

interface Props {
    user?: User;
}

const OrganisationSettings = (props: Props) => {
    const { organisationID } = useParams();

    const formResponseToast = useRef<Toast>(null);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    const navigate = useNavigate();

    const { checkPermission } = usePermission(organisationID || '');

    // API Calls
    const { data, isLoading, isError } = useGetOrganisationQuery(
        { organisationID },
        {
            skip: !organisationID,
        }
    );

    // Handle form deletion submission result
    const handleDelete = (status: string) => {
        setIsDeleted(true);
        const isSuccess = status === 'success';

        formResponseToast.current?.show({
            severity: isSuccess ? 'success' : 'warn',
            summary: isSuccess
                ? 'Organisation Deleted'
                : 'Deletion Unsuccessful',
            detail: isSuccess
                ? `The Organisation has been successfully deleted.`
                : `Organisation deletion could not be completed, try again later.`,
        });
    };

    // Handle form update submission result
    const handleUpdate = (status: string) => {
        const isSuccess = status === 'success';

        formResponseToast.current?.show({
            severity: isSuccess ? 'success' : 'warn',
            summary: isSuccess ? 'Organisation Updated' : 'Update Unsuccessful',
            detail: isSuccess
                ? `The Organisation details have been amended.`
                : `Organisation update could not be completed, try again later.`,
        });
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc="We were unable to load your Organisation Settings, try again or contact Organisation Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Organisation Settings - Rookie Me Hub"
                description="Organisation settings page"
            />
            {permissions.canEdit ? (
                <>
                    <OrganisationForm
                        organisationID={data?.data?.organisationID}
                        initialValues={data?.data}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                    />
                    <RookieButton
                        label="Transfer Ownership"
                        onClick={() => setShowTransferModal(true)}
                    />
                </>
            ) : (
                <ErrorDisplay
                    errorType={ERROR_TYPES.somethingsWrong}
                    title="You lack permissions to see this page"
                    desc="Contact the page admin if you believe this is in error."
                    proportion="enlarged"
                />
            )}

            <Toast
                ref={formResponseToast}
                onHide={() =>
                    navigate(
                        isDeleted
                            ? `/u/${props.user?.userID}/entities/organisations`
                            : '',
                        { replace: false }
                    )
                }
            />
            <Sidebar
                header="Transfer Ownership"
                onHide={() => setShowTransferModal(false)}
                visible={showTransferModal}
                position="right"
            >
                {organisationID && (
                    <TransferEntityForm
                        entityType={BaseEntityType.organisations}
                        entityID={organisationID}
                        onSuccess={() => setShowTransferModal(false)}
                    />
                )}
            </Sidebar>
        </>
    );
};

export default OrganisationSettings;
