import React from 'react';

interface Props {
    name: string;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    title?: string;
    fill?: boolean;
    style: any;
}

const Icon = (props: Props) => {
    const { size, name, className, title } = props;

    const getSizeClassName = (size: Props['size']) => {
        switch (size) {
            case 'small':
                return 'is-small';
            case 'medium':
                return 'is-medium';
            case 'large':
                return 'is-large';
            default:
                return 'is-medium';
        }
    };

    if (!name) return null;

    return (
        <span
            title={title}
            style={
                props.fill
                    ? { fontVariationSettings: '"FILL" 1', ...props.style }
                    : props.style
            }
            className={`icon material-symbols-outlined ${
                className ? className : ''
            } ${size && getSizeClassName(size)}`}
        >
            {name}
        </span>
    );
};

Icon.defaultProps = {
    size: 'medium',
    fill: false,
    style: {},
};

export default Icon;
