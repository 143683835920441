import { useContext } from 'react';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    useUpsertAssociationSeasonMutation,
    useUpsertTeamSeasonMutation,
} from '../../api/seasons';

import { ToastContext } from '../../contexts/ToastContext';

import { ButtonProps } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { ToastMessage } from 'primereact/toast';

import FormGroup from '../../components/FormGroup';
import FormActions from '../../components/FormActions';
import RookieButton from '../../components/RookieButton';

import { SeasonFormData } from '../../types/seasons';

import { Mixpanel } from '../../util/mixpanel';

interface Props {
    actions?: ButtonProps[];
    seasonID?: string;
    associationID?: string;
    teamID?: string;
    initialValues?: any;
    onCancel?: () => void;
    onError?: () => void;
    onSuccess?: () => void;
}

const availableFields: Array<keyof SeasonFormData> = ['seasonName'];

const SeasonForm = (props: Props) => {
    const {
        actions,
        initialValues,
        seasonID,
        teamID,
        associationID,
        onSuccess,
        onError,
    } = props;

    const useUpsertSeason = associationID
        ? useUpsertAssociationSeasonMutation
        : useUpsertTeamSeasonMutation;

    const [upsertSeason] = useUpsertSeason();

    const isCreate = !seasonID;

    const toast = useContext(ToastContext);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleSubmit = (data: SeasonFormData) => {
        if (associationID || teamID) {
            upsertSeason({
                seasonID,
                teamID: teamID || '',
                associationID: associationID || undefined,
                ...data,
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        summary: 'Success',
                        detail: `Updated ${
                            'data' in response ? response.data.seasonName : ''
                        }`,
                    });

                    Mixpanel.track(`${seasonID ? 'Update' : 'Create'} Season`);

                    if (onSuccess) {
                        onSuccess();
                    }
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: `Could not update season.`,
                    });
                    if (onError) {
                        onError();
                    }
                });
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={pick(initialValues, availableFields)}
            onSubmit={handleSubmit}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                values,
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormGroup label="Season Name" htmlFor="seasonName">
                            <InputText
                                id="seasonName"
                                name="seasonName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Season Name"
                                value={values.seasonName}
                                required
                            />
                        </FormGroup>
                        <FormActions
                            start={
                                actions &&
                                actions.map((action, i) => (
                                    <RookieButton
                                        key={`season-form-action-${i}`}
                                        {...action}
                                    />
                                ))
                            }
                            end={
                                <RookieButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    label={
                                        isCreate
                                            ? 'Create Season'
                                            : 'Update Season'
                                    }
                                />
                            }
                        />
                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

SeasonForm.defaultProps = {
    initialValues: {
        seasonName: '',
    },
};

export default SeasonForm;
