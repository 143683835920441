export const PLAYER_FIELD = [
    {
        label: 'First Name',
        key: 'firstName',
        example: 'John',
        fieldType: { type: 'input' },
        alternateMatches: [
            'FirstName',
            'first_name',
            'firstname',
            'FIRST NAME',
            'GivenName',
            'givenname',
            'given_name',
        ],
        validations: [
            {
                rule: 'required',
                errorMessage: 'First Name is required',
                level: 'error',
            },
        ],
    },
    {
        label: 'Last Name',
        key: 'lastName',
        example: 'Doe',
        fieldType: { type: 'input' },
        alternateMatches: [
            'Last Name',
            'last_name',
            'lastname',
            'Lastname',
            'SURNAME',
            'Surname',
            'surname',
            'Sur Name',
            'sur_name',
            'Family Name',
            'familyname',
            'family_name',
        ],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Last Name is required',
                level: 'error',
            },
        ],
    },
    {
        label: 'Display Name',
        key: 'displayName',
        example: 'John Doe',
        fieldType: { type: 'input' },
        alternateMatches: [
            'display',
            'Display',
            'displayname',
            'DisplayName',
            'display_name',
            'nickname',
            'Nickname',
            'nick_name',
            'NickName',
            'Name',
        ],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Display Name is required',
                level: 'error',
            },
        ],
    },
    {
        label: 'Uniform Number',
        key: 'uniformNumber',
        example: '1',
        fieldType: { type: 'input' },
        alternateMatches: [
            'number',
            'Number',
            'NUMBER',
            'jumpernumber',
            'jumper number',
            'Jumper Number',
            'JumperNumber',
            'jumper_number',
            'uniformnumber',
            'uniform number',
            'UniformNumber',
            'Uniform Number',
            'uniform_number',
            'jerseynumber',
            'jersey number',
            'Jersey Number',
            'JerseyNumber',
            'jersey_number',
            'jerseynumber',
        ],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Uniform Number is required',
                level: 'error',
            },
        ],
    },
    {
        label: 'Date of Birth',
        key: 'dateOfBirth',
        example: '1999-11-26',
        fieldType: { type: 'input' },
        alternateMatches: [
            'DOB',
            'dob',
            'birthdate',
            'BirthDate',
            'birth_date',
        ],
    },
    {
        label: 'Gender',
        key: 'gender',
        example: 'Male',
        fieldType: {
            type: 'select',
            options: [
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
            ],
        },
        alternateMatches: ['Gender', 'gender', 'GENDER', 'Sex', 'sex'],
    },
];

export const EVENTS_FIELD = [
    {
        label: 'Event Name',
        key: 'eventName',
        example: 'Round 1',
        fieldType: { type: 'input' },
        alternateMatches: [
            'round',
            'Round',
            'eventName',
            'Event_Name',
            'name',
            'Name',
            'title',
            'Title',
        ],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Event Name is required',
                level: 'error',
            },
        ],
    },
    {
        label: 'Home Game',
        key: 'isHomeTeam',
        fieldType: {
            type: 'checkbox',
        },
    },
    {
        label: 'Opponent',
        key: 'opponentTeam',
        example: 'The A Team',
        fieldType: {
            type: 'input',
        },
        alternateMatches: ['opponent', 'Opponent', 'opposition', 'Opposition'],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Event Type is required',
                level: 'error',
            },
        ],
    },
    {
        label: 'Start Date',
        key: 'startDate',
        example: '2024-06-26',
        fieldType: { type: 'input' },
        alternateMatches: ['StartDate', 'start_date', 'Date', 'date'],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Start Date is required',
                level: 'error',
            },
            {
                rule: 'regex',
                value: '(19|20)\\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[0-1])',
                errorMessage: 'Incorrect date format. Please use yyyy-mm-dd',
                level: 'error',
            },
        ],
    },
    {
        label: 'Start Time',
        key: 'startTime',
        example: '13:00',
        fieldType: { type: 'input' },
        alternateMatches: ['StartTime', 'start_time', 'time', 'Time'],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Start Time is required',
                level: 'error',
            },
            {
                rule: 'regex',
                value: '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$',
                errorMessage: 'Incorrect format. Use hh:mm',
                level: 'error',
            },
        ],
    },
    {
        label: 'End Date',
        key: 'endDate',
        example: '2024-06-26',
        fieldType: { type: 'input' },
        alternateMatches: ['EndDate', 'end_date'],
        validations: [
            {
                rule: 'regex',
                value: '(19|20)\\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[0-1])',
                errorMessage: 'Incorrect date format. Please use yyyy-mm-dd',
                level: 'error',
            },
        ],
    },
    {
        label: 'End Time',
        key: 'endTime',
        example: '15:00',
        fieldType: { type: 'input' },
        alternateMatches: ['EndTime', 'end_time'],
        validations: [
            {
                rule: 'regex',
                value: '^((0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])?$',
                errorMessage: 'Incorrect format. Use hh:mm',
                level: 'error',
            },
        ],
    },
    {
        label: 'Address',
        key: 'address',
        example: '123 Example Street, City, State, 1234',
        fieldType: { type: 'input' },
        alternateMatches: ['location', 'Venue', 'venueName'],
        validations: [
            {
                rule: 'required',
                errorMessage: 'Street is required',
                level: 'error',
            },
        ],
    },
    {
        label: 'Description',
        key: 'description',
        example: 'Test description',
        fieldType: { type: 'input' },
        alternateMatches: ['Details', 'EventDetails', 'event_details'],
    },
];
