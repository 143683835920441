import { TooltipProps } from 'primereact/tooltip';

export const defaultReportState = {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
    isUninitialized: true,
};

export const defaultTooltipOptions: TooltipProps = {
    position: 'top',
    showDelay: 500,
};
