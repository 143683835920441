import { Dialog } from 'primereact/dialog';
import EntityPreview from './EntityPreview';
import EntityDesigner from './EntityDesigner';
import { useState } from 'react';
import { ColumnEditorOptions } from 'primereact/column';

const EntityDesignerInput = ({ options }: { options: ColumnEditorOptions }) => {
    const [showEditor, setShowEditor] = useState(false);

    return (
        <>
            <div onClick={(e) => setShowEditor(true)}>
                <EntityPreview
                    design={options.value}
                    style={{ width: '40px', height: '40px', margin: '0' }}
                />
            </div>
            <Dialog visible={showEditor} onHide={() => setShowEditor(false)}>
                <EntityDesigner
                    initialValues={options.value}
                    onCancel={() => setShowEditor(false)}
                    onSubmit={(value) => {
                        options &&
                            options.editorCallback &&
                            options.editorCallback(value);
                        setShowEditor(false);
                    }}
                    submitLabel="Save Design"
                />
            </Dialog>
        </>
    );
};

export default EntityDesignerInput;
