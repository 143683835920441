import { Message } from 'primereact/message';
import Icon from './Icon';
import React from 'react';
import { classNames } from 'primereact/utils';

interface Props {
    children: React.ReactNode;
    className?: string;
    error?: string;
    message?: string;
    htmlFor?: string;
    showError?: boolean;
    label?: string | React.ReactNode;
    size?: 'full' | 'halfLeft' | 'halfRight';
}

const FormGroup = (props: Props) => {
    const {
        children,
        className,
        label,
        error,
        showError,
        message,
        htmlFor,
        size,
    } = props;

    const fieldClass = classNames('form-group', className, {
        [`is-${size}`]: size,
    });

    return (
        <div className={fieldClass}>
            {label && (
                <label className="form-label" htmlFor={htmlFor ? htmlFor : ''}>
                    {label}
                </label>
            )}
            <div className={`form-field ${error ? 'has-error' : ''}`}>
                {children}
            </div>
            {showError && (
                <Message
                    className="form-message form-message--error message-floated"
                    severity="error"
                    text={error}
                    icon={<Icon name="error" size="small" />}
                />
            )}
            {message && (
                <Message
                    className="form-message"
                    text={message}
                    icon={<Icon name="info" size="small" />}
                    severity="warn"
                />
            )}
        </div>
    );
};

export default FormGroup;
