import { Dispatch, SetStateAction } from 'react';

export enum ThemeScale {
    Default = 'Default',
    Medium = 'Medium',
    Large = 'Large',
}

export enum ThemeMode {
    Light = 'Light',
    Dark = 'Dark',
}

export interface LayoutState {
    collapsedSidebar: boolean;
    pinSidebar: boolean;
    showMenuDrawer: boolean;
}

export interface LayoutConfig {
    theme: ThemeMode;
    scale: ThemeScale;
}

export interface LayoutContextProps {
    layoutConfig: LayoutConfig;
    setLayoutConfig: Dispatch<SetStateAction<LayoutConfig>>;
    layoutState: LayoutState;
    setLayoutState: Dispatch<SetStateAction<LayoutState>>;
    onMenuToggle: () => void;
    onAnchorSidebarToggle: () => void;
}

export interface TopBarRef {
    menubutton?: HTMLButtonElement | null;
}
