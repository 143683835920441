import React from 'react';
import { classNames } from 'primereact/utils';

interface Props {
    children: React.ReactNode;
    className?: string;
    divider?: boolean;
    row?: boolean;
    style?: any;
}

const FormFields = (props: Props) => {
    const { children, className, divider, row, style } = props;

    const fieldClass = classNames('form-fields', className, {
        'is-divider': divider,
        'is-row': row,
    });

    return (
        <div className={fieldClass} style={style}>
            {children}
        </div>
    );
};

export default FormFields;
