import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import usePermission from '../hooks/usePermission';

import { ASSOCIATION_ROUTE, ORGANISATION_ROUTE, TEAM_ROUTE } from './routes';
import { USE_CUSTOM_TOKEN } from '../util/constants';

import ErrorDisplay from '../components/ErrorDisplay';
import Loader from '../components/Loader';

import { ERROR_TYPES } from '../types/common';
import { Route } from '../types/route';
import { Me } from '../types/user';

interface Props {
    children: React.ReactElement;
    route: Route;
    user?: Me;
}

const ProtectedRoute = ({ children, route, user }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { loginWithRedirect, isAuthenticated } = useAuth0();

    const useCustomToken = localStorage.getItem(USE_CUSTOM_TOKEN);

    const pathSplit = location.pathname.split('/');
    const entityRoute = pathSplit[1];
    const entityID = pathSplit[2];

    // Permission Checking
    const { checkPermission, isAdmin, role } = usePermission(
        entityID || '',
        !user
    );

    if (!isAuthenticated && useCustomToken !== 'true') {
        loginWithRedirect({
            appState: {
                returnTo: window.location.href,
            },
        });

        return <Loader size="fullscreen" title="Redirecting to login.." />;
    }

    const hasPermission = checkPermission(route.permissions);

    const requiresRole = [
        ASSOCIATION_ROUTE,
        ORGANISATION_ROUTE,
        TEAM_ROUTE,
    ].includes(entityRoute);

    const resetRoute = () => navigate(0);

    if (requiresRole && !role.data && !isAdmin) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.pageNotFound}
                title="You don't have a role within this entity"
                desc="Contact the entity admin if you believe this is in error."
                proportion="enlarged"
                handleReset={() => resetRoute()}
            />
        );
    }

    if (!hasPermission) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.somethingsWrong}
                title="You lack permissions to see this page"
                desc="Contact the page admin if you believe this is in error."
                proportion="enlarged"
                handleReset={() => resetRoute()}
            />
        );
    }

    return children;
};

export default ProtectedRoute;
