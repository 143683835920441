import { useParams } from 'react-router-dom';

import AddLicencesForm from './AddLicencesForm';
import TransferLicencesForm from './TransferLicencesForm';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { Sidebar } from 'primereact/sidebar';

import EntityName from '../../components/EntityName';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { LicenceGroupInfo } from '../../types/licences';
import { BaseEntityType } from '../../types/common';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
    licenceGroups: LicenceGroupInfo[];
    isLoading: boolean;
    onTransferLicences?: (
        licenceGroupID: string,
        organisationID: string
    ) => void;
    showAddModal: boolean;
    showAssignModal: boolean;
    activeLicenceGroup?: string;
    onLicenceGroupClick: (licenceGroup: LicenceGroupInfo) => void;
    onShowAssignModal: (licenceGroupID: string) => void;
    onCloseAssignModal: () => void;
    onShowAddModal: () => void;
    onCloseAddModal: () => void;
}

const AssociationLicencesView = (props: Props) => {
    const {
        activeLicenceGroup,
        licenceGroups,
        onTransferLicences,
        showAssignModal,
        showAddModal,
        onShowAssignModal,
        onShowAddModal,
        onCloseAddModal,
        onCloseAssignModal,
        onLicenceGroupClick,
        isLoading,
    } = props;

    const { associationID } = useParams();

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                actions={[
                    {
                        label: 'Add Licences',
                        command: () => onShowAddModal(),
                    },
                ]}
            />
            <DataTable
                value={isLoading ? Array(3) : licenceGroups}
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    field="licenceTitle"
                    header="Name"
                    body={isLoading && <Skeleton />}
                />
                <Column
                    field="qtyAvailable"
                    header="Available"
                    body={isLoading && <Skeleton />}
                />
                <Column
                    field="qtyAssigned"
                    header="Used"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <ProgressBar
                                    style={{ flex: '1', height: '8px' }}
                                    showValue={false}
                                    value={
                                        (data.qtyTotal / data.qtyAssigned) * 100
                                    }
                                />
                                <span>
                                    {data.qtyAssigned}/{data.qtyTotal}
                                </span>
                            </div>
                        )
                    }
                />
                <Column
                    field="status"
                    header="Status"
                    body={isLoading && <Skeleton />}
                />
                <Column
                    field="owner"
                    header="Organisation"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : (
                            <EntityName
                                entityID={data.entityID}
                                entityType={data.entityType}
                            />
                        )
                    }
                />
                <Column
                    body={(data) => {
                        if (isLoading) {
                            return <Skeleton />;
                        } else {
                            return data.originalEntityID ||
                                data.qtyTotal === data.qtyAssigned ? (
                                <RookieButton
                                    severity="secondary"
                                    label="View"
                                    onClick={() => onLicenceGroupClick(data)}
                                />
                            ) : (
                                <RookieButton
                                    label="Transfer"
                                    onClick={() => onShowAssignModal(data.id)}
                                />
                            );
                        }
                    }}
                    style={{ textAlign: 'right' }}
                />
            </DataTable>
            <Sidebar
                header="Transfer Licence"
                onHide={() => onCloseAssignModal()}
                visible={showAssignModal}
                position="right"
            >
                {activeLicenceGroup && associationID && (
                    <TransferLicencesForm
                        licenceGroupID={activeLicenceGroup}
                        associationID={associationID}
                        onCancel={() => onCloseAssignModal()}
                        onSubmit={onTransferLicences}
                    />
                )}
            </Sidebar>
            <Sidebar
                header="Add Licence"
                onHide={() => onCloseAddModal()}
                visible={showAddModal}
                position="right"
            >
                {associationID && (
                    <AddLicencesForm
                        entityID={associationID}
                        entityType={BaseEntityType.associations}
                    />
                )}
            </Sidebar>
        </PageContainer>
    );
};

export default AssociationLicencesView;
