import { useEffect, useState } from 'react';
import { isObject, sortBy } from 'lodash';

import { useAddTeamToCollectionMutation } from '../../api/collections';
import { useGetAssociationOrganisationsQuery } from '../../api/associations';
import { useLazyGetOrganisationTeamsQuery } from '../../api/organisations';

import {
    Tree,
    TreeCheckboxSelectionKeys,
    TreeCheckboxSelectionKeyType,
    TreeMultipleSelectionKeys,
    TreeSelectionEvent,
} from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';

import { BaseEntityType } from '../../types/common';
import { OrganisationStatus } from '../../types/organisation';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    collectionID: string;
    disabledIDs?: string[];
    onError?: (error?: any) => void;
    onComplete?: (response: any) => void;
}

const AddToCollectionForm = ({
    entityType,
    entityID,
    collectionID,
    disabledIDs,
    onError,
    onComplete,
}: Props) => {
    const [loading, setLoading] = useState(false);

    const [selectedKeys, setSelectedKeys] = useState<
        string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null
    >(null);

    const [nodes, setNodes] = useState<TreeNode[]>([]);

    const { data: organisationsData, isLoading } =
        useGetAssociationOrganisationsQuery({
            associationID: entityID,
            limit: 50,
        });

    const [getTeams] = useLazyGetOrganisationTeamsQuery();
    const [addToCollection] = useAddTeamToCollectionMutation();

    useEffect(() => {
        if (organisationsData?.data && organisationsData?.data.length > 0) {
            const sortedOrgs = sortBy(
                organisationsData.data,
                'organisationName'
            );

            setNodes(
                sortedOrgs.map((org, index) => ({
                    key: index.toString(),
                    label: org.organisationName,
                    leaf: false,
                    id: org.organisationID,
                    data: {
                        entityType: BaseEntityType.organisations,
                    },
                }))
            );
        }
    }, [organisationsData]);

    // Get selected nodes based on keys
    const getSelectedNodes = (
        nodes: TreeNode[],
        selectedKeys: (string | number | undefined)[]
    ): TreeNode[] => {
        const selectedNodes: TreeNode[] = [];

        const findSelectedNodes = (nodeList: TreeNode[]) => {
            for (const node of nodeList) {
                // Check if the current node is selected
                if (selectedKeys.includes(node.key)) {
                    selectedNodes.push(node);
                }

                // If the node has children, recursively check them
                if (node.children && node.children.length > 0) {
                    findSelectedNodes(node.children);
                }
            }
        };

        // Start recursive traversal from the top level nodes
        findSelectedNodes(nodes);

        return selectedNodes;
    };

    const handleSelectionChange = ({ value }: TreeSelectionEvent) => {
        if (isObject(value)) {
            // get a list of selected nodes
            const selectedNodes = getSelectedNodes(nodes, Object.keys(value));

            // get a list of selectable node keys
            const selectableKeys = selectedNodes
                .filter((node) => node.id && !disabledIDs?.includes(node.id))
                .map((node) => node.key);

            const selection = Object.keys(value).reduce((result, key) => {
                if (selectableKeys.includes(key)) {
                    result[key] = value[key] as TreeCheckboxSelectionKeyType;
                }
                return result;
            }, {} as TreeCheckboxSelectionKeys);

            setSelectedKeys(selection);
        }
    };

    const handleSubmit = () => {
        setLoading(true);

        if (selectedKeys && entityType && entityID) {
            const checked = Object.keys(selectedKeys).filter((key) => {
                const value =
                    isObject(selectedKeys) &&
                    (selectedKeys[key] as TreeCheckboxSelectionKeyType);

                return value && value.checked;
            });

            const selected = getSelectedNodes(nodes, checked)
                .filter((node) => node.data.entityType === BaseEntityType.teams)
                .map((node) => node.id || '');

            addToCollection({
                entityType,
                entityID,
                collectionID,
                teamIDs: selected,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onComplete) {
                            onComplete(response.data.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);

            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const loadTeams = async (node: TreeNode, selectAll = false) => {
        if (node.leaf === false) {
            if (!node.children) {
                node.children = [];

                if (node.id && node.key) {
                    const keyString = node.key.toString();

                    try {
                        const { data: teams } = await getTeams({
                            organisationID: node.id,
                            entityStatus: OrganisationStatus.Active,
                            limit: 50,
                            cursor: '',
                        }).unwrap();

                        const sortedTeams = sortBy(teams, 'teamName');

                        node.children = sortedTeams.map((team, index) => ({
                            key: keyString + '-' + index,
                            label: team.teamName,
                            id: team.teamID,
                            selectable: !disabledIDs?.includes(team.teamID),
                            className: disabledIDs?.includes(team.teamID)
                                ? 'p-disabled'
                                : '',
                            data: {
                                entityType: BaseEntityType.teams,
                            },
                        }));

                        let value = [...nodes];
                        value[parseInt(node.key.toString(), 10)] = node;

                        if (
                            selectAll &&
                            (!selectedKeys ||
                                (isObject(selectedKeys) &&
                                    !selectedKeys[
                                        keyString as keyof typeof selectedKeys
                                    ]))
                        ) {
                            const selectedChildren = node.children.reduce(
                                (result, item) => {
                                    if (
                                        item.key &&
                                        item.id &&
                                        !disabledIDs?.includes(item.id)
                                    ) {
                                        result[item.key] = {
                                            checked: true,
                                            partialChecked: false,
                                        };
                                    }

                                    return result;
                                },
                                {} as TreeCheckboxSelectionKeys
                            );

                            setSelectedKeys((prev) => {
                                const prevTeams = isObject(prev) ? prev : {};

                                return {
                                    ...prevTeams,
                                    ...selectedChildren,
                                } as TreeCheckboxSelectionKeys;
                            });
                        }

                        setNodes(value);
                    } catch (error) {
                        console.error(
                            `Error fetching seasons for ${node.label}:`,
                            error
                        );
                    }
                }
            }
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="addto-collections-form">
            <Tree
                className="p-tree-borderless"
                value={nodes}
                onExpand={(e) => loadTeams(e.node)}
                loading={loading}
                selectionMode="checkbox"
                selectionKeys={selectedKeys}
                expandIcon={() => <Icon name="arrow_right" />}
                collapseIcon={() => <Icon name="arrow_drop_down" />}
                onSelectionChange={handleSelectionChange}
                onSelect={(e) => loadTeams(e.node, true)}
            />
            <RookieButton label="Add To Collection" onClick={handleSubmit} />
        </div>
    );
};

export default AddToCollectionForm;
