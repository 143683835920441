import { useState } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';

import {
    useCreateCollectionMutation,
    useUpdateCollectionMutation,
} from '../../api/collections';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';

import { BaseEntityType } from '../../types/common';
import { Collection, CollectionFormData } from '../../types/collections';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    collection?: Collection;
    onError?: (error?: any) => void;
    onCreate?: (response: Collection) => void;
    onUpdate?: (response: Collection) => void;
}

const CollectionsForm = ({
    entityType,
    entityID,
    collection,
    onError,
    onCreate,
    onUpdate,
}: Props) => {
    const [loading, setLoading] = useState(false);

    const isUpdating = !!collection?.collectionID;

    const [createCollection] = useCreateCollectionMutation();
    const [updateCollection] = useUpdateCollectionMutation();

    const upsertCollection = isUpdating ? updateCollection : createCollection;

    const handleSubmit = (data: CollectionFormData) => {
        setLoading(true);

        const args = {
            entityType,
            entityID,
            ...data,
        };

        if (isUpdating && collection.collectionID) {
            args.collectionID = collection.collectionID;
        }

        if (entityType && entityID) {
            upsertCollection(args)
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onUpdate) {
                            onUpdate(response.data.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);

            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<CollectionFormData> = {};

        if (!values.collectionName || values.collectionName.trim() === '') {
            errors.collectionName = 'Field cannot be blank';
        }

        return errors;
    };

    return (
        <div>
            <Formik
                initialValues={{
                    collectionName: collection?.collectionName || '',
                    collectionDescription:
                        collection?.collectionDescription || '',
                }}
                onSubmit={handleSubmit}
                validate={validate}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormFields
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <FormGroup
                                    label="Name"
                                    htmlFor="collectionName"
                                    error={errors.collectionName}
                                    showError={
                                        !!errors.collectionName &&
                                        touched.collectionName
                                    }
                                >
                                    <InputText
                                        id="collectionName"
                                        name="collectionName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Collection Name"
                                        value={values.collectionName}
                                        autoFocus
                                        disabled={loading}
                                    />
                                </FormGroup>
                                <FormGroup label="Description">
                                    <InputTextarea
                                        id="collectionDescription"
                                        name="collectionDescription"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Add a description"
                                        value={values.collectionDescription}
                                        autoResize
                                        disabled={loading}
                                    />
                                </FormGroup>
                            </FormFields>
                            <FormActions
                                end={
                                    <RookieButton
                                        type="submit"
                                        label={isUpdating ? 'Update' : 'Create'}
                                        disabled={
                                            loading ||
                                            values.collectionName === ''
                                        }
                                        loading={loading}
                                    />
                                }
                            />
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CollectionsForm;
