import { useRef, useContext } from 'react';
import { format } from 'date-fns';
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import ErrorDisplay from '../../components/ErrorDisplay';
import PageHeader from '../../layout/PageHeader';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import { ToastMessage } from 'primereact/toast';
import { ToastContext } from '../../contexts/ToastContext';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';
import { TabMenu } from 'primereact/tabmenu';

import { Season } from '../../types/seasons';
import { ERROR_TYPES } from '../../types/common';

import SeasonSettings from './SeasonSettings';
import SeasonForm from './SeasonForm';
import { Sidebar } from 'primereact/sidebar';
import { useGetTeamQuery, useUpdateTeamMutation } from '../../api/teams';
import { UserDetails } from '../../types/user';

interface Props {
    data: Season[];
    staffData: UserDetails[];
    isError: boolean;
    isLoading: boolean;
    isFetching: boolean;
    onCloseSeasonDialog: () => void;
    onCloseSettingsDialog: () => void;
    onCreate: () => void;
    onDelete: () => void;
    onLoadMore: () => void;
    onSelect: (season: Season) => void;
    onShowSeasonDialog: () => void;
    onShowSettingsDialog: () => void;
    onUpdate: () => void;
    setFocus: (season: Season) => void;
    selectedSeason: Season | null;
    showPagination: boolean;
    showSeasonDialog: boolean;
    showSettingsDialog: boolean;
}

const TeamSeasonsOwnedView = ({
    data,
    staffData,
    isFetching,
    isLoading,
    onCloseSeasonDialog,
    onCloseSettingsDialog,
    onCreate,
    onLoadMore,
    onSelect,
    onUpdate,
    selectedSeason,
    setFocus,
    showPagination,
    showSettingsDialog,
    showSeasonDialog,
}: Props) => {
    const { teamID } = useParams();
    const rowMenu = useRef<Menu>(null);

    const location = useLocation();
    const navigate = useNavigate();

    const footerTemplate = (
        <RookieButton
            onClick={onLoadMore}
            severity="secondary"
            label="Load more"
            icon="pending"
        />
    );

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            desc={`Seasons will appear here.`}
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No Seasons"
        />
    );

    const actionTemplate = (row: Season) => {
        return (
            <div className="p-buttonset">
                <RookieButton
                    severity="secondary"
                    text={true}
                    className="td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        setFocus(row);
                    }}
                    icon="more_horiz"
                />
            </div>
        );
    };

    // Get default seasonID for the team.
    const teamQueryData = useGetTeamQuery(
        {
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const teamObjectData: any = teamQueryData.data;
    const defaultSeasonID = teamObjectData?.data?.defaultSeasonID;
    const toast = useContext(ToastContext);
    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const [updateTeam] = useUpdateTeamMutation();
    const handleSetDefaultSeason = async () => {
        if (selectedSeason?.seasonID) {
            try {
                await updateTeam({
                    teamID: teamID || '',
                    defaultSeasonID: selectedSeason.seasonID,
                });
                showToast({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Updated Current Season`,
                });
            } catch (error) {
                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Could not update season.',
                });
            }
        }
    };

    const columns = [
        {
            field: 'seasonName',
            header: 'Name',
            body: isLoading && <Skeleton />,
        },
        {
            header: 'Status',
            body: (row: Season) =>
                row.seasonID === defaultSeasonID ? 'Current Season' : '',
        },
        {
            field: 'createdAt',
            header: 'Created',
            body: (row: Season) =>
                isLoading ? (
                    <Skeleton />
                ) : (
                    format(new Date(row.createdAt), 'MMM yyyy')
                ),
        },
        {
            field: 'createdBy',
            header: 'Created By',
            body: (row: Season) => {
                const staff = staffData.find(
                    (staff) => staff.userID === row.createdBy
                );

                return staff ? `${staff?.firstName} ${staff?.lastName}` : '';
            },
        },
        {
            body: isLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
        },
    ];

    const rowActions = [
        {
            label: 'Edit',
            command: onUpdate,
        },

        ...(selectedSeason?.seasonID !== defaultSeasonID
            ? [
                  {
                      label: 'Set Current Season',
                      command: handleSetDefaultSeason,
                  },
              ]
            : []),
    ];

    const tabs = [
        {
            id: 'owned',
            label: 'Owned',
            data: {
                path: teamID
                    ? generatePath('/t/:teamID/seasons/owned', { teamID })
                    : '',
            },
        },
        {
            id: 'participating',
            label: 'Participating',
            data: {
                path: teamID
                    ? generatePath('/t/:teamID/seasons/participating', {
                          teamID,
                      })
                    : '',
            },
        },
    ];

    return (
        <PageContainer>
            <PageHeader
                title="Seasons"
                actions={[
                    {
                        label: 'Create Season',
                        command: onCreate,
                    },
                ]}
            />
            <TabMenu
                model={tabs}
                activeIndex={tabs.findIndex((tab) =>
                    location.pathname.includes(tab.data.path)
                )}
                onTabChange={(e) =>
                    e.value.data.path && navigate(e.value.data.path)
                }
            />
            <DataTable
                value={isLoading ? Array(5) : data}
                loading={isFetching && !isLoading}
                footer={!isFetching && showPagination ? footerTemplate : null}
                emptyMessage={emptyTemplate}
                selectionMode="single"
                onSelectionChange={(e) => {
                    onSelect(e.value);
                }}
                columnResizeMode="expand"
                resizableColumns
            >
                {(isLoading || data.length > 0) &&
                    columns.map((col: ColumnProps) => (
                        <Column key={col.field} {...col} />
                    ))}
            </DataTable>

            <Menu model={rowActions} popup ref={rowMenu} />

            <Dialog
                header={selectedSeason ? 'Edit Season' : 'Create Season'}
                onHide={onCloseSeasonDialog}
                visible={showSeasonDialog}
            >
                <SeasonForm
                    seasonID={selectedSeason?.seasonID}
                    teamID={teamID}
                    initialValues={selectedSeason}
                    onSuccess={onCloseSeasonDialog}
                    actions={[
                        {
                            label: 'Cancel',
                            type: 'button',
                            className: 'p-button-secondary',
                            onClick: onCloseSeasonDialog,
                        },
                    ]}
                />
            </Dialog>

            <Sidebar
                header="Season Settings"
                onHide={onCloseSettingsDialog}
                visible={showSettingsDialog}
                position="right"
            >
                <SeasonSettings
                    seasonID={selectedSeason?.seasonID}
                    teamID={teamID}
                    initialValues={selectedSeason}
                    onSuccess={onCloseSettingsDialog}
                    actions={[
                        {
                            label: 'Cancel',
                            type: 'button',
                            className: 'p-button-secondary',
                            onClick: onCloseSettingsDialog,
                        },
                    ]}
                />
            </Sidebar>
        </PageContainer>
    );
};

export default TeamSeasonsOwnedView;
