import { useParams } from 'react-router-dom';
import { useGetAssociationSeasonQuery } from '../../api/seasons';

import SeasonSettings from './SeasonSettings';

const SeasonSettingsContainer = () => {
    // Route Hooks
    const { associationID, seasonID } = useParams();

    // API Hooks
    const { data } = useGetAssociationSeasonQuery({
        associationID: associationID || '',
        seasonID: seasonID || '',
    });

    return (
        <SeasonSettings
            seasonID={data?.data.seasonID}
            associationID={associationID}
            initialValues={data?.data}
        />
    );
};

export default SeasonSettingsContainer;
