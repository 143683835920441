import React, { useMemo } from 'react';
import {
    ProgressSpinner,
    ProgressSpinnerProps,
} from 'primereact/progressspinner';

interface Props extends ProgressSpinnerProps {
    caps?: 'rounded' | 'squared';
    size?: 'small' | 'medium' | 'large' | 'fullscreen';
    weight?: 'light' | 'medium' | 'heavy';
    accented?: boolean;
    overlay?: boolean;
}

const Loader = (props: Props) => {
    const { accented, caps, children, className, size, weight, overlay } =
        props;

    const getSizeClassName = useMemo(() => {
        if (size) {
            switch (size) {
                case 'small':
                    return 'is-small';
                case 'medium':
                    return 'is-medium';
                case 'large':
                    return 'is-large';
                case 'fullscreen':
                    return 'is-fullscreen';
                default:
                    return 'is-medium';
            }
        }
    }, [size]);

    const getLoaderWeight = useMemo(() => {
        if (weight) {
            switch (weight) {
                case 'light':
                    return 'light-weight';
                case 'medium':
                    return 'medium-weight';
                case 'heavy':
                    return 'heavy-weight';
                default:
                    return 'medium-weight';
            }
        }
    }, [weight]);

    return (
        <div
            className={`loader loader-wrapper ${
                className ? className : ''
            } ${getSizeClassName} ${overlay ? 'is-overlay' : ''}`}
            data-weight={getLoaderWeight}
        >
            <ProgressSpinner
                className={`loader-element ${caps && caps} ${
                    accented ? 'is-accented' : 'is-default'
                }`}
            >
                {children}
            </ProgressSpinner>
        </div>
    );
};

export default Loader;

Loader.defaultProps = {
    accented: false,
    caps: 'rounded',
    size: 'medium',
    weight: 'medium',
};
