import { useEventListener, useUnmountEffect } from 'primereact/hooks';
import { useContext, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';

import AppSidebar from './AppSideBar';
import AppTopbar from './AppTopBar';

import { LayoutContext } from '../contexts/LayoutContext';

import { LayoutState, TopBarRef } from '../types/layout';
import { Outlet, useParams } from 'react-router-dom';
import { Me } from '../types/user';

interface Props {
    user?: Me;
}

const Layout = ({ user }: Props) => {
    const { layoutState, setLayoutState } = useContext(LayoutContext);
    const { teamID, organisationID, associationID } = useParams();

    const topbarRef = useRef<TopBarRef>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);

    const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] =
        useEventListener({
            type: 'click',
            listener: (event) => {
                const isOutsideClicked = !(
                    sidebarRef.current?.isSameNode(event.target as Node) ||
                    sidebarRef.current?.contains(event.target as Node) ||
                    topbarRef.current?.menubutton?.isSameNode(
                        event.target as Node
                    ) ||
                    topbarRef.current?.menubutton?.contains(
                        event.target as Node
                    )
                );

                if (isOutsideClicked) {
                    hideMenu();
                }
            },
        });

    const hideMenu = () => {
        setLayoutState((prev: LayoutState) => ({
            ...prev,
            showMenuDrawer: false,
        }));
        unbindMenuOutsideClickListener();
        unlockBodyScroll();
    };

    const lockBodyScroll = (): void => {
        document.body.classList.add('lock-scroll');
    };

    const unlockBodyScroll = (): void => {
        document.body.classList.remove('lock-scroll');
    };

    const handleSidebarMouseEnter = () => {
        if (!layoutState.pinSidebar && !layoutState.showMenuDrawer) {
            setLayoutState((prev) => ({
                ...prev,
                collapsedSidebar: false,
            }));
        }
    };

    const handleSidebarMouseLeave = () => {
        if (!layoutState.pinSidebar && !layoutState.showMenuDrawer) {
            setLayoutState((prev) => ({
                ...prev,
                collapsedSidebar: true,
            }));
        }
    };

    useEffect(() => {
        if (layoutState.showMenuDrawer) {
            bindMenuOutsideClickListener();
        }

        layoutState.showMenuDrawer && lockBodyScroll();
    }, [layoutState.showMenuDrawer, bindMenuOutsideClickListener]);

    useUnmountEffect(() => {
        unbindMenuOutsideClickListener();
    });

    const containerClass = classNames('layout-container', {
        'layout-sidebar-collapsed': layoutState.collapsedSidebar,
        'layout-sidebar-pinned': layoutState.pinSidebar,
        'layout-menu-active': layoutState.showMenuDrawer,
    });

    return (
        <>
            <div className={containerClass}>
                {(teamID || organisationID || associationID) && (
                    <div
                        ref={sidebarRef}
                        className="layout-sidebar is-theme-darkest"
                        onMouseEnter={handleSidebarMouseEnter}
                        onMouseLeave={handleSidebarMouseLeave}
                    >
                        <AppSidebar />
                    </div>
                )}
                <div className="layout-main">
                    <AppTopbar ref={topbarRef} user={user} />
                    <div className="layout-content">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
