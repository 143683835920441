// Licences
import { PaginatedResponse, Response } from './api';
import { BaseEntityType } from './common';
import { StripeInterval, Subscription } from './subscriptions';

export enum RookieProduct {
    GameDay = 'game_day',
}

export type Limits = { [key: string]: number };

export type Privileges = string[];

export type Restrictions = { [key: string]: string[] };

export interface LicenceType {
    privileges: Privileges;
    limits: Limits;
    restrictions: Restrictions;
    version: string;
    createdAt: string;
    ruleSet: string;
    lastEditedBy: string;
    createdBy: string;
    licenceTypeStatus: string;
    licenceTypeID: string;
    entitySportID: string;
    licenceName: string;
    lastEdited: string;
    rookieProduct: RookieProduct;
    product: StripeWhitelistedProduct;
}

export interface LicenceTypeFeature {
    unavailable: boolean;
    cardTitle: string;
    createdAt: string;
    featureID: string;
    lastEditedBy: string;
    createdBy: string;
    privilege: string;
    licenceTypeID: string;
    lastEdited: string;
    cardDescription: string;
    title: string;
    sortOrder: number;
}

export interface LicenceGroup {
    createdAt: string;
    createdBy: string;
    entityID: string;
    entitySportID: string;
    entityType: BaseEntityType;
    invoiceID: string;
    invoiceItemID: string;
    lastEditedAt: string;
    lastEditedBy: string;
    licenceGroupID: string;
    licenceTypeID: string;
    priceID: string;
    quantity: number;
    rookieProduct: RookieProduct;
    status: string;
    subscription: Subscription;
    subscriptionID: string;
    subscriptionItemID: string;
    totalAssigned: number;
    licenceType?: LicenceType;
    originalEntityID?: string;
    originalEntityType?: BaseEntityType;
    transactionID: string | number;
}

export interface LicenceGroupInfo {
    id: string;
    priceID: string;
    productTitle: string;
    licenceTitle: string;
    price: number;
    currency: string;
    billingPeriod: string;
    renewsAt: Date | null;
    trialActive: any;
    trialEndsAt: any;
    qtyAvailable: number;
    qtyAssigned: number;
    qtyTotal: number;
    status: string;
    groupID: string;
    description: string;
    canUpgrade: boolean;
    isOwner: boolean;
    owner: string;
    features: string[];
    originalEntityID?: string;
    originalEntityType?: BaseEntityType;
    entityID: string;
    entitySportID: string;
    entityType: BaseEntityType;
    lastEdited?: string;
    createdAt?: string;
    subscriptionID: string;
    transactionID: string | number;
}

export interface Licence {
    createdAt: string;
    createdBy: string;
    entityID: string;
    entitySportID: string;
    entityType: BaseEntityType;
    lastEditedAt: string;
    lastEditedBy: string;
    licenceGroupID: string;
    licenceGroupStatus: string;
    licenceID: string;
    licenceTypeDetails: LicenceType;
    licenceTypeID: string;
    rookieProduct: RookieProduct;
}

export interface StripeWhitelistedProduct {
    prices: StripeWhiteListedPrice[];
    productFeatures: ProductFeature[];
    productID: string;
    productName: string;
    productDescription: string;
    defaultPrice: string;
    images: string[];
    unitLabel: string;
    rookieProduct: RookieProduct;
}

export interface StripeWhiteListedPrice {
    recurring: StripeRecurring;
    unitAmount: number;
    currency: string;
    priceID: string;
    productID: string;
    tiersMode: object;
    tiers: object[];
    licenceTypeID?: string;
    default?: boolean;
}

export interface StripeRecurring {
    interval: StripeInterval;
}

export interface ProductFeature {
    name: string;
}

export type LicenceResponse = Response<Licence>;
export type LicencesResponse = PaginatedResponse<Licence[]>;

export type LicenceGroupResponse = Response<LicenceGroup>;
export type LicenceGroupsResponse = Response<LicenceGroup[]>;

export type LicenceTypesResponse = Response<LicenceType[]>;

export type LicenceTypeFeaturesResponse = Response<LicenceTypeFeature[]>;
