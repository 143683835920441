import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';

import { useGetGameTimeAdvancedReportQuery } from '../../api/reports';
import { useGetPlayersQuery } from '../../api/players';

import { periodSuffix } from '../../util/helper';
import { calculateTotals } from './helpers';
import { defaultReportState, defaultTooltipOptions } from './constants';
import { GameStat, ReportState } from '../../types/reports';
import { gameTimeAdvancedColumns, statKeys } from './gameTimeAdvancedConfig';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';

import PlayerAlertSettings from './PlayerAlertSettings';
import PlayerAlertButton from './PlayerAlertButton';
import { playerAlertTargetCell } from './DataTableCells';
import ErrorDisplay from '../../components/ErrorDisplay';
import TextSwap from '../../components/TextSwap';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { PlayerAlertModes } from '../../types/game';
import RookieButton from '../../components/RookieButton';
import { Mixpanel } from '../../util/mixpanel';
import { useGetEventQuery } from '../../api/events';

const GameTimeAdvancedReport = () => {
    // Route hooks
    const { teamID, eventID } = useParams();

    // Ref hooks
    const playerAlertOverlay = useRef<OverlayPanel>(null);
    const timestampRef = useRef(Date.now()).current; // for report cache busting
    const dataTable = useRef<DataTable<any>>(null);

    // State hooks
    const [playerAlertMode, setPlayerAlertMode] = useState<PlayerAlertModes>(
        PlayerAlertModes.None
    );
    const [playerAlertTarget, setPlayerAlertTarget] = useState(0.5);
    const [filter, setFilter] = useState(0);
    const [reportData, setReportData] =
        useState<ReportState<GameStat[]>>(defaultReportState);
    const [playersCursor, setPlayersCursor] = useState<string>('');

    // API hooks
    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'All',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const requestReportData = useGetGameTimeAdvancedReportQuery(
        {
            eventID: eventID || '',
            teamID: teamID || '',
            sessionID: timestampRef,
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const eventData = useGetEventQuery(
        {
            eventID: eventID || '',
            entityType: BaseEntityType.teams,
            entityID: teamID || '',
            expand: 'gameSummaryReport',
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const totalPeriods = useMemo(
        () =>
            eventData?.data?.data.gameDetails?.gameSummaryReport?.gameSummary
                ?.noPeriods || 0,
        [eventData.data]
    );

    // Auto paginate players
    useEffect(() => {
        const { data } = playerData;

        if (
            data?.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor &&
            data.lastEvaluatedKey.cursor !== playersCursor
        ) {
            setPlayersCursor(data.lastEvaluatedKey.cursor);
        }
    }, [playerData, playersCursor]);

    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl) {
            setReportData((prev) => ({
                ...prev,
                error: null,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    const summed = calculateTotals(data, statKeys);
                    const allData = [...data, ...summed];

                    setReportData((prev) => ({
                        ...prev,
                        data: allData,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        }
    }, [requestReportData]);

    useEffect(() => {
        const players = playerData?.data?.data;

        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: GameStat) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerData, reportData.isLoading]);

    const tableValues = useMemo(() => {
        const filteredData = reportData.data
            ? reportData.data.filter((row: GameStat) => row.period === filter)
            : [];

        const sortedData = sortBy(
            filteredData,
            (s) => s.player && Number(s.player.uniformNumber)
        );

        return sortedData;
    }, [reportData, filter]);

    // TODO Sort default

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Advanced Player Time',
        });
    };

    const tableHeader = (
        <Toolbar
            start={
                <div className="p-buttonset">
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[{ label: 'All', value: 0 }, ...periodOptions]}
                    />
                </div>
            }
            end={
                <div className="p-button-group">
                    <>
                        <PlayerAlertButton
                            mode={playerAlertMode}
                            onClick={(e) =>
                                playerAlertOverlay.current &&
                                playerAlertOverlay.current.toggle(e)
                            }
                            value={`${Math.round(playerAlertTarget * 100)}%`}
                        />
                        <RookieButton
                            type="button"
                            onClick={() => exportCSV()}
                            label="Export CSV"
                            severity="secondary"
                        />
                    </>
                </div>
            }
        />
    );

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const emptyMessage = () => {
        const status = requestReportData?.data?.data?.reportStatus;

        if (
            ['ErrorEvent', 'ErrorServer'].includes(status) ||
            requestReportData.isError ||
            reportData.isError
        ) {
            const errorMsg = requestReportData?.data?.data?.errorMessage;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={'An error has occurred.'}
                    desc={
                        errorMsg ||
                        'Try refreshing, if the issue persists please contact support'
                    }
                    errorType={ERROR_TYPES.somethingsWrong}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        if (['Queued', 'Started', 'Building'].includes(status)) {
            const msgs = [
                'Downloading Database...',
                'Fetching Players...',
                ...gameTimeAdvancedColumns.map(
                    (stat) => `Fetching ${stat.name}s...`
                ),
            ];
            const eta = status === 'Queued' ? 5 : status === 'Started' ? 4 : 3;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={
                        <TextSwap
                            strings={msgs}
                            animationDuration="4s"
                            animationType="slideUp"
                            interval={4000}
                        />
                    }
                    desc={`Your report is getting built. Please refresh the page after ${eta} minutes.`}
                    errorType={ERROR_TYPES.maintenance}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        return (
            <ErrorDisplay
                hasReturn={false}
                title={'No game data found.'}
                desc="If you believe this to be an error, please contact support."
                errorType={ERROR_TYPES.empty}
                actions={[
                    {
                        label: 'Support',
                        command: () =>
                            window
                                .open(
                                    'https://www.rookieme.com/contact',
                                    '_blank'
                                )
                                ?.focus(),
                    },
                ]}
            />
        );
    };

    const columns: ColumnProps[] = useMemo(() => {
        const colIndex = 3;

        return playerAlertMode !== 'None'
            ? [
                  ...gameTimeAdvancedColumns.slice(0, colIndex),
                  {
                      field: 'targetPGT',
                      header: 'Target',
                      align: 'center',
                      alignHeader: 'center',
                      body: (data: GameStat) =>
                          playerAlertTargetCell(
                              data.totalPGT,
                              playerAlertTarget
                          ),
                  },
                  ...gameTimeAdvancedColumns.slice(colIndex),
              ]
            : gameTimeAdvancedColumns;
    }, [playerAlertMode, playerAlertTarget]);

    return (
        <>
            <DataTable
                ref={dataTable}
                header={tableHeader}
                value={
                    requestReportData.isLoading || reportData.isLoading
                        ? Array(5)
                        : tableValues
                }
                footer={tableFooter}
                emptyMessage={emptyMessage()}
                columnResizeMode="expand"
                resizableColumns
                exportFilename="game-time-advanced-report"
            >
                {columns.map((col) => (
                    <Column
                        key={col.field}
                        headerTooltipOptions={defaultTooltipOptions}
                        {...col}
                    />
                ))}
            </DataTable>

            <OverlayPanel ref={playerAlertOverlay} dismissable>
                <PlayerAlertSettings
                    onModeChange={setPlayerAlertMode}
                    onTargetChange={setPlayerAlertTarget}
                    mode={playerAlertMode}
                    target={playerAlertTarget}
                />
            </OverlayPanel>
        </>
    );
};

export default GameTimeAdvancedReport;
