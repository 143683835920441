import { LicenceType, RookieProduct } from '../../types/licences';
import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import { SelectButton } from 'primereact/selectbutton';
import { RadioButton } from 'primereact/radiobutton';
import { useEffect, useMemo, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

import {
    useCreateCheckoutSessionMutation,
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
    useUpdateLicenceGroupMutation,
} from '../../api/licences';
import { BaseEntityType } from '../../types/common';
import { ProgressBar } from 'primereact/progressbar';
import RookieButton from '../../components/RookieButton';

import { Mixpanel } from '../../util/mixpanel';
import { useGetEntityQuery } from '../../api/core';

interface Props {
    entityID: string;
    entityType: BaseEntityType;
    subscriptionID?: string;
    onUpdateSuccess?: (response: any) => void;
    onUpdateError?: (response: any) => void;
    onCreateError?: (response: any) => void;
    onCancel?: () => void;
}

const AddLicencesForm = (props: Props) => {
    const {
        entityID,
        entityType,
        subscriptionID,
        onUpdateSuccess,
        onUpdateError,
        onCreateError,
    } = props;

    const [selectedPlan, setSelectedPlan] = useState<string | undefined>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [qty, setQty] = useState<number>(1);
    const [interval, setInterval] = useState<StripeInterval>('month');

    const entityData = useGetEntityQuery(
        { entityType, entityID },
        { skip: !entityType || !entityID }
    );

    const { data: licenceTypesRaw, isLoading: loadingLicenceTypes } =
        useGetLicenceTypesQuery(
            {
                rookieProduct: RookieProduct.GameDay,
                // @ts-expect-error
                sportID: entityData?.data?.data?.entitySportID,
            },
            { skip: !entityData?.data?.data }
        );

    const { data: licenceGroupsRaw, isLoading: loadingLicenceGroups } =
        useGetLicenceGroupsQuery({
            entityID,
            entityType,
        });

    const [createCheckoutSession] = useCreateCheckoutSessionMutation();
    const [updateLicenceGroup] = useUpdateLicenceGroupMutation();

    const licenceGroups = useMemo(
        () => licenceGroupsRaw?.data || [],
        [licenceGroupsRaw]
    );

    const currentLicenceGroup =
        subscriptionID &&
        licenceGroups.find((group) => group.subscriptionID === subscriptionID);

    // Flatten all prices within a licenceType and make details more consumable
    const plans = useMemo(() => {
        const licenceTypes = licenceTypesRaw?.data || [];
        let arr: PlanEssentials[] = [];

        if (licenceTypes) {
            licenceTypes.forEach(
                ({ product, licenceTypeID, rookieProduct }: LicenceType) => {
                    product.prices.forEach((price) => {
                        arr.push({
                            id: price.priceID,
                            title: product.productName,
                            price: price.unitAmount / 100,
                            currency: 'AUD',
                            interval: price.recurring?.interval,
                            description: product.productDescription,
                            licenceTypeID,
                            rookieProduct,
                        });
                    });
                }
            );
        }
        return arr;
    }, [licenceTypesRaw]);

    useEffect(() => {
        if (currentLicenceGroup) {
            setSelectedPlan(currentLicenceGroup.priceID);
            setQty(currentLicenceGroup.quantity);

            if (plans) {
                const subscriptionPlan = plans.find(
                    (plan) => plan.id === currentLicenceGroup.priceID
                );

                if (subscriptionPlan?.interval) {
                    setInterval(subscriptionPlan.interval);
                }
            }
        }
    }, [currentLicenceGroup, plans]);

    const purchasePlan = (priceID: string) => {
        const returnUrl = window.location.href;

        setLoading(true);

        createCheckoutSession({
            entityType,
            entityID,
            items: [{ quantity: qty, price: priceID }],
            successURL: returnUrl,
            cancelURL: returnUrl,
        })
            .then((response) => {
                if ('error' in response) {
                    if (onCreateError) {
                        onCreateError(response);
                    }
                } else {
                    Mixpanel.track(`Create Checkout Session`);

                    // Redirect to stripe checkout
                    window.location.href = response.data.data.url;
                }
            })
            .catch((err) => {
                if (onCreateError) {
                    onCreateError(err);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updatePlan = (licenceGroupID: string, priceID: string) => {
        setLoading(true);

        updateLicenceGroup({
            entityType,
            entityID,
            quantity: qty,
            licenceGroupID,
            priceID,
        })
            .then((response) => {
                if ('error' in response) {
                    if (onUpdateError) {
                        onUpdateError(response);
                    }
                } else {
                    if (onUpdateSuccess) {
                        onUpdateSuccess(response);
                    }
                }
            })
            .catch((err) => {
                if (onUpdateError) {
                    onUpdateError(err);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const plan = plans.find((p: PlanEssentials) => p.id === selectedPlan);

    const loading = isLoading || loadingLicenceGroups || loadingLicenceTypes;

    console.log(selectedPlan, loading, subscriptionID, currentLicenceGroup);

    return (
        <>
            <div className="form">
                {loading && (
                    <div className="form-overlay">
                        <ProgressBar mode="indeterminate" />
                    </div>
                )}
                <SelectButton
                    style={{ marginBottom: '1em' }}
                    allowEmpty={false}
                    value={interval}
                    onChange={(e) => setInterval(e.value)}
                    options={[
                        { label: 'Monthly', value: 'month' },
                        { label: 'Yearly', value: 'year' },
                    ]}
                />

                {plans
                    .filter((o) => o.interval === interval)
                    .sort((a, b) => a.price - b.price)
                    .map((plan) => {
                        const { id, title, price } = plan;

                        return (
                            <div className="field-radiobutton">
                                <RadioButton
                                    inputId={id}
                                    name={title}
                                    value={id}
                                    onChange={(e) => setSelectedPlan(e.value)}
                                    checked={selectedPlan === id}
                                />
                                <label htmlFor={id}>
                                    <span>{title}</span>
                                    <span> - </span>
                                    <span>
                                        <span>
                                            {price > 0
                                                ? '$' + price.toFixed(2)
                                                : 'FREE'}
                                        </span>
                                        {price > 0 && interval && (
                                            <span>
                                                {interval === 'year'
                                                    ? 'per year'
                                                    : interval === 'month'
                                                    ? 'per month'
                                                    : ''}
                                            </span>
                                        )}
                                    </span>
                                </label>
                            </div>
                        );
                    })}
                <InputNumber
                    value={qty}
                    onValueChange={(e) => e.value && setQty(e.value)}
                    min={1}
                    max={100}
                    step={1}
                    showButtons
                />

                {plan && (
                    <div>
                        <div>Summary</div>
                        <p>
                            ${(qty * plan.price).toFixed(2)} {plan.currency}
                        </p>
                    </div>
                )}

                <div>
                    <RookieButton
                        disabled={!selectedPlan || loading}
                        label={subscriptionID ? 'Upgrade' : 'Add'}
                        onClick={() => {
                            if (selectedPlan) {
                                if (subscriptionID && currentLicenceGroup) {
                                    updatePlan(
                                        currentLicenceGroup.licenceGroupID,
                                        selectedPlan
                                    );
                                } else {
                                    purchasePlan(selectedPlan);
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default AddLicencesForm;
