import { useMemo } from 'react';
import { uniqueId } from 'lodash';
import { useGetDesignOptionsQuery } from '../api/core';
import { Skeleton } from 'primereact/skeleton';
import { Design } from '../types/design';

interface Props {
    design: Design;
    style?: { [keyof: string]: string };
}

const EntityPreview = ({ design, style }: Props) => {
    const { data, refetch, isError, isLoading } = useGetDesignOptionsQuery();

    const uid = uniqueId();
    const designOptions = useMemo(() => data?.data, [data]);
    const colours = useMemo(() => {
        let arr = [];

        if (!designOptions) return [];

        if (design?.primary) {
            arr.push(
                designOptions.colours.find(
                    (col) => col.colourHex === design.primary
                )
            );
        }

        if (design?.secondary) {
            arr.push(
                designOptions.colours.find(
                    (col) => col.colourHex === design.secondary
                )
            );
        }

        if (design?.tertiary) {
            arr.push(
                designOptions.colours.find(
                    (col) => col.colourHex === design.tertiary
                )
            );
        }

        return arr.filter((o) => !!o);
    }, [designOptions, design]);

    if (isLoading) {
        return (
            <div className="kit-preview kit-preview-loading" style={style}>
                <Skeleton />
            </div>
        );
    }

    if (isError) {
        return (
            <div
                className="kit-preview kit-preview-error"
                onClick={refetch}
                style={style}
            >
                <span>Couldn't load. Tap to retry.</span>
            </div>
        );
    }

    const template =
        design?.template &&
        designOptions &&
        designOptions.templates.find(
            (temp) => temp.templateSlug === design.template
        );

    if (!template) {
        return <div className="kit-preview kit-preview-empty" style={style} />;
    }

    let styles;

    switch (colours.length) {
        case 2:
            styles = ` 
                .kit-preview-${uid} svg > *:nth-child(2n) {
                    fill: ${
                        colours[1] && colours[1].colourHex
                            ? colours[1].colourHex
                            : 'inherit'
                    }
                }`;
            break;
        case 3:
            styles = `
                .kit-preview-${uid} svg > *:nth-child(3n+2) {
                    fill: ${
                        colours[1] && colours[1].colourHex
                            ? colours[1].colourHex
                            : 'inherit'
                    }
                }
                .kit-preview-${uid} svg > *:nth-child(3n + 3) {
                    fill: ${
                        colours[2] && colours[2].colourHex
                            ? colours[2].colourHex
                            : 'inherit'
                    }
                }`;
            break;
        default:
            styles = ``;
    }

    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                        .kit-preview-${uid} svg {
                            fill: ${colours[0] && colours[0].colourHex}
                        }

                        .kit-preview-${uid} svg > * {
                            fill: inherit
                        }

                        ${styles}
                    `,
                }}
            />
            <div
                style={style}
                className={`kit-preview kit-preview-${uid}`}
                dangerouslySetInnerHTML={{
                    __html: template.templateContent,
                }}
            />
        </>
    );
};

export default EntityPreview;
