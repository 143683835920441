import { useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';

import RookieButton from '../../components/RookieButton';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';

import { INPUT_TYPES } from '../../types/common';
import { OrganisationFormData } from '../../types/organisation';
import { useCreateAssociationOrganisationMutation } from '../../api/associations';

interface FormProps {
    organisationID: string;
}

interface OrganisationFormProps {
    formHasActions: boolean;
    initialValues: FormProps;
    associationID?: OrganisationFormData['associationID'];
    onCancel?: () => void;
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    onDelete?: (status: string) => void;
    onUpdate?: (data: any) => void;
}

export default function OrganisationAddForm(
    props: OrganisationFormProps
): JSX.Element {
    const {
        formHasActions,
        initialValues,
        associationID: associationIDProp,
        onCancel,
        onSuccess,
        onError,
    } = props;
    const { associationID: associationIDParam } = useParams();

    const associationID = associationIDProp || associationIDParam;

    const [linkOrganisation] = useCreateAssociationOrganisationMutation();

    const handleSubmit = (
        data: FormProps,
        { setSubmitting }: FormikHelpers<FormProps>
    ) => {
        setSubmitting(true);

        linkOrganisation({
            associationID,
            organisationID: data.organisationID,
        })
            .then((response: any) => {
                if ('error' in response) {
                    if (onError) {
                        onError('An error has occured');
                    }
                } else {
                    onSuccess && onSuccess(response);
                }
            })
            .catch((error: any) => {
                if (onError) onError(error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <>
                    <form onSubmit={handleSubmit}>
                        <FormFields>
                            <FormGroup label="Organisation ID">
                                <InputText
                                    id="organisationID"
                                    name="organisationID"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Provide Organisation ID"
                                    value={values.organisationID}
                                    required
                                />
                            </FormGroup>
                        </FormFields>

                        {formHasActions && (
                            <FormActions
                                start={
                                    <RookieButton
                                        severity="secondary"
                                        text={true}
                                        disabled={isSubmitting}
                                        onClick={() => onCancel && onCancel()}
                                        type="button"
                                        icon="cancel"
                                        label="Cancel"
                                    />
                                }
                                end={
                                    <RookieButton
                                        disabled={
                                            isSubmitting ||
                                            !values.organisationID
                                        }
                                        type="submit"
                                        icon={
                                            isSubmitting
                                                ? 'autorenew'
                                                : 'add_home'
                                        }
                                        className={
                                            isSubmitting
                                                ? 'is-submitting'
                                                : undefined
                                        }
                                        label="Add Organisation"
                                    />
                                }
                            />
                        )}

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                </>
            )}
        </Formik>
    );
}

OrganisationAddForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        organisationID: '',
    },
};
