import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getEntityFromParam } from '../../util/helper';

import OrganisationLicencesView from './OrganisationLicencesView';

import {
    useAssignLicenceMutation,
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
} from '../../api/licences';

import {
    LicenceGroupInfo,
    LicenceType,
    RookieProduct,
} from '../../types/licences';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { Team } from '../../types/team';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';
import { DataTableValue } from 'primereact/datatable';
import { Permissions } from '../../types/permissions';
import usePermission from '../../hooks/usePermission';
import { useGetEntityQuery } from '../../api/core';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const OrganisationLicencesContainer = (props: Props) => {
    const { user } = props;

    // Context hooks
    const toast = useContext(ToastContext);

    // Route Hooks
    const navigate = useNavigate();
    const params = useParams();
    const entity = getEntityFromParam(params);

    // State Hooks
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
    const [activeLicenceGroup, setActiveLicenceGroup] = useState<string>();

    const { checkPermission } = usePermission(params.organisationID || '');

    // API Hooks
    const entityData = useGetEntityQuery(
        // @ts-expect-error
        { entityType: entity?.entityType, entityID: entity?.entityID || '' },
        { skip: !entity }
    );
    const { data: licenceGroupsRaw, isLoading: isLoadingGroups } =
        // @ts-ignore
        useGetLicenceGroupsQuery(entity, { skip: !entity });

    const { data: licenceTypes, isLoading: isLoadingTypes } =
        useGetLicenceTypesQuery(
            {
                rookieProduct: RookieProduct.GameDay,
                // @ts-expect-error
                sportID: entityData?.data?.data?.entitySportID,
            },
            { skip: !entityData?.data?.data }
        );

    const [assignLicence] = useAssignLicenceMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleShowAssignModal = (licenceGroupID: string) => {
        setActiveLicenceGroup(licenceGroupID);
        setShowAssignModal(true);
    };

    const handleAssignLicences = (licenceGroupID: string, teams: Team[]) => {
        if (teams.length > 0) {
            if (entity) {
                assignLicence({
                    ...entity,
                    licenceGroupID,
                    teamsIDList: teams.map((t) => t.teamID),
                })
                    .then((response) => {
                        if ('error' in response) {
                            showToast({
                                severity: 'error',
                                summary: 'Error',
                                // @ts-ignore
                                detail: response.error.data.error,
                            });
                        } else {
                            showToast({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Licences have successfully been assigned.',
                            });
                        }
                    })
                    .catch((err) => {
                        showToast({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'There was error assigning licences. Please try again later.',
                        });
                    })
                    .finally(() => {
                        setShowAssignModal(false);
                    });
            }
        }
    };

    const handleLicenceGroupClick = (licenceGroup: DataTableValue) => {
        navigate(licenceGroup.id);
    };

    const licenceGroups = useMemo<LicenceGroupInfo[]>(() => {
        let arr: LicenceGroupInfo[] = [];

        if (licenceGroupsRaw && licenceGroupsRaw?.data.length > 0) {
            licenceGroupsRaw.data.forEach((licenceGroup) => {
                const licenceType: LicenceType | undefined =
                    licenceTypes?.data.find(
                        (type) =>
                            licenceGroup.licenceTypeID === type.licenceTypeID
                    );
                if (!licenceType) {
                    return null;
                }
                const { subscription } = licenceGroup;
                const { product } = licenceType;

                if (product) {
                    const price = product.prices.find(
                        (p) => p.priceID === licenceGroup.priceID
                    );
                    arr.push({
                        id: licenceGroup.licenceGroupID,
                        priceID: price?.priceID || '',
                        productTitle: product.productName,
                        licenceTitle: licenceType.licenceName,
                        price: (price && price.unitAmount / 100) || 0,
                        currency: 'AUD',
                        billingPeriod:
                            (price && price.recurring?.interval) || 'unknown', // interval is always month | year
                        renewsAt: subscription
                            ? new Date(subscription.current_period_end * 1000)
                            : null,
                        trialActive:
                            subscription && subscription.trial_end
                                ? new Date().getTime() -
                                      subscription.trial_end * 1000 <
                                  0
                                : false,
                        trialEndsAt:
                            subscription && subscription.trial_end
                                ? new Date(subscription.trial_end * 1000)
                                : null,
                        qtyAvailable:
                            licenceGroup.quantity - licenceGroup.totalAssigned,
                        qtyAssigned: licenceGroup.totalAssigned,
                        qtyTotal: licenceGroup.quantity,
                        status: licenceGroup.status,
                        groupID:
                            licenceGroup.subscriptionID ||
                            licenceGroup.invoiceID ||
                            'unknown',
                        description: product.productDescription,
                        canUpgrade: (price && price.unitAmount < 10000) || true, // hard-code this for now,
                        isOwner:
                            licenceGroup.createdBy === user.userID || false, // hard-code this for now,
                        owner: licenceGroup.createdBy,
                        features: product.productFeatures.map(
                            (feat) => feat.name
                        ),
                        originalEntityID: licenceGroup.originalEntityID,
                        originalEntityType: licenceGroup.originalEntityType,
                        entityID: licenceGroup.entityID,
                        entitySportID: licenceGroup.entitySportID,
                        entityType: licenceGroup.entityType,
                        subscriptionID: licenceGroup.subscriptionID,
                        transactionID: licenceGroup.transactionID,
                    });
                }
            });
        }

        // order by groupID (either subID or invoiceID) - more useful for ass and org
        arr.sort((a, b) =>
            a.groupID === b.groupID ? 0 : a.groupID < b.groupID ? -1 : 1
        );

        return arr;
    }, [licenceGroupsRaw, licenceTypes, user]);

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
    };

    return (
        <OrganisationLicencesView
            isLoading={isLoadingGroups || isLoadingTypes}
            licenceGroups={licenceGroups}
            onAssignLicences={handleAssignLicences}
            showAssignModal={showAssignModal}
            showAddModal={showAddModal}
            activeLicenceGroup={activeLicenceGroup}
            onLicenceGroupClick={handleLicenceGroupClick}
            onShowAssignModal={handleShowAssignModal}
            onCloseAssignModal={() => setShowAssignModal(false)}
            onShowAddModal={() => setShowAddModal(true)}
            onCloseAddModal={() => setShowAddModal(false)}
            permissions={permissions}
            {...props}
        />
    );
};

export default OrganisationLicencesContainer;
