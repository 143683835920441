import { useEffect, useState, useMemo, useContext } from 'react';
import {
    useParams,
    useMatch,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    useDeleteEventInviteMutation,
    useGetEventQuery,
} from '../../api/events';
import { useGetTeamQuery } from '../../api/teams';

import EventsSingleView from './EventSingleView';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';

import usePermission from '../../hooks/usePermission';
import { Permissions } from '../../types/permissions';
import { getEntityFromParam } from '../../util/helper';
import { useGetGameRolesQuery } from '../../api/games';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import Icon from '../../components/Icon';

interface Props {
    roles: Roles;
    route: Route;
}

const EventsSingleContainer = ({ route, roles }: Props) => {
    // Route hooks
    const params = useParams();
    const { teamID, eventID } = params;
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const location = useLocation();
    const match = useMatch(route.path);
    const navigate = useNavigate();

    // State hooks
    const [showEventDialog, setShowEventDialog] = useState<boolean>(false);
    const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);

    const toast = useContext(ToastContext);

    // Permission Hook
    const { checkPermission } = usePermission(teamID || '');

    // API hooks
    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );

    const eventData = useGetEventQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            eventID: eventID || '',
            expand: 'gameSummaryReport',
        },
        {
            skip: !activeEntity,
        }
    );

    const gameRolesData = useGetGameRolesQuery(
        {
            teamID: activeEntity?.entityID || '',
            eventID: eventID || '',
        },
        {
            skip: !activeEntity,
        }
    );

    const [deleteInvitee] = useDeleteEventInviteMutation();

    useEffect(() => {
        // Handle redirection to "details" for specific URLs with /events
        if (location.pathname === match?.pathname) {
            navigate('details', {
                replace: true,
            });
        }
    }, [navigate, location, match]);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleEditEvent = () => {
        setShowEventDialog(true);
    };

    const handleCloseEventDialog = () => {
        setShowEventDialog(false);
    };

    const handleInviteUser = () => {
        setShowInviteDialog(true);
    };

    const handleCloseInviteDialog = () => {
        setShowInviteDialog(false);
    };

    const handleDeleteInvite = (inviteeID: string) => {
        if (activeEntity && eventID) {
            confirmDialog({
                message: `Are you sure you want to remove this user from the event?`,
                header: 'Remove User',
                icon: <Icon name="dangerous" />,
                accept: () => {
                    deleteInvitee({
                        entityType: activeEntity.entityType,
                        entityID: activeEntity.entityID,
                        inviteeID,
                        eventID,
                    })
                        .then((response) => {
                            if ('error' in response) {
                                showToast({
                                    severity: 'error',
                                    summary:
                                        'There was an error removing the user. Please try again.',
                                });
                            } else {
                                showToast({
                                    severity: 'success',
                                    summary: `Successfully removed user from event.`,
                                });
                            }
                        })
                        .catch(() => {
                            showToast({
                                severity: 'error',
                                summary:
                                    'There was an error removing the user. Please try again.',
                            });
                        });
                },
            });
        }
    };

    const handleInviteSuccess = () => {
        setShowInviteDialog(false);

        showToast({
            severity: 'success',
            summary: 'Invite successfully sent.',
        });
    };

    const handleInviteError = (err: any) => {
        showToast({
            severity: 'error',
            summary: 'There was an error sending invite. Please try again.',
        });
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    return (
        <EventsSingleView
            eventData={eventData}
            teamData={teamData}
            gameRolesData={gameRolesData}
            roles={roles}
            route={route}
            onCloseEventDialog={handleCloseEventDialog}
            onCloseInviteDialog={handleCloseInviteDialog}
            onEditEvent={handleEditEvent}
            onInviteUser={handleInviteUser}
            showEventDialog={showEventDialog}
            showInviteDialog={showInviteDialog}
            onDeleteInvite={handleDeleteInvite}
            onInviteError={handleInviteError}
            onInviteSuccess={handleInviteSuccess}
            permissions={permissions}
        />
    );
};

export default EventsSingleContainer;
