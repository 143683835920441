import { useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { routes } from '../routes/routes';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

import Icon from './Icon';
import RookieButton from './RookieButton';

interface Props {
    overflow?: boolean;
}

const BreadCrumbs = ({ overflow }: Props) => {
    const crumbPopover = useRef<Menu>(null);
    const navigate = useNavigate();
    const crumbs = useBreadcrumbs(routes, {
        excludePaths: [
            '/u',
            '/t',
            '/o',
            '/o/:organisationID/t',
            '/a',
            '/a/:associationID/o',
            '/a/:associationID/o/:organisationID/t',
        ],
    });

    const showCount = 2;

    const renderCrumb = (route: BreadcrumbData, active?: boolean) =>
        active ? (
            <span className="p-menuitem-text ">{route.breadcrumb}</span>
        ) : (
            <Link className="p-menuitem-link" to={route.match.pathname}>
                <span className="p-menuitem-text ">{route.breadcrumb}</span>
            </Link>
        );

    const breadCrumbProps = useMemo((): {
        home: MenuItem;
        model: MenuItem[];
    } => {
        const getFullProps = () => {
            const home = { template: renderCrumb(crumbs[0]) };
            const model = crumbs.slice(1).map((route, i) => ({
                template: renderCrumb(route, i === crumbs.length - 1),
            }));

            return {
                home,
                model,
            };
        };

        const getCompactProps = () => {
            const visibleCrumbs = crumbs.slice(-showCount);
            const hiddenCrumbs = crumbs.slice(0, -showCount);
            const visibleCount = visibleCrumbs.length - 1;

            const menu = hiddenCrumbs.reverse().map(
                ({ match, breadcrumb }): MenuItem => ({
                    id: match.pathname,
                    template: (
                        <div className="p-menuitem-content">
                            <Link
                                className="p-menuitem-link"
                                role="menuitem"
                                to={match.pathname}
                            >
                                <span className="p-menuitem-text">
                                    {breadcrumb}
                                </span>
                            </Link>
                        </div>
                    ),
                    command: () => navigate(match.pathname),
                })
            );

            const home = {
                template: (
                    <>
                        <RookieButton
                            onClick={(e) =>
                                crumbPopover.current &&
                                crumbPopover.current.toggle(e)
                            }
                            icon="more_horiz"
                            size="small"
                            severity="secondary"
                        />
                        <Menu model={menu} popup={true} ref={crumbPopover} />
                    </>
                ),
            };

            const model = visibleCrumbs.map((route, i) => ({
                template: renderCrumb(route, i === visibleCount),
            }));

            return {
                home,
                model,
            };
        };

        return overflow && crumbs.length > showCount + 1
            ? getCompactProps()
            : getFullProps();
    }, [crumbs, navigate, overflow]);

    return (
        <BreadCrumb
            {...breadCrumbProps}
            separatorIcon={(props) => <Icon {...props} name="chevron_right" />}
        />
    );
};

BreadCrumbs.defaultProps = {
    overflow: true,
};

export default BreadCrumbs;
