import { useMemo } from 'react';
import {
    Outlet,
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import PageHeader from '../../layout/PageHeader';
import { TabMenu } from 'primereact/tabmenu';
import { UseQueryResult } from '../../types/api';
import { SeasonResponse } from '../../types/seasons';
import PageContainer from '../../layout/PageContainer';

interface Props {
    seasonData: UseQueryResult<SeasonResponse>;
}

const SeasonSingleView = (props: Props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const season = props.seasonData?.data?.data;

    const tabs = useMemo(
        () => [
            {
                id: 'teams',
                label: 'Teams',
                data: {
                    path: generatePath(`teams`, params),
                },
            },
            {
                id: 'settings',
                label: 'Settings',
                data: {
                    path: generatePath(`settings`, params),
                },
            },
        ],
        [params]
    );

    return (
        <PageContainer>
            <PageHeader title={season?.seasonName ? season.seasonName : ''} />
            <TabMenu
                model={tabs}
                activeIndex={tabs.findIndex((tab) =>
                    location.pathname.includes(tab.data.path)
                )}
                onTabChange={(e) =>
                    e.value.data.path && navigate(e.value.data.path)
                }
            />
            <Outlet context={props} />
        </PageContainer>
    );
};

export default SeasonSingleView;
