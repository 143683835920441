import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { NotificationsResponse } from '../types/notification';

export const notificationsApi = createApi({
    reducerPath: 'notificationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getNotifications: builder.query<
            NotificationsResponse,
            { userID: string }
        >({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getNotifications.url, {
                    userID,
                }),
                method: apiEndpoints.getNotifications.method,
            }),
        }),
    }),
});

export const { useGetNotificationsQuery } = notificationsApi;
