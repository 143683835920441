interface Props {
    images: string[];
}

const ImageGrid = ({ images }: Props) => {
    const imageCount = images.length;

    return (
        <div className={`image-grid image-grid-${imageCount}`}>
            {images.map((image, index) => (
                <div key={index} className="image-grid-item">
                    <img src={image} alt="" />
                </div>
            ))}
        </div>
    );
};

export default ImageGrid;
