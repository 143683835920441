import { useOutletContext } from 'react-router-dom';
import { ButtonProps } from 'primereact/button';
import { format, isSameDay } from 'date-fns';

import { Tooltip } from 'primereact/tooltip';
import { niceFlags } from '../reportsTeamSeason/configSummary';

import { UseQueryResult } from '../../types/api';
import { TeamResponse } from '../../types/team';
import { EventResponse } from '../../types/event';
import { formatTime } from '../../util/helper';
import RookieButton from '../../components/RookieButton';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    teamData: UseQueryResult<TeamResponse>;
}

interface SectionHeaderProps {
    title: string;
    subtitle?: string;
    actions?: ButtonProps[];
}

interface DetailListItemProps {
    title: React.ReactNode;
    content: any;
    actions?: ButtonProps[];
}

interface DetailListProps {
    items: DetailListItemProps[];
}

const SectionHeader = (props: SectionHeaderProps) => {
    const renderAction = ({ label, ...buttonProps }: ButtonProps) => {
        return <RookieButton {...buttonProps} label={label} />;
    };

    return (
        <div className="section-header">
            <div className="section-header_inner">
                <h1 className="section-header_title">{props.title}</h1>
                {props.subtitle && (
                    <p className="section-header_subtitle">{props.subtitle}</p>
                )}
            </div>
            {props.actions && props.actions.length > 0 && (
                <div className="section-header_actions">
                    {props.actions.map(renderAction)}
                </div>
            )}
        </div>
    );
};

const DetailList = (props: DetailListProps) => {
    const renderAction = ({ label, ...buttonProps }: ButtonProps) => {
        return <RookieButton {...buttonProps} label={label} />;
    };

    const renderItem = (
        { title, content, actions }: DetailListItemProps,
        index: number
    ) => {
        return (
            <li key={`detail-item-${index}`} className="details-list_item">
                <div className="details-list_title">{title}</div>
                <div className="details-list_caption">{content}</div>
                {actions && actions.length > 0 && actions.map(renderAction)}
            </li>
        );
    };
    return <ul className="details-list">{props.items.map(renderItem)}</ul>;
};

const EventDetails = () => {
    const { eventData } = useOutletContext<Props>();
    const event = eventData?.data?.data;
    const gameSummary = event?.gameDetails?.gameSummaryReport?.gameSummary;
    let eventDetails = [
        {
            title: 'Scheduled Date',
            content:
                event &&
                format(new Date(event.startDateTime), 'eeee, MMMM do, yyyy'),
        },
        ...(gameSummary
            ? [
                  {
                      title: 'Actual Date',
                      content:
                          event &&
                          format(
                              new Date(event.startedAt),
                              'eeee, MMMM do, yyyy'
                          ),
                  },
              ]
            : []),
        {
            title: 'Scheduled Start Time',
            content: event && format(new Date(event.startDateTime), 'h:mm aaa'),
        },
        ...(gameSummary
            ? [
                  {
                      title: 'Actual Time',
                      content:
                          event &&
                          format(new Date(event.startedAt), 'h:mm aaa'),
                  },
              ]
            : []),
    ];

    if (event?.endDateTime && event?.endDateTime !== event.startDateTime) {
        if (
            !isSameDay(new Date(event.endDateTime), new Date(event.endDateTime))
        ) {
            eventDetails.push({
                title: 'End Date',
                content: format(
                    new Date(event.endDateTime),
                    'eeee, MMMM do, yyyy'
                ),
            });
        }
        eventDetails.push({
            title: 'End Time',
            content: format(new Date(event.endDateTime), 'h:mm aaa'),
        });
    }

    if (event?.location?.locationName) {
        eventDetails.push({
            title: 'Location',
            content: event.location.locationName,
        });
    }

    if (event?.description) {
        eventDetails.push({
            title: 'Description',
            content: event.description,
        });
    }

    if (event?.eventStatus) {
        eventDetails.push({
            title: 'Status',
            content: event.eventStatus,
        });
    }

    let gameDetails: Array<{ title: React.ReactNode; content: any }> = [];

    if (event?.game) {
        const { game } = event;

        gameDetails = [
            {
                title: 'Home/Away',
                content: game.isHomeTeam ? 'Home' : 'Away',
            },
            {
                title: 'Opponent',
                content: game.opponentTeam?.teamName || 'Opponent',
            },
        ];

        if (gameSummary) {
            if (gameSummary.noPeriods) {
                gameDetails.push({
                    title: 'Periods played',
                    content: gameSummary.noPeriods,
                });
            }
            if (gameSummary.duration) {
                gameDetails.push({
                    title: 'Game Duration',
                    content: formatTime(gameSummary.duration),
                });
            }

            if (gameSummary.fairGameTime) {
                gameDetails.push({
                    title: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Fair Game Time Score{' '}
                            {
                                <RookieButton
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: 'inherit',
                                        opacity: 'inherit',
                                    }}
                                    text={true}
                                    data-beacon-article={
                                        '6530a57b554393421d94c725'
                                    }
                                    icon="help"
                                />
                            }
                        </div>
                    ),
                    content: `${Math.round(gameSummary.fairGameTime * 100)}%`,
                });
            }
            if (
                gameSummary.integrityScore ||
                gameSummary.integrityScore === 0
            ) {
                type NiceFlagKeys = keyof typeof niceFlags; // This will be 'skippedTime' | 'shortLongPeriod' | 'playersWithZeroPGT' | 'outsideSchedTime' | 'forceEndGame'

                // Ensure that flags can be either string[] or string[][].
                let flags: string[] | string[][] = gameSummary.flags || [];

                // Flatten flags if it's a two-dimensional array.
                flags = Array.isArray(flags[0])
                    ? (flags as string[][]).flat()
                    : flags;

                // Filter and map the flags to their corresponding values in niceFlags.
                flags = (flags as string[])
                    .filter(
                        (flag) => typeof flag === 'string' && flag in niceFlags
                    ) // Filter to keep only valid keys
                    .map((flag) => niceFlags[flag as NiceFlagKeys]);
                const matchedFlags = flags;

                // Push details after flag processing
                gameDetails.push({
                    title: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Integrity Score
                            <Tooltip target=".help-icon" autoHide={false}>
                                <div>
                                    {Array.isArray(matchedFlags) && (
                                        <div>
                                            {gameSummary.integrityScore ===
                                            5 ? (
                                                <div>Issues: Nil.</div>
                                            ) : (
                                                <div>
                                                    Issues:
                                                    {matchedFlags.map(
                                                        (flag, index) => (
                                                            <div key={index}>
                                                                {niceFlags[
                                                                    flag as keyof typeof niceFlags
                                                                ] || flag}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Tooltip>
                            <RookieButton
                                className="help-icon"
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: 'inherit',
                                    opacity: 'inherit',
                                    cursor: 'pointer',
                                    marginLeft: '8px',
                                }}
                                text={true}
                                data-beacon-article={'6530a9986ea22a692042cc16'}
                                icon="help"
                            />
                        </div>
                    ),
                    content: `${gameSummary.integrityScore}/5`,
                });
            }
            gameDetails.push({
                title: <strong>Match Settings</strong>, // Add section header
                content: '', // You can leave content empty or add a descriptive text
            });
            gameDetails.push({
                title: 'Max Periods',
                content: game.settings.periods,
            });
            gameDetails.push({
                title: 'Max On Field',
                content: game.settings.maxOnField,
            });
            gameDetails.push({
                title: 'Period Length (mins)',
                content: game.settings.periodLength,
            });
        }
    }

    return (
        <div className="event-details">
            <SectionHeader title={`${event?.eventType} Details`} />
            <DetailList items={eventDetails} />

            {event?.eventType === 'Game' && <DetailList items={gameDetails} />}
        </div>
    );
};

export default EventDetails;
