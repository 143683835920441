import { useContext } from 'react';
import { Formik } from 'formik';

import { ToastContext } from '../../contexts/ToastContext';

import { useTransferEntityOwnershipMutation } from '../../api/core';

import { ButtonProps } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { ToastMessage } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';

import FormGroup from '../../components/FormGroup';
import FormActions from '../../components/FormActions';
import RookieButton from '../../components/RookieButton';

import { BaseEntityType } from '../../types/common';

import { Mixpanel } from '../../util/mixpanel';

interface Props {
    actions?: ButtonProps[];
    entityType: BaseEntityType;
    entityID: string;
    initialValues?: any;
    onError?: () => void;
    onSuccess?: () => void;
}

const TransferEntityForm = (props: Props) => {
    const { actions, entityType, entityID, initialValues, onSuccess, onError } =
        props;

    const [transferEntity] = useTransferEntityOwnershipMutation();

    const toast = useContext(ToastContext);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleSubmit = (data: { email: string }) => {
        if (entityType && entityID) {
            transferEntity({
                entityType,
                entityID,
                data,
            })
                .then((response) => {
                    if ('data' in response) {
                        showToast({
                            severity: 'success',
                            summary: 'Success',
                            detail: response.data?.data?.message,
                        });

                        Mixpanel.track(`Transfer Entity`);

                        if (onSuccess) {
                            onSuccess();
                        }
                    }
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: `Could not transfer.`,
                    });
                    if (onError) {
                        onError();
                    }
                });
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormGroup label="Email" htmlFor="email">
                            <InputText
                                id="email"
                                name="email"
                                type="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Email address"
                                value={values.email}
                                required
                            />
                        </FormGroup>
                        <FormActions
                            start={
                                actions &&
                                actions.map((action, i) => (
                                    <RookieButton
                                        key={`transfer-form-action-${i}`}
                                        {...action}
                                    />
                                ))
                            }
                            end={
                                <RookieButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    label="Transfer"
                                />
                            }
                        />

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

TransferEntityForm.defaultProps = {
    initialValues: {
        email: '',
    },
};

export default TransferEntityForm;
