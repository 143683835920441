import { Response } from './api';
import { BaseEntityType, EntityType } from './common';

export enum NoteStatus {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted',
}

export enum NotePrivacy {
    Public = 'Public',
    Private = 'Private',
}

export interface NoteNode {
    createdAt: string;
    createdBy: string;
    lookupID: string;
    nodeID: string;
    nodeSummary: string;
    nodeType: EntityType;
}

export interface Note {
    noteID: string;
    createdAt: string;
    createdBy: string;
    entityID: string;
    entityType: BaseEntityType;
    lastEdited: string;
    lastEditedBy: string;
    nodeList: NoteNode[];
    noteBackground: string;
    noteContent: string;
    noteHashtags: string;
    noteLabels: string;
    noteNodes: string;
    notePrivacy: NotePrivacy;
    noteStatus: NoteStatus;
    noteSticky: boolean;
    noteTitle: string;
}

export interface NoteFormData {
    noteID?: string;
    noteContent?: string;
    noteTitle?: string;
    notePrivacy?: NotePrivacy;
    nodeList?: {
        nodeID: string;
        nodeType: EntityType;
        nodeSummary: string;
    }[];
}

export type NoteResponse = Response<Note>;

export interface NotesResponse {
    data: {
        notes: Note[];
        lastEvaluatedKey: {
            cursor: string;
        };
    };
}
