import {
    DataTable,
    DataTableSelectionSingleChangeEvent,
} from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState } from 'react';
import { useGetAssociationOrganisationsQuery } from '../../api/associations';
import { Organisation } from '../../types/organisation';
import RookieButton from '../../components/RookieButton';
//import { useGetTransferredLicenceGroupsQuery } from '../../api/licences';
//import { BaseEntityType } from '../../types/common';

interface Props {
    associationID: string;
    licenceGroupID: string;
    onSubmit?: (licenceGroupID: string, organisationID: string) => void;
    onCancel?: () => void;
}

const TransferLicencesForm = ({
    associationID,
    onCancel,
    onSubmit,
    licenceGroupID,
}: Props) => {
    const [selected, setSelected] = useState<Organisation>();

    const { data } = useGetAssociationOrganisationsQuery({
        associationID,
    });

    /*
    const { data: transferred } = useGetTransferredLicenceGroupsQuery({
        entityType: BaseEntityType.associations,
        entityID: associationID,
    });
    */

    const handleSubmit = () => {
        if (onSubmit && selected) {
            onSubmit(licenceGroupID, selected.organisationID);
        }
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
    };

    const handleSelectionChange = (
        event: DataTableSelectionSingleChangeEvent<Organisation[]>
    ) => {
        setSelected(event.value);
    };

    return (
        <>
            <DataTable
                value={data?.data || []}
                selection={selected}
                onSelectionChange={handleSelectionChange}
                selectionMode="single"
                dataKey="organisationID"
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    selectionMode="single"
                    headerStyle={{ width: '3em' }}
                ></Column>
                <Column field="organisationName" header="Team"></Column>
            </DataTable>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    textAlign: 'right',
                    marginTop: '20px',
                }}
            >
                <RookieButton
                    label="Cancel"
                    severity="secondary"
                    onClick={handleCancel}
                />
                <RookieButton
                    label="Transfer"
                    onClick={handleSubmit}
                    disabled={!selected}
                />
            </div>
        </>
    );
};

export default TransferLicencesForm;
