import { useParams } from 'react-router-dom';
import { useGetTeamQuery } from '../../api/teams';
import { Skeleton } from 'primereact/skeleton';

const TeamBreadcrumb = () => {
    const { teamID } = useParams();

    const { data, isLoading, isError } = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;

    if (isError) return 'Team';

    return data?.data?.teamName;
};

export default TeamBreadcrumb;
