import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { random, startCase } from 'lodash';

import { useGetDesignOptionsQuery } from '../api/core';

import ErrorDisplay from './ErrorDisplay';
import EntityPreview from './EntityPreview';
import FormActions from './FormActions';
import FormFields from './FormFields';
import Icon from './Icon';
import Loader from './Loader';
import RookieButton from './RookieButton';

import { DesignOptions, Design } from '../types/design';
import { ERROR_TYPES } from '../types/common';

interface FieldProps {
    label?: string;
    value?: string;
    children: React.ReactNode;
}

interface EditorProps {
    cancelLabel?: string;
    initialValues: Design;
    randomise?: boolean;
    submitLabel?: string;
    onCancel?: () => void;
    onSubmit: (
        value: Design,
        formikHelpers: FormikHelpers<DesignOptions>
    ) => void;
}

const Field = (props: FieldProps) => (
    <div className="field form-group">
        <div className="field-header">
            <label className="field-label">{props.label}</label>
            <span className="field-value">{props.value}</span>
        </div>
        <div className="field-body">{props.children}</div>
    </div>
);

const EntityDesigner = (props: EditorProps) => {
    const { submitLabel, onCancel, onSubmit, initialValues } = props;

    const { data, isLoading, isError } = useGetDesignOptionsQuery();

    const designOptions = useMemo(() => data?.data, [data]);

    const navigate = useNavigate();

    if (isLoading) {
        return <Loader size="medium" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc={`Could not load the requested module, please try again later.`}
                errorType={ERROR_TYPES.notFound}
                hasReturn={false}
                proportion="regular"
                title="Kit Designer Unavailable"
            />
        );
    }

    const handleSubmit = (
        values: DesignOptions,
        formikHelpers: FormikHelpers<DesignOptions>
    ) => {
        if (onSubmit) {
            let formData = {
                template: values.template ? values.template.templateSlug : '',
                primary: values.primary ? values.primary.colourHex : '',
                secondary: values.secondary ? values.secondary.colourHex : '',
                tertiary: values.tertiary ? values.tertiary.colourHex : '',
            };

            onSubmit(formData, formikHelpers);
        }
    };

    const renderColourField = (
        fieldName: keyof Omit<DesignOptions, 'template'>,
        { values, setFieldValue }: Partial<FormikProps<DesignOptions>>,
        allowEmpty: boolean,
        isDisabled?: boolean
    ) => {
        if (!setFieldValue || !values) return;

        const value = values[fieldName];

        return (
            <div
                className={`swatch-options${isDisabled ? ' is-disabled' : ''}`}
            >
                {allowEmpty && (
                    <div
                        className={`swatch is-blank${
                            !value || !value.colourHex ? ' is-selected' : ''
                        }`}
                        onClick={() => setFieldValue(fieldName, null)}
                    >
                        <Icon name="close" />
                    </div>
                )}
                {designOptions?.colours.map((colour) => {
                    const selected = value?.colourHex === colour.colourHex;

                    if(!colour.enabled && !selected){
                        return (<></>);
                    }
                    return (
                        <div
                            key={colour.colourHex}
                            className={`swatch${
                                selected ? ' is-selected' : ''
                            }`}
                            style={{
                                background: colour.colourHex,
                            }}
                            onClick={() => setFieldValue(fieldName, colour)}
                        />
                    );
                })}
            </div>
        );
    };

    const getInitValues = (values: Design): DesignOptions => {
        let newValues: DesignOptions = {
            template: undefined,
            primary: undefined,
            secondary: undefined,
            tertiary: undefined,
        };

        if (values.template) {
            newValues.template = designOptions?.templates.find(
                (temp) => temp.templateSlug === values.template
            );
        }

        if (values.primary) {
            newValues.primary = designOptions?.colours.find(
                (col) => col.colourHex === values.primary
            );
        }

        if (values.primary) {
            newValues.secondary = designOptions?.colours.find(
                (col) => col.colourHex === values.secondary
            );
        }

        if (values.primary) {
            newValues.tertiary = designOptions?.colours.find(
                (col) => col.colourHex === values.tertiary
            );
        }

        return newValues;
    };

    const initValues: DesignOptions = initialValues
        ? getInitValues(initialValues)
        : {
              template: props.randomise
                  ? designOptions?.templates[
                        random(0, designOptions.templates.length)
                    ]
                  : designOptions?.templates[0],
              primary: props.randomise
                  ? designOptions?.colours[
                        random(0, designOptions.colours.length)
                    ]
                  : designOptions?.colours[0],
              secondary: props.randomise
                  ? designOptions?.colours[
                        random(0, designOptions.colours.length)
                    ]
                  : undefined,
              tertiary: undefined,
          };

    return (
        <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                return (
                    <form onSubmit={handleSubmit} className="form kit-editor">
                        <EntityPreview
                            design={{
                                template: values.template
                                    ? values.template.templateSlug
                                    : '',
                                primary: values.primary
                                    ? values.primary.colourHex
                                    : '',
                                secondary: values.secondary
                                    ? values.secondary.colourHex
                                    : '',
                                tertiary: values.tertiary
                                    ? values.tertiary.colourHex
                                    : '',
                            }}
                        />
                        <FormFields>
                            <Field
                                label="Pattern"
                                value={
                                    values.template &&
                                    startCase(values.template.templateName)
                                }
                            >
                                <div className="swatch-options">
                                    {designOptions?.templates.map(
                                        (template, key) => {
                                            const selected =
                                                values.template &&
                                                values.template.templateSlug ===
                                                    template.templateSlug;
                                            return (
                                                <div
                                                    key={key}
                                                    className={`swatch${
                                                        selected
                                                            ? ' is-selected'
                                                            : ''
                                                    }`}
                                                    dangerouslySetInnerHTML={{
                                                        __html: template.templateContent,
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            template.requiredColours <
                                                            3
                                                        ) {
                                                            setFieldValue(
                                                                'tertiary',
                                                                null
                                                            );
                                                        }
                                                        if (
                                                            template.requiredColours <
                                                            2
                                                        ) {
                                                            setFieldValue(
                                                                'secondary',
                                                                null
                                                            );
                                                        }

                                                        setFieldValue(
                                                            'template',
                                                            template
                                                        );
                                                    }}
                                                ></div>
                                            );
                                        }
                                    )}
                                </div>
                            </Field>
                            <Field
                                label="Primary Colour"
                                value={
                                    values.primary &&
                                    startCase(values.primary.colourName)
                                }
                            >
                                {renderColourField(
                                    'primary',
                                    {
                                        values,
                                        setFieldValue,
                                    },
                                    false,
                                    false
                                )}
                            </Field>
                            <Field
                                label="Secondary Colour"
                                value={
                                    values.secondary &&
                                    startCase(values.secondary.colourName)
                                }
                            >
                                {renderColourField(
                                    'secondary',
                                    {
                                        values,
                                        setFieldValue,
                                    },
                                    true,
                                    values.template &&
                                        values.template.requiredColours < 2
                                )}
                            </Field>
                            <Field
                                label="Tertiary Colour"
                                value={
                                    values.tertiary &&
                                    startCase(values.tertiary.colourName)
                                }
                            >
                                {renderColourField(
                                    'tertiary',
                                    {
                                        values,
                                        setFieldValue,
                                    },
                                    true,
                                    values.template &&
                                        values.template.requiredColours < 3
                                )}
                            </Field>
                        </FormFields>
                        <FormActions
                            start={
                                <RookieButton
                                    text={true}
                                    severity="secondary"
                                    disabled={isSubmitting}
                                    onClick={() => onCancel && onCancel()}
                                    type="button"
                                    icon="cancel"
                                    label="Cancel"
                                />
                            }
                            end={
                                <RookieButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    icon="save"
                                    label={submitLabel}
                                />
                            }
                        />
                    </form>
                );
            }}
        </Formik>
    );
};

EntityDesigner.defaultProps = {
    submitLabel: 'Submit',
    cancelLabel: 'Cancel',
    randomise: false,
};

export default EntityDesigner;
