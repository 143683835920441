import { useMemo, useState } from 'react';

import { PlanContext } from '../../contexts/PlanContext';

import PlanModal from './PlanModal';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog, DialogProps } from 'primereact/dialog';

import { EntityStructure } from '../../types/common';

interface Props {
    children: any;
}

const PlanProvider = ({ children }: Props) => {
    const [entity, setEntity] = useState<EntityStructure | undefined>(
        undefined
    );

    const [options, setOptions] = useState<Partial<DialogProps> | undefined>(
        undefined
    );

    return (
        <PlanContext.Provider
            value={useMemo(
                () => ({
                    close: () => setEntity(undefined),
                    open: ({ entity, modalProps }) => {
                        setEntity(entity);
                        setOptions(modalProps);
                    },
                }),
                []
            )}
        >
            {children}
            <Dialog
                className="plans-modal"
                header="Plans"
                visible={!!entity}
                onHide={() => setEntity(undefined)}
                closeOnEscape={false}
                dismissableMask={false}
                {...options}
            >
                <PlanModal
                    entityID={entity?.entityID}
                    entityType={entity?.entityType}
                    onClose={() => setEntity(undefined)}
                />
            </Dialog>
            <ConfirmDialog />
        </PlanContext.Provider>
    );
};

export default PlanProvider;
