import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { isArray, unionBy } from 'lodash';
import { format } from 'date-fns';

import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import { useGetTeamSeasonSummaryReportQuery } from '../../api/reports';
import { useGetTeamQuery } from '../../api/teams';

import PageHeader from '../../layout/PageHeader';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { SelectButton } from 'primereact/selectbutton';

import { columns, config } from './configSummary';
import { defaultReportState } from '../reports/constants';
import {
    GameSummary,
    ReportState,
    StatConfig,
    StatTypes,
    ReportDataTypes,
} from '../../types/reports';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useGetEventsQuery } from '../../api/events';
import { Tag } from 'primereact/tag';
import { toISOStringWithTimezone } from '../../util/helper';
import { BaseEntityType } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import { renderResult } from './helpers';
import { InputSwitch } from 'primereact/inputswitch';
import { niceFlags } from './configSummary';
import Icon from '../../components/Icon';
import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

interface DateCellProps {
    type: StatTypes;
    value: number;
    totalTime: number;
}

const DataCell = ({ type, value, totalTime }: DateCellProps) => {
    const component = useMemo(() => {
        return {
            [StatTypes.Count]: value,
            [StatTypes.Percentage]: Math.round(value * 100) + '%',
            [StatTypes.Time]: Math.round((value / totalTime) * 100) + '%',
        }[type];
    }, [type, value, totalTime]);

    return <>{component ? component : value}</>;
};

const GameSummaryReport = () => {
    // Route Params
    const { teamID } = useParams();
    const location = useLocation();

    // State Hooks
    const [season, setSeason] = useState<string | null>(null);
    const [showInfo, setShowInfo] = useState(false);
    const [reportData, setReportData] =
        useState<ReportState<GameSummary[]>>(defaultReportState);
    const [hideEmptyCol, setHideEmptyCol] = useState<boolean>(true);
    const [hideIntFGTScore, sethideIntFGTScore] = useState(false);
    const [dataType, setDataType] = useState(ReportDataTypes.Total);
    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // API Hooks
    const requestReportData = useGetTeamSeasonSummaryReportQuery(
        {
            seasonID: season || '',
            teamID: teamID || '',
            sessionID: timestampRef,
            concatenated: true,
        },
        {
            skip: !season || !teamID,
        }
    );

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );
    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );
    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
        expand: 'gameDetails',
    });

    // Helpers
    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    useEffect(() => {
        const events = eventData?.data?.data;

        if (events && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data
                        .filter((stat: GameSummary) =>
                            events.find((o) => o.eventID === stat.eventID)
                        )
                        .map((stat: GameSummary) => ({
                            ...stat,
                            event:
                                events.find(
                                    (o) => o.eventID === stat.eventID
                                ) || stat.eventID,
                        })),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData, reportData.isLoading]);

    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;
        if (reportUrl && requestReportData.isSuccess) {
            setReportData((prev) => ({
                ...prev,
                data: undefined,
                error: undefined,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setReportData(defaultReportState);
        }
    }, [requestReportData]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(teamData?.data?.data.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, teamData]);

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    // Renders
    const leftToolbar = (
        <Dropdown
            onChange={(e) => setSeason(e.value)}
            value={season}
            options={seasonOptions}
        />
    );

    const rightToolbar = (
        <div className="p-button-group">
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide Integrity & FGT Scores
                </label>
                <InputSwitch
                    checked={hideIntFGTScore}
                    onChange={(e) => sethideIntFGTScore(e.value)}
                />
            </div>
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide Empty Cols
                </label>
                <InputSwitch
                    checked={hideEmptyCol}
                    onChange={(e) => setHideEmptyCol(e.value)}
                />
            </div>
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
                //disabled={event !== ''}
            />
        </div>
    );

    const renderHeaderCell = (stat: StatConfig) => {
        const { description, hsArticle } = stat;

        return (
            <div>
                <Tooltip
                    target={`.cell-${stat.shortName}`}
                    autoHide={false}
                    position="right"
                    onBeforeHide={() => setShowInfo(false)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ color: '#fff' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 500,
                                }}
                            >
                                <div>{stat.name}</div>
                            </div>
                            {description && showInfo && (
                                <div>{stat.description}</div>
                            )}
                        </div>
                        {hsArticle ? (
                            <RookieButton
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#ff6700',
                                    opacity: '0.9',
                                }}
                                text={true}
                                data-beacon-article={hsArticle}
                                icon="help"
                            />
                        ) : (
                            description && (
                                <RookieButton
                                    text={true}
                                    style={{ flex: 'none' }}
                                    onClick={() =>
                                        description && setShowInfo(!showInfo)
                                    }
                                    icon={showInfo ? 'close' : 'info'}
                                />
                            )
                        )}
                    </div>
                </Tooltip>
                <span>{stat.shortName}</span>
            </div>
        );
    };

    const calculateTotals = useMemo(() => {
        const data = reportData?.data || [];
        let teamScoringAccuracy = 0;

        let totals = {
            wins: 0,
            losses: 0,
            draws: 0,
            teamScoringAccuracy: 'N/A',
            teamScore: 0,
            oppositionScore: 0,
            scoreMargin: 0,
            rotationCount: 0,
        };

        if (data && data.length > 0) {
            data.forEach((event: GameSummary) => {
                teamScoringAccuracy += event.teamScoringAccuracy;
                totals.wins += event.wins;
                totals.losses += event.losses;
                totals.draws += event.draws;
                totals.teamScore += event.teamScore;
                totals.oppositionScore += event.oppositionScore;
                totals.scoreMargin += event.teamScore - event.oppositionScore;
                totals.rotationCount += event.rotationCount;
            });

            if (dataType !== 'Total') {
                totals.teamScore = parseFloat(
                    (totals.teamScore / data.length).toFixed(1)
                );
                totals.oppositionScore = parseFloat(
                    (totals.oppositionScore / data.length).toFixed(1)
                );
                totals.scoreMargin = parseFloat(
                    (totals.scoreMargin / data.length).toFixed(1)
                );
                totals.rotationCount = parseFloat(
                    (totals.rotationCount / data.length).toFixed(1)
                );
            }

            totals.teamScoringAccuracy = `${(
                Math.round((teamScoringAccuracy / data.length) * 1000) / 10
            ).toFixed(1)}%`;
        }

        return totals;
    }, [reportData, dataType]);

    let emptyCols: string[] = [];
    if (calculateTotals.teamScoringAccuracy === '0%') {
        emptyCols.push('teamScoringAccuracy');
    }
    if (calculateTotals.wins === 0 && calculateTotals.losses === 0) {
        emptyCols.push('matchResult');
    }
    if (calculateTotals.teamScore === 0) {
        emptyCols.push('teamScore');
    }
    if (calculateTotals.oppositionScore === 0) {
        emptyCols.push('oppositionScore');
    }
    if (calculateTotals.scoreMargin === 0) {
        emptyCols.push('scoreMargin');
    }
    if (calculateTotals.rotationCount === 0) {
        emptyCols.push('rotationCount');
    }

    let footertitle: string = dataType === 'Total' ? 'Totals:' : 'Averages:';
    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    footer={footertitle}
                    colSpan={6 - (hideIntFGTScore ? 2 : 0)}
                    footerStyle={{ textAlign: 'right' }}
                />
                {hideEmptyCol &&
                calculateTotals.wins === 0 &&
                calculateTotals.losses === 0 ? null : (
                    <Column
                        footer={`W: ${calculateTotals.wins} / L: ${calculateTotals.losses} / D: ${calculateTotals.draws}`}
                        align="center"
                    />
                )}
                {hideEmptyCol &&
                calculateTotals.teamScoringAccuracy === '0%' ? null : (
                    <Column
                        footer={calculateTotals.teamScoringAccuracy}
                        align="center"
                    />
                )}
                {hideEmptyCol && calculateTotals.teamScore === 0 ? null : (
                    <Column footer={calculateTotals.teamScore} align="center" />
                )}
                {hideEmptyCol &&
                calculateTotals.oppositionScore === 0 ? null : (
                    <Column
                        footer={calculateTotals.oppositionScore}
                        align="center"
                    />
                )}
                {hideEmptyCol && calculateTotals.scoreMargin === 0 ? null : (
                    <Column
                        footer={calculateTotals.scoreMargin}
                        align="center"
                    />
                )}
                {hideEmptyCol && calculateTotals.rotationCount === 0 ? null : (
                    <Column
                        footer={calculateTotals.rotationCount}
                        align="center"
                    />
                )}
            </Row>
        </ColumnGroup>
    );

    const renderIntegrityScoreTeam = (
        value: number,
        rowData: GameSummary,
        stat: StatConfig
    ) => {
        let flags = rowData.flags || [];

        flags =
            flags && flags.length > 0
                ? typeof flags[0] === 'string'
                    ? flags
                    : flags[0]
                : [];

        const matchedFlags = flags.filter((val: string) =>
            stat.flagLabels?.includes(val)
        );
        const flagsIntegrityScore = 5 - matchedFlags.length;
        const { hsArticle } = stat;

        const iconAndTooltip = (name: string, color: string) => (
            <div className={`integrity-score-icon-${rowData.eventID}`}>
                <Tooltip
                    target={`.integrity-score-icon-${rowData.eventID}`}
                    autoHide={false}
                >
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Integrity Score: {flagsIntegrityScore}/5
                            <RookieButton
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#ff6700',
                                    opacity: '0.9',
                                }}
                                text={true}
                                data-beacon-article={hsArticle}
                                icon="help"
                            />
                        </div>
                        {isArray(matchedFlags) && (
                            <div>
                                {flagsIntegrityScore === 5 ? (
                                    <div>Issues: Nil.</div>
                                ) : (
                                    <div>
                                        Issues:
                                        {matchedFlags.map((flag, index) => (
                                            <div key={index}>
                                                {niceFlags[
                                                    flag as keyof typeof niceFlags
                                                ] || flag}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Tooltip>
                <Icon name={name} size="large" style={{ color }} />
            </div>
        );

        switch (true) {
            case flagsIntegrityScore === 0:
                return iconAndTooltip('mood_bad', '#CD2335');
            case flagsIntegrityScore <= 1:
                return iconAndTooltip('sentiment_dissatisfied', '#AF553A');
            case flagsIntegrityScore <= 2:
                return iconAndTooltip('sentiment_neutral', '#9D743C');
            case flagsIntegrityScore <= 3:
                return iconAndTooltip('sentiment_satisfied', '#F08036');
            case flagsIntegrityScore <= 4:
                return iconAndTooltip('mood', '#EDB024');
            case flagsIntegrityScore > 4:
                return iconAndTooltip('add_reaction', '#6FBF42');
            default:
                return null;
        }
    };

    const renderFairGameScore = (
        value: number | string | undefined | null,
        maxOnField: number,
        eventID?: string
    ) => {
        if (typeof value === 'string') {
            return <span>{value}</span>;
        }

        if (value === undefined || value === null || isNaN(value)) {
            const tooltipTarget = `na-tooltip-${eventID}`;

            return (
                <>
                    <Tag
                        className={tooltipTarget}
                        style={{
                            width: '40px',
                            color: '#fff',
                            background: '#808080',
                        }}
                    >
                        NA
                    </Tag>
                    <Tooltip target={`.${tooltipTarget}`} autoHide={false}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Total active players is equal to or less than your{' '}
                            <br />
                            game setting of max {maxOnField} players on the
                            field.{' '}
                            <RookieButton
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#ff6700',
                                    opacity: '0.9',
                                }}
                                text={true}
                                data-beacon-article={'6530a57b554393421d94c725'}
                                icon="help"
                            />
                        </div>
                    </Tooltip>
                </>
            );
        }

        let rating = {
            label: '',
            color: '',
        };

        switch (true) {
            case value < 0.5:
                rating = {
                    label: 'Below Average',
                    color: '#CD2335',
                };
                break;
            case value < 0.6:
                rating = {
                    label: 'Average',
                    color: '#EE5145',
                };
                break;
            case value < 0.75:
                rating = {
                    label: 'Good',
                    color: '#F08036',
                };
                break;
            case value < 0.9:
                rating = {
                    label: 'Very Good',
                    color: '#EDB024',
                };
                break;
            case value <= 1:
                rating = {
                    label: 'Excellent',
                    color: '#6FBF42',
                };
                break;
        }

        return (
            <Tag
                style={{
                    width: '40px',
                    color: '#fff',
                    background: rating.color,
                }}
            >
                {Math.round(value * 100)}
            </Tag>
        );
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader title="Summary Report" />
            <Toolbar start={leftToolbar} end={rightToolbar} />

            <DataTable
                showGridlines
                value={reportData && reportData.data ? reportData.data : []}
                footerColumnGroup={footerGroup}
                sortField="event.startDateTime"
                sortOrder={1}
                columnResizeMode="expand"
                resizableColumns
                stripedRows={true}
                footer={tableFooter}
            >
                <Column
                    sortable
                    field="event.eventName"
                    header="Event"
                    sortField="event.startDateTime"
                    body={(row) => {
                        if (!row || !row.event) {
                            return <Skeleton />;
                        }
                        const url = `${location.pathname
                            .split('/t/')
                            .slice(0, -1)
                            .join('/')}/t/${teamID}/events/${
                            row.event.eventID
                        }`;

                        return (
                            <Link to={url} style={{ color: '#000' }}>
                                <div
                                    style={{
                                        fontWeight: '600',
                                        marginBottom: '5px',
                                    }}
                                >
                                    {row.event.eventName}
                                </div>
                                <div
                                    style={{
                                        fontSize: '12px',
                                        opacity: '0.7',
                                    }}
                                >
                                    {row.event.startDateTime &&
                                        format(
                                            new Date(row.event.startDateTime),
                                            'eeee, MMMM do, yyyy'
                                        )}
                                </div>
                            </Link>
                        );
                    }}
                />
                <Column
                    sortable
                    field="event.opponentTeam.teamName"
                    header="Opponent"
                    body={(row) => {
                        if (!row || !row.event) {
                            return <Skeleton />;
                        }

                        return row.event.gameDetails.opponentTeam.teamName ===
                            ''
                            ? 'Not provided'
                            : row.event.gameDetails.opponentTeam.teamName;
                    }}
                />
                <Column
                    sortable
                    header="Location"
                    body={(row) => {
                        if (!row || !row.event) {
                            return <Skeleton />;
                        }

                        return row.event.location.locationName === ''
                            ? 'Not provided'
                            : row.event.location.locationName;
                    }}
                />
                <Column
                    sortable
                    field="event.isHomeTeam"
                    header="Home/Away"
                    body={(row) => {
                        if (!row || !row.event) {
                            return <Skeleton />;
                        }

                        return row.event.gameDetails.isHomeTeam
                            ? 'Home'
                            : 'Away';
                    }}
                />

                {columns.map((val) => {
                    const stat = config[val as keyof typeof config];

                    if (!stat) return null;

                    if (hideIntFGTScore) {
                        if (
                            val === 'integrityScore' ||
                            val === 'fairGameTime'
                        ) {
                            return null;
                        }
                    }

                    if (val === 'environmentCoeff' || val === 'gamesPlayed') {
                        return null; // Hide environment coefficent from team summary reports
                    }

                    if (hideEmptyCol && emptyCols.includes(val)) {
                        return null;
                    }

                    return (
                        <Column
                            key={stat.shortName}
                            sortable
                            field={val}
                            header={renderHeaderCell(stat)}
                            align="center"
                            alignHeader="center"
                            headerClassName={`cell-${stat.shortName}`}
                            body={(row) => {
                                if (!row || reportData.isLoading) {
                                    return (
                                        <Skeleton width="2rem" height="1rem" />
                                    );
                                }
                                const value = row[val];

                                if (val === 'matchResult' && value) {
                                    return renderResult(value);
                                }

                                if (val === 'integrityScore') {
                                    return renderIntegrityScoreTeam(
                                        value,
                                        row,
                                        stat
                                    ); // Pass value and rowData here
                                }

                                if (val === 'fairGameTime') {
                                    const maxOnField =
                                        row.event?.gameDetails.settings
                                            .maxOnField;
                                    return renderFairGameScore(
                                        value,
                                        maxOnField
                                    );
                                }

                                if (stat.format) {
                                    return stat.format(row);
                                }

                                return (
                                    <DataCell
                                        value={value}
                                        type={stat.type}
                                        totalTime={row['PT']}
                                    />
                                );
                            }}
                        />
                    );
                })}
            </DataTable>
        </PageContainer>
    );
};

export default GameSummaryReport;
