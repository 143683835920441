import React from 'react';
import { classNames } from 'primereact/utils';

interface Props {
    start?: React.ReactNode;
    center?: React.ReactNode;
    end?: React.ReactNode;
    className?: string;
}

const FormActions = (props: Props) => {
    const { className, start, center, end } = props;

    const fieldClass = classNames('form-actions', className);

    return (
        <div className={fieldClass}>
            <div className="form-actions-start">{start}</div>
            <div className="form-actions-center">{center}</div>
            <div className="form-actions-end">{end}</div>
        </div>
    );
};

export default FormActions;
