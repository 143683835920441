import { useRef } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import ErrorDisplay from '../../components/ErrorDisplay';
import RookieButton from '../../components/RookieButton';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';

import SeasonAddTeams from './SeasonAddTeams';

import { ERROR_TYPES } from '../../types/common';
import { SeasonTeam, SeasonTeamsResponse } from '../../types/seasons';
import { UseQueryResult } from '../../types/api';
import { Team } from '../../types/team';

interface Props {
    teamData: UseQueryResult<SeasonTeamsResponse>;
    onCloseCreateDialog: () => void;
    onCreate: () => void;
    onDelete: () => void;
    onLoadMore: () => void;
    addTeamToSeason: (team: Team) => void;
    setFocus: (team: SeasonTeam) => void;
    showPagination: boolean;
    showCreateDialog: boolean;
}

const SeasonTeamsView = ({
    teamData,
    onCreate,
    onDelete,
    onLoadMore,
    onCloseCreateDialog,
    setFocus,
    showPagination,
    showCreateDialog,
    addTeamToSeason,
}: Props) => {
    const { associationID } = useParams();

    const teams = teamData?.data?.data || [];
    const isTeamsLoading = teamData.isLoading;
    const isTeamsFetching = teamData.isFetching;

    const rowMenu = useRef<Menu>(null);

    const footerTemplate = (
        <RookieButton
            onClick={onLoadMore}
            severity="secondary"
            label="Load more"
            icon="pending"
        />
    );

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            desc={`Teams added to this season will appear here.`}
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No Teams"
        />
    );

    const actionTemplate = (row: SeasonTeam) => {
        return (
            <div className="p-buttonset">
                <RookieButton
                    severity="secondary"
                    text={true}
                    className="td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        setFocus(row);
                    }}
                    icon="more_horiz"
                />
            </div>
        );
    };

    const columns = [
        {
            field: 'teamDetails.teamName',
            header: 'Name',
            body: isTeamsLoading && <Skeleton />,
        },
        {
            field: 'createdAt',
            header: 'Joined',
            body: (row: SeasonTeam) =>
                isTeamsLoading ? (
                    <Skeleton />
                ) : (
                    format(new Date(row.createdAt), 'MMM yyyy')
                ),
        },
        {
            body: isTeamsLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
        },
    ];

    const rowActions = [
        {
            label: 'Remove',
            command: onDelete,
        },
    ];

    return (
        <>
            <DataTable
                header={
                    <Toolbar
                        end={
                            <RookieButton onClick={onCreate} label="Add Team" />
                        }
                    />
                }
                value={isTeamsLoading ? Array(5) : teams}
                loading={isTeamsFetching && !isTeamsLoading}
                footer={
                    !isTeamsFetching && showPagination ? footerTemplate : null
                }
                emptyMessage={emptyTemplate}
                columnResizeMode="expand"
                resizableColumns
            >
                {(isTeamsLoading || teams.length > 0) &&
                    columns.map((col: ColumnProps) => (
                        <Column key={col.field} {...col} />
                    ))}
            </DataTable>

            <Sidebar
                header="Add Team To Season"
                onHide={onCloseCreateDialog}
                visible={showCreateDialog}
                position="right"
            >
                <SeasonAddTeams
                    associationID={associationID || ''}
                    showFilter
                    onTeamClick={addTeamToSeason}
                />
            </Sidebar>

            <Menu model={rowActions} popup ref={rowMenu} />
        </>
    );
};

export default SeasonTeamsView;
