import { Navigate } from 'react-router-dom';
import DocumentHead from '../../components/DocumentHead';

const TeamHome = (): JSX.Element => {
    return (
        <>
            <DocumentHead
                title="Team Home - Rookie Me Hub"
                description="Team home page"
            />
            <Navigate to="events" replace />
        </>
    );
};

export default TeamHome;
