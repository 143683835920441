import { useEffect } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';

const TeamSeasonsContainer = () => {
    const outlet = useOutlet();
    const navigate = useNavigate();

    useEffect(() => {
        if (!outlet) {
            navigate('owned', { replace: true });
        }
    }, [navigate, outlet]);

    return outlet;
};

export default TeamSeasonsContainer;
