import { useMemo, useState } from 'react';
import { useDeleteEventMutation, useGetEventsQuery } from '../../api/events';
import CalendarView from './CalendarView';
import DocumentHead from '../../components/DocumentHead';

import { CalendarEvent, EventFormData } from '../../types/event';
import { useParams } from 'react-router-dom';
import { getEntityFromParam } from '../../util/helper';

const CalendarContainer = () => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    // State Hooks
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
        null
    );

    const [createEvent, setCreateEvent] = useState<EventFormData | null>(null);

    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    // API Hooks
    const { data, isError, isFetching, isLoading } = useGetEventsQuery(
        {
            //@ts-expect-error
            entityType: activeEntity?.entityType,
            //@ts-expect-error
            entityID: activeEntity?.entityID,
        },
        {
            skip: !activeEntity,
        }
    );

    const [deleteEvent] = useDeleteEventMutation();

    const handleShowCreate = (event?: EventFormData) => {
        if (event) {
            setCreateEvent(event);
        }

        setShowCreateDialog(true);
    };

    const handleCloseCreate = () => {
        setCreateEvent(null);
        setShowCreateDialog(false);
    };

    const handleShowDelete = () => {
        setShowDeleteDialog(true);
    };

    const handleCloseDelete = () => {
        setShowDeleteDialog(false);
    };

    const handleShowEdit = (event: CalendarEvent) => {
        if (event) {
            setSelectedEvent(event);
        }

        setShowEditDialog(true);
    };

    const handleCloseEdit = () => {
        setSelectedEvent(null);
        setShowEditDialog(false);
    };

    const handleDeleteEvent = (eventID: string) => {
        if (activeEntity) {
            deleteEvent({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                eventID,
            })
                .then((response) => {
                    console.log('DELETE EVENT SUCCESS', response);
                })
                .catch((err) => {
                    console.warn('DELETE EVENT ERROR', err);
                })
                .finally(() => {
                    setShowDeleteDialog(false);
                });
        }
    };

    const handleSelectEvent = (value: CalendarEvent | null) => {
        setSelectedEvent(value);
    };

    return (
        <>
            <DocumentHead
                title="Events - Rookie Me Hub"
                description="User events page"
            />
            <CalendarView
                data={data ? data.data : []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                createEvent={createEvent}
                selectedEvent={selectedEvent}
                showDeleteDialog={showDeleteDialog}
                showCreateDialog={showCreateDialog}
                showEditDialog={showEditDialog}
                onCloseCreateDialog={handleCloseCreate}
                onCloseDeleteDialog={handleCloseDelete}
                onCloseEditDialog={handleCloseEdit}
                onShowCreateDialog={handleShowCreate}
                onShowDeleteDialog={handleShowDelete}
                onShowEditDialog={handleShowEdit}
                onDeleteEvent={handleDeleteEvent}
                onSelectEvent={handleSelectEvent}
            />
        </>
    );
};

export default CalendarContainer;
