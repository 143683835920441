import { useAuth0 } from '@auth0/auth0-react';

const LogOut = (props) => {
    const { logout } = useAuth0();

    logout();
    localStorage.clear();

    return (
        <div className="auth0-app auth0-lock auth0-lock">
            <p>Logging out...</p>
        </div>
    );
};

export default LogOut;
