import { useParams } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import {
    useGetAssociationSeasonQuery,
    useGetTeamSeasonQuery,
} from '../../api/seasons';

const SeasonBreadcrumb = () => {
    const { teamID, associationID, seasonID } = useParams();

    const associationSeason = useGetAssociationSeasonQuery(
        {
            seasonID: seasonID || '',
            associationID: associationID || '',
        },
        { skip: !associationID }
    );

    const teamSeason = useGetTeamSeasonQuery(
        {
            seasonID: seasonID || '',
            teamID: teamID || '',
        },
        { skip: !teamID }
    );

    const seasonData = associationID
        ? associationSeason
        : teamID
        ? teamSeason
        : null;

    const { data, isLoading, isError } = seasonData || {};

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;

    if (isError) return 'Season';

    return data?.data?.seasonName;
};
export default SeasonBreadcrumb;
