import { Link, useParams } from 'react-router-dom';
import PageHeader from '../../layout/PageHeader';
import Icon from '../../components/Icon';
import { Tag } from 'primereact/tag';
import { Me } from '../../types/user';
import PageContainer from '../../layout/PageContainer';
import usePermission from '../../hooks/usePermission';

interface Report {
    title: string;
    path: string;
    content: string;
    icon: string;
    locked?: boolean;
}

interface ReportCardGroup {
    title: string;
    items: Report[];
}

interface ReportsListingProps {
    user: Me;
}

const ReportCard = (props: Report) => {
    return (
        <Link
            to={props.locked ? '#' : props.path}
            className={`report-card ${props.locked ? 'is-locked' : ''}`}
        >
            <Icon name={props.icon} size="large" />
            <div>
                <h3 style={{ marginBottom: '5px' }}>
                    {props.title}{' '}
                    {props.locked && (
                        <Tag style={{ verticalAlign: 'middle', color: '#fff' }}>
                            Upgrade
                        </Tag>
                    )}
                </h3>
                <p
                    style={{
                        opacity: '0.7',
                        lineHeight: '1.3',
                        fontSize: '14px',
                    }}
                >
                    {props.content}
                </p>
            </div>
        </Link>
    );
};

const ReportSection = (props: ReportCardGroup) => {
    return (
        <div
            style={{
                marginBottom: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #e5e5e5',
            }}
        >
            <h4 style={{ marginBottom: '10px' }}>{props.title}</h4>
            <div style={{ display: 'flex', gap: '20px' }}>
                {props.items.map((item, i) => (
                    <ReportCard key={`report-card-${i}`} {...item} />
                ))}
            </div>
        </div>
    );
};

const TeamSeasonReportsListing = (props: ReportsListingProps) => {
    const { teamID } = useParams();
    const { isAdmin } = usePermission(teamID);

    const reportSections: ReportCardGroup[] = [
        {
            title: 'Game',
            items: [
                {
                    title: 'Summary',
                    content:
                        'Season data for FGT score, data integrity, win/loss ratio, scoring accuracy and more.',
                    icon: 'sports',
                    path: 'summary',
                },
            ],
        },
        {
            title: 'Player Stats',
            items: [
                {
                    title: 'Player Basic',
                    content:
                        'Total individual scoring stats for a season, including averages per game.',
                    icon: 'person',
                    path: 'player-basic',
                },
                {
                    title: 'Player Advanced',
                    content:
                        'Total individual for all stats for a season, including averages per game.',
                    icon: 'person',
                    path: 'player-advanced',
                },
            ],
        },
        {
            title: 'Team Stats',
            items: [
                {
                    title: 'Team Basic',
                    content:
                        'Total scoring stats for a season, including averages per game.',
                    icon: 'groups',
                    path: 'teams-basic',
                },
                {
                    title: 'Team Advanced',
                    content:
                        'Total team and opposition stats for a season, including averages per game.',
                    icon: 'groups',
                    path: 'teams-advanced',
                },
            ],
        },
        {
            title: 'Time',
            items: [
                {
                    title: 'Time Basic',
                    content:
                        'Player on/off total times, including average per game.',
                    icon: 'timer',
                    path: 'time-basic',
                },
                {
                    title: 'Time Advanced',
                    content:
                        'Player time breakdown for zone time and game status e.g field, bench, injury, including average per game.',
                    icon: 'timer',
                    path: 'time-advanced',
                },
                {
                    title: 'Time Expert',
                    content:
                        'All advanced reports as well as time in position; stints, average stints.',
                    icon: 'timer',
                    path: 'time-expert',
                },
            ],
        },
        {
            title: 'Insights',
            items: [
                {
                    title: 'Bench Starts',
                    content:
                        'Analysis of the number of times a player has started a period on the bench across a season.',
                    icon: 'analytics',
                    path: 'bench-starts',
                },
                ...(isAdmin
                    ? [
                          {
                              title: 'Player Voting / Rating',
                              content:
                                  'An advanced level report allowing the user to evaluate player performance. This can also be used for best and fairest voting purposes.',
                              icon: 'military_tech',
                              path: 'player-votes',
                          },
                      ]
                    : []),
                {
                    title: 'Interchange Player',
                    content:
                        'Game-by-game summary of stints on and off the field for each period and the match.',
                    icon: 'analytics',
                    path: 'season-interchange-player',
                },
                {
                    title: 'Interchange On/Off',
                    content:
                        'Analysis of each individual stint for each player across each match.',
                    icon: 'analytics',
                    path: 'season-interchange-on-off',
                },
            ],
        },
        {
            title: 'Coming soon...',
            items: [
                {
                    title: 'Attendance Report',
                    content:
                        'Report highlighting players that have been absent for a given game and season.',
                    icon: 'analytics',
                    path: '',
                },
                {
                    title: 'Stat Density',
                    content:
                        'A report that looks at any stat recorded across a season and when the given stat typically occurs throughout a match.',
                    icon: 'analytics',
                    path: '',
                },
                {
                    title: 'Optimal Lineups',
                    content:
                        'Based on user prefernces, recommended lineups are provided based on preferred outcomes (e.g., the defensive group that results in least scores conceded).',
                    icon: 'analytics',
                    path: '',
                },
            ],
        },
    ];

    return (
        <PageContainer>
            <PageHeader title="Season Reports" />
            {reportSections.map((section, i) => (
                <ReportSection
                    key={`report-section-${i}`}
                    title={section.title}
                    items={section.items}
                />
            ))}
        </PageContainer>
    );
};

export default TeamSeasonReportsListing;
