import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { apiEndpoints, prepareHeaders } from './apiEndpoints';

import { GameRole, GameRolesResponse } from '../types/game';

export const gamesApi = createApi({
    reducerPath: 'gamesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['GameRole'],
    endpoints: (builder) => ({
        getGameRoles: builder.query<
            GameRolesResponse,
            {
                teamID: string;
                eventID: string;
                cursor?: string;
            }
        >({
            query: ({ teamID, eventID, ...params }) => ({
                url: generatePath(apiEndpoints.getGameRoles.url, {
                    teamID,
                    eventID,
                }),
                method: apiEndpoints.getGameRoles.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(
                              ({ role: { roleID } }: GameRole) => ({
                                  type: 'GameRole' as const,
                                  roleID,
                              })
                          ),
                          'GameRole',
                      ]
                    : ['GameRole'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];
                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
    }),
});

export const { useGetGameRolesQuery } = gamesApi;
