import { useEffect, useState } from 'react';
import { useGetAssociationOrganisationsQuery } from '../../api/associations';
import { Tree, TreeEventNodeEvent } from 'primereact/tree';
import { useLazyGetOrganisationTeamsQuery } from '../../api/organisations';
import ListItem from '../../components/ListItem';
import { TreeNode } from 'primereact/treenode';
import { Team } from '../../types/team';

interface Props {
    associationID: string;
    onTeamClick?: (team: Team) => void;
    showFilter?: boolean;
}

const SeasonAddTeams = ({ associationID, onTeamClick, showFilter }: Props) => {
    const [nodes, setNodes] = useState<TreeNode[]>();
    const [loadingTeams, setLoadingTeams] = useState(false);

    const { data, isLoading } = useGetAssociationOrganisationsQuery({
        associationID: associationID || '',
    });

    const [fetchTeams] = useLazyGetOrganisationTeamsQuery();

    useEffect(() => {
        if (data?.data) {
            setNodes(
                data.data.map((o) => ({
                    key: o.organisationID,
                    data: o,
                    label: o.organisationName,
                    leaf: false,
                }))
            );
        }
    }, [data]);

    const loadOnExpand = (event: TreeEventNodeEvent) => {
        const { node } = event;

        if (node.data.organisationID) {
            setLoadingTeams(true);

            fetchTeams({
                organisationID: node.data.organisationID,
            })
                .then(({ data }) => {
                    let newNode: TreeNode = { ...event.node, children: [] };

                    data &&
                        data.data.forEach((o: Team) => {
                            newNode.children &&
                                newNode.children.push({
                                    key: o.teamID,
                                    label: o.teamName,
                                    data: o,
                                });
                        });

                    setNodes(
                        (prevNodes) =>
                            prevNodes &&
                            prevNodes.map((prevNode) =>
                                prevNode.data.organisationID ===
                                node.data.organisationID
                                    ? newNode
                                    : prevNode
                            )
                    );
                })
                .catch((e) => {
                    console.warn('error', e);
                })
                .finally(() => {
                    setLoadingTeams(false);
                });
        }
    };

    const nodeTemplate = (node: TreeNode) => {
        return (
            <ListItem
                title={node.label || ''}
                onClick={node.data && onTeamClick && onTeamClick(node.data)}
            />
        );
    };

    return (
        <Tree
            value={nodes}
            onExpand={loadOnExpand}
            loading={isLoading || loadingTeams}
            nodeTemplate={nodeTemplate}
            filter={showFilter}
            filterMode="lenient"
            filterPlaceholder="Search"
        />
    );
};

export default SeasonAddTeams;
