import { generatePath } from 'react-router-dom';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';

import { Team, TeamForm, TeamResponse } from '../types/team';

export const teamsApi = createApi({
    reducerPath: 'teamsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Team'],
    endpoints: (builder) => ({
        getTeam: builder.query<TeamResponse, { teamID: string }>({
            query: ({ teamID }) => ({
                url: generatePath(apiEndpoints.getTeam.url, { teamID }),
                method: apiEndpoints.getTeam.method,
                params: { expand: 'jumperKit' },
            }),
            providesTags: ['Team'],
        }),
        createTeam: builder.mutation<Team, TeamForm>({
            query: ({ userID, ...data }) => ({
                url: generatePath(apiEndpoints.createTeam.url, { userID }),
                method: apiEndpoints.createTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        joinTeam: builder.mutation<
            Team,
            { userID: string; teamJoinCode: string; userType?: string }
        >({
            query: ({ userID, teamJoinCode, userType }) => ({
                url: generatePath(apiEndpoints.joinTeam.url, { userID }),
                method: apiEndpoints.joinTeam.method,
                body: {
                    teamJoinCode,
                    userType,
                },
            }),
            invalidatesTags: ['Team'],
        }),
        updateTeam: builder.mutation<Team, Partial<TeamForm>>({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.updateTeam.url, { teamID }),
                method: apiEndpoints.updateTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
        deleteTeam: builder.mutation<Team, { teamID: string }>({
            query: ({ teamID }) => ({
                url: generatePath(apiEndpoints.deleteTeam.url, { teamID }),
                method: apiEndpoints.deleteTeam.method,
            }),
            invalidatesTags: ['Team'],
        }),
        archiveTeam: builder.mutation<Team, { teamID: string }>({
            query: ({ teamID }) => ({
                url: generatePath(apiEndpoints.archiveTeam.url, { teamID }),
                method: apiEndpoints.archiveTeam.method,
                body: { teamStatus: 'Archived' },
            }),
            invalidatesTags: ['Team'],
        }),
        upsertTeam: builder.mutation({
            query: ({ teamID, userID, ...data }) => ({
                url: generatePath(
                    teamID
                        ? apiEndpoints.updateTeam.url
                        : apiEndpoints.createTeam.url,
                    {
                        teamID,
                        userID,
                    }
                ),
                method: teamID
                    ? apiEndpoints.updateTeam.method
                    : apiEndpoints.createTeam.method,
                body: data,
            }),
            invalidatesTags: ['Team'],
        }),
    }),
});

export const {
    useGetTeamQuery,
    useCreateTeamMutation,
    useJoinTeamMutation,
    useUpdateTeamMutation,
    useDeleteTeamMutation,
    useUpsertTeamMutation,
    useArchiveTeamMutation,
} = teamsApi;
