import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import CampaignContainer from '../campaign/CampaignContainer';

interface UserHomeProps {
    user: {
        userID: string;
    };
}

const UserHome = ({ user }: UserHomeProps): JSX.Element => {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    if (searchParams.has('campaign')) {
        return (
            <CampaignContainer
                userID={user.userID}
                campaign={searchParams.get('campaign')}
                promoCode={searchParams.get('promoCode')}
            />
        );
    }

    return (
        <Navigate to={`/u/${user.userID}/entities${location.search}`} replace />
    );
};

export default UserHome;
