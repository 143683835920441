import { useParams } from 'react-router-dom';

import AddLicencesForm from './AddLicencesForm';
import AssignLicencesForm from './AssignLicencesForm';

import EntityName from '../../components/EntityName';
import RookieButton from '../../components/RookieButton';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';

import { BaseEntityType } from '../../types/common';
import { Licence, LicenceGroup } from '../../types/licences';
import { Team } from '../../types/team';
import { User } from '../../types/user';

interface Props {
    licences: Licence[];
    licenceGroup?: LicenceGroup;
    showAssignModal: boolean;
    showAddModal: boolean;
    isLoading: boolean;
    onAssignLicence: (licenceGroupID: string, teams: Team[]) => void;
    onUnassignLicence: (licenceID: string) => void;
    onManageBilling: (subscriptionID: string) => void;
    onShowAddModal: () => void;
    onCloseAddModal: () => void;
    onShowAssignModal: () => void;
    onCloseAssignModal: () => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
    user: User;
}

const OrganisationLicenceSingleView = (props: Props) => {
    const {
        licences,
        licenceGroup,
        onAssignLicence,
        onUnassignLicence,
        showAssignModal,
        showAddModal,
        onManageBilling,
        onShowAddModal,
        onCloseAddModal,
        onShowAssignModal,
        onCloseAssignModal,
        isLoading,
        permissions,
        user,
    } = props;
    const { organisationID, licenceGroupID } = useParams();

    const rowActions = (data: any) => (
        <div>
            {data.status === 'Unassigned' && (
                <RookieButton
                    label="Assign Licence"
                    onClick={() => onShowAssignModal()}
                />
            )}
            {permissions.canEdit && (
                <RookieButton
                    label="Remove Licence"
                    onClick={() => onUnassignLicence(data.licenceID)}
                    severity="secondary"
                />
            )}
        </div>
    );

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                actions={[
                    ...(permissions.canEdit &&
                    licenceGroup?.createdBy === user.userID &&
                    licenceGroup?.subscriptionID
                        ? [
                              {
                                  label: 'Manage',
                                  command: () =>
                                      onManageBilling(
                                          licenceGroup.subscriptionID
                                      ),
                                  className: 'p-button-secondary',
                              },
                              {
                                  label: 'Assign Licences',
                                  command: () => onShowAssignModal(),
                              },
                          ]
                        : []),
                    ...(permissions.canDelete
                        ? [
                              {
                                  label: 'Add Licences',
                                  command: () => onShowAddModal(),
                              },
                          ]
                        : []),
                ]}
            />
            <DataTable
                value={isLoading ? Array(3) : licences}
                emptyMessage="No assigned licences"
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    field="entityID"
                    header="Team"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : (
                            <EntityName
                                entityType={data.entityType}
                                entityID={data.entityID}
                            />
                        )
                    }
                />
                <Column
                    body={isLoading ? <Skeleton /> : rowActions}
                    style={{ textAlign: 'right' }}
                />
            </DataTable>
            <Sidebar
                header="Add Licence"
                onHide={() => onCloseAddModal()}
                visible={showAddModal}
                position="right"
            >
                {organisationID && licenceGroup && (
                    <AddLicencesForm
                        onCancel={() => onCloseAddModal()}
                        entityID={organisationID}
                        entityType={BaseEntityType.organisations}
                        subscriptionID={licenceGroup.subscriptionID}
                    />
                )}
            </Sidebar>
            <Sidebar
                header="Assign Licence"
                onHide={() => onCloseAssignModal()}
                visible={showAssignModal}
                position="right"
            >
                {licenceGroupID && organisationID && (
                    <AssignLicencesForm
                        licenceGroupID={licenceGroupID}
                        organisationID={organisationID}
                        onCancel={() => onCloseAssignModal()}
                        onSubmit={onAssignLicence}
                    />
                )}
            </Sidebar>
        </PageContainer>
    );
};

export default OrganisationLicenceSingleView;
