import React from 'react';

interface Props {
    children: React.ReactNode;
    size?: 'full' | 'fluid' | 'narrow';
}

const PageContainer = (props: Props) => {
    return (
        <div
            className={`page-container ${
                props.size ? 'page-container-' + props.size : ''
            }`}
        >
            {props.children}
        </div>
    );
};

PageContainer.defaultProps = {
    size: 'fluid',
};

export default PageContainer;
