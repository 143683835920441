import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { TicketResponse } from '../types/ticket';

export const ticketsApi = createApi({
    reducerPath: 'ticketsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getTicket: builder.query<
            TicketResponse,
            { ticketID: string; tokenID: string }
        >({
            query: ({ ticketID, tokenID }) => ({
                url:
                    generatePath(apiEndpoints.getTicket.url, {
                        ticketID,
                    }) + `?verify=${tokenID}`,
                method: apiEndpoints.getTicket.method,
            }),
        }),
        acceptTicket: builder.query<any, { ticketID: string; tokenID: string }>(
            {
                query: ({ ticketID, tokenID }) => ({
                    url:
                        generatePath(apiEndpoints.acceptTicket.url, {
                            ticketID,
                        }) + `?verify=${tokenID}`,
                    method: apiEndpoints.acceptTicket.method,
                }),
            }
        ),
        declineTicket: builder.query<
            any,
            { ticketID: string; tokenID: string }
        >({
            query: ({ ticketID, tokenID }) => ({
                url:
                    generatePath(apiEndpoints.declineTicket.url, { ticketID }) +
                    `?verify=${tokenID}`,
                method: apiEndpoints.declineTicket.method,
            }),
        }),
    }),
});

export const {
    useGetTicketQuery,
    useAcceptTicketQuery,
    useDeclineTicketQuery,
    useLazyAcceptTicketQuery,
    useLazyDeclineTicketQuery,
} = ticketsApi;
