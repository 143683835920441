import {
    percentageCell,
    playerGroupCell,
    playerNameCell,
    textCell,
    timeCell,
} from './DataTableCells';

import { ReportColumn } from '../../types/reports';

export const metaKeys = [
    'player.firstName',
    'player.lastName',
    'player.colour',
    'player.uniformNumber',
];

export const statKeys = [
    'PT',
    'timeOnField',
    'timeOffField',
    'timeBench',
    'timeAssessment',
    'timeInjury',
    'timeDisciplinary',
    'timeOpposition',
    'timeAbsent',
    'zoneTimeFOR',
    'zoneTimeMID',
    'zoneTimeBAC',
    'timePos__Field',
    'timePos__afl_mid_left_half_forward',
    'timePos__afl_mid_centre_half_back',
    'timePos__afl_ruck',
    'timePos__afl_mid_right_half_forward',
    'timePos__afl_right_half_forward',
    'timePos__afl_left_forward_pocket',
    'timePos__afl_attacking_left_wing',
    'timePos__afl_left_back_pocket',
    'timePos__afl_right_wing',
    'timePos__afl_right_forward_pocket',
    'timePos__afl_defensive_left_wing',
    'timePos__afl_full_forward',
    'timePos__afl_defensive_right_wing',
    'timePos__afl_mid_2',
    'timePos__afl_left_half_back',
    'timePos__afl_left_wing',
    'timePos__afl_right_back_pocket',
    'timePos__afl_mid_1',
    'timePos__afl_left_half_forward',
    'timePos__afl_mid_left_half_back',
    'timePos__afl_full_back',
    'timePos__afl_attacking_right_wing',
    'timePos__afl_mid_centre_half_forward',
    'timePos__afl_mid_right_half_back',
    'timePos__afl_right_half_back',
    'timePos__afl_mid_3',
    'timePos__afl_centre_half_back',
    'timePos__afl_centre_half_forward',
    'countStintPos__Field',
    'countStintPos__afl_mid_left_half_forward',
    'countStintPos__afl_mid_centre_half_back',
    'countStintPos__afl_ruck',
    'countStintPos__afl_mid_right_half_forward',
    'countStintPos__afl_right_half_forward',
    'countStintPos__afl_left_forward_pocket',
    'countStintPos__afl_attacking_left_wing',
    'countStintPos__afl_left_back_pocket',
    'countStintPos__afl_right_wing',
    'countStintPos__afl_right_forward_pocket',
    'countStintPos__afl_defensive_left_wing',
    'countStintPos__afl_full_forward',
    'countStintPos__afl_defensive_right_wing',
    'countStintPos__afl_mid_2',
    'countStintPos__afl_left_half_back',
    'countStintPos__afl_left_wing',
    'countStintPos__afl_right_back_pocket',
    'countStintPos__afl_mid_1',
    'countStintPos__afl_left_half_forward',
    'countStintPos__afl_mid_left_half_back',
    'countStintPos__afl_full_back',
    'countStintPos__afl_attacking_right_wing',
    'countStintPos__afl_mid_centre_half_forward',
    'countStintPos__afl_mid_right_half_back',
    'countStintPos__afl_right_half_back',
    'countStintPos__afl_mid_3',
    'countStintPos__afl_centre_half_back',
    'countStintPos__afl_centre_half_forward',
    'countStintBench',
    'countStintAssessment',
    'countStintInjury',
    'countStintDisciplinary',
    'countStintOpposition',
    'countStintAbsent',
    'countStintOnField',
    'countStintOffField',
    'period__unavailable_time',
    'period__available_time',
    'PMT',
    'rotationsPerQuarter',
    'totalAvailableTime',
    'totalOnFieldTime',
];

export const gameTimeExpertColumns: ReportColumn[] = [
    {
        field: 'player.uniformNumber',
        name: 'Player Number',
        header: '#',
        shortName: 'No',
        sortable: true,
        body: textCell,
    },
    {
        field: 'player.lastName',
        name: 'Name',
        header: 'Name',
        shortName: 'Name',
        sortable: true,
        body: playerNameCell,
        exportable: false,
    },
    {
        field: 'player.firstName',
        name: 'First Name',
        header: 'First Name',
        shortName: 'First Name',
        hidden: true,
    },
    {
        field: 'player.lastName',
        name: 'Last Name',
        header: 'Last Name',
        shortName: 'Last Name',
        hidden: true,
    },
    {
        field: 'player.colour',
        name: 'Group',
        header: 'Group',
        shortName: 'Group',
        sortable: true,
        body: playerGroupCell,
    },
    {
        field: 'totalPGT',
        name: 'Percentage Game Time',
        header: 'PGT',
        shortName: 'PGT',
        sortable: true,
        body: percentageCell,
        headerTooltip: '',
        hsArticle: '662dc12f0cfcb4508af6a0fc',
    },
    {
        field: 'timeOnField',
        name: 'Percentage Match Time',
        header: 'PMT',
        shortName: 'PMT',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
        hsArticle: '662dc12f0cfcb4508af6a0fc',
    },
    {
        field: 'countStintOnField',
        name: 'Stints on Field',
        header: 'SFLD',
        shortName: 'SFLD',
        description: 'Total stints a player has had on the field.',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timeBench',
        name: 'Time on Bench',
        header: 'BEN',
        shortName: 'BEN',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintBench',
        name: 'Stints on Bench',
        description: 'Total stints a player has had on the bench.',
        header: 'SBEN',
        shortName: 'SBEN',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timeInjury',
        name: 'Injury Time',
        description: '',
        header: 'INJ',
        shortName: 'INJ',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintInjury',
        name: 'Stints in Injury',
        description: '',
        header: 'SINJ',
        shortName: 'SINJ',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'zoneTimeFOR',
        name: 'Forward Zone Time',
        description: '',
        header: 'FWD',
        shortName: 'FWD',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'zoneTimeMID',
        name: 'Midfield Zone Time',
        description: '',
        shortName: 'MID',
        header: 'MID',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'zoneTimeBAC',
        name: 'Defense Zone Time',
        description: '',
        shortName: 'DEF',
        header: 'DEF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timeAssessment',
        name: 'Assessment Time',
        description: '',
        header: 'ASS',
        shortName: 'ASS',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintAssessment',
        name: 'Stints in Assessment',
        description: '',
        header: 'SASS',
        shortName: 'SASS',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timeDisciplinary',
        name: 'Disciplinary Time',
        description: '',
        header: 'DIS',
        shortName: 'DIS',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintDisciplinary',
        name: 'Stints in Disciplinary',
        description: '',
        header: 'SDIS',
        shortName: 'SDIS',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timeOpposition',
        name: 'Stints with Opposition',
        description: '',
        header: 'OPP',
        shortName: 'OPP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },

    {
        field: 'countStintOpposition',
        name: 'Count Opposition',
        description: '',
        header: 'SOPP',
        shortName: 'SOPP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timeAbsent',
        name: 'Absent Time',
        description: '',
        header: 'ABS',
        shortName: 'ABS',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintAbsent',
        name: 'Count Absent',
        description: '',
        header: 'SABS',
        shortName: 'SABS',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_left_back_pocket',
        name: 'Left back pocket',
        description: '',
        header: 'LBP',
        shortName: 'LBP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_left_back_pocket',
        name: 'Count Left back pocket',
        description: '',
        header: 'SLBP',
        shortName: 'SLBP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_full_back',
        name: 'Full back',
        description: '',
        header: 'FB',
        shortName: 'FB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_full_back',
        name: 'Count Field Time',
        description: '',
        header: 'SFB',
        shortName: 'SFB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_right_back_pocket',
        name: 'Back pocket',
        description: '',
        header: 'RBP',
        shortName: 'BP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_right_back_pocket',
        name: 'Count Back pocket',
        description: '',
        header: 'SRBP',
        shortName: 'SRBP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_left_half_back',
        name: 'Left half back',
        description: '',
        header: 'LHB',
        shortName: 'LHB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_left_half_back',
        name: 'Count Left half back',
        description: '',
        header: 'SLHB',
        shortName: 'SLHB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //    {
    //        field: 'timePos__afl_mid_left_half_back',
    //        name: 'Mid left half back',
    //        header: 'MLHB',
    //        shortName: 'MLHB',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Mid Left Half Back',
    //    },
    //    {
    //        field: 'countStintPos__afl_mid_left_half_back',
    //        name: 'Count Mid left half back',
    //        header: 'SMLHB',
    //        shortName: 'SMLHB',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Mid Left Half Back',
    //    },
    {
        field: 'timePos__afl_centre_half_back',
        name: 'Centre half back',
        description: '',
        header: 'CHB',
        shortName: 'CHB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_centre_half_back',
        name: 'Count Centre half back',
        description: '',
        header: 'SCHB',
        shortName: 'SCHB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //    {
    //        field: 'timePos__afl_mid_centre_half_back',
    //        name: 'Mid-centre half back',
    //        header: 'MCHB',
    //        shortName: 'MCHB',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Mid-Centre Half Back',
    //    },
    //    {
    //        field: 'countStintPos__afl_mid_centre_half_back',
    //        name: 'Count Mid-centre half back',
    //        header: 'CMCHB',
    //       shortName: 'CMCHB',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Mid-Centre Half Back',
    //    },
    {
        field: 'timePos__afl_right_half_back',
        name: 'Right half back',
        description: '',
        header: 'RHB',
        shortName: 'RHB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_right_half_back',
        name: 'Count Right half back',
        description: '',
        header: 'SRHB',
        shortName: 'SRHB',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //    {
    //        field: 'timePos__afl_mid_right_half_back',
    //        name: 'Mid right half back',
    //        header: 'MRHB',
    //        shortName: 'MRHB',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Mid Right Half Back',
    //    },
    //    {
    //        field: 'countStintPos__afl_mid_right_half_back',
    //        name: 'Mid right half back',
    //        header: 'CFLD',
    //        shortName: 'CFLD',
    //       sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Mid Right Half Back',
    //   },
    {
        field: 'timePos__afl_left_wing',
        name: 'Left wing',
        header: 'LW',
        shortName: 'LW',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_left_wing',
        name: 'Count Field Time',
        header: 'SLW',
        shortName: 'SLW',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //    {
    //        field: 'timePos__afl_attacking_left_wing',
    //        name: 'Attacking left wing',
    //        header: 'ALW',
    //        shortName: 'ALW',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Attacking Left Wing',
    //    },
    //    {
    //        field: 'countStintPos__afl_attacking_left_wing',
    //        name: 'Count Attacking left wing',
    //        header: 'CALW',
    //        shortName: 'CALW',
    //       sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Attacking Left Wing',
    //    },
    //    {
    //        field: 'timePos__afl_defensive_left_wing',
    //        name: 'Defensive left wing',
    //        header: 'DLW',
    //        shortName: 'DLW',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Defensive Left Wing',
    //    },
    //    {
    //        field: 'countStintPos__afl_defensive_left_wing',
    //        name: 'Count Defensive left wing',
    //        header: 'CDLW',
    //        shortName: 'CDLW',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Defensive Left Wing',
    //    },
    {
        field: 'timePos__afl_ruck',
        name: 'Ruck',
        description: '',
        header: 'RU',
        shortName: 'RU',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_ruck',
        name: 'Count Ruck',
        description: '',
        header: 'SRU',
        shortName: 'SRU',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_mid_1',
        name: 'Mid 1',
        description: '',
        header: 'MID1',
        shortName: 'MID1',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_mid_1',
        name: 'Count Mid 1',
        description: '',
        header: 'SMID1',
        shortName: 'SMID1',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_mid_2',
        name: 'Mid 2',
        description: '',
        header: 'MID2',
        shortName: 'MID2',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_mid_2',
        name: 'Count Mid 2',
        description: '',
        header: 'SMID2',
        shortName: 'SMID2',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_mid_3',
        name: 'Mid 3',
        description: '',
        header: 'MID3',
        shortName: 'MID3',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_mid_3',
        name: 'Count Mid 3',
        description: '',
        header: 'SMID3',
        shortName: 'SMID3',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_right_wing',
        name: 'Right wing',
        description: '',
        header: 'RW',
        shortName: 'RW',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_right_wing',
        name: 'Count Right wing',
        description: '',
        header: 'SRW',
        shortName: 'SRW',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //    {
    //        field: 'timePos__afl_attacking_right_wing',
    //        name: 'Attacking right wing',
    //        header: 'ARW',
    //        shortName: 'ARW',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Attacking Right Wing',
    //    },
    //    {
    //        field: 'countStintPos__afl_attacking_right_wing',
    //        name: 'Count Field Time',
    //        header: 'CFLD',
    //        shortName: 'CFLD',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Attacking Right Wing',
    //    },
    //    {
    //        field: 'timePos__afl_defensive_right_wing',
    //        name: 'Defensive right wing',
    //        header: 'DRW',
    //        shortName: 'DRW',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Defensive Right Wing',
    //    },
    //    {
    //        field: 'countStintPos__afl_defensive_right_wing',
    //        name: 'Count Defensive right wing',
    //        header: 'CDRW',
    //        shortName: 'CDRW',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Defensive Right Wing',
    //    },
    {
        field: 'timePos__afl_left_half_forward',
        name: 'Left half forward',
        description: '',
        header: 'LHF',
        shortName: 'LHF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_left_half_forward',
        name: 'Count Left half forward',
        description: '',
        header: 'SLHF',
        shortName: 'SLHF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //   {
    //        field: 'timePos__afl_mid_left_half_forward',
    //        name: 'Mid-left half forward',
    //        header: 'MLHF',
    //        shortName: 'MLHF',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Mid-Left Half Forward',
    //    },
    //    {
    //        field: 'countStintPos__afl_mid_left_half_forward',
    //        name: 'Count Mid-left half forward',
    //        header: 'CMLHF',
    //        shortName: 'CMLHF',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Mid-Left Half Forward',
    //    },
    {
        field: 'timePos__afl_centre_half_forward',
        name: 'Centre half forward',
        description: '',
        header: 'CHF',
        shortName: 'CHF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_centre_half_forward',
        name: 'Count Centre half forward',
        description: '',
        header: 'SCHF',
        shortName: 'SCHF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //    {
    //        field: 'timePos__afl_mid_centre_half_forward',
    //        name: 'Mid centre half forward',
    //        header: 'MCHF',
    //        shortName: 'MCHF',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Mid Centre Half Forward',
    //    },
    //    {
    //        field: 'countStintPos__afl_mid_centre_half_forward',
    //        name: 'Count Field Time',
    //        header: 'CFLD',
    //        shortName: 'CFLD',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Mid Centre Half Forward',
    //    },
    {
        field: 'timePos__afl_right_half_forward',
        name: 'Right half forward',
        description: '',
        header: 'RHF',
        shortName: 'RHF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_right_half_forward',
        name: 'Count Right half forward',
        description: '',
        header: 'SRHF',
        shortName: 'SRHF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    //    {
    //        field: 'timePos__afl_mid_right_half_forward',
    //        name: 'Mid-right half forward',
    //        header: 'MRHF',
    //        shortName: 'MRHF',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Time in Mid-Right Half Forward',
    //    },
    //    {
    //        field: 'countStintPos__afl_mid_right_half_forward',
    //        name: 'Count Mid-right half forward',
    //        header: 'CMRHF',
    //        shortName: 'CMRHF',
    //        sortable: true,
    //        body: timeCell,
    //        headerTooltip: 'Stints in Mid-Right Half Forward',
    //    },
    {
        field: 'timePos__afl_left_forward_pocket',
        name: 'Left forward pocket',
        description: '',
        header: 'LFP',
        shortName: 'LFP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_left_forward_pocket',
        name: 'Count Left forward pocket',
        description: '',
        header: 'SLFP',
        shortName: 'SLFP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_full_forward',
        name: 'Full forward',
        description: '',
        header: 'FF',
        shortName: 'FF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_full_forward',
        name: 'Count Full forward',
        description: '',
        header: 'SFF',
        shortName: 'SFF',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'timePos__afl_right_forward_pocket',
        name: 'Right forward pocket',
        description: '',
        header: 'RFP',
        shortName: 'RFP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
    {
        field: 'countStintPos__afl_right_forward_pocket',
        name: 'Count Right Forward Pocket',
        description: '',
        header: 'SRFP',
        shortName: 'SRFP',
        sortable: true,
        body: timeCell,
        headerTooltip: '',
    },
];
