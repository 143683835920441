export interface GoogleLocation {
    address: string;
    locality: string;
    adminArea1Short: string;
    adminArea1Long: string;
    postalCode: string;
    countryShort: string;
    countryLong: string;
    placeID: string;
    formattedAddress: string;
    latitude: string;
    longitude: string;
    name: string;
    streetNumberShort: string;
    streetNumberLong: string;
    routeShort: string;
    routeLong: string;
}

const useGooglePlaceAutoComplete = () => {
    const search = async (query: any) => {
        return new Promise((resolve, reject) => {
            const service = new window.google.maps.places.PlacesService(
                document.createElement('div')
            );
            service.textSearch({ query }, (results, status) => {
                if (
                    status ===
                        window.google.maps.places.PlacesServiceStatus.OK &&
                    results &&
                    results.length
                ) {
                    resolve(results);
                } else {
                    reject(new Error('Failed to find location.'));
                }
            });
        });
    };

    const getDetails = async (placeId: string) => {
        return new Promise((resolve, reject) => {
            const service = new window.google.maps.places.PlacesService(
                document.createElement('div')
            );
            service.getDetails(
                {
                    placeId,
                    fields: [
                        'formatted_address',
                        'address_component',
                        'geometry',
                        'place_id',
                        'name',
                    ],
                },
                (place, status) => {
                    if (
                        status ===
                        window.google.maps.places.PlacesServiceStatus.OK
                    ) {
                        resolve(place);
                    } else {
                        reject(new Error('Failed to fetch location details.'));
                    }
                }
            );
        });
    };

    const initAutoComplete = async (
        input: HTMLInputElement,
        callback: Function
    ) => {
        let autoComplete = new window.google.maps.places.Autocomplete(input, {
            // limit to Australia for now
            componentRestrictions: { country: ['au'] },
            fields: [
                'formatted_address',
                'address_component',
                'geometry',
                'place_id',
                'name',
            ],
            types: ['address'],
        });
        autoComplete.addListener('place_changed', callback);

        return autoComplete;
    };

    const getFullAddress = async (
        autoComplete: any
    ): Promise<GoogleLocation> => {
        const place = autoComplete.name
            ? autoComplete
            : autoComplete.getPlace();

        let address,
            locality,
            adminArea1Short,
            adminArea1Long,
            countryShort,
            countryLong,
            postalCode = '',
            streetNumberShort,
            streetNumberLong,
            routeShort,
            routeLong,
            placeID = place && place.place_id,
            formattedAddress = place && place.formatted_address,
            latitude = place && place.geometry.location.lat(),
            longitude = place && place.geometry.location.lng(),
            name = place && place.name;

        // Get each component of the address from the place details,
        for (const component of place.address_components) {
            const componentType = component.types[0];

            if (componentType === 'street_number') {
                address = component.long_name;
            }
            if (componentType === 'route') {
                address = `${address} ${component.long_name}`;
            }
            if (componentType === 'locality') {
                locality = component.long_name;
            }
            if (componentType === 'administrative_area_level_1') {
                adminArea1Short = component.short_name;
                adminArea1Long = component.long_name;
            }
            if (componentType === 'postal_code') {
                postalCode = component.long_name;
            }
            if (componentType === 'postal_code_suffix') {
                postalCode = `${postalCode}-${component.long_name}`;
            }
            if (componentType === 'country') {
                countryShort = component.short_name;
                countryLong = component.long_name;
            }

            if (componentType === 'street_number') {
                streetNumberShort = component.short_name;
                streetNumberLong = component.long_name;
            }

            if (componentType === 'route') {
                routeShort = component.short_name;
                routeLong = component.long_name;
            }
        }

        let resAddress = {
            address,
            locality,
            adminArea1Short,
            adminArea1Long,
            postalCode,
            countryShort,
            countryLong,
            placeID,
            formattedAddress,
            latitude,
            longitude,
            name,
            streetNumberShort,
            streetNumberLong,
            routeShort,
            routeLong,
        };

        return resAddress;
    };

    return {
        initAutoComplete,
        getFullAddress,
        search,
        getDetails,
    };
};

export default useGooglePlaceAutoComplete;
