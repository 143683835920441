import { useParams } from 'react-router-dom';

import {
    useCreateBillingSessionMutation,
    useGetSubscriptionsQuery,
    useLazyGetSubscriptionsQuery,
} from '../../api/licences';

import SubscriptionsView from './SubscriptionsView';

import { User } from '../../types/user';
import { Route } from '../../types/route';
import { Roles } from '../../types/roles';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const SubscriptionsContainer = (props: Props) => {
    const { user } = props;
    const { userID } = useParams();

    const [refetch] = useLazyGetSubscriptionsQuery();

    const { data, isLoading, isError, isFetching } = useGetSubscriptionsQuery({
        userID,
    });

    const [fetchBillingUrl] = useCreateBillingSessionMutation();

    const handleManageBilling = () => {
        fetchBillingUrl({
            userID: user.userID,
            returnURL: window.location.href,
        }).then((response) => {
            if (!('error' in response)) {
                window.location.href = response.data.data.url;
            }
        });
    };

    const handleLoadMore = () => {
        const subscriptions = data.data.data;

        if (subscriptions.length > 0) {
            refetch({
                userID: user.userID,
                cursor: subscriptions[subscriptions.length - 1].id,
            });
        }
    };

    return (
        <SubscriptionsView
            data={data ? data.data.data : []}
            isLoading={isLoading}
            isError={isError}
            isFetching={isFetching}
            onManageClick={handleManageBilling}
            onLoadMore={handleLoadMore}
            showPagination={data?.data.has_more}
            {...props}
        />
    );
};

export default SubscriptionsContainer;
