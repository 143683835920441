import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isNull, orderBy } from 'lodash';
import { ProgressBar } from 'primereact/progressbar';

import { useLazyGetOrganisationTeamsQuery } from '../../api/organisations';

import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { niceFlags } from './configAssocENV';

import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import { Column, ColumnSortEvent } from 'primereact/column';
import {
    DataTable,
    DataTableExpandedRows,
    DataTableRowEvent,
    DataTableValueArray,
} from 'primereact/datatable';
import { Image } from 'primereact/image';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';

import { useGetAssociationOrganisationsQuery } from '../../api/associations';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const AssocOrgSummaryReport = () => {
    const { associationID } = useParams();

    const [organisationTeamsData, setOrganisationTeamsData] = useState<{
        [key: string]: any[];
    }>({});

    const [expandedRows, setExpandedRows] = useState<
        DataTableExpandedRows | DataTableValueArray | undefined
    >(undefined);

    // API Hooks
    // Get organisations under association ID
    const organisationsData = useGetAssociationOrganisationsQuery({
        associationID,
    });

    const [fetchOrgTeams] = useLazyGetOrganisationTeamsQuery();

    const organisations = useMemo(
        () => organisationsData.data?.data || [],
        [organisationsData]
    );

    // Fetch organisation Teams with expanded report
    useEffect(() => {
        const fetchOrganisationsTeams = async () => {
            for (const organisation of organisations) {
                const { organisationID } = organisation;
                const organisationTeamResult = await fetchOrgTeams({
                    organisationID,
                    cursor: '',
                    expand: 'teamSeasonSummary',
                });

                const orgTeams = organisationTeamResult.data?.data;

                if (orgTeams) {
                    setOrganisationTeamsData((prev) => ({
                        ...prev,
                        [organisationID]: {
                            ...prev[organisationID],
                            ...organisation,
                            teams: orgTeams,
                        },
                    }));
                }

                await delay(1000); // Throttle requests

                // Increment team count
                setTeamCount((prevCount) => prevCount + 1);
            }
        };

        if (organisations && organisations.length > 0) {
            fetchOrganisationsTeams();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations]);

    // Get report of active season, if not yet fetched already
    const [organisationCount, setTeamCount] = useState(0);
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [loading, setLoading] = useState(true);

    // Calculate loading percentage and update loading state
    useEffect(() => {
        if (organisations.length > 0) {
            const newLoadingPercentage = Math.round(
                (organisationCount / organisations.length) * 100
            );
            setLoadingPercentage(newLoadingPercentage);

            if (newLoadingPercentage >= 100) {
                setLoading(false);
            }
        }
    }, [organisationCount, organisations.length]);

    const renderRating = (value: any) => {
        if (value === undefined || value === null || isNaN(value)) {
            return (
                <Tag
                    style={{
                        width: '40px',
                        height: '40px',
                        fontSize: '16px',
                        color: '#fff',
                        background: '#808080',
                    }}
                >
                    NA
                </Tag>
            );
        }

        let rating = {
            label: '',
            color: '',
        };

        switch (true) {
            case value < 70:
                rating = {
                    label: 'Below Average',
                    color: '#CD2335',
                };
                break;

            case value < 80:
                rating = {
                    label: 'Excellent',
                    color: '#F08036',
                };
                break;
            case value < 90:
                rating = {
                    label: 'Excellent',
                    color: '#EDB024',
                };
                break;
            case value <= 100:
                rating = {
                    label: 'Excellent',
                    color: '#6FBF42',
                };
                break;
        }

        return (
            <Tag
                style={{
                    width: '40px',
                    height: '40px',
                    fontSize: '17px',
                    color: '#fff',
                    background: rating.color,
                }}
            >
                {Math.round(value)}
            </Tag>
        );
    };

    const renderDiscRating = (value: any) => {
        if (value === undefined || value === null || isNaN(value)) {
            return (
                <Tag
                    style={{
                        width: '40px',
                        height: '40px',
                        fontSize: '16px',
                        color: '#fff',
                        background: '#808080',
                    }}
                >
                    NA
                </Tag>
            );
        }

        let rating = {
            label: '',
            color: '',
        };

        switch (true) {
            case value < 94:
                rating = {
                    label: 'Below Average',
                    color: '#CD2335',
                };
                break;

            case value < 96:
                rating = {
                    label: 'Excellent',
                    color: '#F08036',
                };
                break;
            case value < 99:
                rating = {
                    label: 'Excellent',
                    color: '#EDB024',
                };
                break;
            case value <= 100:
                rating = {
                    label: 'Excellent',
                    color: '#6FBF42',
                };
                break;
        }

        return (
            <Tag
                style={{
                    width: '40px',
                    height: '40px',
                    fontSize: '17px',
                    color: '#fff',
                    background: rating.color,
                }}
            >
                {Math.round(value)}
            </Tag>
        );
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    // Function to count flags that match the niceFlags keys
    const countMatchingFlags = (flags: { [key: string]: string[] }) => {
        if (!flags) return 0;

        let totalCount = 0;

        Object.keys(flags).forEach((key) => {
            if (key in niceFlags) {
                totalCount += flags[key].length;
            }
        });

        return totalCount;
    };
    // Define the row expansion template

    const onRowExpand = (event: DataTableRowEvent) => {
        // Handle row expand event
    };

    const onRowCollapse = (event: DataTableRowEvent) => {
        // Handle row collapse event
    };

    const allowExpansion = (rowData: any) => {
        // Define logic to determine if the row can be expanded
        return rowData.teams.length > 0; // Example condition
    };

    const calculateEnvScore = (rowData: any) => {
        const teams = rowData.teams || [];
        // Sum the noEvents for all teams
        const totalNoEvents = teams.reduce((sum: number, team: any) => {
            const noEvents =
                team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
            return sum + noEvents;
        }, 0);

        // Calculate MGT
        const mgtValue = (() => {
            let pBelowScore = 0;
            // Iterate over each team
            teams.forEach((team: any) => {
                const pBelowList =
                    team.defaultSeasonDetails?.teamSeasonSummary
                        ?.pBelowTarget || {};

                const countMap = new Map();

                // Calculate pBelowScore for the current team
                let teamScore =
                    team.defaultSeasonDetails?.teamSeasonSummary?.noEvents * 4;
                const isEmptyObject = (obj: any) => {
                    return Object.keys(obj).length === 0;
                };

                if (!isEmptyObject(pBelowList)) {
                    Object.keys(pBelowList).forEach((key) => {
                        pBelowList[key].forEach((item: any) => {
                            if (countMap.has(item)) {
                                countMap.set(item, countMap.get(item) + 1);
                            } else {
                                countMap.set(item, 1);
                            }
                        });
                    });

                    countMap.forEach((count, item) => {
                        if (count > 3) {
                            teamScore -= 4;
                        } else if (count === 3) {
                            teamScore -= 3;
                        } else if (count === 2) {
                            teamScore -= 2;
                        } else if (count === 1) {
                            teamScore -= 1;
                        }
                    });
                }
                if (isNaN(teamScore)) {
                    teamScore = 0;
                }
                pBelowScore += teamScore; // Accumulate score from each team
            });
            const totalEvents =
                teams.reduce(
                    (acc: any, team: any) =>
                        acc +
                        (team.defaultSeasonDetails?.teamSeasonSummary
                            ?.noEvents || 0),
                    0
                ) * 4;
            return totalEvents > 0 ? (pBelowScore / totalEvents) * 100 : 0;
        })();

        // Calculate FGT
        const fgtValue = (() => {
            const totalNoEvents = teams.reduce((sum: number, team: any) => {
                const noEvents =
                    team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
                return sum + noEvents;
            }, 0);

            const netFGTScore = teams.reduce((sum: number, team: any) => {
                const fgtScore =
                    team.defaultSeasonDetails?.teamSeasonSummary
                        ?.fairGameTime || 0;

                const flags =
                    team.defaultSeasonDetails?.teamSeasonSummary?.flags || {};
                const fgtIsNA = Array.isArray(flags.fgtIsNA)
                    ? flags.fgtIsNA.length
                    : 0;

                const totalNoEvents =
                    team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;

                const avgFGTScore = fgtScore / (totalNoEvents - fgtIsNA);

                // Check if avgFGTScore is NaN, if not add it to the sum, otherwise add 0
                return sum + (isNaN(avgFGTScore) ? 0 : avgFGTScore);
            }, 0);

            const countTeamsWithGames = teams.reduce(
                (sum: number, team: any) => {
                    // Check if defaultSeasonDetails exists and is not empty
                    const flags =
                        team.defaultSeasonDetails?.teamSeasonSummary?.flags ||
                        {};
                    const fgtIsNA = Array.isArray(flags.fgtIsNA)
                        ? flags.fgtIsNA.length
                        : 0;

                    let hasGames =
                        team.defaultSeasonDetails?.teamSeasonSummary?.noEvents -
                            fgtIsNA >
                        0
                            ? 1
                            : 0;

                    return sum + hasGames;
                },
                0
            );

            const avgFGT =
                countTeamsWithGames > 0
                    ? (netFGTScore / countTeamsWithGames) * 100
                    : null;
            if (totalNoEvents === 0) {
                return 0;
            }

            // Ensure totalCount is a valid number and return a number
            return !isNull(avgFGT) ? parseFloat(avgFGT.toFixed(1)) : 0; // Convert to number, not string
        })();

        const intValue = (() => {
            const flagsCount = teams.reduce((sum: number, team: any) => {
                const flags =
                    team.defaultSeasonDetails?.teamSeasonSummary?.flags || {};

                const countOfFlags = countMatchingFlags(flags);

                const noEvents =
                    team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;

                const avgIntScore = noEvents * 5 - countOfFlags;

                // Assuming countMatchingFlags is a function that takes `flags` and returns a number
                return sum + avgIntScore;
            }, 0); // Changed from null to 0 to maintain numeric context

            const countOrgGames = teams.reduce((sum: number, team: any) => {
                const hasGames =
                    team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
                return sum + hasGames;
            }, 0);

            const totalCount =
                countOrgGames !== 0 ? flagsCount / countOrgGames : 0;

            // Ensure totalCount is a valid number and return a number
            return !isNaN(totalCount) ? parseFloat(totalCount.toFixed(1)) : 0; // Convert to number, not string
        })();

        // Calculate DIS
        const disValue = (() => {
            const countMap = new Map<string, number>();
            let discScore = teams.reduce(
                (acc: any, team: any) =>
                    acc +
                    (team.defaultSeasonDetails?.teamSeasonSummary?.noEvents ||
                        0) *
                        2,
                0
            );

            teams.forEach((team: any) => {
                const discList =
                    team.defaultSeasonDetails?.teamSeasonSummary
                        ?.disciplineList || {};
                if (Object.keys(discList).length > 0) {
                    Object.keys(discList).forEach((key) => {
                        discList[key].forEach((item: any) => {
                            if (countMap.has(item)) {
                                countMap.set(item, countMap.get(item)! + 1);
                            } else {
                                countMap.set(item, 1);
                            }
                        });
                    });
                }
            });
            countMap.forEach((count) => {
                if (count > 1) discScore -= 2;
                else if (count === 1) discScore -= 1;
            });
            const totalEvents =
                teams.reduce(
                    (acc: any, team: any) =>
                        acc +
                        (team.defaultSeasonDetails?.teamSeasonSummary
                            ?.noEvents || 0),
                    0
                ) * 2;
            return totalEvents > 0 ? (discScore / totalEvents) * 100 : 0;
        })();

        if (totalNoEvents === 0) {
            return null;
        }

        return Number(
            (
                (mgtValue * 0.4 || 0) +
                (fgtValue * 0.4 || 0) +
                ((intValue / 5) * 100 * 0.1 || 0) +
                (disValue * 0.1 || 0)
            ).toFixed(0)
        );
    };

    const calculateMgtScore = (rowData: any) => {
        const teams = rowData.teams || [];

        // Initialize pBelowScore
        let pBelowScore = 0;

        // Iterate over each team
        teams.forEach((team: any) => {
            const pBelowList =
                team.defaultSeasonDetails?.teamSeasonSummary?.pBelowTarget ||
                {};

            const countMap = new Map();

            // Calculate pBelowScore for the current team

            const flags =
                team.defaultSeasonDetails?.teamSeasonSummary?.flags || {};

            const fgtIsNA = Array.isArray(flags.fgtIsNA)
                ? flags.fgtIsNA.length
                : 0;

            // Check if avgFGTScore is NaN, if not add it to the sum, otherwise add 0
            let maxTeamScore =
                (team.defaultSeasonDetails?.teamSeasonSummary?.noEvents -
                    fgtIsNA) *
                4;
            let teamScore =
                (team.defaultSeasonDetails?.teamSeasonSummary?.noEvents -
                    fgtIsNA) *
                4;
            const isEmptyObject = (obj: any) => {
                return Object.keys(obj).length === 0;
            };

            if (!isEmptyObject(pBelowList)) {
                Object.keys(pBelowList).forEach((key) => {
                    pBelowList[key].forEach((item: any) => {
                        if (countMap.has(item)) {
                            countMap.set(item, countMap.get(item) + 1);
                        } else {
                            countMap.set(item, 1);
                        }
                    });
                });

                countMap.forEach((count, item) => {
                    if (count > 3) {
                        teamScore -= 4;
                    } else if (count === 3) {
                        teamScore -= 3;
                    } else if (count === 2) {
                        teamScore -= 2;
                    } else if (count === 1) {
                        teamScore -= 1;
                    }
                });
            }
            if (isNaN(teamScore) || maxTeamScore === 0) {
                teamScore = 0;
                maxTeamScore = 1;
            }

            pBelowScore += teamScore / maxTeamScore; // Accumulate score from each team
        });

        const totalTeams = teams.reduce((acc: number, team: any) => {
            const flags =
                team.defaultSeasonDetails?.teamSeasonSummary?.flags || {};

            const fgtIsNA = Array.isArray(flags.fgtIsNA)
                ? flags.fgtIsNA.length
                : 0;

            const noEvents =
                team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;

            // Add to accumulator if condition is met
            return acc + (noEvents - fgtIsNA > 0 ? 1 : 0);
        }, 0);

        if (totalTeams === 0) {
            return null;
        }

        return totalTeams
            ? Number(((pBelowScore / totalTeams) * 100).toFixed(0))
            : '0';
    };

    const calculateFgtScore = (rowData: any) => {
        const fgtValue = (() => {
            const teams = rowData.teams || [];

            // Calculate total number of events
            const totalNoEvents = teams.reduce((sum: number, team: any) => {
                const noEvents =
                    team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
                return sum + noEvents;
            }, 0);

            // Calculate net Fair Game Time (FGT) score
            const netFGTScore = teams.reduce((sum: number, team: any) => {
                const fgtScore =
                    team.defaultSeasonDetails?.teamSeasonSummary
                        ?.fairGameTime || 0;

                const flags =
                    team.defaultSeasonDetails?.teamSeasonSummary?.flags || {};
                const fgtIsNA = Array.isArray(flags.fgtIsNA)
                    ? flags.fgtIsNA.length
                    : 0;

                const teamNoEvents =
                    team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
                const avgFGTScore =
                    teamNoEvents > fgtIsNA
                        ? fgtScore / (teamNoEvents - fgtIsNA)
                        : 0;

                // Check if avgFGTScore is NaN, if not add it to the sum, otherwise add 0
                return sum + (isNaN(avgFGTScore) ? 0 : avgFGTScore);
            }, 0);

            // Count teams with games (non-NA events)
            const countTeamsWithGames = teams.reduce(
                (sum: number, team: any) => {
                    const flags =
                        team.defaultSeasonDetails?.teamSeasonSummary?.flags ||
                        {};
                    const fgtIsNA = Array.isArray(flags.fgtIsNA)
                        ? flags.fgtIsNA.length
                        : 0;

                    const teamNoEvents =
                        team.defaultSeasonDetails?.teamSeasonSummary
                            ?.noEvents || 0;
                    let hasGames = teamNoEvents - fgtIsNA > 0 ? 1 : 0;

                    return sum + hasGames;
                },
                0
            );

            // Calculate average Fair Game Time score
            const avgFGT =
                countTeamsWithGames > 0
                    ? (netFGTScore / countTeamsWithGames) * 100
                    : null;

            if (totalNoEvents === 0) {
                return 0;
            }

            // Ensure avgFGT is a valid number, return parsed float
            return avgFGT !== null ? parseFloat(avgFGT.toFixed(1)) : 0;
        })();
        if (fgtValue === 0) {
            return null;
        }
        // Use the computed fgtValue here
        return fgtValue;
    };

    const calculateGamesCount = (rowData: any) => {
        // Ensure that rowData.teams exists and is an array
        const teams = rowData.teams || [];

        // Sum the noEvents for all teams
        const totalNoEvents = teams.reduce((sum: number, team: any) => {
            const noEvents =
                team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
            return sum + noEvents;
        }, 0);

        return totalNoEvents;
    };

    const calculateDisScore = (rowData: any) => {
        // Ensure that rowData.teams exists and is an array
        const teams = rowData.teams || [];

        const isEmptyObject = (obj: any) => {
            return !Array.isArray(obj) && Object.keys(obj).length === 0;
        };
        let discScore = 0; // This is the main discScore that will accumulate across all teams

        // Iterate over each team to process their discipline list
        teams.forEach((team: any) => {
            const countMap = new Map();

            let maxDiscScore =
                (team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0) *
                2;
            let teamDiscScore = maxDiscScore; // Use a temporary variable to store discScore for each team

            const discList =
                team.defaultSeasonDetails?.teamSeasonSummary?.disciplineList ||
                {};

            if (!isEmptyObject(discList)) {
                Object.keys(discList).forEach((key) => {
                    discList[key].forEach((item: any) => {
                        // If the item already exists in the countMap, increment its count
                        // Otherwise, initialize it to 1
                        if (countMap.has(item)) {
                            countMap.set(item, countMap.get(item) + 1);
                        } else {
                            countMap.set(item, 1);
                        }
                    });
                });
            }

            // Adjust teamDiscScore based on the counts in countMap
            countMap.forEach((count, item) => {
                if (count > 1) {
                    teamDiscScore -= 2;
                } else if (count === 1) {
                    teamDiscScore -= 1;
                }
            });
            if (isNaN(maxDiscScore) || maxDiscScore === 0) {
                maxDiscScore = 1;
            }
            // Accumulate the team's discScore into the main discScore
            discScore += teamDiscScore / maxDiscScore;
        });

        // Adjust the discScore based on the counts in countMap

        // Calculate the total number of events across all teams for normalization
        const totalEvents = teams.reduce(
            (acc: number, team: any) =>
                acc +
                (team.defaultSeasonDetails?.teamSeasonSummary?.noEvents > 0
                    ? 1
                    : 0),
            0
        );
        if (totalEvents === 0) {
            return null;
        }
        // Return the calculated percentage score
        return Number(((discScore / totalEvents) * 100).toFixed(0));
    };

    const calculateIntScore = (rowData: any) => {
        // Ensure that rowData.teams exists and is an array
        const teams = rowData.teams || [];

        // Accumulate the count of matching flags
        const flagsCount = teams.reduce((sum: number, team: any) => {
            const flags =
                team.defaultSeasonDetails?.teamSeasonSummary?.flags || {};

            const countOfFlags = countMatchingFlags(flags);

            let noEvents =
                team.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;

            const avgIntScore = noEvents * 5 - countOfFlags;

            if (noEvents === 0) {
                noEvents = 1;
            }

            // Assuming countMatchingFlags is a function that takes `flags` and returns a number
            return sum + avgIntScore / noEvents;
        }, null);

        const countOrgWithGames = teams.reduce(
            (acc: number, team: any) =>
                acc +
                (team.defaultSeasonDetails?.teamSeasonSummary?.noEvents > 0
                    ? 1
                    : 0),
            0
        );

        const totalCount = Number(flagsCount) / countOrgWithGames;

        // Return the formatted total count
        return isNaN(totalCount) ? null : Number(totalCount.toFixed(1));
    };

    const calculateTeamEnvScore = (rowData: any) => {
        // For minimum Game Time
        const pBelowList =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.pBelowTarget || {};

        const countMap = new Map();
        let pBelowScore =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents * 4;
        const isEmptyObject = (obj: any) => {
            return Object.keys(obj).length === 0;
        };
        if (!isEmptyObject(pBelowList)) {
            Object.keys(pBelowList).forEach((key) => {
                if (Array.isArray(pBelowList[key])) {
                    pBelowList[key].forEach((item: any) => {
                        if (countMap.has(item)) {
                            countMap.set(item, countMap.get(item) + 1);
                        } else {
                            countMap.set(item, 1);
                        }
                    });
                } else {
                }
            });

            countMap.forEach((count, item) => {
                if (count > 3) {
                    pBelowScore = pBelowScore - 4;
                } else if (count === 3) {
                    pBelowScore = pBelowScore - 3;
                } else if (count === 2) {
                    pBelowScore = pBelowScore - 2;
                } else if (count === 1) {
                    pBelowScore = pBelowScore - 1;
                }
            });
        }

        const minGT =
            (pBelowScore /
                (rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents *
                    4)) *
            100;

        // For Fair Game Time
        const fairGameTime =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.fairGameTime || 0;
        const noEvents =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
        const fgtNAEvents = Array.isArray(
            rowData.defaultSeasonDetails?.teamSeasonSummary?.flags?.fgtIsNA
        )
            ? rowData.defaultSeasonDetails.teamSeasonSummary.flags.fgtIsNA
                  .length
            : 0;
        const fgt: number =
            noEvents !== 0
                ? (fairGameTime / (noEvents - fgtNAEvents)) * 100
                : 0;

        // For Intergity Score
        const flags =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.flags || {};
        // Get the sum of all matching flags
        const intScore =
            5 -
            countMatchingFlags(flags) /
                rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents;

        // For Discipline Score
        const discList =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.disciplineList ||
            {};

        // Initialize a Map to track the counts of each unique string
        const countThisMap = new Map();
        let discScore =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents * 2;

        if (!isEmptyObject(discList)) {
            // Iterate over each array in discList
            Object.keys(discList).forEach((key) => {
                discList[key].forEach((item: any) => {
                    // If the item already exists in the countMap, increment its count
                    // Otherwise, initialize it to 1
                    if (countThisMap.has(item)) {
                        countThisMap.set(item, countThisMap.get(item) + 1);
                    } else {
                        countThisMap.set(item, 1);
                    }
                });
            });

            countThisMap.forEach((count, item) => {
                if (count > 1) {
                    discScore = discScore - 2;
                } else if (count === 1) {
                    discScore = discScore - 1;
                }
            });
        }

        const disciplineScore =
            (discScore /
                (rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents *
                    2)) *
            100;
        let envScore;

        if (fgt === 0 || isNaN(fgt)) {
            envScore = (intScore / 5) * 100 * 0.5 + disciplineScore * 0.5;
        } else {
            envScore =
                minGT * 0.4 +
                fgt * 0.4 +
                (intScore / 5) * 100 * 0.1 +
                disciplineScore * 0.1;
        }

        if (noEvents === 0) {
            return null;
        }
        return envScore;
    };

    const calculateTeamMgtScore = (rowData: any) => {
        const pBelowList =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.pBelowTarget || {};
        const fgtNAEvents = Array.isArray(
            rowData.defaultSeasonDetails?.teamSeasonSummary?.flags?.fgtIsNA
        )
            ? rowData.defaultSeasonDetails.teamSeasonSummary.flags.fgtIsNA
                  .length
            : 0;
        const countMap = new Map();
        let pBelowScore =
            (rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents -
                fgtNAEvents) *
            4;
        const isEmptyObject = (obj: any) => {
            return Object.keys(obj).length === 0;
        };
        if (!isEmptyObject(pBelowList)) {
            Object.keys(pBelowList).forEach((key) => {
                pBelowList[key].forEach((item: any) => {
                    if (countMap.has(item)) {
                        countMap.set(item, countMap.get(item) + 1);
                    } else {
                        countMap.set(item, 1);
                    }
                });
            });

            countMap.forEach((count, item) => {
                if (count > 3) {
                    pBelowScore = pBelowScore - 4;
                } else if (count === 3) {
                    pBelowScore = pBelowScore - 3;
                } else if (count === 2) {
                    pBelowScore = pBelowScore - 2;
                } else if (count === 1) {
                    pBelowScore = pBelowScore - 1;
                }
            });
        }

        let noEvents =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents -
                fgtNAEvents || 0;

        if (
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents ===
            undefined
        ) {
            return null;
        } else if (noEvents === 0) {
            pBelowScore = 0;
        }

        const score = (pBelowScore / (noEvents * 4)) * 100;

        return isNaN(score) ? null : Number(score.toFixed(0));
    };

    const calculateTeamFgtScore = (rowData: any) => {
        const fairGameTime =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.fairGameTime || 0;
        const noEvents =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
        const fgtNAEvents = Array.isArray(
            rowData.defaultSeasonDetails?.teamSeasonSummary?.flags?.fgtIsNA
        )
            ? rowData.defaultSeasonDetails.teamSeasonSummary.flags.fgtIsNA
                  .length
            : 0;
        const fgt: number =
            noEvents !== 0
                ? (fairGameTime / (noEvents - fgtNAEvents)) * 100
                : 0;
        if (noEvents === 0) {
            return null;
        }
        return isNaN(fgt) ? null : Number(fgt.toFixed(0));
    };

    const calculateTeamIntScore = (rowData: any) => {
        const flags =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.flags || {};
        const noEvents =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
        if (noEvents === 0) {
            return null;
        }
        // Get the sum of all matching flags
        const totalCount =
            5 -
            countMatchingFlags(flags) /
                rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents;

        return Number(totalCount.toFixed(1));
    };

    const calculateTeamDisScore = (rowData: any) => {
        const discList =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.disciplineList ||
            {};

        // Initialize a Map to track the counts of each unique string
        const countMap = new Map();
        let discScore =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents * 2;
        const isEmptyObject = (obj: any) => {
            return !Array.isArray(obj) && Object.keys(obj).length === 0;
        };
        if (!isEmptyObject(discList)) {
            // Iterate over each array in discList
            Object.keys(discList).forEach((key) => {
                discList[key].forEach((item: any) => {
                    // If the item already exists in the countMap, increment its count
                    // Otherwise, initialize it to 1
                    if (countMap.has(item)) {
                        countMap.set(item, countMap.get(item) + 1);
                    } else {
                        countMap.set(item, 1);
                    }
                });
            });

            countMap.forEach((count, item) => {
                if (count > 1) {
                    discScore = discScore - 2;
                } else if (count === 1) {
                    discScore = discScore - 1;
                }
            });
        }
        const noEvents =
            rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents || 0;
        if (noEvents === 0) {
            return null;
        }
        // Return the total count (or you could return countMap to see counts for each string)
        const score =
            (discScore /
                (rowData.defaultSeasonDetails?.teamSeasonSummary?.noEvents *
                    2)) *
            100;

        return isNaN(score) ? null : Number(score.toFixed(0));
    };

    const rowExpansionTemplate = (data: any) => {
        return (
            <div>
                {data.teams.length > 0 ? (
                    <DataTable
                        value={data.teams}
                        stripedRows
                        scrollable
                        scrollHeight="100vh"
                    >
                        <Column
                            align="center"
                            alignHeader="center"
                            headerStyle={{ backgroundColor: 'white' }}
                            sortable
                            field="teamName"
                            header="Team Name"
                        />
                        <Column
                            align="center"
                            alignHeader="center"
                            headerStyle={{ backgroundColor: 'white' }}
                            header="ENV"
                            field="teamEnvScore"
                            sortable
                            sortFunction={({
                                data,
                                order,
                            }: ColumnSortEvent) => {
                                return (
                                    order &&
                                    orderBy(
                                        data,
                                        (o) => {
                                            const score =
                                                calculateTeamEnvScore(o);

                                            return score || 0;
                                        },
                                        order > 0 ? 'asc' : 'desc'
                                    )
                                );
                            }}
                            body={(rowData) => {
                                const score = calculateTeamEnvScore(rowData);

                                return renderRating(score);
                            }}
                        />
                        <Column
                            align="center"
                            alignHeader="center"
                            headerStyle={{ backgroundColor: 'white' }}
                            sortable
                            field="defaultSeasonDetails.teamSeasonSummary.noEvents"
                            header="Games"
                        />
                        <Column
                            align="center"
                            alignHeader="center"
                            headerStyle={{ backgroundColor: 'white' }}
                            header="MGT"
                            field="teamMGTScore"
                            sortable
                            sortFunction={({
                                data,
                                order,
                            }: ColumnSortEvent) => {
                                return (
                                    order &&
                                    orderBy(
                                        data,
                                        (o) => {
                                            const score =
                                                calculateTeamMgtScore(o);

                                            return score || 0;
                                        },
                                        order > 0 ? 'asc' : 'desc'
                                    )
                                );
                            }}
                            body={(rowData) => {
                                const score = calculateTeamMgtScore(rowData);

                                return renderRating(score);
                            }}
                        />
                        <Column
                            align="center"
                            alignHeader="center"
                            headerStyle={{ backgroundColor: 'white' }}
                            header="FGT"
                            field="teamFGTScore"
                            sortable
                            sortFunction={({
                                data,
                                order,
                            }: ColumnSortEvent) => {
                                return (
                                    order &&
                                    orderBy(
                                        data,
                                        (o) => {
                                            const score =
                                                calculateTeamFgtScore(o);

                                            return score || 0;
                                        },
                                        order > 0 ? 'asc' : 'desc'
                                    )
                                );
                            }}
                            body={(rowData) => {
                                const score = calculateTeamFgtScore(rowData);

                                return renderRating(score);
                            }}
                        />
                        <Column
                            align="center"
                            alignHeader="center"
                            headerStyle={{ backgroundColor: 'white' }}
                            header="INT"
                            field="teamIntScore"
                            sortable
                            sortFunction={({
                                data,
                                order,
                            }: ColumnSortEvent) => {
                                return (
                                    order &&
                                    orderBy(
                                        data,
                                        (o) => {
                                            const score =
                                                calculateTeamIntScore(o);

                                            return score || 0;
                                        },
                                        order > 0 ? 'asc' : 'desc'
                                    )
                                );
                            }}
                            body={(rowData) => {
                                const score = calculateTeamIntScore(rowData);

                                return score;
                            }}
                        />
                        <Column
                            align="center"
                            alignHeader="center"
                            headerStyle={{ backgroundColor: 'white' }}
                            header="DIS"
                            field="teamDisScore"
                            sortable
                            sortFunction={({
                                data,
                                order,
                            }: ColumnSortEvent) => {
                                return (
                                    order &&
                                    orderBy(
                                        data,
                                        (o) => {
                                            const score =
                                                calculateTeamDisScore(o);

                                            return score || 0;
                                        },
                                        order > 0 ? 'asc' : 'desc'
                                    )
                                );
                            }}
                            body={(rowData) => {
                                const score = calculateTeamDisScore(rowData);

                                return renderDiscRating(score);
                            }}
                        />
                    </DataTable>
                ) : (
                    <Skeleton width="100%" height="2rem" />
                )}
            </div>
        );
    };

    return (
        <PageContainer>
            <PageHeader title="Environment Report" />
            {loading ? (
                <ProgressBar
                    value={loadingPercentage}
                    style={{ height: '20px' }}
                />
            ) : (
                <DataTable
                    showGridlines
                    value={
                        organisationTeamsData
                            ? Object.values(organisationTeamsData)
                            : []
                    }
                    sortField="organisations.organisationName"
                    sortOrder={1}
                    columnResizeMode="expand"
                    resizableColumns
                    stripedRows={true}
                    footer={tableFooter}
                    scrollable
                    scrollHeight="100vh"
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    onRowExpand={onRowExpand}
                    onRowCollapse={onRowCollapse}
                    rowExpansionTemplate={rowExpansionTemplate}
                >
                    <Column
                        expander={allowExpansion}
                        style={{ width: '3rem' }}
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                    <Column
                        sortable
                        field="organisationName"
                        header="ORGANISATION"
                        headerStyle={{ backgroundColor: 'white' }}
                        body={(row) => {
                            if (!row || !row.organisationName) {
                                return <Skeleton />;
                            }

                            const url = '';

                            return (
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        fontWeight: '600',
                                        color: '#000',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {row.organisationName}
                                </a>
                            );
                        }}
                    />
                    <Column
                        align="center"
                        alignHeader="center"
                        header="ENV"
                        headerStyle={{ backgroundColor: 'white' }}
                        field="env"
                        sortable={true}
                        sortFunction={({ data, order }: ColumnSortEvent) => {
                            return (
                                order &&
                                orderBy(
                                    data,
                                    (o) => {
                                        const score = calculateEnvScore(o);

                                        return score || 0;
                                    },
                                    order > 0 ? 'asc' : 'desc'
                                )
                            );
                        }}
                        body={(rowData) => {
                            const score = calculateEnvScore(rowData);

                            return renderRating(score);
                        }}
                    />

                    <Column
                        align="center"
                        alignHeader="center"
                        sortable
                        headerStyle={{ backgroundColor: 'white' }}
                        field="teams"
                        header="Teams"
                        sortFunction={({ data, order }: ColumnSortEvent) => {
                            return orderBy(
                                data,
                                (o) => (o.teams ? o.teams.length : 0),
                                order!! > 0 ? 'asc' : 'desc'
                            );
                        }}
                        body={(rowData) => {
                            return (
                                <span style={{ fontSize: '17px' }}>
                                    {rowData.teams ? rowData.teams.length : 0}
                                </span>
                            );
                        }}
                    />
                    <Column
                        align="center"
                        alignHeader="center"
                        headerStyle={{ backgroundColor: 'white' }}
                        field="GamesCount"
                        sortable
                        sortFunction={({ data, order }: ColumnSortEvent) => {
                            return (
                                order &&
                                orderBy(
                                    data,
                                    (o) => calculateGamesCount(o) || 0,
                                    order > 0 ? 'asc' : 'desc'
                                )
                            );
                        }}
                        header="Games"
                        body={(rowData) => {
                            const count = calculateGamesCount(rowData);

                            return (
                                <span style={{ fontSize: '17px' }}>
                                    {count}
                                </span>
                            );
                        }}
                    />
                    <Column
                        align="center"
                        alignHeader="center"
                        headerStyle={{ backgroundColor: 'white' }}
                        header="MGT"
                        field="MGT"
                        sortable
                        sortFunction={({ data, order }: ColumnSortEvent) => {
                            return (
                                order &&
                                orderBy(
                                    data,
                                    (o) => calculateMgtScore(o) || 0,
                                    order > 0 ? 'asc' : 'desc'
                                )
                            );
                        }}
                        body={(rowData) => {
                            const score = calculateMgtScore(rowData);

                            return renderRating(score);
                        }}
                    />
                    <Column
                        align="center"
                        alignHeader="center"
                        sortable
                        headerStyle={{ backgroundColor: 'white' }}
                        header="FGT"
                        field="FGT"
                        sortFunction={({ data, order }: ColumnSortEvent) => {
                            return (
                                order &&
                                orderBy(
                                    data,
                                    (o) => calculateFgtScore(o) || 0,
                                    order > 0 ? 'asc' : 'desc'
                                )
                            );
                        }}
                        body={(rowData) => {
                            const score = calculateFgtScore(rowData);

                            return renderRating(score);
                        }}
                    />
                    <Column
                        align="center"
                        alignHeader="center"
                        headerStyle={{ backgroundColor: 'white' }}
                        header="INT"
                        field="Int"
                        sortable
                        sortFunction={({ data, order }: ColumnSortEvent) => {
                            return (
                                order &&
                                orderBy(
                                    data,
                                    (o) => {
                                        const score = calculateIntScore(o);

                                        return score || 0;
                                    },
                                    order > 0 ? 'asc' : 'desc'
                                )
                            );
                        }}
                        body={(rowData) => {
                            const score = calculateIntScore(rowData);

                            return (
                                <span style={{ fontSize: '17px' }}>
                                    {score}
                                </span>
                            );
                        }}
                    />
                    <Column
                        align="center"
                        alignHeader="center"
                        headerStyle={{ backgroundColor: 'white' }}
                        header="DIS"
                        field="Dis"
                        sortable
                        sortFunction={({ data, order }: ColumnSortEvent) => {
                            return (
                                order &&
                                orderBy(
                                    data,
                                    (o) => {
                                        const score = calculateDisScore(o);

                                        return score || 0;
                                    },
                                    order > 0 ? 'asc' : 'desc'
                                )
                            );
                        }}
                        body={(rowData) => {
                            const score = calculateDisScore(rowData);

                            return renderDiscRating(score);
                        }}
                    />
                </DataTable>
            )}
        </PageContainer>
    );
};

export default AssocOrgSummaryReport;
