import { Navigate, NavigateProps } from 'react-router-dom';
import DocumentHead from '../../components/DocumentHead';

const OrganisationHome: React.FC<NavigateProps> = () => {
    return (
        <>
            <DocumentHead
                title="Organisation Home - Rookie Me Hub"
                description="Organisation home page"
            />
            <Navigate to="teams" replace />
        </>
    );
};

export default OrganisationHome;
