import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { PostResponse, PostsResponse } from '../types/posts';
import { BaseEntityType } from '../types/common';
import { isEqual } from 'lodash';

export const postsApi = createApi({
    reducerPath: 'postApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        getPosts: builder.query<
            PostsResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                cursor?: string;
                status?: string;
                limit?: number;
            }
        >({
            query: ({ entityType, entityID, ...params }) => ({
                url: generatePath(apiEndpoints.getPosts.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getPosts.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ postID }) => ({
                              type: 'Post' as const,
                              postID,
                          })),
                          'Post',
                      ]
                    : ['Post'];
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getPost: builder.query<
            PostResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                postID: string;
            }
        >({
            query: ({ entityType, entityID, postID }) => ({
                url: generatePath(apiEndpoints.getPost.url, {
                    entityType,
                    entityID,
                    postID,
                }),
                method: apiEndpoints.getPost.method,
            }),
        }),
        createPost: builder.mutation<
            PostResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                content: string;
            }
        >({
            query: ({ entityType, entityID, ...data }) => ({
                url: generatePath(apiEndpoints.createPost.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createPost.method,
                body: data,
            }),
            invalidatesTags: ['Post'],
        }),
        updatePost: builder.mutation({
            query: ({ entityType, entityID, postID, ...data }) => ({
                url: generatePath(apiEndpoints.updatePost.url, {
                    entityType,
                    entityID,
                    postID,
                }),
                method: apiEndpoints.updatePost.method,
                body: data,
            }),
            invalidatesTags: ['Post'],
        }),
        deletePost: builder.mutation({
            query: ({ entityType, entityID, postID }) => ({
                url: generatePath(apiEndpoints.deletePost.url, {
                    entityType,
                    entityID,
                    postID,
                }),
                method: apiEndpoints.deletePost.method,
            }),
            invalidatesTags: ['Post'],
        }),
    }),
});

export const {
    useGetPostsQuery,
    useGetPostQuery,
    useCreatePostMutation,
    useUpdatePostMutation,
    useDeletePostMutation,
} = postsApi;
