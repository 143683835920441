import { FC } from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';

interface Option {
    label: string;
    value: string;
}

interface Field {
    label: string;
    key: string;
    fieldType: {
        type: string;
        options?: Option[];
    };
}

interface Data {
    [key: string]: any;
}

interface SpreadsheetImportProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: Data, file: File) => void;
    fields: Field[];
}

const SpreadsheetImport: FC<SpreadsheetImportProps> = ({
    isOpen,
    onClose,
    onSubmit,
    fields = [], // Default value for fields prop is an empty array
}) => {
    return (
        // ReactSpreadsheetImport component to handle spreadsheet importing with specified props
        <ReactSpreadsheetImport
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            fields={fields} // Fields prop to specify the fields to be displayed
            autoMapSelectValues={true}
            customTheme={{
                colors: {
                    background: 'white',
                    rsi: {
                        500: '#ff6700',
                        900: '#ff6700',
                        200: '#ff6700',
                        300: '#ff6700',
                        600: '#ff4500',
                        700: '#ff4500',
                    },
                },
                components: {
                    Button: {
                        defaultProps: {
                            colorScheme: 'rsi',
                        },
                    },
                },
            }}
        />
    );
};

export default SpreadsheetImport;
