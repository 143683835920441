import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getEntityFromParam } from '../../util/helper';

import AssociationLicencesView from './AssociationLicencesView';

import {
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
    useGetTransferredLicenceGroupsQuery,
    useTransferLicenceGroupMutation,
} from '../../api/licences';

import {
    LicenceGroupInfo,
    LicenceType,
    RookieProduct,
} from '../../types/licences';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';
import { BaseEntityType } from '../../types/common';
import { useGetEntityQuery } from '../../api/core';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const AssociationLicencesContainer = (props: Props) => {
    const { user } = props;

    // Context hooks
    const toast = useContext(ToastContext);

    // Route Hooks
    const params = useParams();
    const entity = getEntityFromParam(params);
    const navigate = useNavigate();

    // State Hooks
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
    const [activeLicenceGroup, setActiveLicenceGroup] = useState<string>();

    // API Hooks
    const entityData = useGetEntityQuery(
        // @ts-expect-error
        { entityType: entity?.entityType, entityID: entity?.entityID || '' },
        { skip: !entity }
    );
    const { data: licenceGroupsRaw, isLoading: isLoadingGroups } =
        // @ts-ignore
        useGetLicenceGroupsQuery(entity, { skip: !entity });

    const { data: licenceTypes, isLoading: isLoadingTypes } =
        useGetLicenceTypesQuery(
            {
                rookieProduct: RookieProduct.GameDay,
                // @ts-expect-error
                sportID: entityData?.data?.data?.entitySportID,
            },
            { skip: !entityData?.data?.data }
        );

    const { data: transferredRaw } = useGetTransferredLicenceGroupsQuery({
        entityType: BaseEntityType.associations,
        // @ts-ignore
        entityID: params.associationID,
    });

    const [transferLicence] = useTransferLicenceGroupMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleShowAssignModal = (licenceGroupID: string) => {
        setActiveLicenceGroup(licenceGroupID);
        setShowAssignModal(true);
    };

    const handleLicenceGroupClick = (licenceGroup: LicenceGroupInfo) => {
        navigate(licenceGroup.id);
    };

    const handleTransferLicences = (
        licenceGroupID: string,
        organisationID: string
    ) => {
        if (entity && organisationID && licenceGroupID) {
            transferLicence({
                ...entity,
                licenceGroupID,
                targetEntityType: BaseEntityType.organisations,
                targetEntityID: organisationID,
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        detail: 'Success',
                    });
                })
                .catch((err) => {
                    console.log('ERR', err);
                })
                .finally(() => {
                    setShowAssignModal(false);
                });
        }
    };

    const licenceGroups = useMemo<LicenceGroupInfo[]>(() => {
        let arr: LicenceGroupInfo[] = [];

        const nonTransferred = licenceGroupsRaw?.data || [];
        const transferred = transferredRaw?.data || [];

        const allLicenceGroups = [...nonTransferred, ...transferred];

        if (allLicenceGroups.length > 0) {
            allLicenceGroups.forEach((licenceGroup) => {
                const licenceType: LicenceType | undefined =
                    licenceTypes?.data.find(
                        (type) =>
                            licenceGroup.licenceTypeID === type.licenceTypeID
                    );
                if (!licenceType) {
                    return null;
                }
                const { subscription } = licenceGroup;
                const { product } = licenceType;

                if (product) {
                    const price = product.prices.find(
                        (p) => p.priceID === licenceGroup.priceID
                    );
                    arr.push({
                        id: licenceGroup.licenceGroupID,
                        priceID: price?.priceID || '',
                        productTitle: product.productName,
                        licenceTitle: licenceType.licenceName,
                        price: (price && price.unitAmount / 100) || 0,
                        currency: 'AUD',
                        billingPeriod:
                            (price && price.recurring?.interval) || 'unknown', // interval is always month | year
                        renewsAt: subscription
                            ? new Date(subscription.current_period_end * 1000)
                            : null,
                        trialActive:
                            subscription && subscription.trial_end
                                ? new Date().getTime() -
                                      subscription.trial_end * 1000 <
                                  0
                                : false,
                        trialEndsAt:
                            subscription && subscription.trial_end
                                ? new Date(subscription.trial_end * 1000)
                                : null,
                        qtyAvailable:
                            licenceGroup.quantity - licenceGroup.totalAssigned,
                        qtyAssigned: licenceGroup.totalAssigned,
                        qtyTotal: licenceGroup.quantity,
                        status: licenceGroup.status,
                        groupID:
                            licenceGroup.subscriptionID ||
                            licenceGroup.invoiceID ||
                            'unknown',
                        description: product.productDescription,
                        canUpgrade: (price && price.unitAmount < 10000) || true, // hard-code this for now,
                        isOwner:
                            licenceGroup.createdBy === user.userID || false, // hard-code this for now,
                        owner: licenceGroup.createdBy,
                        features: product.productFeatures.map(
                            (feat) => feat.name
                        ),
                        originalEntityID: licenceGroup.originalEntityID,
                        originalEntityType: licenceGroup.originalEntityType,
                        entityID: licenceGroup.entityID,
                        entitySportID: licenceGroup.entitySportID,
                        entityType: licenceGroup.entityType,
                        subscriptionID: licenceGroup.subscriptionID,
                        transactionID: licenceGroup.transactionID,
                    });
                }
            });
        }

        // order by groupID (either subID or invoiceID) - more useful for ass and org
        arr.sort((a, b) =>
            a.groupID === b.groupID ? 0 : a.groupID < b.groupID ? -1 : 1
        );

        return arr;
    }, [licenceGroupsRaw, transferredRaw, licenceTypes, user]);

    return (
        <AssociationLicencesView
            isLoading={isLoadingGroups || isLoadingTypes}
            licenceGroups={licenceGroups}
            onTransferLicences={handleTransferLicences}
            showAssignModal={showAssignModal}
            activeLicenceGroup={activeLicenceGroup}
            onShowAssignModal={handleShowAssignModal}
            onCloseAssignModal={() => setShowAssignModal(false)}
            onLicenceGroupClick={handleLicenceGroupClick}
            showAddModal={showAddModal}
            onShowAddModal={() => setShowAddModal(true)}
            onCloseAddModal={() => setShowAddModal(false)}
            {...props}
        />
    );
};

export default AssociationLicencesContainer;
