import EntityName from '../../components/EntityName';
import PageHeader from '../../layout/PageHeader';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Licence } from '../../types/licences';
import PageContainer from '../../layout/PageContainer';

interface Props {
    licences: Licence[];
    onManageBilling: () => void;
}

const AssociationLicenceSingleView = (props: Props) => {
    const { licences, onManageBilling } = props;

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                actions={[
                    {
                        label: 'Manage',
                        command: () => onManageBilling(),
                        className: 'p-button-secondary',
                    },
                ]}
            />
            <DataTable
                value={licences}
                emptyMessage="No assigned licences"
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    field="entityID"
                    header="Team"
                    body={(data) => (
                        <EntityName
                            entityType={data.entityType}
                            entityID={data.entityID}
                        />
                    )}
                />
            </DataTable>
        </PageContainer>
    );
};

export default AssociationLicenceSingleView;
