import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetRolesQuery } from '../../api/core';

import EntityListingView from './EntityListingView';

import DocumentHead from '../../components/DocumentHead';

const EntityListingContainer = (props) => {
    const { userID } = useParams();

    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState('');

    const { data, isLoading, isError, isFetching } = useGetRolesQuery(
        { userID },
        { skip: !userID }
    );

    return (
        <>
            <DocumentHead
                title="Your Entities - Rookie Me Hub"
                description="Entity listing page"
            />
            <EntityListingView
                data={data ? data.data : {}}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                filter={filter}
                setFilter={setFilter}
                search={search}
                setSearch={setSearch}
                {...props}
            />
        </>
    );
};

export default EntityListingContainer;
