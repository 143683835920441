import { useMemo, useRef, useState } from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { sortBy } from 'lodash';

import OrganisationForm from './OrganisationForm';
import OrganisationAddForm from './OrganisationAddForm';

import ErrorDisplay from '../../components/ErrorDisplay';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import RookieButton from '../../components/RookieButton';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { ERROR_TYPES } from '../../types/common';
import { Mixpanel } from '../../util/mixpanel';
import usePermission from '../../hooks/usePermission';
import ListItem from '../../components/ListItem';
import EntityPreview from '../../components/EntityPreview';

const OrganisationListingView = (props) => {
    const { isError, isFetching, isLoading } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const organisationCodeToast = useRef(null);
    const formResponseToast = useRef();

    const { associationID } = useParams();

    const [searchParams, setSearchParams] = useSearchParams('');

    const { isAdmin } = usePermission(associationID);

    const openCreationPortal = () => {
        if (!searchParams.get('form')) {
            setSearchParams((params) => {
                params.set('form', 'create');
                return params;
            });
            Mixpanel.track('Open Organisation Create');
        }
    };
    const [addAnOrg, setAddAnOrg] = useState(false); // Default to false
    const openAddAnOrgPortal = () => {
        if (!searchParams.get('form')) {
            setSearchParams((params) => {
                params.set('form', 'add');
                return params;
            });
            setAddAnOrg(true);
            Mixpanel.track('Open Organisation Add');
        }
    };

    const closeCreationPortal = () => {
        if (searchParams.get('form')) {
            setSearchParams((params) => {
                params.delete('form');
                return params;
            });
            setAddAnOrg(false);
        }
    };

    const handleSubmitResult = (submitResult) => {
        const hasSubmitted = submitResult === 'success';
        const hasUpdated = submitResult === 'updated';

        formResponseToast.current?.show({
            severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
            summary: hasUpdated
                ? `Organisation Updated`
                : hasSubmitted
                ? `Organisation Added`
                : `Organisation Addition Unsuccessful`,
            detail: hasUpdated
                ? `The Organisation details have been amended.`
                : hasSubmitted
                ? `The new Organisation has been successfully added.`
                : `Organisation creation could not be completed, try again later.`,
        });
    };

    const columns = useMemo(() => {
        return [
            {
                field: 'organisationName',
                header: 'Name',
                sortable: true,
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (row) => (
                        <ListItem
                            title={row.organisationName}
                            start={
                                <EntityPreview
                                    design={row.design}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '4px',
                                    }}
                                />
                            }
                        />
                    )
                ),
            },
            {
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data) => format(parseISO(data.lastEdited), 'dd/MM/yyyy')
                ),
                field: 'lastEdited',
                header: 'Last Edited',
                sortable: true,
            },
        ];
    }, [isLoading]);

    const mappedColumns = columns.map((col, i) => (
        <Column key={`col-${i}`} {...col} />
    ));

    const headerActions = [
        ...(isAdmin
            ? [
                  {
                      command: props.onImportClick,
                      key: 'import',
                      label: 'Import',
                      severity: 'secondary',
                  },
                  {
                      command: () => openAddAnOrgPortal(),
                      icon: 'add',
                      key: 'add',
                      label: 'Link Organisation',
                  },
                  {
                      command: () => openCreationPortal(),
                      icon: 'add',
                      key: 'create-new',
                      label: 'Create New',
                  },
              ]
            : []),
    ];

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : headerActions
            }
            alignment="middle"
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : 'Check in with Rookie Me to receive oversight of your organisations.'
            }
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No Organisations found`}
        />
    );

    // empty array to populate rows for skeleton loading components
    const blankRows = Array.from({ length: 5 });

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={props.onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    return (
        <PageContainer>
            <PageHeader title="Organisations" actions={headerActions} />
            <DataTable
                value={
                    isLoading
                        ? blankRows
                        : sortBy(props.data, 'organisationName')
                }
                loading={isFetching && !isLoading}
                footer={
                    !isFetching && props.showPagination ? footerTemplate : null
                }
                emptyMessage={tableEmptyMessage}
                columnResizeMode="expand"
                resizableColumns
                onRowClick={(e) => {
                    navigate(
                        `${location.pathname.replace('organisations', 'o')}/${
                            e.data.organisationID
                        }`
                    );
                }}
            >
                {isLoading
                    ? mappedColumns
                    : props.data.length > 0
                    ? mappedColumns
                    : null}
            </DataTable>

            <Sidebar
                header={addAnOrg ? 'Add Organisation' : 'Create Organisation'}
                onHide={() => {
                    closeCreationPortal();
                }}
                visible={searchParams.has('form')}
                position="right"
            >
                {addAnOrg ? (
                    <OrganisationAddForm
                        onSubmit={handleSubmitResult}
                        onCancel={() => closeCreationPortal()}
                    />
                ) : (
                    <OrganisationForm
                        onSubmit={handleSubmitResult}
                        onCancel={() => closeCreationPortal()}
                    />
                )}
            </Sidebar>

            <Toast ref={formResponseToast} />
            <Toast ref={organisationCodeToast} />
        </PageContainer>
    );
};

export default OrganisationListingView;
