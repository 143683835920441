import { useParams } from 'react-router-dom';
import { useGetUserDetailsQuery } from '../../api/core';
import { Skeleton } from 'primereact/skeleton';

const UserBreadcrumb = () => {
    const { userID } = useParams();

    const { data, isLoading, isError } = useGetUserDetailsQuery(
        { userID: userID || '' },
        {
            skip: !userID,
        }
    );

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;
    if (isError) return userID;

    return data?.data?.name;
};

export default UserBreadcrumb;
