import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';

import { useAuthProcessUpdateProfileMutation } from '../../api/profiles';

import { ToastContext } from '../../contexts/ToastContext';

import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';
import { InputText } from 'primereact/inputtext';

import { INPUT_TYPES } from '../../types/common';
import { Auth0Form } from '../../types/user';
import { parseJwt } from '../../util/helper';
import { AUTH_UUID_KEY } from '../../util/constants';

const requiredFields: Array<keyof Auth0Form> = ['firstName', 'lastName'];

const CompleteAccountView = () => {
    const [searchParams] = useSearchParams();

    const authToken = searchParams.get('session_id');
    const authState = searchParams.get('state');
    const redirectUrl = `https://${
        process.env.REACT_APP_AUTH0_DOMAIN
    }/continue${authState ? '?state=' + authState : ''}`;

    const authTokenPayload = authToken && parseJwt(authToken);

    const userID = authTokenPayload[AUTH_UUID_KEY];
    const existingFirstName: string = authTokenPayload.given_name?.trim() || '';
    const existingLastName: string = authTokenPayload.family_name?.trim() || '';

    // Context Hooks
    const toast = useContext(ToastContext);

    const [authProcessUpdateProfile] = useAuthProcessUpdateProfileMutation();

    // Handle form update submission result
    const handleSubmit = (
        data: Auth0Form,
        formikProps?: FormikHelpers<Auth0Form>
    ) => {
        formikProps?.setSubmitting(true);

        authProcessUpdateProfile({ userID, ...data })
            .then((response) => {
                if ('error' in response) {
                    toast?.current?.show({
                        severity: 'warn',
                        summary: 'Error',
                        // @ts-ignore
                        detail: response.error.data.error,
                    });
                } else {
                    // Redirect back to auth0
                    window.location.href = redirectUrl;
                }
            })
            .catch((err) => {
                toast?.current?.show({
                    severity: 'warn',
                    summary: 'Error',
                    detail: `Sorry, there was an error. Please try again later.`,
                });
            })
            .finally(() => {
                formikProps?.setSubmitting(false);
            });
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<Auth0Form> = {};

        requiredFields.forEach((field) => {
            if (!values[field] || values[field].trim() === '') {
                errors[field] = 'Field cannot be blank';
            }
        });

        return errors;
    };

    return (
        <PageContainer size="narrow">
            <PageHeader title="Complete Your Account" showBreadcrumbs={false} />
            <div>
                <p>
                    There is some important info missing from your account.
                    Please fill in the fields below to continue.
                </p>
            </div>
            <Formik
                initialValues={{
                    firstName: existingFirstName,
                    lastName: existingLastName,
                }}
                onSubmit={handleSubmit}
                validate={validate}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormFields>
                                <FormGroup
                                    label="First Name"
                                    htmlFor="firstName"
                                    error={errors.firstName}
                                    showError={
                                        !!errors.firstName && touched.firstName
                                    }
                                >
                                    <InputText
                                        id="firstName"
                                        name="firstName"
                                        type={INPUT_TYPES.text}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter your first name"
                                        value={values.firstName}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Last Name"
                                    htmlFor="lastName"
                                    error={errors.lastName}
                                    showError={
                                        !!errors.lastName && touched.lastName
                                    }
                                >
                                    <InputText
                                        id="lastName"
                                        name="lastName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter your last name"
                                        type={INPUT_TYPES.text}
                                        value={values.lastName}
                                        required
                                    />
                                </FormGroup>
                            </FormFields>
                            <FormActions
                                start={
                                    <RookieButton
                                        type="submit"
                                        label="Continue"
                                        loading={isSubmitting}
                                    />
                                }
                            />
                        </form>
                    );
                }}
            </Formik>
        </PageContainer>
    );
};

export default CompleteAccountView;
