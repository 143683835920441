import { useGetLicenceTypeFeaturesQuery } from '../../api/licences';

import { Card } from 'primereact/card';
import { Skeleton } from 'primereact/skeleton';
import Icon from '../../components/Icon';

interface Props {
    title: string;
    description: string;
    price: number;
    currency: string;
    billingPeriod?: string;
    footer?: any;
    onClick?: any;
    licenceTypeID: string;
}

const PlanCard = ({
    title,
    description,
    price,
    billingPeriod,
    footer,
    licenceTypeID,
}: Props) => {
    const { data: featuresRaw } = useGetLicenceTypeFeaturesQuery({
        licenceTypeID,
    });

    const features = featuresRaw?.data;

    return (
        <Card className="plan-card" title={title} footer={footer}>
            <div className="plan-description">{description}</div>
            <div className="plan-price">
                <span className="plan-price-value">
                    {price > 0 ? '$' + price.toFixed(2) : 'FREE'}
                </span>{' '}
                {price > 0 && billingPeriod && (
                    <span>
                        {billingPeriod === 'year'
                            ? 'per year'
                            : billingPeriod === 'month'
                            ? 'per month'
                            : ''}
                    </span>
                )}
            </div>
            <ul className="plan-features">
                {features
                    ? features.map((feat) => (
                          <li key={feat.featureID}>
                              <Icon name="check" />
                              <span>{feat.title}</span>
                          </li>
                      ))
                    : Array.from({ length: 5 }).map((value, i) => {
                          return (
                              <li key={`skel-loader-${i}`}>
                                  <Icon name="check" />
                                  <Skeleton className="rectangle" width="30%" />
                              </li>
                          );
                      })}
            </ul>
        </Card>
    );
};

export default PlanCard;
