import React from 'react';

interface Props {
    children: React.ReactNode;
    disableGutters?: boolean;
}

const List = ({ children, disableGutters }: Props) => (
    <div className="list">{children}</div>
);

List.defaultProps = {
    disablePadding: false,
};

export default List;
