import { Dialog } from 'primereact/dialog';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import { Collection } from '../../types/collections';
import { BaseEntityType } from '../../types/common';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import CollectionsForm from './CollectionsForm';
import { useNavigate } from 'react-router-dom';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    data: Collection[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onLoadMore?: () => void;
    onDelete?: (collectionID: string) => void;
    onEdit?: (collection: Collection) => void;
    roles: Roles;
    showPagination: boolean;
    onCloseCreateModal: () => void;
    onOpenCreateModal: () => void;
    showFormModal: boolean;
    editing: Collection | null;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const CollectionsView = (props: Props) => {
    const navigate = useNavigate();

    return (
        <PageContainer>
            <PageHeader
                title="Collections"
                actions={
                    props.permissions.canCreate
                        ? [
                              {
                                  label: 'Create Collection',
                                  command: props.onOpenCreateModal,
                              },
                          ]
                        : []
                }
            />
            {props.isLoading ? (
                <Loader />
            ) : (
                <DataTable
                    value={props.data}
                    onRowClick={(row) => navigate(row.data.collectionID)}
                    selectionMode="single"
                    footer={
                        props.showPagination &&
                        props.onLoadMore && (
                            <RookieButton
                                label="Load More"
                                onClick={props.onLoadMore}
                                size="large"
                                className="load-more"
                                severity="secondary"
                                loading={props.isFetching}
                            />
                        )
                    }
                >
                    <Column header="Name" field="collectionName" />
                    <Column
                        header="Description"
                        field="collectionDescription"
                    />
                </DataTable>
            )}
            {props.entityType && props.entityID && (
                <Dialog
                    onHide={props.onCloseCreateModal}
                    visible={props.showFormModal}
                    header={
                        props.editing ? 'Edit Collection' : 'Create Collection'
                    }
                    resizable={false}
                    draggable={false}
                >
                    <CollectionsForm
                        collection={props.editing || undefined}
                        entityType={props.entityType}
                        entityID={props.entityID}
                        onUpdate={() => props.onCloseCreateModal()}
                        onCreate={() => props.onCloseCreateModal()}
                        onError={() => props.onCloseCreateModal()}
                    />
                </Dialog>
            )}
        </PageContainer>
    );
};

export default CollectionsView;
