import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useGetGameInterchangePlayerReportQuery } from '../../api/reports';
import { useGetPlayersQuery } from '../../api/players';

import { defaultReportState } from './constants';
import { GameStat, ReportState } from '../../types/reports';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';

import ErrorDisplay from '../../components/ErrorDisplay';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import TextSwap from '../../components/TextSwap';

import { Mixpanel } from '../../util/mixpanel';
import RookieButton from '../../components/RookieButton';
import { playerGroupCell, playerNameCell } from './DataTableCells';
import { Toolbar } from 'primereact/toolbar';
import { formatTime, periodSuffix } from '../../util/helper';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useGetEventQuery } from '../../api/events';

const columnSchema = [
    {
        header: 'Total on',
        field: 'totalOn',
    },
    {
        header: 'Avg. on',
        field: 'avgOn',
    },
    {
        header: 'Max on stint',
        field: 'maxOnStint',
    },
    {
        header: 'Total off',
        field: 'totalOff',
    },
    {
        header: 'Avg. off',
        field: 'avgOff',
    },
    {
        header: 'Max off stint',
        field: 'maxOffStint',
    },
];

const periodColumnSchema = [
    {
        field: 'On',
        label: 'On',
    },
    {
        field: 'AvgOn',
        label: 'Avg. on',
    },
    {
        field: 'Off',
        label: 'Off',
    },
    {
        field: 'AvgOff',
        label: 'Avg. off',
    },
];

const GameInterchangePlayerReport = () => {
    // Route hooks
    const { teamID, eventID } = useParams();

    // Ref hooks
    const timestampRef = useRef(Date.now()).current; // for report cache busting
    const dataTable = useRef<DataTable<any>>(null);

    // State hooks
    const [reportData, setReportData] =
        useState<ReportState<GameStat[]>>(defaultReportState);
    const [playersCursor, setPlayersCursor] = useState<string>('');

    // API hooks
    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'All',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const eventData = useGetEventQuery(
        {
            eventID: eventID || '',
            entityType: BaseEntityType.teams,
            entityID: teamID || '',
            expand: 'gameSummaryReport',
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const requestReportData = useGetGameInterchangePlayerReportQuery(
        {
            eventID: eventID || '',
            teamID: teamID || '',
            sessionID: timestampRef,
        },
        {
            skip: !teamID || !eventID,
        }
    );

    // Auto paginate players
    useEffect(() => {
        const { data } = playerData;

        if (
            data?.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor &&
            data.lastEvaluatedKey.cursor !== playersCursor
        ) {
            setPlayersCursor(data.lastEvaluatedKey.cursor);
        }
    }, [playerData, playersCursor]);

    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl) {
            setReportData((prev) => ({
                ...prev,
                error: null,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        }
    }, [requestReportData]);

    useEffect(() => {
        const players = playerData?.data?.data;

        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: GameStat) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerData, reportData.isLoading]);

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Expert Player Time',
        });
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const emptyMessage = () => {
        const status = requestReportData?.data?.data?.reportStatus;

        if (
            ['ErrorEvent', 'ErrorServer'].includes(status) ||
            requestReportData.isError ||
            reportData.isError
        ) {
            const errorMsg = requestReportData?.data?.data?.errorMessage;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={'An error has occurred.'}
                    desc={
                        errorMsg ||
                        'Try refreshing, if the issue persists please contact support'
                    }
                    errorType={ERROR_TYPES.somethingsWrong}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        if (['Queued', 'Started', 'Building'].includes(status)) {
            const msgs = [
                'Downloading Database...',
                'Fetching Players...',
                'Fetching Time Data...',
            ];
            const eta = status === 'Queued' ? 5 : status === 'Started' ? 4 : 3;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={
                        <TextSwap
                            strings={msgs}
                            animationDuration="4s"
                            animationType="slideUp"
                            interval={4000}
                        />
                    }
                    desc={`Your report is getting built. Please refresh the page after ${eta} minutes.`}
                    errorType={ERROR_TYPES.maintenance}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        return (
            <ErrorDisplay
                hasReturn={false}
                title={'No game data found.'}
                desc="If you believe this to be an error, please contact support."
                errorType={ERROR_TYPES.empty}
                actions={[
                    {
                        label: 'Support',
                        command: () =>
                            window
                                .open(
                                    'https://www.rookieme.com/contact',
                                    '_blank'
                                )
                                ?.focus(),
                    },
                ]}
            />
        );
    };

    const data = useMemo(() => reportData?.data || [], [reportData]);

    const totalPeriods = useMemo(
        () =>
            eventData?.data?.data.gameDetails?.gameSummaryReport?.gameSummary
                ?.noPeriods || 0,
        [eventData.data]
    );

    const periodCols = Array(totalPeriods)
        .fill(undefined)
        .map((val, idx) => {
            const period = idx + 1;

            return periodColumnSchema.map((col) => {
                const header = `${period}${periodSuffix(period)} ${col.label}`;
                const field = `q${period}${col.field}`;

                return (
                    <Column
                        field={field}
                        header={header}
                        body={(col) => formatTime(col[field])}
                        style={{ textAlign: 'center', minWidth: '120px' }}
                    />
                );
            });
        });

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Player" colSpan={3} />
                <Column header="Total" colSpan={columnSchema.length} />

                {Array(totalPeriods)
                    .fill(undefined)
                    .map((val, idx) => {
                        const period = idx + 1;

                        return (
                            <Column
                                header={`${period}${periodSuffix(period)}`}
                                colSpan={4}
                            />
                        );
                    })}
            </Row>
            <Row>
                <Column header="#" sortable field="player.uniformNumber" />
                <Column header="Player" field="player.lastName" sortable />
                <Column header="Group" field="player.colour" sortable />

                {columnSchema.map((col) => (
                    <Column {...col} sortable />
                ))}

                {Array(totalPeriods)
                    .fill(undefined)
                    .map((val, idx) => {
                        return periodColumnSchema.map((col) => (
                            <Column
                                header={col.label}
                                sortable
                                field={`q${idx + 1}${col.field}`}
                            />
                        ));
                    })}
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            <Toolbar
                end={
                    <RookieButton
                        type="button"
                        onClick={() => exportCSV()}
                        label="Export CSV"
                        severity="secondary"
                    />
                }
            />
            <DataTable
                ref={dataTable}
                value={
                    requestReportData.isLoading || reportData.isLoading
                        ? Array(5)
                        : data
                }
                footer={tableFooter}
                emptyMessage={emptyMessage()}
                columnResizeMode="expand"
                resizableColumns
                exportFilename="game-interchange-report"
                showGridlines
                headerColumnGroup={headerGroup}
            >
                <Column header="#" field="player.uniformNumber" />
                <Column
                    header="Player"
                    field="player.lastName"
                    body={playerNameCell}
                    exportable={false}
                />
                <Column
                    header="First Name"
                    field="player.firstName"
                    hidden={true}
                />
                <Column
                    header="Last Name"
                    field="player.lastName"
                    hidden={true}
                />
                <Column
                    header="Group"
                    field="player.colour"
                    body={playerGroupCell}
                />
                {columnSchema.map((col) => (
                    <Column
                        {...col}
                        body={(row) => row && formatTime(row[col.field])}
                        style={{ textAlign: 'center', minWidth: '120px' }}
                    />
                ))}
                {periodCols}
            </DataTable>
        </>
    );
};

export default GameInterchangePlayerReport;
