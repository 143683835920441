import { useMemo, useRef, useState } from 'react';
import enAu from 'date-fns/locale/en-AU';
import { isArray } from 'lodash';
import { format, getDay, isSameDay, parse, startOfWeek } from 'date-fns';
import {
    Calendar,
    Components,
    dateFnsLocalizer,
    EventProps,
    Formats,
    Navigate,
    SlotInfo,
    ToolbarProps,
    Views,
    Messages,
} from 'react-big-calendar';

import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';

import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';

import CalendarAgenda from './CalendarAgenda';
import CalendarPane from './CalendarPane';
import EventForm from './EventForm';

import { toISOString } from '../../util/helper';

import { ERROR_TYPES } from '../../types/common';
import { CalendarEvent, Event, EventFormData } from '../../types/event';
import { Sidebar } from 'primereact/sidebar';

interface TruncatedEvent {
    truncatedEvents?: CalendarEvent[];
    truncatedDate?: Date;
    visible: boolean;
}

interface Props {
    data: Event[];
    createEvent: EventFormData | null;
    selectedEvent: CalendarEvent | null;
    onCloseCreateDialog: () => void;
    onCloseDeleteDialog: () => void;
    onCloseEditDialog: () => void;
    onDeleteEvent: (eventID: string) => void;
    onShowDeleteDialog: () => void;
    onShowCreateDialog: (event?: EventFormData) => void;
    onShowEditDialog: (event: CalendarEvent) => void;
    onSelectEvent: (event: CalendarEvent) => void;
    showCreateDialog: boolean;
    showDeleteDialog: boolean;
    showEditDialog: boolean;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
}

const CREATE_FORM_ID = 'create-event-form';
const EDIT_FORM_ID = 'edit-event-form';

const locales = {
    'en-AU': enAu,
};

const localizer = dateFnsLocalizer({
    format,
    getDay,
    locales,
    parse,
    startOfWeek,
});

const CalendarView = (props: Props) => {
    const {
        data,
        onCloseCreateDialog,
        onCloseDeleteDialog,
        onCloseEditDialog,
        onDeleteEvent,
        onSelectEvent,
        onShowCreateDialog,
        onShowDeleteDialog,
        onShowEditDialog,
        createEvent,
        selectedEvent,
        showCreateDialog,
        showDeleteDialog,
        showEditDialog,
    } = props;

    const [showMoreConfig, setShowMoreConfig] = useState<TruncatedEvent>({
        truncatedDate: undefined,
        truncatedEvents: [],
        visible: false,
    });
    const eventPopover = useRef<OverlayPanel>(null);
    const selectedEventID = selectedEvent?.eventID;
    const eventSingleFormToast = useRef<Toast>(null);

    const eventFormToast = (isCreate: boolean) => {
        eventSingleFormToast.current?.show({
            severity: isCreate ? 'success' : 'warn',
            summary: isCreate ? 'New event created' : 'Event changes saved',
            detail: isCreate
                ? 'Your event has been created and is ready to manage.'
                : 'This events details have been updated.',
        });
    };

    /**
     * @desc checks passed in start/end data + time and returns a check on event extending a full day.
     * @param startDateTime
     * @param endDateTime
     * @returns boolean
     */
    const allDayCheck = (startDateTime: Date, endDateTime: Date) => {
        let isAllDay = false;

        const endDay = endDateTime.getDate();
        const nextDay = startDateTime.getDate() + 1;
        const endTime = format(endDateTime, 'H:mm:ss');

        if (nextDay === endDay && endTime === '0:00:00') {
            isAllDay = true;
        }

        return isAllDay;
    };

    const components = useMemo((): Components<CalendarEvent> => {
        /**
         * @desc single event render method.
         * @param eventData
         * @param eventView
         * @returns JSX.Element
         */
        const renderEvent = (
            eventData: EventProps<CalendarEvent>,
            eventView: string
        ) => {
            const {
                event: { startDateTime, endDateTime },
            } = eventData;

            const allDayEvent =
                startDateTime &&
                endDateTime &&
                allDayCheck(startDateTime, endDateTime);

            const sameDayEvent =
                startDateTime &&
                endDateTime &&
                isSameDay(startDateTime, endDateTime);

            return (
                <ListItem
                    caption={eventData.title}
                    title={
                        eventView === 'week' && allDayEvent
                            ? 'All-Day'
                            : sameDayEvent
                            ? format(startDateTime, 'h:mmaaa') +
                              ' - ' +
                              format(endDateTime, 'h:mmaaa')
                            : format(startDateTime, 'h:mmaaa')
                    }
                    start={
                        <Icon
                            name={
                                eventData.event.eventType === 'Game'
                                    ? 'stadium'
                                    : 'event_available'
                            }
                        />
                    }
                    className="event-calendar_chip"
                    compact
                />
            );
        };

        return {
            toolbar: (cal: ToolbarProps) => {
                return (
                    <Toolbar
                        start={
                            <>
                                <div className="p-buttonset">
                                    <RookieButton
                                        severity="secondary"
                                        onClick={() =>
                                            cal.onNavigate(Navigate.PREVIOUS)
                                        }
                                        icon="chevron_left"
                                    />
                                    <RookieButton
                                        severity="secondary"
                                        icon="today"
                                        label="Today"
                                        onClick={() =>
                                            cal.onNavigate(Navigate.TODAY)
                                        }
                                    />
                                    <RookieButton
                                        severity="secondary"
                                        onClick={() =>
                                            cal.onNavigate(Navigate.NEXT)
                                        }
                                        icon="chevron_right"
                                    />
                                </div>
                                <h4 className="toolbar-title">{cal.label}</h4>
                            </>
                        }
                        end={
                            <>
                                <RookieButton
                                    onClick={() => onShowCreateDialog()}
                                    icon="add"
                                    label="Create Event"
                                />
                                <SelectButton
                                    value={cal.view}
                                    onChange={(e) => cal.onView(e.value)}
                                    options={
                                        isArray(cal.views)
                                            ? cal.views.map((view) => ({
                                                  label: (
                                                      <Icon
                                                          name={
                                                              view === 'month'
                                                                  ? 'calendar_view_month'
                                                                  : view ===
                                                                    'week'
                                                                  ? 'calendar_view_week'
                                                                  : 'calendar_view_day'
                                                          }
                                                          size="small"
                                                      />
                                                  ),
                                                  value: view,
                                              }))
                                            : []
                                    }
                                />
                            </>
                        }
                    />
                );
            },
            /*
            list: {
                event: (comp: AgendaEvent) => {
                    return renderEvent(comp, 'agenda');
                },
            },
            */
            week: {
                event: (comp: EventProps<CalendarEvent>) => {
                    return renderEvent(comp, 'week');
                },
            },
            month: {
                event: (comp: EventProps<CalendarEvent>) => {
                    return renderEvent(comp, 'month');
                },
            },
        };
    }, [onShowCreateDialog]);

    const formats = useMemo<Formats>(
        () => ({
            agendaHeaderFormat: (date, culture, localizer) => {
                const { start, end } = date;
                const startFormat =
                    start.getFullYear() !== end.getFullYear()
                        ? 'MMMM yyyy'
                        : 'MMMM';

                return `${localizer?.format(
                    start,
                    startFormat,
                    culture
                )} - ${localizer?.format(end, 'MMMM yyyy', culture)}`;
            },
        }),
        []
    );

    const { views } = useMemo(
        () => ({
            views: {
                month: true,
                week: true,
                list: CalendarAgenda,
            },
        }),
        []
    );

    const events = data.map((event) => ({
        ...event,
        arrivalDateTime:
            event.arrivalDateTime && new Date(event.arrivalDateTime),
        startDateTime: event.startDateTime && new Date(event.startDateTime),
        endDateTime: event.endDateTime && new Date(event.endDateTime),
    })) as CalendarEvent[];

    /**
     * @desc custom classes applied along-side .event-wrapper
     * @param event
     * @returns string
     */
    const eventWrapperProps = (event: CalendarEvent) => {
        return {
            className: ` event-item_wrapper ${
                event.eventType ? event.eventType.toLowerCase() + '-event' : ''
            }`,
        };
    };

    /**
     * @desc Modal onHide handler
     * @return useState
     */
    const closeModalHandler = () => {
        setShowMoreConfig({ visible: false });
    };

    /**
     * @desc Map over truncated events to be displayed within modal.
     * @param truncatedEvents
     * @returns JSX.Element[]
     */
    const mappedTruncatedEvents =
        showMoreConfig.truncatedEvents &&
        showMoreConfig.truncatedEvents.length > 0 &&
        showMoreConfig.truncatedEvents.map((truncEvent: CalendarEvent) => {
            return (
                <div
                    className={`rbc-event event-item_wrapper ${truncEvent.eventType.toLowerCase()}-event`}
                >
                    <div
                        className="rbc-event-content"
                        onClick={(e) => {
                            if (onSelectEvent) {
                                onSelectEvent(truncEvent);
                            }
                            if (eventPopover.current) {
                                eventPopover.current.toggle(e);
                            }
                        }}
                    >
                        <ListItem
                            caption={
                                // TODO: clean up and pass in as helper method, this is second use of same code
                                truncEvent.endDateTime &&
                                isSameDay(
                                    new Date(truncEvent.startDateTime),
                                    new Date(truncEvent.endDateTime)
                                )
                                    ? format(
                                          new Date(truncEvent.startDateTime),
                                          'h:mmaaa'
                                      ) +
                                      ' - ' +
                                      format(
                                          new Date(truncEvent.endDateTime),
                                          'h:mmaaa'
                                      )
                                    : format(
                                          new Date(truncEvent.startDateTime),
                                          'h:mmaaa'
                                      )
                            }
                            title={truncEvent.eventName}
                            start={
                                <Icon
                                    name={
                                        truncEvent.eventType === 'Game'
                                            ? 'stadium'
                                            : 'event_available'
                                    }
                                />
                            }
                            className="event-calendar_chip"
                            compact
                        />
                    </div>
                </div>
            );
        });

    /**
     * @desc render content for Modal component
     * @returns JSX.Element
     */
    const modalContent = showMoreConfig.truncatedEvents ? (
        <div className="truncated-events-wrapper">{mappedTruncatedEvents}</div>
    ) : (
        <ErrorDisplay
            errorType={ERROR_TYPES.notFound}
            desc="Try again or refresh your browser."
            title="Events not found"
        />
    );

    /**
     * @desc pass custom messages to replace rbc defaults
     * @returns string
     */
    const messages: Messages = {
        showMore: (total) => `${total} more`,
    };

    const handleSelectEvent = (
        event: CalendarEvent,
        e: React.SyntheticEvent<HTMLElement>
    ) => {
        if (onSelectEvent) {
            const { arrivalDateTime, startDateTime, endDateTime } = event;

            onSelectEvent({
                ...event,
                arrivalDateTime: arrivalDateTime ? arrivalDateTime : undefined,
                startDateTime: startDateTime,
                endDateTime: endDateTime ? endDateTime : undefined,
            });
        }
        if (eventPopover.current) {
            eventPopover.current.toggle(e);
        }
    };

    const handleSelectSlot = (slot: SlotInfo) => {
        if (onShowCreateDialog) {
            onShowCreateDialog({
                startDateTime: toISOString(slot.start),
                endDateTime: toISOString(slot.end),
                arrivalDateTime: toISOString(slot.start),
            });
        }
    };

    const handleShowMore = (events: CalendarEvent[], date: Date) => {
        setShowMoreConfig({
            truncatedDate: date,
            truncatedEvents: events,
            visible: true,
        });
    };

    return (
        <>
            <Calendar
                eventPropGetter={eventWrapperProps}
                className="calendar-wrapper"
                events={events}
                components={components}
                formats={formats}
                defaultView={Views.MONTH}
                localizer={localizer}
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                messages={messages}
                onShowMore={handleShowMore}
                selectable
                views={views}
                titleAccessor="eventName"
                tooltipAccessor="eventName"
                startAccessor="startDateTime"
                endAccessor="endDateTime"
            />
            <OverlayPanel
                appendTo="self"
                ref={eventPopover}
                className="calendar-popover"
                dismissable
            >
                {selectedEvent?.eventID && (
                    <CalendarPane
                        eventID={selectedEvent.eventID}
                        onClose={() => {
                            if (eventPopover.current) {
                                eventPopover.current.hide();
                            }
                        }}
                        onDelete={() => {
                            if (eventPopover.current) {
                                eventPopover.current.hide();
                            }
                            if (onShowDeleteDialog) {
                                onShowDeleteDialog();
                            }
                        }}
                        onEdit={() => {
                            if (eventPopover.current) {
                                eventPopover.current.hide();
                            }
                            if (onShowEditDialog) {
                                onShowEditDialog(selectedEvent);
                            }
                        }}
                    />
                )}
            </OverlayPanel>
            <Dialog
                className="truncated-events"
                header={
                    showMoreConfig.truncatedDate
                        ? format(showMoreConfig.truncatedDate, 'd')
                        : ''
                }
                onHide={closeModalHandler}
                visible={showMoreConfig.visible}
            >
                {modalContent}
            </Dialog>
            <Sidebar
                header="Create New Event"
                onHide={onCloseCreateDialog}
                visible={showCreateDialog}
                position="right"
            >
                <EventForm
                    eventID={selectedEvent?.eventID}
                    formID={CREATE_FORM_ID}
                    initialValues={createEvent ? createEvent : undefined}
                    onSubmit={eventFormToast}
                />
            </Sidebar>
            <Sidebar
                header="Edit Event"
                onHide={onCloseEditDialog}
                visible={showEditDialog}
                position="right"
            >
                <EventForm formID={EDIT_FORM_ID} onSubmit={eventFormToast} />
            </Sidebar>
            <Dialog
                header="Delete Event"
                onHide={onCloseDeleteDialog}
                visible={showDeleteDialog}
            >
                <div>
                    <div>
                        Are you sure you want to delete{' '}
                        <b>
                            {selectedEvent
                                ? selectedEvent.eventName
                                : 'this event'}
                        </b>
                        ?
                    </div>
                    <RookieButton
                        icon="delete"
                        label="Delete"
                        severity="danger"
                        onClick={() =>
                            selectedEventID
                                ? onDeleteEvent(selectedEventID)
                                : undefined
                        }
                    />
                </div>
            </Dialog>

            <Toast ref={eventSingleFormToast} />
        </>
    );
};

export default CalendarView;
