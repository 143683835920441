import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { StatConfig, StatConfigResponse } from '../types/sports';
import { generatePath } from 'react-router-dom';

export const statsApi = createApi({
    reducerPath: 'statsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getStats: builder.query<StatConfigResponse, { sportID: string }>({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getStats.url, { sportID }),
                method: apiEndpoints.getStats.method,
            }),
        }),
        getStat: builder.query<StatConfig, { sportID: string; statID: string }>(
            {
                query: ({ sportID, statID }) => ({
                    url: generatePath(apiEndpoints.getStat.url, {
                        sportID,
                        statID,
                    }),
                    method: apiEndpoints.getStat.method,
                }),
            }
        ),
    }),
});

export const { useGetStatsQuery, useGetStatQuery } = statsApi;
