import { useContext, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
    useCreateNoteMutation,
    useDeleteNoteMutation,
    useDeleteNoteNodeMutation,
    useGetNotesQuery,
    useUpdateNoteMutation,
} from '../../api/notes';

import NotesView from './NotesView';

import usePermission from '../../hooks/usePermission';
import { getRouteByID } from '../../routes/routes';
import { getEntityFromParam } from '../../util/helper';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';

import { EntityType } from '../../types/common';
import { NoteFormData, NotePrivacy } from '../../types/note';
import { Permissions } from '../../types/permissions';
import { Route } from '../../types/route';

interface Props {
    route: Route;
}

const NotesContainer = ({ route }: Props) => {
    const navigate = useNavigate();
    const params = useParams();

    const toast = useContext(ToastContext);

    // Get active entity id and type
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    // Permission Hook
    const { checkPermission } = usePermission(activeEntity?.entityID);

    // Used to set the first note to active once data has loaded.
    // const initialNoteLoaded = useRef<boolean>(false);

    // API Hooks
    const { data, isLoading, isError, isFetching } = useGetNotesQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
        },
        {
            skip: !activeEntity,
        }
    );

    const [createNote] = useCreateNoteMutation();
    const [updateNote] = useUpdateNoteMutation();
    const [deleteNote] = useDeleteNoteMutation();
    const [deleteNode] = useDeleteNoteNodeMutation();

    const notes = useMemo(() => data?.data.notes || [], [data]);

    /*
    useEffect(() => {
        if (
            !params.noteID &&
            !initialNoteLoaded.current &&
            !isLoading &&
            notes[0]
        ) {
            navigate(
                generatePath(route.path, {
                    ...params,
                    noteID: notes[0].noteID,
                })
            );

            initialNoteLoaded.current = true;
        }
    }, [isLoading, navigate, notes, params, route.path]);
    */

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleNoteClick = (noteID: string) => {
        navigate(generatePath(route.path, { ...params, noteID }));
    };

    const handleNoteSave = (data: NoteFormData) => {
        updateNote({
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            ...data,
        })
            .then((response) => {
                if ('data' in response) {
                    showToast({
                        summary: `${data.noteTitle} has been saved.`,
                        severity: 'success',
                    });
                } else {
                    showToast({
                        summary: `There was an error saving the note. Please try again.`,
                        severity: 'error',
                    });
                }
            })
            .catch(() => {
                showToast({
                    summary: `There was an error saving the note. Please try again.`,
                    severity: 'error',
                });
            });
    };

    const handleNoteCreate = () => {
        createNote({
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            noteContent: '',
            notePrivacy: NotePrivacy.Private,
            noteTitle: '',
            nodeList: [],
        })
            .then((response) => {
                if ('data' in response) {
                    navigate(
                        generatePath(route.path, {
                            ...params,
                            noteID: response.data.data.noteID,
                        })
                    );
                } else {
                    showToast({
                        summary: `There was an error creating the note. Please try again.`,
                        severity: 'error',
                    });
                }
            })
            .catch(() => {
                showToast({
                    summary: `There was an error creating the note. Please try again.`,
                    severity: 'error',
                });
            });
    };

    const handleDeleteNote = (noteID: string) => {
        const currIndex = notes.findIndex((note) => note.noteID === noteID);
        const currNote = notes[currIndex];
        const nextNote = notes[currIndex + 1];
        const prevNote = notes[currIndex - 1];

        deleteNote({
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            noteID,
        })
            .then(() => {
                navigate(
                    generatePath(route.path, {
                        ...params,
                        noteID: nextNote
                            ? nextNote.noteID
                            : prevNote
                            ? prevNote.noteID
                            : '',
                    })
                );

                showToast({
                    summary: `${
                        currNote ? '"' + currNote.noteTitle + '"' : 'Note'
                    } has been deleted.`,
                    severity: 'success',
                });
            })
            .catch(() => {
                showToast({
                    summary: `Error deleting ${
                        currNote ? '"' + currNote.noteTitle + '"' : 'Note'
                    }.`,
                    severity: 'error',
                });
            });
    };

    const handeNodeDelete = (
        noteID: string,
        nodeID: string,
        nodeType: EntityType
    ) => {
        deleteNode({
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            noteID,
            nodeID,
            nodeType,
        })
            .then(() => {
                showToast({
                    summary: `Deleted`,
                    severity: 'success',
                });
            })
            .catch(() => {
                showToast({
                    summary: `Error`,
                    severity: 'error',
                });
            });
    };

    const handeNodeClick = (nodeID: string, nodeType: EntityType) => {
        if (nodeType === EntityType.events) {
            const route = getRouteByID('teams-event');

            if (route) {
                navigate(
                    generatePath(route.path, {
                        ...params,
                        eventID: nodeID,
                    })
                );
            }
        }
    };

    const clearNoteSelection = () => {
        navigate(generatePath(route.path, { ...params, noteID: '' }));
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.MANAGE_NOTES,
            Permissions.POST_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.MANAGE_NOTES,
            Permissions.DELETE_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.MANAGE_NOTES,
            Permissions.PUT_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.MANAGE_NOTES,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };
    return (
        <NotesView
            activeNote={notes.find((note) => note.noteID === params.noteID)}
            notes={notes}
            isLoading={isLoading}
            isError={isError}
            isFetching={isFetching}
            onNoteClick={handleNoteClick}
            onNoteCreate={handleNoteCreate}
            onNoteSave={handleNoteSave}
            onNoteDelete={handleDeleteNote}
            onNodeClick={handeNodeClick}
            onNodeDelete={handeNodeDelete}
            onClearSelection={clearNoteSelection}
            permissions={permissions}
        />
    );
};

export default NotesContainer;
