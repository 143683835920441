import { format } from 'date-fns';

import List from '../../components/List';
import ListItem from '../../components/ListItem';

import { Note } from '../../types/note';

interface Props {
    activeNote?: Note;
    notes: Note[];
    isLoading: boolean;
    onNoteClick: (noteID: string) => void;
}

const NoteList = ({ activeNote, notes, onNoteClick }: Props) => {
    return (
        <List>
            {notes.map((note) => {
                return (
                    <ListItem
                        key={note.noteID}
                        selected={
                            activeNote && activeNote.noteID === note.noteID
                        }
                        title={note.noteTitle ? note.noteTitle : 'Untitled'}
                        caption={`${format(
                            new Date(note.lastEdited),
                            'MMM d, yyyy'
                        )} - ${note.notePrivacy}`}
                        onClick={() => onNoteClick(note.noteID)}
                    />
                );
            })}
        </List>
    );
};

export default NoteList;
