import { format, fromUnixTime } from 'date-fns';
import { upperFirst } from 'lodash';

import AddLicencesForm from '../licences/AddLicencesForm';
import ErrorDisplay from '../../components/ErrorDisplay';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import {
    DataTable,
    DataTableRowClickEvent,
    DataTableValue,
} from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { SubscriptionResponse } from '../../types/subscriptions';
import { UseQueryResult } from '../../types/api';
import { ERROR_TYPES } from '../../types/common';

const currency = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
});

interface Props {
    subscription: UseQueryResult<SubscriptionResponse>;
    invoices: UseQueryResult<any>;
    roles: Roles;
    route: Route;
    user: User;
    showAddModal: boolean;
    onCancelSubscription: () => void;
    onShowAddModal: () => void;
    onCloseAddModal: () => void;
    onSubscriptionClick: (data: DataTableValue) => void;
}

const SubscriptionSingleView = (props: Props) => {
    const {
        subscription: subscriptionResponse,
        invoices: invoicesResponse,
        showAddModal,
        onShowAddModal,
        onCloseAddModal,
        onCancelSubscription,
        onSubscriptionClick,
    } = props;

    const { data: subscription, isLoading: isLoadingSubscription } =
        subscriptionResponse;

    const { data: invoices, isLoading: isLoadingInvoices } = invoicesResponse;

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No results found"
        />
    );

    const emptyRows = Array(2);

    return (
        <PageContainer>
            <PageHeader
                title="Subscription"
                actions={
                    subscription?.data.canceled_at
                        ? []
                        : [
                              {
                                  label: 'Cancel Subscription',
                                  command: onCancelSubscription,
                                  severity: 'danger',
                              },
                              {
                                  label: 'Add Licences',
                                  command: onShowAddModal,
                              },
                          ]
                }
            />

            {subscription && (
                <ul>
                    <li>
                        <strong>Created:</strong>
                        {format(
                            fromUnixTime(subscription.data?.created),
                            'MMMM dd, yyyy'
                        )}
                    </li>
                    <li>
                        <strong>Status:</strong>{' '}
                        {subscription.data.canceled_at
                            ? 'Cancelled'
                            : upperFirst(subscription.data?.status)}
                    </li>
                    {subscription.data.cancel_at && (
                        <li>
                            <strong>Cancelled on:</strong>
                            {format(
                                fromUnixTime(subscription.data.cancel_at),
                                'MMMM dd, yyyy'
                            )}
                        </li>
                    )}
                    {subscription.data.status === 'trialing' && (
                        <li>
                            <strong>Trial ending:</strong>
                            {format(
                                fromUnixTime(subscription.data.trial_end),
                                'MMMM dd, yyyy'
                            )}
                        </li>
                    )}
                    {subscription.data.next_pending_invoice_item_invoice && (
                        <li>
                            <strong>Next Renewal:</strong>
                            {
                                subscription.data
                                    ?.next_pending_invoice_item_invoice
                            }
                        </li>
                    )}
                </ul>
            )}

            <h1>Items</h1>

            <DataTable
                value={
                    isLoadingSubscription
                        ? emptyRows
                        : subscription?.data?.items?.data
                        ? subscription.data.items.data
                        : []
                }
                onRowClick={(row: DataTableRowClickEvent) =>
                    onSubscriptionClick(row.data)
                }
                emptyMessage={emptyTemplate}
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    field="price.nickname"
                    header="Description"
                    body={isLoadingSubscription && <Skeleton />}
                />

                <Column
                    field="created"
                    header="Created"
                    body={(data) =>
                        isLoadingSubscription ? (
                            <Skeleton />
                        ) : (
                            format(fromUnixTime(data.created), 'MMMM dd, yyyy')
                        )
                    }
                />

                <Column
                    field="quantity"
                    header="Qty"
                    body={isLoadingSubscription && <Skeleton />}
                />
                <Column
                    field="price.unit_amount"
                    header="Unit Price"
                    body={(data) =>
                        isLoadingSubscription ? (
                            <Skeleton />
                        ) : (
                            currency.format(data.price.unit_amount / 100)
                        )
                    }
                />
            </DataTable>

            <h1>Invoices</h1>

            <DataTable
                value={
                    isLoadingInvoices ? emptyRows : invoices?.data?.data || []
                }
                emptyMessage={emptyTemplate}
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    field="created"
                    header="Payment Date"
                    body={(data) =>
                        isLoadingInvoices ? (
                            <Skeleton />
                        ) : (
                            <a
                                href={`${data.hosted_invoice_url}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {format(
                                    fromUnixTime(data.created),
                                    'MMMM dd, yyyy'
                                )}
                            </a>
                        )
                    }
                />
                <Column
                    field="number"
                    header="Invoice No."
                    body={isLoadingInvoices && <Skeleton />}
                />
                <Column
                    field="status"
                    header="Status"
                    body={isLoadingInvoices && <Skeleton />}
                />
                <Column
                    field="total"
                    header="Total"
                    body={(data) =>
                        isLoadingInvoices ? (
                            <Skeleton />
                        ) : (
                            currency.format(data.total / 100)
                        )
                    }
                />
            </DataTable>
            <Sidebar
                header="Add Licence"
                visible={showAddModal}
                onHide={() => onCloseAddModal()}
                position="right"
            >
                {subscription?.data?.metadata && (
                    <AddLicencesForm
                        entityID={subscription.data.metadata.entityID}
                        entityType={subscription.data.metadata.entityType}
                        subscriptionID={subscription.data.id}
                        onUpdateSuccess={() => {
                            window.location.reload();
                        }}
                    />
                )}
            </Sidebar>
        </PageContainer>
    );
};

export default SubscriptionSingleView;
