import { GameStat } from '../../types/reports';

export const calculateTotals = (
    array: GameStat[],
    summableStats: string[] = []
): GameStat[] => {
    const data = array.reduce((result: any, statRow: any) => {
        // set player id in results obj
        result[statRow.playerID] = result[statRow.playerID] || {};

        // loop through stats and sum
        Object.keys(statRow).forEach((key) => {
            if (summableStats.includes(key)) {
                // sum up only summable stats
                result[statRow.playerID][key] =
                    (result[statRow.playerID][key] || 0) + statRow[key];
            } else {
                // return static values
                result[statRow.playerID][key] = statRow[key];
            }
        });

        result[statRow.playerID].period = 0;
        return result;
    }, {});

    return Object.values(data);
};
