import { PaginatedResponse, Response } from './api';

export enum NotificationType {
    EventUpdate = 'EventUpdate',
    EventInvite = 'EventInvite',
    TeamInvite = 'TeamInvite',
}

export enum NotificationNode {
    Events = 'Events',
    Teams = 'Teams',
}

export enum NotificationStatus {
    Unread = 'Unread',
    Read = 'Read',
}

export interface Notification {
    notificationID: string;
    status: NotificationStatus;
    userID: string;
    metadata: {
        inviteeID: string;
        userEventID: string;
    };
    actor: {
        entityID: string;
        entityType: string;
        name: string;
        picture: string;
    };
    node: {
        entityID: string;
        entityType: NotificationNode;
        name: string;
    };
    entity: {
        entityID: string;
        entityType: string;
        name: string;
    };
    notificationType: NotificationType;
    lastEdited: string;
    lastEditedBy: string;
    createdAt: string;
    createdBy: string;
}

export type NotificationResponse = Response<Notification>;
export type NotificationsResponse = PaginatedResponse<Notification[]>;
