import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { unionBy, orderBy, sortBy } from 'lodash';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import { useGetPlayersQuery } from '../../api/players';
import {
    useGetTeamSeasonTimeBasicReportQuery,
    useGetTeamSeasonSummaryReportQuery,
} from '../../api/reports';

import PageHeader from '../../layout/PageHeader';
import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

import { Column, ColumnSortEvent } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { SelectButton } from 'primereact/selectbutton';

import { defaultReportState } from '../reports/constants';
import { playerGroupCell, playerNameCell } from '../reports/DataTableCells';

import { ReportDataTypes, ReportState, TimeStat } from '../../types/reports';
import ErrorDisplay from '../../components/ErrorDisplay';
import { ERROR_TYPES } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import { Tag } from 'primereact/tag';

import { Mixpanel } from '../../util/mixpanel';
import { calculateTotals } from './helpers';
import { useGetTeamQuery } from '../../api/teams';

interface Props {
    reportType: 'basic';
}

const BenchStartReport = ({ reportType }: Props) => {
    // Route Params
    const { teamID } = useParams();

    // State Hooks
    const [season, setSeason] = useState<string | null>(null);
    const [event] = useState<string>('');
    const [reportData, setReportData] =
        useState<ReportState<TimeStat>>(defaultReportState);
    const [summaryReportData, setSummaryReportData] =
        useState<ReportState<TimeStat>>(defaultReportState);
    const [dataType, setDataType] = useState(ReportDataTypes.Total);

    const [playersCursor, setPlayersCursor] = useState<string>('');

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    const reportParams = {
        seasonID: season || '',
        teamID: teamID || '',
        sessionID: timestampRef,
    };

    // API Hooks
    const { data: teamData } = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    const requestTeamSummaryReport = useGetTeamSeasonSummaryReportQuery(
        { ...reportParams, concatenated: true },
        {
            skip: !season || !teamID,
        }
    );

    const requestBasicReport = useGetTeamSeasonTimeBasicReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType === 'basic',
        }
    );

    const requestSummaryData = requestTeamSummaryReport;
    const requestReportData = requestBasicReport;

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'All',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    // Helpers
    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    // Auto paginate players
    useEffect(() => {
        const { data } = playerData;

        if (
            data?.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor &&
            data.lastEvaluatedKey.cursor !== playersCursor
        ) {
            setPlayersCursor(data.lastEvaluatedKey.cursor);
        }
    }, [playerData, playersCursor]);

    // Fetch report Time Report
    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl && requestReportData.isSuccess) {
            setReportData({
                ...defaultReportState,
                isLoading: true,
                isUninitialized: false,
            });

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setReportData(defaultReportState);
        }
    }, [requestReportData]);

    // Fetch report Summary Report
    useEffect(() => {
        const reportUrl = requestSummaryData?.data?.data.objectURL;

        if (reportUrl && requestSummaryData.isSuccess) {
            setSummaryReportData({
                ...defaultReportState,
                isLoading: true,
                isUninitialized: false,
            });

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSummaryReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setSummaryReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setSummaryReportData(defaultReportState);
        }
    }, [requestSummaryData]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(teamData?.data.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, teamData]);

    // Inject player details into data
    useEffect(() => {
        const players = playerData?.data?.data;

        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: TimeStat) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerData, reportData.isLoading]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Cumulative Bench Starts`,
        });
    };

    // Renders
    const leftToolbar = (
        <>
            <Dropdown
                onChange={(e) => setSeason(e.value)}
                value={season}
                options={seasonOptions}
            />
        </>
    );

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Percentage,
            value: ReportDataTypes.Average,
        },
    ];

    const rightToolbar = (
        <div className="p-button-group">
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
                disabled={event !== ''}
            />
            <RookieButton
                type="button"
                onClick={() => exportCSV()}
                label="Export CSV"
                severity="secondary"
            />
        </div>
    );

    const renderHeaderCell = (column: string) => {
        const isSGBColumn = column === 'SGB';
        const isSPBColumn = column === 'SPB';

        return (
            <>
                {isSGBColumn && (
                    <Tooltip
                        target={`.header-SGB`}
                        autoHide={false}
                        position="right"
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ color: '#fff' }}>
                                <div>Start Game Bench</div>
                            </div>
                            <RookieButton
                                icon="help"
                                data-beacon-article={'668e119ad5f6cf2baa1f234c'}
                            />
                        </div>
                    </Tooltip>
                )}
                {isSPBColumn && (
                    <Tooltip
                        target={`.header-SPB`}
                        autoHide={false}
                        position="right"
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ color: '#fff' }}>
                                <div>Start Period Bench</div>
                            </div>
                            <RookieButton
                                icon="help"
                                data-beacon-article={'668e119ad5f6cf2baa1f234c'}
                            />
                        </div>
                    </Tooltip>
                )}
                {column}
            </>
        );
    };

    const renderEmpty = () => {
        const msg = event
            ? "Can't load report for this event. The data may be corrupt. Try rebuilding your report or reach out to us via the question mark at the base of the page."
            : 'Unable to find report. Please try again later';
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ErrorDisplay
                    alignment="middle"
                    title="No Report Found"
                    desc={msg}
                    errorType={errorType}
                    hasReturn={false}
                    proportion="compact"
                />
            </div>
        );
    };

    const [spbCounts, setSpbCounts] = useState<Record<string, number>>({});
    const [spbCountsAverage, setSpbCountsAverage] = useState<number>(0);
    const [sgbCounts, setSgbCounts] = useState<Record<string, number>>({});
    const [sgbCountsAverage, setSgbCountsAverage] = useState<number>(0);

    useEffect(() => {
        if (summaryReportData && summaryReportData.data) {
            const spbCountsTemp: Record<string, number> = {};
            const sgbCountsTemp: Record<string, number> = {};

            const calculatePlayedCount = (playerID: string) => {
                let value = 0; // default to 0 to avoid null issues
                if (reportData && reportData.data) {
                    value = reportData.data.filter(
                        (o: TimeStat) =>
                            o.playerID === playerID && o.period === 1
                    ).length;
                }
                return value;
            };

            const calculateNetPeriodsCount = (playerID: string) => {
                let netPeriodValue = 0; // default to 0 to avoid null issues
                if (reportData && reportData.data) {
                    netPeriodValue = reportData.data.filter(
                        (o: TimeStat) => o.playerID === playerID
                    ).length;
                }

                return netPeriodValue;
            };

            summaryReportData.data.forEach((game: any) => {
                for (const playerID in game.benchStarts) {
                    spbCountsTemp[playerID] = spbCountsTemp[playerID] || 0;
                    sgbCountsTemp[playerID] = sgbCountsTemp[playerID] || 0;

                    if (
                        game.benchStarts[playerID] &&
                        game.benchStarts[playerID].length > 0 &&
                        dataType === ReportDataTypes.Total
                    ) {
                        // Count all numbers in the array for spbCounts
                        spbCountsTemp[playerID] +=
                            game.benchStarts[playerID].length;

                        // Count occurrences of the number 1 in the array for sgbCounts
                        sgbCountsTemp[playerID] += game.benchStarts[
                            playerID
                        ].filter((val: number) => val === 1).length;
                    } else if (
                        game.benchStarts[playerID] &&
                        game.benchStarts[playerID].length > 0 &&
                        dataType === ReportDataTypes.Average
                    ) {
                        const playedCount = calculatePlayedCount(playerID);
                        const netPeriods = calculateNetPeriodsCount(playerID);

                        // Avoid division by 0 by checking the counts
                        if (netPeriods > 0) {
                            spbCountsTemp[playerID] += Math.round(
                                (game.benchStarts[playerID].length /
                                    netPeriods) *
                                    100
                            );
                        }

                        if (playedCount > 0) {
                            sgbCountsTemp[playerID] += Math.round(
                                (game.benchStarts[playerID].filter(
                                    (val: number) => val === 1
                                ).length /
                                    playedCount) *
                                    100
                            );
                        }
                    }
                }
            });

            setSpbCounts(spbCountsTemp);
            setSgbCounts(sgbCountsTemp);

            // Calculate the average spbCounts
            const totalSpbCounts = Object.values(spbCountsTemp).reduce(
                (sum, count) => sum + count,
                0
            );
            const averageSpbCounts =
                totalSpbCounts / Object.keys(spbCountsTemp).length;
            setSpbCountsAverage(averageSpbCounts);

            const totalSgbCounts = Object.values(sgbCountsTemp).reduce(
                (sum, count) => sum + count,
                0
            );
            const averageSgbCounts =
                totalSgbCounts / Object.keys(sgbCountsTemp).length;
            setSgbCountsAverage(averageSgbCounts);
        }
    }, [summaryReportData, dataType, reportData]);

    const filteredData = useMemo(() => {
        let filtered = reportData.data || [];

        const brokenEvents = filtered
            .filter((item: TimeStat) => item.timePos__Field > 0)
            .map((item: TimeStat) => item.gameID);

        filtered = filtered.filter(
            (item: TimeStat) => !brokenEvents.includes(item.gameID)
        );

        // Apply category filter
        if (event !== '') {
            filtered = filtered.filter(
                (item: TimeStat) => item.gameID === event
            );
        }

        const sorted = sortBy(
            filtered,
            (item: TimeStat) => item.player && Number(item.player.uniformNumber)
        );
        return sorted;
    }, [reportData, event]); // Recalculate when rawData or filter changes

    const calculatedData = useMemo(() => {
        let calculated = calculateTotals(filteredData, 'playerID');

        calculated = calculated.map((data: any) => ({
            ...data,
            spbCounts: spbCounts[data.playerID] || 0,
            sgbCounts: sgbCounts[data.playerID] || 0,
        }));

        return calculated;
    }, [filteredData, sgbCounts, spbCounts]);

    const calculatePlayedCount = (row: TimeStat) => {
        let value = null;
        if (reportData && reportData.data) {
            value = reportData.data.filter(
                (o: TimeStat) => o.playerID === row.playerID && o.period === 1
            ).length;
        }

        return value;
    };

    const sortByGamesPlayed = ({ data, order }: ColumnSortEvent) =>
        order &&
        orderBy(
            data,
            (o) => calculatePlayedCount(o),
            order > 0 ? 'asc' : 'desc'
        );

    let [countAboveGameThreshold, setCountAboveGameThreshold] = useState(0); // State to hold countAboveThreshold
    const allGameValues: number[] = []; // Array to store all values
    // for game render
    const renderAverageSGBCells = (rowData: any, sgbCountsAverage: number) => {
        const value = rowData.sgbCounts;
        allGameValues.push(value);

        if (typeof value === 'string') {
            return <span>{value}</span>;
        }

        if (dataType === ReportDataTypes.Average) {
            // Calculate bench score
            const countAbove22 =
                (allGameValues.filter(
                    (val) =>
                        val > sgbCountsAverage + 22.5 ||
                        val < sgbCountsAverage - 22.5
                ).length /
                    2) *
                1;
            const countAbove1722 =
                (allGameValues.filter(
                    (val) =>
                        (val > sgbCountsAverage + 17.5 &&
                            val < sgbCountsAverage + 22.5) ||
                        (val < sgbCountsAverage - 17.5 &&
                            val > sgbCountsAverage - 22.5)
                ).length /
                    2) *
                2;
            const countAbove1217 =
                (allGameValues.filter(
                    (val) =>
                        (val > sgbCountsAverage + 12.5 &&
                            val < sgbCountsAverage + 17.5) ||
                        (val < sgbCountsAverage - 12.5 &&
                            val > sgbCountsAverage - 17.5)
                ).length /
                    2) *
                3;
            const countAbove712 =
                (allGameValues.filter(
                    (val) =>
                        (val > sgbCountsAverage + 7.5 &&
                            val < sgbCountsAverage + 12.5) ||
                        (val < sgbCountsAverage - 7.5 &&
                            val > sgbCountsAverage - 12.5)
                ).length /
                    2) *
                4;
            const countAbove07 =
                (allGameValues.filter(
                    (val) =>
                        (val > sgbCountsAverage + 0 &&
                            val < sgbCountsAverage + 7.5) ||
                        (val < sgbCountsAverage - 0 &&
                            val > sgbCountsAverage - 7.5)
                ).length /
                    2) *
                5;

            setCountAboveGameThreshold(
                Math.round(
                    ((countAbove22 +
                        countAbove1722 +
                        countAbove1217 +
                        countAbove712 +
                        countAbove07) /
                        (allGameValues.length / 2) /
                        5) *
                        100
                )
            );

            let color = '#6FBF42'; // Great

            if (
                value < sgbCountsAverage - 22.5 ||
                value > sgbCountsAverage + 22.5
            ) {
                color = '#CD2335'; // Below average
            } else if (
                value < sgbCountsAverage - 17.5 ||
                value > sgbCountsAverage + 17.5
            ) {
                color = '#EE5145'; //
            } else if (
                value < sgbCountsAverage - 12.5 ||
                value > sgbCountsAverage + 12.5
            ) {
                color = '#F08036'; //
            } else if (
                value < sgbCountsAverage - 7.5 ||
                value > sgbCountsAverage + 7.5
            ) {
                color = '#EDB024'; //
            }

            return (
                <Tag
                    style={{
                        width: '40px',
                        color: '#fff',
                        background: color,
                    }}
                >
                    {`${value}%`}
                </Tag>
            );
        } else {
            return <span>{value}</span>;
        }
    };

    let [countAbovePeriodThreshold, setCountAbovePeriodThreshold] = useState(0); // State to hold countAboveThreshold
    const allPeriodValues: number[] = []; // Array to store all values

    // for period render
    const renderAverageSPBCells = (rowData: any, spbCountsAverage: number) => {
        const value = rowData.spbCounts;
        allPeriodValues.push(value);
        if (typeof value === 'string') {
            return <span>{value}</span>;
        }

        if (dataType === ReportDataTypes.Average) {
            // Calculate bench score
            const countAbove22 =
                (allPeriodValues.filter(
                    (val) =>
                        val > spbCountsAverage + 22.5 ||
                        val < spbCountsAverage - 22.5
                ).length /
                    2) *
                1;
            const countAbove1722 =
                (allPeriodValues.filter(
                    (val) =>
                        (val > spbCountsAverage + 17.5 &&
                            val < spbCountsAverage + 22.5) ||
                        (val < spbCountsAverage - 17.5 &&
                            val > spbCountsAverage - 22.5)
                ).length /
                    2) *
                2;
            const countAbove1217 =
                (allPeriodValues.filter(
                    (val) =>
                        (val > spbCountsAverage + 12.5 &&
                            val < spbCountsAverage + 17.5) ||
                        (val < spbCountsAverage - 12.5 &&
                            val > spbCountsAverage - 17.5)
                ).length /
                    2) *
                3;
            const countAbove712 =
                (allPeriodValues.filter(
                    (val) =>
                        (val > spbCountsAverage + 7.5 &&
                            val < spbCountsAverage + 12.5) ||
                        (val < spbCountsAverage - 7.5 &&
                            val > spbCountsAverage - 12.5)
                ).length /
                    2) *
                4;
            const countAbove07 =
                (allPeriodValues.filter(
                    (val) =>
                        (val > spbCountsAverage + 0 &&
                            val < spbCountsAverage + 7.5) ||
                        (val < spbCountsAverage - 0 &&
                            val > spbCountsAverage - 7.5)
                ).length /
                    2) *
                5;

            setCountAbovePeriodThreshold(
                Math.round(
                    ((countAbove22 +
                        countAbove1722 +
                        countAbove1217 +
                        countAbove712 +
                        countAbove07) /
                        (allGameValues.length / 2) /
                        5) *
                        100
                )
            );

            let color = '#6FBF42'; // Great
            if (
                value < spbCountsAverage - 22.5 ||
                value > spbCountsAverage + 22.5
            ) {
                color = '#CD2335'; // Below average
            } else if (
                value < spbCountsAverage - 17.5 ||
                value > spbCountsAverage + 17.5
            ) {
                color = '#EE5145'; //
            } else if (
                value < spbCountsAverage - 12.5 ||
                value > spbCountsAverage + 12.5
            ) {
                color = '#F08036'; //
            } else if (
                value < spbCountsAverage - 7.5 ||
                value > spbCountsAverage + 7.5
            ) {
                color = '#EDB024'; //
            }

            return (
                <Tag
                    style={{
                        width: '40px',
                        color: '#fff',
                        background: color,
                    }}
                >
                    {`${value}%`}
                </Tag>
            );
        } else {
            return <span>{value}</span>;
        }
    };

    let footertitle: string = 'Bench Spread Ratings:';
    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    footer={footertitle}
                    colSpan={4}
                    footerStyle={{ textAlign: 'right' }}
                    style={{ backgroundColor: 'white' }}
                />
                {dataType !== ReportDataTypes.Average ? (
                    <Column
                        footer={`Select '% PER'`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                ) : (
                    <Column
                        footer={`${countAboveGameThreshold} / 100`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                )}
                {dataType !== ReportDataTypes.Average ? (
                    <Column
                        footer={`Select '% PER`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                ) : (
                    <Column
                        footer={`${countAbovePeriodThreshold} / 100`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                )}
            </Row>
        </ColumnGroup>
    );

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader title="Bench Starts Report" />
            <Toolbar start={leftToolbar} end={rightToolbar} />

            <DataTable
                ref={dataTable}
                removableSort
                showGridlines
                footerColumnGroup={footerGroup}
                value={
                    reportData.isLoading || reportData.isUninitialized
                        ? Array(5)
                        : calculatedData
                }
                emptyMessage={renderEmpty()}
                scrollable
                scrollHeight="100vh"
                columnResizeMode="expand"
                resizableColumns
                footer={tableFooter}
                exportFilename={`season-bench-start-report`}
                stripedRows={true}
            >
                <Column
                    header="#"
                    align="center"
                    alignHeader="center"
                    sortable
                    field="player.uniformNumber"
                    frozen
                    headerStyle={{ backgroundColor: 'white' }}
                />
                <Column
                    frozen
                    sortable
                    header="Player"
                    field="player.lastName"
                    body={playerData.isLoading ? <Skeleton /> : playerNameCell}
                    exportable={false}
                    headerStyle={{ backgroundColor: 'white' }}
                />
                <Column
                    header="First Name"
                    field="player.firstName"
                    hidden={true}
                />
                <Column
                    header="Last Name"
                    field="player.lastName"
                    hidden={true}
                />
                <Column
                    sortable
                    header="Group"
                    field="player.colour"
                    body={playerData.isLoading ? <Skeleton /> : playerGroupCell}
                    headerStyle={{ backgroundColor: 'white' }}
                />
                {event === '' && (
                    <Column
                        align="center"
                        alignHeader="center"
                        sortable
                        sortFunction={sortByGamesPlayed}
                        body={(row) =>
                            !row || reportData.isLoading ? (
                                <Skeleton />
                            ) : (
                                calculatePlayedCount(row)
                            )
                        }
                        header="Games"
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                )}
                <Column
                    align="center"
                    alignHeader="center"
                    sortable
                    header={renderHeaderCell('SGB')}
                    headerClassName="header-SGB"
                    field="sgbCounts"
                    body={(rowData: any) =>
                        renderAverageSGBCells(rowData, sgbCountsAverage)
                    }
                    headerStyle={{ backgroundColor: 'white' }}
                />
                <Column
                    align="center"
                    alignHeader="center"
                    sortable
                    header={renderHeaderCell('SPB')}
                    headerClassName="header-SPB"
                    field="spbCounts"
                    body={(rowData: any) =>
                        renderAverageSPBCells(rowData, spbCountsAverage)
                    }
                    headerStyle={{ backgroundColor: 'white' }}
                />
            </DataTable>
        </PageContainer>
    );
};

export default BenchStartReport;
