export enum Permissions {
    // 3 default roles
    OWNER_ROLE = 'OWNER_ROLE',
    ADMIN_ROLE = 'ADMIN_ROLE',
    VIEWER_ROLE = 'VIEWER_ROLE',

    // entity roles
    TEAM_OWNER = 'TEAM_OWNER',
    TEAM_ADMIN = 'TEAM_ADMIN',

    // users
    MANAGE_USERS_ALL = 'MANAGE_USERS_*',
    MANAGE_USERS_TEAMS_ALL = 'MANAGE_USERS_TEAMS_*',

    MANAGE_USERS_ORGANISATIONS = 'MANAGE_USERS_ORGANISATIONS',
    POST_USERS_ORGANISATIONS = 'POST_USERS_ORGANISATIONS',
    PUT_USERS_ORGANISATIONS = 'PUT_USERS_ORGANISATIONS',
    DELETE_USERS_ORGANISATIONS = 'DELETE_USERS_ORGANISATIONS',

    MANAGE_USERS_ASSOCIATIONS = 'MANAGE_USERS_ASSOCIATIONS',
    POST_USERS_ASSOCIATIONS = 'POST_USERS_ASSOCIATIONS',
    PUT_USERS_ASSOCIATIONS = 'PUT_USERS_ASSOCIATIONS',
    DELETE_USERS_ASSOCIATIONS = 'DELETE_USERS_ASSOCIATIONS',

    // roles
    GET_ROLES = 'GET_ROLES',
    POST_ROLES = 'POST_ROLES',
    PUT_ROLES = 'UPDATE_ROLES',
    DELETE_ROLES = 'DELETE_ROLES',
    MANAGE_ROLES = 'MANAGE_ROLES',
    GET_USERS_DETAILS = 'GET_USERS_DETAILS',

    // profiles
    MANAGE_PROFILE = 'MANAGE_PROFILE',
    MANAGE_USERS = 'MANAGE_USERS',

    // tickets
    GET_TICKETS = 'GET_TICKETS',
    GET_TICKETS_ACCEPT = 'GET_TICKETS_ACCEPT',
    GET_TICKETS_DECLINE = 'GET_TICKETS_DECLINE',

    // events
    GET_EVENTS = 'GET_EVENTS',
    POST_EVENTS = 'POST_EVENTS',
    PUT_EVENTS = 'PUT_EVENTS',
    DELETE_EVENTS = 'DELETE_EVENTS',
    MANAGE_EVENTS = 'MANAGE_EVENTS',

    // teams
    GET_TEAMS = 'GET_TEAMS',
    GET_TEAMS_ALL = 'GET_TEAMS_*',
    POST_TEAMS = 'POST_TEAMS',
    PUT_TEAMS = 'PUT_TEAMS',
    DELETE_TEAMS = 'DELETE_TEAMS',
    MANAGE_TEAMS = 'MANAGE_TEAMS',
    MANAGE_TEAMS_ALL = 'MANAGE_TEAMS_*',

    // design
    GET_DESIGN = 'GET_DESIGN',
    POST_DESIGN = 'POST_DESIGN',
    PUT_DESIGN = 'PUT_DESIGN',
    DELETE_DESIGN = 'DELETE_DESIGN',
    MANAGE_DESIGN = 'MANAGE_DESIGN',

    // kit
    GET_KIT = 'GET_KIT',
    POST_KIT = 'POST_KIT',
    PUT_KIT = 'PUT_KIT',
    DELETE_KIT = 'DELETE_KIT',
    MANAGE_KIT = 'MANAGE_KIT',

    // players
    GET_PLAYERS = 'GET_PLAYERS',
    POST_PLAYERS = 'POST_PLAYERS',
    PUT_PLAYERS = 'PUT_PLAYERS',
    DELETE_PLAYERS = 'DELETE_PLAYERS',
    MANAGE_PLAYERS = 'MANAGE_PLAYERS',
    POST_PLAYERS_USERS = 'POST_PLAYERS_USERS',
    DELETE_PLAYERS_USERS = 'DELETE_PLAYERS_USERS',
    MANAGE_PLAYERS_USERS = 'MANAGE_PLAYERS_USERS',

    // staff
    GET_STAFF = 'GET_STAFF',
    POST_STAFF = 'POST_STAFF',
    PUT_STAFF = 'PUT_STAFF',
    DELETE_STAFF = 'DELETE_STAFF',
    MANAGE_STAFF = 'MANAGE_STAFF',

    // organisations
    GET_ORGANISATIONS = 'GET_ORGANISATIONS',
    GET_ORGANISATIONS_ALL = 'GET_ORGANISATIONS_*',
    POST_ORGANISATIONS = 'POST_ORGANISATIONS',
    PUT_ORGANISATIONS = 'PUT_ORGANISATIONS',
    DELETE_ORGANISATIONS = 'DELETE_ORGANISATIONS',
    MANAGE_ORGANISATIONS = 'MANAGE_ORGANISATIONS',
    MANAGE_ORGANISATIONS_ALL = 'MANAGE_ORGANISATIONS_*',
    MANAGE_ORGANISATIONS_TEAMS_ALL = 'MANAGE_ORGANISATIONS_TEAMS_*',

    // associations
    GET_ASSOCIATIONS = 'GET_ASSOCIATIONS',
    GET_ASSOCIATIONS_ALL = 'GET_ASSOCIATIONS_*',
    MANAGE_ASSOCIATIONS = 'MANAGE_ASSOCIATIONS',
    MANAGE_ASSOCIATIONS_ALL = 'MANAGE_ASSOCIATIONS_*',

    // notes
    GET_NOTES = 'GET_NOTES',
    POST_NOTES = 'POST_NOTES',
    PUT_NOTES = 'PUT_NOTES',
    DELETE_NOTES = 'DELETE_NOTES',
    MANAGE_NOTES = 'MANAGE_NOTES',

    POST_NOTES_COLLABORATORS = 'POST_NOTES_COLLABORATORS',
    DELETE_NOTES_COLLABORATORS = 'DELETE_NOTES_COLLABORATORS',

    GET_NOTES_NODES = 'GET_NOTES_NODES',
    POST_NOTES_NODES = 'POST_NOTES_NODES',
    DELETE_NOTES_NODES = 'DELETE_NOTES_NODES',

    GET_NOTES_LABELS = 'GET_NOTES_LABELS',
    POST_NOTES_LABELS = 'POST_NOTES_LABELS',
    DELETE_NOTES_LABELS = 'DELETE_NOTES_LABELS',

    // licences
    GET_LICENCES = 'GET_LICENCES',
    POST_LICENCES = 'POST_LICENCES',
    DELETE_LICENCES = 'DELETE_LICENCES',
    MANAGE_LICENCES = 'MANAGE_LICENCES',

    POST_LICENCES_CHECKOUT = 'POST_LICENCES_CHECKOUT',

    GET_LICENCEGROUPS = 'GET_LICENCEGROUPS',
    MANAGE_LICENCEGROUPS = 'MANAGE_LICENCEGROUPS',
    GET_LICENCEGROUPS_HISTORY = 'GET_LICENCEGROUPS_HISTORY',

    GET_INVOICES = 'GET_INVOICES',
    GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS',
    DELETE_SUBSCRIPTIONS = 'DELETE_SUBSCRIPTIONS',
    MANAGE_SUBSCRIPTIONS = 'MANAGE_SUBSCRIPTIONS',

    // events
    GET_TEAMS_EVENTS = 'GET_TEAMS_EVENTS',
    POST_TEAMS_EVENTS = 'POST_TEAMS_EVENTS',
    PUT_TEAMS_EVENTS = 'PUT_TEAMS_EVENTS',
    DELETE_TEAMS_EVENTS = 'DELETE_TEAMS_EVENTS',
    MANAGE_TEAMS_EVENTS = 'MANAGE_TEAMS_EVENTS',

    GET_COMPETITIONS_EVENTS = 'GET_COMPETITIONS_EVENTS',
    POST_COMPETITIONS_EVENTS = 'POST_COMPETITIONS_EVENTS',
    PUT_COMPETITIONS_EVENTS = 'PUT_COMPETITIONS_EVENTS',
    DELETE_COMPETITIONS_EVENTS = 'DELETE_COMPETITIONS_EVENTS',
    MANAGE_COMPETITIONS_EVENTS = 'MANAGE_COMPETITIONS_EVENTS',

    // resources
    GET_RESOURCES = 'GET_RESOURCES',
    POST_RESOURCES = 'POST_RESOURCES',
    PUT_RESOURCES = 'PUT_RESOURCES',
    DELETE_RESOURCES = 'DELETE_RESOURCES',
    MANAGE_RESOURCES = 'MANAGE_RESOURCES',

    GET_FOLDERS = 'GET_FOLDERS',
    POST_FOLDERS = 'POST_FOLDERS',
    PUT_FOLDERS = 'PUT_FOLDERS',
    DELETE_FOLDERS = 'DELETE_FOLDERS',
    MANAGE_FOLDERS = 'MANAGE_FOLDERS',

    POST_PICTURE = 'POST_PICTURE',
    GET_RESOURCES_FILE = 'GET_RESOURCES_FILE',
    GET_RESOURCES_LOGS = 'GET_RESOURCES_LOGS',

    // sports
    GET_SPORTS = 'GET_SPORTS',
    POST_SPORTS = 'POST_SPORTS',
    PUT_SPORTS = 'PUT_SPORTS',
    DELETE_SPORTS = 'DELETE_SPORTS',
    MANAGE_SPORTS = 'MANAGE_SPORTS',

    GET_SPORTS_STATS = 'GET_SPORTS_STATS',
    POST_SPORTS_STATS = 'POST_SPORTS_STATS',
    PUT_SPORTS_STATS = 'PUT_SPORTS_STATS',
    DELETE_SPORTS_STATS = 'DELETE_SPORTS_STATS',
    MANAGE_SPORTS_STATS = 'MANAGE_SPORTS_STATS',

    GET_SPORTS_STATVIEWS = 'GET_SPORTS_STATVIEWS',
    POST_SPORTS_STATVIEWS = 'POST_SPORTS_STATVIEWS',
    PUT_SPORTS_STATVIEWS = 'PUT_SPORTS_STATVIEWS',
    DELETE_SPORTS_STATVIEWS = 'DELETE_SPORTS_STATVIEWS',
    MANAGE_SPORTS_STATVIEWS = 'MANAGE_SPORTS_STATVIEWS',

    GET_SPORTS_FORMATIONS = 'GET_SPORTS_FORMATIONS',
    POST_SPORTS_FORMATIONS = 'POST_SPORTS_FORMATIONS',
    PUT_SPORTS_FORMATIONS = 'PUT_SPORTS_FORMATIONS',
    DELETE_SPORTS_FORMATIONS = 'DELETE_SPORTS_FORMATIONS',
    MANAGE_SPORTS_FORMATIONS = 'MANAGE_SPORTS_FORMATIONS',

    GET_SPORTS_ENTITYCATEGORIES = 'GET_SPORTS_ENTITYCATEGORIES',
    POST_SPORTS_ENTITYCATEGORIES = 'POST_SPORTS_ENTITYCATEGORIES',
    PUT_SPORTS_ENTITYCATEGORIES = 'PUT_SPORTS_ENTITYCATEGORIES',
    DELETE_SPORTS_ENTITYCATEGORIES = 'DELETE_SPORTS_ENTITYCATEGORIES',
    MANAGE_SPORTS_ENTITYCATEGORIES = 'MANAGE_SPORTS_ENTITYCATEGORIES',

    GET_SPORTS_POSITIONS = 'GET_SPORTS_POSITIONS',
    POST_SPORTS_POSITIONS = 'POST_SPORTS_POSITIONS',
    PUT_SPORTS_POSITIONS = 'PUT_SPORTS_POSITIONS',
    DELETE_SPORTS_POSITIONS = 'DELETE_SPORTS_POSITIONS',
    MANAGE_SPORTS_POSITIONS = 'MANAGE_SPORTS_POSITIONS',

    // games
    MANAGE_GAMES = 'MANAGE_GAMES',
    MANAGE_GAMES_LINEUPS = 'MANAGE_GAMES_LINEUPS',

    // games lineup
    GET_LINEUPS = 'GET_LINEUPS',
    POST_LINEUPS = 'POST_LINEUPS',
    PUT_LINEUPS = 'PUT_LINEUPS',
    DELETE_LINEUPS = 'DELETE_LINEUPS',
    MANAGE_LINEUPS = 'MANAGE_LINEUPS',

    // games season
    GET_SEASONS = 'GET_SEASONS',
    POST_SEASONS = 'POST_SEASONS',
    PUT_SEASONS = 'PUT_SEASONS',
    DELETE_SEASONS = 'DELETE_SEASONS',
    MANAGE_SEASONS = 'MANAGE_SEASONS',

    // games stuff
    GET_SCREEN_FIELD = 'GET_SCREEN_FIELD',
    GET_SCREEN_LIST = 'GET_SCREEN_LIST',
    GET_SCREEN_PLAN_MODE = 'GET_SCREEN_PLAN_MODE',
    GET_PANEL_GAME = 'GET_PANEL_GAME',
    GET_PANEL_CLOCK = 'GET_PANEL_CLOCK',
    GET_PANEL_STATS = 'GET_PANEL_STATS',
    GET_PANEL_ACTIVITY = 'GET_PANEL_ACTIVITY',
    GET_PANEL_NOTES = 'GET_PANEL_NOTES',
    GET_PANEL_ATHLETE_SHELF = 'GET_PANEL_ATHLETE_SHELF',

    //
    POST_NOTES_TEAM = 'POST_NOTES_TEAM',
    MANAGE_NOTES_TEAM = 'MANAGE_NOTES_TEAM',
    POST_SCORE_STAT_LOG_TEAM = 'POST_SCORE_STAT_LOG_TEAM',
    POST_SCORE_STAT_LOG_PLAYER = 'POST_SCORE_STAT_LOG_PLAYER',
    POST_NOSCORE_STAT_LOG_TEAM = 'POST_NOSCORE_STAT_LOG_TEAM',
    POST_NOSCORE_STAT_LOG_PLAYER = 'POST_NOSCORE_STAT_LOG_PLAYER',
    MANAGE_CLOCKS = 'MANAGE_CLOCKS',

    //
    GET_PANEL_QUEUE = 'GET_PANEL_QUEUE',
    POST_SUBSTITUTION_QUEUED = 'POST_SUBSTITUTION_QUEUED',
    EXECUTE_SUBSTITUTION = 'EXECUTE_SUBSTITUTION',
    POST_ROTATION_QUEUED = 'POST_ROTATION_QUEUED',
    EXECUTE_ROTATION = 'EXECUTE_ROTATION',

    // Tickets
    GET_ACCEPT = 'GET_ACCEPT',
    GET_DECLINE = 'GET_DECLINE',

    // Posts
    GET_POSTS = 'GET_POSTS',
    POST_POSTS = 'POST_POSTS',
    PUT_POSTS = 'PUT_POSTS',
    DELETE_POSTS = 'DELETE_POSTS',
    MANAGE_POSTS = 'MANAGE_POSTS',
}
