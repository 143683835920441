import { useMemo, useRef } from 'react';

import { getFirstChars, getTimeAgo } from '../../util/helper';

import { Avatar } from 'primereact/avatar';
import { classNames } from 'primereact/utils';
//import { OverlayPanel } from 'primereact/overlaypanel';

import { BaseEntityType } from '../../types/common';
import { Post } from '../../types/posts';
import RookieButton from '../../components/RookieButton';
import { Menu } from 'primereact/menu';
import usePermission from '../../hooks/usePermission';
import { Permissions } from '../../types/permissions';
import ImageGrid from '../../components/ImageGrid';
import { useGetEntityQuery } from '../../api/core';

interface Props {
    post: Post;
    entityType?: BaseEntityType;
    entityID?: string;
    onEdit?: (post: Post) => void;
    onDelete?: (postID: string) => void;
}

const reactionsConfig = {
    angryReact: {
        label: 'Angry',
        icon: '😠',
    },
    applauseReact: {
        label: 'Applaud',
        icon: '👏',
    },
    celebrationReact: {
        label: 'Celebrate',
        icon: '🥳',
    },
    heartReact: {
        label: 'Love',
        icon: '❤️',
    },
    laughReact: {
        label: 'Laugh',
        icon: '😂',
    },
    sadReact: {
        label: 'Sad',
        icon: '😥',
    },
    surpriseReact: {
        label: 'Suprised',
        icon: '😯',
    },
    thumbsUpReact: {
        label: 'Like',
        icon: '👍',
    },
};

const PostItem = ({ post, entityType, entityID, onDelete, onEdit }: Props) => {
    //const reactionPopover = useRef<OverlayPanel>(null);
    //const reactionBtn = useRef(null);
    const moreMenu = useRef<Menu>(null);

    const { checkPermission } = usePermission(entityID);
    const isEntityOwner = post.createdBy !== post.ownerID;

    const { data: entityData } = useGetEntityQuery(
        {
            // @ts-expect-error
            entityID: entityID,
            // @ts-expect-error
            entityType: entityType,
            expand: 'jumperKit',
        },
        {
            skip: !entityID || !entityType || post.createdBy === post.ownerID,
        }
    );

    const permissions = {
        canCreate: checkPermission([
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.POST_POSTS,
        ]),
        canDelete: checkPermission([
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.DELETE_POSTS,
        ]),
        canEdit: checkPermission([
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.PUT_POSTS,
        ]),
        canView: checkPermission([
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_POSTS,
        ]),
    };

    const reactionsSummary = useMemo(() => {
        return Object.keys(reactionsConfig).reduce(
            (result, key) => {
                const stat =
                    post.postStats[key as keyof typeof reactionsConfig];
                result.total += stat;

                if (stat > 0) {
                    result.reactions.push(key);
                }

                return result;
            },
            {
                total: 0,
                reactions: [],
            } as {
                total: number;
                reactions: string[];
            }
        );
    }, [post.postStats]);

    const contentClassName = classNames('post-content', {
        'is-large': post.content.length < 80,
    });

    //const activeReaction = null;

    const ownerName = useMemo(() => {
        if (isEntityOwner && entityData?.data) {
            switch (entityType) {
                case BaseEntityType.teams:
                    return entityData.data.teamName;
                case BaseEntityType.organisations:
                    return entityData.data.organisationName;
                case BaseEntityType.associations:
                    return entityData.data.associationName;
            }
        } else {
            return post.creatorDetails?.name;
        }
    }, [entityData, entityType, isEntityOwner, post]);

    const ownerPicture = useMemo(() => {
        if (isEntityOwner && entityData?.data) {
            return entityData.data.jumperKit;
        } else {
            return post.creatorDetails?.picture;
        }
    }, [entityData, isEntityOwner, post]);

    return (
        <>
            <article className="post-item">
                <header className="post-header">
                    <Avatar
                        image={ownerPicture}
                        template={
                            isEntityOwner &&
                            ownerPicture && (
                                <span
                                    className="svg-image"
                                    dangerouslySetInnerHTML={{
                                        __html: ownerPicture,
                                    }}
                                />
                            )
                        }
                        label={
                            ownerName &&
                            getFirstChars(ownerName, 2).toUpperCase()
                        }
                        shape={isEntityOwner ? 'square' : 'circle'}
                        size="large"
                    />
                    <div className="post-header-details">
                        <h4 className="post-header-author">{ownerName}</h4>
                        <div className="post-header-time">
                            {getTimeAgo(post.createdAt, false)}
                        </div>
                    </div>

                    {(permissions.canEdit || permissions.canDelete) && (
                        <div className="post-header-actions">
                            <Menu
                                ref={moreMenu}
                                model={[
                                    ...(permissions.canEdit && onEdit
                                        ? [
                                              {
                                                  label: 'Edit',
                                                  command: () => onEdit(post),
                                              },
                                          ]
                                        : []),
                                    ...(permissions.canDelete && onDelete
                                        ? [
                                              {
                                                  label: 'Delete',
                                                  command: () =>
                                                      onDelete(post.postID),
                                              },
                                          ]
                                        : []),
                                ]}
                                popup
                                popupAlignment="right"
                            />
                            <RookieButton
                                onClick={(event) =>
                                    moreMenu.current &&
                                    moreMenu.current.toggle(event)
                                }
                                icon="more_horiz"
                                size="small"
                                text
                            />
                        </div>
                    )}
                </header>
                {post.attachments && post.attachments.length > 0 && (
                    <div className="post-media">
                        <ImageGrid images={post.attachments} />
                    </div>
                )}
                <div className={contentClassName}>{post.content}</div>
                {reactionsSummary.total > 0 && (
                    <div className="post-stats">
                        {reactionsSummary.total}
                        {reactionsSummary.reactions.map((reaction) => {
                            const stat =
                                reactionsConfig[
                                    reaction as keyof typeof reactionsConfig
                                ];

                            return stat.icon;
                        })}
                    </div>
                )}
                {/*
                <footer className="post-footer">
                    <div className="post-actions">
                        <div className="post-footer-action">
                            <RookieButton icon="comment" label="Comment" text />
                        </div>
                        <div
                            className="post-footer-action"
                            onMouseEnter={(e) =>
                                reactionPopover?.current?.show(
                                    e,
                                    reactionBtn.current
                                )
                            }
                            onMouseLeave={(e) =>
                                reactionPopover?.current?.hide()
                            }
                        >
                            <RookieButton
                                ref={reactionBtn}
                                label={
                                    activeReaction &&
                                    reactionsConfig[activeReaction]
                                        ? reactionsConfig[activeReaction].label
                                        : 'Like'
                                }
                                text={true}
                                icon={
                                    activeReaction ? (
                                        <span className="icon p-button-icon p-button-icon-left">
                                            😥
                                        </span>
                                    ) : (
                                        'thumb_up'
                                    )
                                }
                            />
                            <OverlayPanel
                                ref={reactionPopover}
                                appendTo="self"
                                className="reactions-popover"
                            >
                                <div className="p-button-group">
                                    {Object.keys(reactionsConfig).map((key) => {
                                        const reaction =
                                            reactionsConfig[
                                                key as keyof typeof reactionsConfig
                                            ];

                                        return (
                                            <RookieButton
                                                className="reaction-button"
                                                label={reaction.icon}
                                                text
                                            />
                                        );
                                    })}
                                </div>
                            </OverlayPanel>
                        </div>
                    </div>
                    <div className="post-comments">
                        <div className="post-comments-composer">
                            <Avatar
                                image={author?.picture}
                                label={getFirstChars(
                                    `${author?.firstName} ${author?.lastName}`,
                                    2
                                ).toUpperCase()}
                                shape="circle"
                            />
                            <div className="p-inputgroup">
                                <InputTextarea rows={1} autoResize />
                                <div className="p-inputgroup-addon">
                                    <RookieButton icon="send" text />
                                </div>
                            </div>
                        </div>
                        <div className="post-comments-body">
                            <div className="post-comment">
                                <div className="post-comment-header">
                                    <Avatar label="AB" shape="circle" />
                                    <div className="comment-header-details">
                                        <h4 className="comment-header-author">
                                            Jonathan Doe
                                        </h4>
                                        <div className="comment-header-time">
                                            2 days ago
                                        </div>
                                    </div>
                                    <div className="comment-header-actions">
                                        <RookieButton
                                            icon="more_horiz"
                                            size="small"
                                            text
                                        />
                                    </div>
                                </div>
                                <div className="post-comment-body">
                                    Nunc dapibus ex eu libero congue, nec
                                    aliquam risus elementum. Sed dignissim est
                                    quis arcu condimentum consequat.
                                </div>
                                <div className="post-comment-footer"></div>
                            </div>
                        </div>
                    </div>
                </footer>
            */}
            </article>
        </>
    );
};

export default PostItem;
