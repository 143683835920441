import { useContext, useState } from 'react';
import { Formik, FormikProps, FormikHelpers, FormikErrors } from 'formik';
import { pick } from 'lodash';

import { ToastContext } from '../../contexts/ToastContext';

import {
    useUpdateProfileMutation,
    useVerifyProfileMutation,
} from '../../api/profiles';

import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';

import RookieButton from '../../components/RookieButton';
import FormGroup from '../../components/FormGroup';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import { Avatar } from 'primereact/avatar';
import { ToastMessage } from 'primereact/toast';

import { Me, MeForm } from '../../types/user';
import PageContainer from '../../layout/PageContainer';
import Loader from '../../components/Loader';
import ErrorDisplay from '../../components/ErrorDisplay';

interface Props {
    user?: Me;
    isLoading: boolean;
    isError: boolean;
}

const requiredFields: Array<keyof MeForm> = [
    'firstName',
    'lastName',
    'picture',
    'email',
];

const AccountView = ({ user, isLoading, isError }: Props) => {
    const toast = useContext(ToastContext);

    const [isVerifying, setIsVerifying] = useState(false);

    const [updateProfile] = useUpdateProfileMutation();
    const [verifyProfile] = useVerifyProfileMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleSubmit = (
        data: MeForm,
        { setSubmitting }: FormikHelpers<MeForm>
    ) => {
        if (user?.userID) {
            setSubmitting(true);

            updateProfile({ userID: user.userID, ...data })
                .then(() => {
                    showToast({
                        severity: 'success',
                        detail: 'Successfully updated profile.',
                    });
                })
                .catch(() => {
                    showToast({
                        severity: 'error',
                        detail: 'Error updating profile.',
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
    };

    const handleVerify = () => {
        if (user?.userID) {
            setIsVerifying(true);

            verifyProfile({ userID: user?.userID })
                .then(() => {
                    showToast({
                        severity: 'success',
                        detail: 'Successfully verified profile.',
                    });
                })
                .catch(() => {
                    showToast({
                        severity: 'error',
                        detail: 'Error verifying profile.',
                    });
                })
                .finally(() => {
                    setIsVerifying(false);
                });
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    if (isError || !user) {
        return (
            <ErrorDisplay
                title={!user ? 'No user found.' : 'An error has occurred'}
            />
        );
    }

    const canEditCore = user.loginSource === 'Email/Password';
    const emailVerified = user.emailVerified && user.emailVerified !== 'false';

    return (
        <PageContainer size="narrow">
            {!canEditCore && (
                <Message
                    text={
                        'Please note, you will need to edit these details on your ' +
                        user.loginSource +
                        ' account.'
                    }
                    severity={'info'}
                    style={{ width: '100%' }}
                />
            )}
            <Formik
                initialValues={pick(user, requiredFields)}
                validate={(values: MeForm) => {
                    const errors: FormikErrors<MeForm> = {};

                    requiredFields.forEach((field) => {
                        if (!values[field]) {
                            errors[field] = 'Required';
                        }
                    });
                    return errors;
                }}
                onSubmit={(values, formikHelpers) =>
                    handleSubmit(values, formikHelpers)
                }
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }: FormikProps<MeForm>) => (
                    <form onSubmit={handleSubmit}>
                        <FormFields>
                            <FormGroup
                                error={errors.picture}
                                showError={!!errors.picture && touched.picture}
                            >
                                <div style={{ margin: '2rem auto 0' }}>
                                    <Avatar
                                        image={values.picture}
                                        shape="circle"
                                        size="xlarge"
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label="First Name"
                                htmlFor="firstName"
                                error={errors.firstName}
                                showError={
                                    !!errors.firstName && touched.firstName
                                }
                            >
                                <InputText
                                    id="firstName"
                                    type="text"
                                    onInput={handleChange}
                                    value={values.firstName}
                                    disabled={!canEditCore}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Last Name"
                                htmlFor="lastName"
                                error={errors.lastName}
                                showError={
                                    !!errors.lastName && touched.lastName
                                }
                            >
                                <InputText
                                    id="lastName"
                                    type="text"
                                    onInput={handleChange}
                                    value={values.lastName}
                                    disabled={!canEditCore}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Email"
                                htmlFor="email"
                                message={
                                    !emailVerified
                                        ? 'This email has not been verified. Please check your email to find your verification link.'
                                        : ''
                                }
                                error={errors.email}
                                showError={!!errors.email && touched.email}
                            >
                                <div className="p-inputgroup">
                                    <InputText
                                        id="email"
                                        type="text"
                                        onInput={handleChange}
                                        value={values.email}
                                        disabled={true}
                                    />
                                    {!emailVerified && (
                                        <RookieButton
                                            severity="warning"
                                            onClick={handleVerify}
                                            disabled={isVerifying}
                                            label="Verify Email"
                                        />
                                    )}
                                </div>
                            </FormGroup>
                        </FormFields>
                        <FormActions
                            end={
                                <RookieButton
                                    type="submit"
                                    disabled={isSubmitting || !canEditCore}
                                    loading={isSubmitting}
                                    label="Save"
                                />
                            }
                        />
                    </form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default AccountView;
