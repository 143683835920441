import { groupBy } from 'lodash';
import { GameSummary } from '../../types/reports';
import Icon from '../../components/Icon';
import { Tag } from 'primereact/tag';

interface Data {
    [key: string]: any;
}

export const calculateAverages = (data: Data, group: string, count: number) => {
    return Object.values(groupBy(data, group)).map((item) => {
        const totals = item.reduce((result, curr) => {
            Object.keys(curr).forEach((k) => {
                if (curr.hasOwnProperty(k)) {
                    result[k] =
                        typeof curr[k] === 'number'
                            ? (result[k] || 0) + curr[k]
                            : curr[k];
                }
            });
            return result;
        }, {});

        let averages: Data = {};
        Object.keys(totals).forEach((k) => {
            averages[k] =
                typeof totals[k] === 'number' ? totals[k] / count : totals[k];
        });

        return averages;
    });
};

export const calculateTotals = (data: Data, group: string) => {
    return Object.values(groupBy(data, group)).map((item) => {
        return item.reduce((result, curr) => {
            Object.keys(curr).forEach((k) => {
                result[k] =
                    typeof curr[k] === 'number'
                        ? (result[k] || 0) + curr[k]
                        : curr[k];
            });
            return result;
        }, {});
    });
};



// Define the new Result column logic
export const renderResult = (rowData: GameSummary) => {
    if (rowData.wins === 1) {
        return (
            <Icon
                name="check"
                size="large"
                style={{
                    color: 'white',
                    backgroundColor: 'green',
                    borderRadius: '50%',
                }}
            />
        );
    } else if (rowData.losses === 1) {
        return (
            <Icon
                name="close"
                size="large"
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                }}
            />
        );
    } else if (rowData.draws === 1) {
        return (
            <Tag
                style={{
                    width: '30px',
                    height: '30px',
                    color: '#fff',
                    background: '#808080',
                }}
            >
                D
            </Tag>
        );
    } else {
        return null; // Handle default case if needed
    }
};
